import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";

@injectable()
export class YinzCamAppServerAuxiliaryHistory extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;
    return {
      players: [].concat(data?.ArrayOfAuxHistoryXml?.AuxHistoryXml).filter(obj => obj.Id)
    };
  }
}
