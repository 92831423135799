<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
import TextInput from "../TextInput/TextInput.svelte";
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { CardsPageContext, CardsPageContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { JanusSignonManager, JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import { onMount } from 'svelte/internal';
const signonManager = getToken(ROOT, JanusSignonManagerToken);
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const pageContext = getContext(CardsPageContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: firstNameKey = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.firstKey) || 'firstName';
$: lastNameKey = ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.secondKey) || 'lastName';
$: selectedValue = firstNameKey ? ($mergedParams || {})[firstNameKey] : null;
let firstname = '', lastname = '';
$: console.log('NameInputField: ', selectedValue);
const handleFirstNameChange = (e) => {
    firstname = e.target.value;
    console.log('firstName: ', firstname);
    handleChange();
};
const handleLastNameChange = (e) => {
    lastname = e.target.value;
    console.log('lastName: ', lastname);
    handleChange();
};
const handleChange = () => {
    tabContext.setParam(firstNameKey, firstname);
    tabContext.setParam(lastNameKey, lastname);
};
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    const profileData = yield signonManager.getUserProfileSegment();
    const data = (profileData === null || profileData === void 0 ? void 0 : profileData.data) || {};
    firstname = data.first_name;
    lastname = data.last_name;
    tabContext.setParam(firstNameKey, firstname);
    tabContext.setParam(lastNameKey, lastname);
}));
</script>
<CardBase {element}>
    <div class="name-input-field-wrapper">
        <TextInput placeholder="First Name" value={firstname} onChange={handleFirstNameChange} />
        <TextInput placeholder="Last Name" value={lastname} onChange={handleLastNameChange}/>
    </div>
</CardBase>

<style>.name-input-field-wrapper {
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
}</style>