<Boundary>
  <div bind:this={wrapper}>
    <CardsComponentWrapper style="
      {buildCssPadding(tab.padding)}
      {buildCssMargin(tab.padding)}
      {buildCssBackground(tab.background)}
    " {editorOptions}>
      {#if contentStore && $contentStore}
        <CardsSections parentSequenceId={sequenceId} sections={$contentStore.sections} updater={(sections) => tab.sections = sections} />      
      {/if}
    </CardsComponentWrapper>
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { YinzCamCardsService, YinzCamCardsServiceRegion, YinzCamCardsServiceSection, YinzCamCardsServiceSource, YinzCamCardsServiceTab, YinzCamCardsServiceToken } from "yinzcam-cards";
import CardsSections from "./CardsSections.svelte";
import { buildCssPadding, buildCssBackground, buildCssMargin } from '../utilities/index';
import { getContext, onMount, setContext } from "svelte";
import { CardsPageContext, CardsPageContextKey, CardsTabContext, CardsTabContextKey } from "./context";
import { CardsDataSourceManager } from "./CardsDataSourceManager";
import ROOT from "../../../inversify.config";
import { Readable, writable } from "svelte/store";
import { getToken } from "inversify-token";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { JanusModeContextManagerToken } from "../../../js/mode";
import { MergedYinzCamAPIRequestParameterComponent, YinzCamAPIRequestParameters } from "yinzcam-api";
import { AbstractReactiveMicrocomponent, ControlBase, ManualPassthrough } from "yinzcam-rma";
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import { CardsEditorComponentOptions } from "./CardsEditorInterfaces";
import { CARDS_TAB_SCHEMA, generateTabDataSourcesSchema } from "./CardsEditorComponentSchemata";
export let parentSequenceId = null;
export let sequenceId = "";
export let tab;
export let updater;
const cardsService = getToken(ROOT, YinzCamCardsServiceToken);
const dataSourceManager = new CardsDataSourceManager(ROOT);
const pageContext = getContext(CardsPageContextKey);
const defaultParamsComp = pageContext.defaultParamsComp;
const modeParamsComp = getToken(ROOT, JanusModeContextManagerToken).getMergedRequestParameterComponent();
const pageParamsComp = new ManualPassthrough({ name: `CardsTab_${tab.id}_PageParams`, saveToLocalStorage: false }, { params: pageContext.query });
const internalParamsComp = new ManualPassthrough({ name: `CardsTab_${tab.id}_InternalParams`, saveToLocalStorage: false }, { params: {} });
const mergedApiParamsComp = new MergedYinzCamAPIRequestParameterComponent({ name: `CardsTab_${tab.id}_MergedApiParams`, saveToLocalStorage: false }, defaultParamsComp, modeParamsComp, pageParamsComp, internalParamsComp);
const pathParamsMappingComp = pageContext.pathParamsMappingComp;
const mergedParamsComp = new class extends AbstractReactiveMicrocomponent {
    constructor() {
        super(...arguments);
        this.lastParams = undefined;
    }
    update($control, $apiParams, $pathParamsMap) {
        return __awaiter(this, void 0, void 0, function* () {
            const newParams = $apiParams === null || $apiParams === void 0 ? void 0 : $apiParams.params;
            //console.log('TAB API PARAMS', newParams);
            for (let [i, param] of $pathParamsMap.entries()) {
                if (!param)
                    continue;
                const subst = newParams[`path${i}`];
                if (!subst)
                    continue;
                newParams[param] = subst;
            }
            if (this.lastParams === newParams || _.isEqual(this.lastParams, newParams)) {
                throw new Error("params have not changed");
            }
            this.lastParams = newParams;
            return newParams;
        });
    }
}({ name: `CardsTab_${tab.id}_MergedParams`, saveToLocalStorage: false, expectThrowFromUpdate: true }, mergedApiParamsComp, pathParamsMappingComp);
const mergedParamsStore = mergedParamsComp.store;
const setParam = function (key, value) {
    if (!key) {
        return;
    }
    let apiParams = internalParamsComp.getValue();
    if (!(apiParams === null || apiParams === void 0 ? void 0 : apiParams.params)) {
        apiParams = { params: {} };
    }
    if (!value) {
        delete apiParams.params[key];
    }
    else {
        apiParams.params[key] = value;
    }
    internalParamsComp.setValue(apiParams);
};
const scrollContainer = writable(document.body);
setContext(CardsTabContextKey, { dataSourceManager, mergedParamsComp, setParam, scrollContainer });
let contentStore;
let wrapper;
$: fragmentId = tab === null || tab === void 0 ? void 0 : tab.fragmentId;
$: sections = tab === null || tab === void 0 ? void 0 : tab.sections;
$: if (sections) {
    try {
        if (fragmentId) {
            console.log('cannot define both sections and fragment ID for a tab');
        }
        else {
            contentStore = writable(tab);
        }
    }
    finally { }
}
$: if (fragmentId) {
    try {
        if (sections) {
            console.log('cannot define both sections and fragment ID for a tab');
        }
        else {
            contentStore = cardsService.getCardsFragment(fragmentId).store;
        }
    }
    finally { }
}
$: if (contentStore && !_.isNil($contentStore) && mergedParamsStore && !_.isNil($mergedParamsStore)) {
    try {
        dataSourceManager.setDataSources($contentStore.sources, $mergedParamsStore);
    }
    finally { }
}
function generateSchema(component) {
    return __awaiter(this, void 0, void 0, function* () {
        //console.log('GENERATE SCHEMA', JSON.stringify(component, null, 2));
        const schema = _.cloneDeep(CARDS_TAB_SCHEMA);
        schema.properties.sources = yield generateTabDataSourcesSchema(component.sources);
        //await new Promise(resolve => setTimeout(resolve, 1000));
        //console.log('RESULT SCHEMA', schema); 
        return schema;
    });
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: tab.id,
    component: tab,
    componentTypeName: 'Tab',
    primaryColor: '#F3BD1C',
    reverseTextColor: 'black',
    childrenKey: 'sections',
    /*configSpec: CARDS_TAB_SCHEMA,*/
    configSpecGenerator: generateSchema,
    allowChildren: true,
    allowDeletion: false,
    allowDuplication: false,
    allowMovement: false,
    updater
};
function findScrollContainer() {
    let el = wrapper;
    while (el = el.parentElement) {
        let style = getComputedStyle(el);
        if (String(style === null || style === void 0 ? void 0 : style.overflow).toLowerCase() === 'auto') {
            scrollContainer.set(el);
            break;
        }
    }
}
onMount(() => {
    findScrollContainer();
});
</script>
