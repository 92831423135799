import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import { dummy_rounds_data } from "./dummy";

@injectable()
export class PGAPickList extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse): any {
    let data = response?.data as any;
    let rounds = this.isTooltipMode() ? dummy_rounds_data.rounds :  data?.rounds || [];
    rounds.sort((round1, round2) => {
      if (round1.type > round2.type) {
        return -1;
      } else if (round1.type < round2.type) {
        return 1;
      }
      return 0;
    });
    for (let i = 0; i < rounds.length; i++) {
      rounds[i].index = i;
    }
    return rounds;
  }

   //determine if we will show tooltips or not
   protected isTooltipMode = () => {
      let isRead = localStorage.getItem('isTooltipRead');
      let previousTime = localStorage.getItem('previousTooltipTime');
      if (isRead) {
          return false;
      } else {
          return true;
      }
  }
}
