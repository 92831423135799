<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d;
import { getCities, getCountries, getStates } from '../../../../sso/api/countryApi';
import Item from './Item.svelte';
import Select from 'svelte-select';
import TextInput from "../TextInput/TextInput.svelte";
import { afterUpdate, onMount } from 'svelte';
import { Popup, Link } from 'framework7-svelte';
import Location from './Location.svelte';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { JanusSignonManager, JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
export let element;
export let sources;
export let sourceStore;
const signonManager = getToken(ROOT, JanusSignonManagerToken);
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
let formData = {
    address_country: '',
    address_street: '',
    address_street_1: '',
    address_city: '',
    address_division_1: '',
    address_postal: '',
    phone: ''
};
$: enableLocationService = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.enableLocationService) || false;
$: key = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.key;
$: isPhoneRequiredKey = (_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.isPhoneRequiredKey;
$: isPhoneRequired = isPhoneRequiredKey ? ($mergedParams || {})[isPhoneRequiredKey] : '';
let countryAsync = getCountries('', true);
let cityAsync = getCities('', 'US', 'PA');
let stateAsync = getStates('', 'US');
let states = [];
let cities = [];
$: console.log('STATES UPDATE', states);
const getOptionLabel = (option) => (option.name ? option.name : option);
const getSelectionLabel = (option) => (option.name ? option.name : option);
let isClearable = true;
let isDisabled = false;
let wrapperElement;
onMount(() => {
    let parent = wrapperElement;
    const _parent = wrapperElement.closest('.wrapper').parentElement;
    _parent.style.zIndex = "1";
    let i = 0;
    while (parent) {
        parent.style.overflow = 'visible';
        i++;
        if (i === 3) {
            parent.style.zIndex = '1';
        }
        parent = parent.parentElement;
    }
});
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    const profileData = yield signonManager.getUserProfileSegment();
    const data = (profileData === null || profileData === void 0 ? void 0 : profileData.data) || {};
    formData = Object.assign(Object.assign({}, data), { address_street: data.address_street_1, address_division_1: data.address_division_1 || data.address_division_1 });
    console.log('formData: ', formData);
    tabContext.setParam(key, JSON.stringify(formData));
    cityAsync = getCities('', formData.address_country || 'US', formData.address_division_1);
    stateAsync = getStates('', formData.address_country);
    stateAsync.then(res => states = res);
    cityAsync.then(res => cities = res);
}));
//generator for selected item of Select component. calculate item based on value
const findSelectedValue = (v, optionIdentifier, items) => {
    //console.log('findSelectedValue items', items);
    if (!(items === null || items === void 0 ? void 0 : items.find)) {
        return;
    }
    let item = items.find((item) => {
        return item[optionIdentifier] === v;
    });
    return item;
};
const handleStreetChange = (e) => {
    formData.address_street = formData.address_street_1 = e.target.value;
    tabContext.setParam(key, JSON.stringify(formData));
};
const handlePostalCodeChange = (e) => {
    formData.address_postal = e.target.value;
    tabContext.setParam(key, JSON.stringify(formData));
};
const handlePhoneNumberChange = (e) => {
    formData.phone = e.target.value;
    tabContext.setParam(key, JSON.stringify(formData));
};
const onCountrySelect = (e) => {
    if (e.detail) {
        const selectedItem = e.detail;
        cityAsync = getCities('', (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.iso2) || 'US');
        stateAsync = getStates('', (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.iso2) || 'US');
        stateAsync.then(res => states = res);
        cityAsync.then(res => cities = res);
        formData.address_country = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.iso2;
        tabContext.setParam(key, JSON.stringify(formData));
    }
    else {
        cityAsync = getCities('', 'US');
        stateAsync = getStates('', 'US');
        stateAsync.then(res => states = res);
        cityAsync.then(res => cities = res);
        formData.address_country = '';
        tabContext.setParam(key, JSON.stringify(formData));
    }
};
const onCitySelect = (e) => {
    if (e.detail) {
        const selectedItem = e.detail;
        formData.address_city = selectedItem.name;
        tabContext.setParam(key, JSON.stringify(formData));
    }
    else {
        formData.address_city = '';
        tabContext.setParam(key, JSON.stringify(formData));
    }
};
const onStateSelect = (e) => {
    console.log("state selected: ", e);
    if (e.detail) {
        const selectedItem = e.detail;
        formData.address_division_1 = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.iso2;
        cityAsync = getCities('', formData.address_country || "US", selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.iso2);
        cityAsync.then(res => cities = res);
    }
    else {
        formData.address_division_1 = '';
        cityAsync = getCities('', formData.address_country || "US");
        cityAsync.then(res => cities = res);
    }
    tabContext.setParam(key, JSON.stringify(formData));
};
let regPopup, regPopupOpened;
let regWrapper, regElementHeight;
const popupControl = {
    closePopup: function () {
        regPopup.close();
    },
};
const handleMapClick = (georesult) => {
    const { address_components } = georesult;
    if (address_components && address_components.length > 0) {
        const countryCode = address_components.find(component => {
            const types = component.types;
            return (types.indexOf('country') !== -1);
        });
        const countryISO2 = countryCode === null || countryCode === void 0 ? void 0 : countryCode.short_name;
        const stateCode = address_components.find(component => {
            const types = component.types;
            return (types.indexOf("administrative_area_level_1") !== -1);
        });
        const stateISO2 = stateCode === null || stateCode === void 0 ? void 0 : stateCode.short_name;
        /*
        console.log('STATES', states);
        const stateISO2 = states.find(state => state.name === stateCode.long_name)?.iso2;
        console.log('stateCode', stateCode);
        console.log('stateISO2', stateISO2);
        */
        const cityCode = address_components.find(component => {
            const types = component.types;
            return (types.indexOf('locality') !== -1);
        });
        const city = cityCode === null || cityCode === void 0 ? void 0 : cityCode.long_name;
        const postalCode = address_components.find(component => {
            const types = component.types;
            return (types.indexOf('postal_code') !== -1);
        });
        const postCode = (postalCode === null || postalCode === void 0 ? void 0 : postalCode.long_name) || '';
        const streetCode = address_components.find(component => {
            const types = component.types;
            return types.indexOf('street_address') !== -1;
        });
        const street = (streetCode === null || streetCode === void 0 ? void 0 : streetCode.long_name) || '';
        formData = Object.assign(Object.assign({}, formData), { address_street: street, address_country: countryISO2, address_city: city, address_division_1: stateISO2, address_postal: postCode });
    }
};
onMount(() => {
    const resizeObserver = new ResizeObserver(() => regElementHeight = regWrapper.clientHeight);
    resizeObserver.observe(regWrapper);
});
onMount(() => {
    const inputElement = document.getElementsByTagName("input");
    if (inputElement && inputElement.length > 0) {
        for (let i = 0; i <= inputElement.length; i++) {
            const input = inputElement.item(i);
            console.log("inputElmets;", input, inputElement);
            input === null || input === void 0 ? void 0 : input.setAttribute("autocomplete", "off");
        }
    }
});
</script>
    <CardBase {element}>
        <div class="address-input-field-wrapper" bind:this={wrapperElement}>
            {#await countryAsync}
            <div class="wrapper">
                <Select isDisabled={true} placeholder=""/>
            </div>
            {:then result}
            <div class="wrapper">
                <Select
                    items={result}
                    optionIdentifier='iso2'
                    {getSelectionLabel}
                    {getOptionLabel}
                    {Item}
                    placeholder={"Country*"}
                    {isClearable}
                    isDisabled={!result}
                    isVirtualList={true}
                    value={findSelectedValue(formData.address_country, 'iso2', result)}
                    on:select={onCountrySelect}
                    on:clear={onCountrySelect}
                />
            </div>
            {/await}

            <!-- <TextInput placeholder="Street Address" value={formData.address_street || ''} onChange={handleStreetChange} icon="/static/thumbs/location.png" /> -->
            
            {#if enableLocationService}
            <Link
                popupOpen=".sso22-location-service-popup"
                class="label primary"
            >
                <div class="enable-location-service">
                    <img src="/static/icons/icon-location-fill.png" alt="" />
                    <div>
                        <span class="title">Use my current location</span>
                        <span class="description">Enable location services to populate your address</span>
                    </div>
                    <i class="f7-icons">chevron_right</i>
                </div>
            </Link>
            {/if}

            {#await cityAsync}
            <div class="wrapper">
                <Select isDisabled={true} placeholder=""/>
            </div>
            {:then result}
            <div class="wrapper">
                <Select
                    items={result}
                    optionIdentifier='name'
                    {getSelectionLabel}
                    {getOptionLabel}
                    {Item}
                    placeholder={"City"}
                    {isClearable}
                    isDisabled={!result}
                    isVirtualList={true}
                    value={findSelectedValue(formData.address_city, 'name', result)}
                    on:select={onCitySelect}
                    on:clear={onCitySelect}
                />
            </div>
            {/await}

            <div class="row">
                {#await stateAsync}
                <div class="wrapper">
                    <Select isDisabled={true} placeholder=""/>
                </div>
                {:then result}
                <div class="wrapper">
                    <Select
                        items={result.sort((a, b) => a.name > b.name ? 1 : -1)}
                        optionIdentifier='iso2'
                        {getSelectionLabel}
                        {getOptionLabel}
                        {Item}
                        placeholder={"State/Province"}
                        {isClearable}
                        isDisabled={!result}
                        isVirtualList={true}
                        value={findSelectedValue(formData.address_division_1, 'iso2', result)}
                        on:select={onStateSelect}
                        on:clear={onStateSelect}
                    />
                </div>
                {/await}
                <div class="wrapper">
                    <TextInput placeholder="Postal Code" onChange={handlePostalCodeChange} value={formData.address_postal || ''}/>
                </div>
            </div>
            <TextInput placeholder={isPhoneRequired === "true" ? "Phone Number*" : "Phone Number"} type="number" onChange={handlePhoneNumberChange} value={formData.phone || ''} pattern={'\\d*'} />
        </div>
        
    </CardBase>
    <Popup
        bind:this={regPopup}
        class="sso22-popup sso22-location-service-popup"
        backdropEl={'#child-popup-backdrop'}
        style="--f7-popup-tablet-height: {regElementHeight}px;"
        opened={regPopupOpened}
        onPopupClosed={() => (regPopupOpened = false)}
        closeByBackdropClick={true}
        closeOnEscape={true}
        >
        <div class="popup-content-wrapper" bind:this={regWrapper}>
            <Location handleSelect={handleMapClick} {popupControl} />
        </div>
    </Popup>
    
    <style>.address-input-field-wrapper {
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.row {
  display: flex;
  width: 100%;
  gap: 4px;
}

.wrapper {
  flex: 1;
}

:global(.wrapper) {
  overflow: visible !important;
  --placeholderColor: black;
}

.address-input-field-wrapper :global(.selectContainer) {
  border: 1px solid #D8D8D8 !important;
  border-radius: 6px;
  --padding: 0px 16px !important;
  --height: 40px;
  --inputFontSize: 12px;
}

.address-input-field-wrapper :global(.listContainer) {
  box-shadow: 0px 0px 1px #b1afaf;
  z-index: 100;
}

:global(.address-input-field-wrapper .selectContainer input, .address-input-field-wrapper .selection, .address-input-field-wrapper .customItem_name) {
  color: #000 !important;
  font-weight: normal !important;
}

.address-input-field-wrapper ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: black;
}

.address-input-field-wrapper ::-moz-placeholder { /* Firefox 19+ */
  color: black;
}

.address-input-field-wrapper :-ms-input-placeholder { /* IE 10+ */
  color: black;
}

.address-input-field-wrapper :-moz-placeholder { /* Firefox 18- */
  color: black;
}

.enable-location-service {
  display: flex;
  gap: 13px;
  padding: 16px 12px;
  align-items: center;
  background: #69be28;
  border-radius: 18px;
  overflow: hidden;
}

.enable-location-service img {
  width: 30px;
  height: 30px;
}

.enable-location-service div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.enable-location-service span {
  display: block;
  margin: 0;
}

.enable-location-service .title {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 10px;
  color: #000000;
}

.enable-location-service .description {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}

.enable-location-service i {
  font-size: 18px;
  margin-left: auto;
  color: black;
}

.location-popup-backdrop {
  background: rgba(0,0,0,0.66667);
}

:global(.sso22-location-service-popup) {
  --f7-popup-tablet-width: 270px !important;
}

.address-input-field-wrapper :global(a) {
  padding: 0;
}</style>