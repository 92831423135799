import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { PublicAPI, PublicAPIToken } from "./PublicAPI";
import { Dictionary } from "typescript-collections";
import { PublicServer } from "./PublicServer";
import { YinzCamInjectModule } from "yinzcam-inject";

const SERVER_CACHE: Dictionary<string, PublicServer> = new Dictionary<string, PublicServer>();
function getServerCached(service: string, tricode: string, league: string, loader: () => PublicServer): PublicServer {
  const key = `${service}-${tricode}-${league}`;
  let server = SERVER_CACHE.getValue(key);
  if (!server) {
    server = loader();
    SERVER_CACHE.setValue(key, server);
  }
  return server;
}

export let PublicAPIModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    // TODO: To support mode switching, expose a factory that can create an API for different leagues and tricodes
    bindToken(PublicAPIToken).to(PublicAPI).inSingletonScope();
  }));
});
