<Boundary>
  <CardsComponentWrapper style="
    {buildCssPadding(column.padding)}
    {buildCssMargin(column.margin)}
    width: {width};
    flex-grow: {flexGrow};
    flex-shrink: {flexShrink};
  " {editorOptions}>
    <CardsArrays parentSequenceId={sequenceId} arrays={column.content} updater={(arrays) => column.content = arrays} />
  </CardsComponentWrapper>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceColumn } from "yinzcam-cards";
import { buildCssMargin, buildCssPadding } from "../utilities/index";
import CardsArrays from "./CardsArrays.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import { CardsEditorComponentOptions } from "./CardsEditorInterfaces";
import { CARDS_COLUMN_SCHEMA } from "./CardsEditorComponentSchemata";
import { v4 as uuid4 } from "uuid";
export let parentSequenceId = null;
export let sequenceId = "";
export let column;
export let updater;
$: hasWidth = _.isNumber(column === null || column === void 0 ? void 0 : column.width) && column.width > 0 && column.width <= 100;
$: width = (hasWidth) ? `${column.width}%` : '100%';
$: flexGrow = (hasWidth) ? ((column === null || column === void 0 ? void 0 : column.grow) ? '1' : '0') : '1';
$: flexShrink = (hasWidth) ? ((column === null || column === void 0 ? void 0 : column.shrink) ? '1' : '0') : '1';
// child of a column is an array that needs a layout embedded in it
function createChild() {
    return {
        id: uuid4(),
        layouts: [{
                id: uuid4(),
                type: 'GRID',
                elements: []
            }]
    };
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: column.id,
    component: column,
    componentTypeName: 'Column',
    primaryColor: '#2390DE',
    reverseTextColor: 'black',
    childrenKey: 'content',
    configSpec: CARDS_COLUMN_SCHEMA,
    allowChildren: true,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    updater,
    createChild
};
</script>
