<script context="module" lang="ts">import { JSONSchema4 } from 'json-schema';
export function getDisplayName() {
    return "Simple Card (Thumbnail or HTML)";
}
export function getElementConfigSpec() {
    return {
        type: "object",
        properties: {
            url: {
                type: "string",
                format: "url",
                title: "Link",
                description: "The URL to open when the card is clicked."
            },
            thumbnail: {
                type: "string",
                format: "url",
                title: "Thumbnail",
                description: "The thumbnail image for the card.",
                options: {
                    upload: {}
                },
                links: [{ href: "{{self}}" }]
            },
            title: {
                type: "string",
                title: "Title",
                description: "The title that appears below the thumbnail."
            },
            fontSize: {
                type: "string",
                title: "Title Font Size",
                description: "The title font size in standard CSS units."
            },
            fontWeight: {
                type: "string",
                title: "Title Font Weight",
                description: "The title font weight.",
                enum: [
                    "revert",
                    "normal",
                    "bold",
                    "bolder",
                    "lighter",
                    "100"
                ],
                options: {
                    enum_titles: [
                        "Default",
                        "Regular",
                        "Bold",
                        "Extra Bold",
                        "Light",
                        "Extra Light"
                    ]
                }
            },
            fontColor: {
                title: "Subtitle Font Color",
                description: "The title font color.",
                type: "string",
                format: "color"
            },
            subTitle: {
                type: "string",
                title: "Subtitle",
                description: "The subtitle that appears below the title."
            },
            secondaryFontSize: {
                type: "string",
                title: "Subtitle Font Size",
                description: "The subtitle font size in standard CSS units."
            },
            secondaryFontColor: {
                title: "Subtitle Font Color",
                description: "The subtitle font color.",
                type: "string",
                format: "color"
            },
            content: {
                type: "string",
                title: "Content",
                description: "The content that appears below the title and subtitle.",
                format: "jodit",
                // this is needed to prevent the editor from getting stuck disabled
                options: { jodit: { disabled: false } }
            },
            contentFontSize: {
                type: "string",
                title: "Content Font Size",
                description: "The content's default font size in standard CSS units."
            },
            contentColor: {
                title: "Content Font Color",
                description: "The content's default font color.",
                type: "string",
                format: "color"
            },
        }
    };
}
</script>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../common/CardBase.svelte';
import { t } from '../../../../js/i18n';
import { resolveUrl } from '../../../../js/url';
import YCLink from '../../utilities/YCLink.svelte';
import { Device } from 'framework7';
import { getFirstSourceStore, getTemplatedElementDataFromSource } from '../../utilities';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../common/CardsDataSourceRegistration';
export let element;
export let sources;
let registeredToSource = element === null || element === void 0 ? void 0 : element.sourceIds;
$: source = getFirstSourceStore($sources);
$: hasData = !!$source;
$: title = $t(getTemplatedElementDataFromSource(element, 'title', $source));
$: subTitle = $t(getTemplatedElementDataFromSource(element, 'subtitle', $source));
$: content = $t(getTemplatedElementDataFromSource(element, 'content', $source));
$: layout = $t(getTemplatedElementDataFromSource(element, 'layout', $source));
$: thumbnail = $t(getTemplatedElementDataFromSource(element, 'thumbnail', $source));
$: fontSize = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.fontSize) || '14px';
$: fontWeight = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.fontWeight) || 'revert';
$: fontColor = ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.fontColor) || '#FFF';
$: secondaryFontSize = ((_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.secondaryFontSize) || '12px';
$: secondaryFontColor = ((_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.secondaryFontColor) || '#000';
$: contentFontSize = ((_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.contentFontSize) || '12px';
$: contentColor = ((_g = element === null || element === void 0 ? void 0 : element.data) === null || _g === void 0 ? void 0 : _g.contentColor) || '#000';
$: appOnly = (_h = element === null || element === void 0 ? void 0 : element.data) === null || _h === void 0 ? void 0 : _h.appOnly;
$: webOnly = (_j = element === null || element === void 0 ? void 0 : element.data) === null || _j === void 0 ? void 0 : _j.webOnly;
$: external = (_k = element === null || element === void 0 ? void 0 : element.data) === null || _k === void 0 ? void 0 : _k.external;
$: url = resolveUrl($t(getTemplatedElementDataFromSource(element, 'url', $source)));
$: height = (_l = element === null || element === void 0 ? void 0 : element.data) === null || _l === void 0 ? void 0 : _l.height;
$: width = (_m = element === null || element === void 0 ? void 0 : element.data) === null || _m === void 0 ? void 0 : _m.width;
$: padding = ((_o = element === null || element === void 0 ? void 0 : element.data) === null || _o === void 0 ? void 0 : _o.padding) || { top: 0, bottom: 0, left: 0, right: 0 };
$: border_radius = ((_p = element === null || element === void 0 ? void 0 : element.data) === null || _p === void 0 ? void 0 : _p.border_radius) || '';
$: hideIfSourceHasData = ((_q = element === null || element === void 0 ? void 0 : element.data) === null || _q === void 0 ? void 0 : _q.hideIfSourceHasData) || false;
$: heightStyle = (height) ? `height: ${height} !important;` : `height: 100% !important; object-fit: cover;`;
$: thumbnailWidth = ((_r = element === null || element === void 0 ? void 0 : element.data) === null || _r === void 0 ? void 0 : _r.thumbnailWidth) || 'auto';
$: thumbnailHeight = ((_s = element === null || element === void 0 ? void 0 : element.data) === null || _s === void 0 ? void 0 : _s.thumbnailHeight) || 'auto';
$: hAlign = ((_t = element === null || element === void 0 ? void 0 : element.data) === null || _t === void 0 ? void 0 : _t.hAlign) || 'left';
$: fontFamily = ((_u = element === null || element === void 0 ? void 0 : element.data) === null || _u === void 0 ? void 0 : _u.fontFamily) || 'inherit';
$: secondFontFamily = ((_v = element === null || element === void 0 ? void 0 : element.data) === null || _v === void 0 ? void 0 : _v.secondFontFamily) || 'inherit';
$: contentFontFamily = ((_w = element === null || element === void 0 ? void 0 : element.data) === null || _w === void 0 ? void 0 : _w.contentFontFamily) || 'inherit';
$: contentFontWeight = (_x = element === null || element === void 0 ? void 0 : element.data) === null || _x === void 0 ? void 0 : _x.contentFontWeight;
</script>

<CardBase {element}>
  {#if (appOnly && Device.cordova) || (!appOnly && !webOnly) || (webOnly && !Device.cordova)}
    {#if !hideIfSourceHasData || !registeredToSource || (registeredToSource && hasData === false)}
      <YCLink resolved={url} style="width:{width ? width : '100%'}; {heightStyle} display: block! important;">
        <div
          class="simple-card {layout || ''}"
          style="{heightStyle}
          padding-left: {padding.left}; padding-right: {padding.right }; padding-top: {padding.top }; padding-bottom: {padding.bottom };
          border-radius: {border_radius};
          text-align: {hAlign};
          "
        >
          {#if thumbnail}
            <div class="thumbnail" style="">
              <img
                src={thumbnail}
                alt={title || 'thumbnail'}
                style="width: {thumbnailWidth}; height: {thumbnailHeight};"
              />
            </div>
          {/if}
          {#if title}
            <div class="title" style="font-family: {fontFamily}; color:{fontColor}; font-size:{fontSize};font-weight:{fontWeight}">
              {@html title}
            </div>
          {/if}
          {#if subTitle}
            <div
              class="subtitle title"
              style="font-family: {secondFontFamily};  color:{secondaryFontColor}; font-size:{secondaryFontSize};"
            >
              {@html subTitle}
            </div>
          {/if}
          {#if content}
            <div
              class="content"
              style="font-family: {contentFontFamily};  color:{contentColor}; font-size:{contentFontSize};"
            >
              {@html content}
            </div>
          {/if}
        </div>
      </YCLink>
    {/if}
  {/if}
</CardBase>

<style>.simple-card {
  width: 100%;
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.simple-card > div {
  width: 100%;
}

.simple-card .thumbnail img {
  width: 100%;
  height: intrinsic;
  height: 100%;
  object-fit: cover;
  display: block;
}

.simple-card .title {
  padding: 6px;
  color: var(--f7-theme-color);
  text-align: center;
}

.simple-card.inline-logo-left,
  .simple-card.inline-logo-right {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.simple-card.inline-logo-left .thumbnail,
  .simple-card.inline-logo-right .thumbnail {
  width: 56px;
}

.simple-card.inline-logo-left .title,
  .simple-card.inline-logo-right .title {
  width: auto;
  font-weight: bold;
  text-transform: uppercase;
}

.simple-card.inline-logo-right .thumbnail {
  order: 2;
}

.simple-card.inline-logo-right .title {
  order: 1;
}

.thumbnail img {
  margin-left: auto;
  margin-right: auto;
}</style>
