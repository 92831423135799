<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import ActionButton from "../ActionButton/ActionButton.svelte";
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { CardsPageContext, CardsPageContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { resolveUrl } from '../../../../../js/url';
import { JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import { ycSSOSetState } from '../../../../../js/ycapp';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const pageContext = getContext(CardsPageContextKey);
const signonManager = getToken(ROOT, JanusSignonManagerToken);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: firstNameKey = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.firstNameKey;
$: lastNameKey = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.lastNameKey;
$: firstEmailKey = (_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.emailKey;
$: emailValidKey = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.isValidEmailKey;
$: passwordKey = (_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.passwordKey;
$: passwordValidKey = (_g = element === null || element === void 0 ? void 0 : element.data) === null || _g === void 0 ? void 0 : _g.isValidPasswordKey;
$: emailOptinKey = (_h = element === null || element === void 0 ? void 0 : element.data) === null || _h === void 0 ? void 0 : _h.emailOptinKey;
$: privacyOptinKey = (_j = element === null || element === void 0 ? void 0 : element.data) === null || _j === void 0 ? void 0 : _j.privacyOptinKey;
$: newsletterOptinKey = (_k = element === null || element === void 0 ? void 0 : element.data) === null || _k === void 0 ? void 0 : _k.newsletterOptinKey;
$: endWorkflow = (_l = element === null || element === void 0 ? void 0 : element.data) === null || _l === void 0 ? void 0 : _l.endWorkflow;
$: firstName = firstNameKey ? ($mergedParams || {})[firstNameKey] : '';
$: lastName = lastNameKey ? ($mergedParams || {})[lastNameKey] : '';
$: email = firstEmailKey ? ($mergedParams || {})[firstEmailKey] : '';
$: isEmailValid = emailValidKey ? ($mergedParams || {})[emailValidKey] : '';
$: password = passwordKey ? ($mergedParams || {})[passwordKey] : '';
$: passwordValid = passwordValidKey ? ($mergedParams || {})[passwordValidKey] : '';
$: emailOptin = emailOptinKey ? ($mergedParams || {})[emailOptinKey] : '';
$: privacyOptin = privacyOptinKey ? ($mergedParams || {})[privacyOptinKey] : '';
$: newsletterOptin = newsletterOptinKey ? ($mergedParams || {})[newsletterOptinKey] : '';
$: console.log('account_register: ', firstName, lastName, email, isEmailValid, password, passwordValid, firstEmailKey, passwordKey, emailOptin, privacyOptin, newsletterOptin);
// Until validation is fixed
//$: isValid = !!(firstName && lastName && isEmailValid === 'true' && passwordValid === 'true');
$: isValid = !!(firstName && lastName && (firstEmailKey ? isEmailValid === "true" : true) && (passwordValidKey ? passwordValid === "true" : true) && (!emailOptinKey || emailOptin === 'true') && (!privacyOptinKey || privacyOptin === 'true'));
const handleClick = (e) => __awaiter(void 0, void 0, void 0, function* () {
    var _m, _o;
    const profile = {
        email
    };
    if (firstNameKey) {
        profile.first_name = firstName;
    }
    if (lastNameKey) {
        profile.last_name = lastName;
    }
    if (firstNameKey && lastNameKey) {
        profile.name = firstName + " " + lastName;
    }
    if (emailOptinKey)
        profile.email_optin = emailOptin === "true" ? 'true' : 'false';
    if (privacyOptinKey)
        profile.privacy_optin = privacyOptin === "true" ? 'true' : 'false';
    if (newsletterOptinKey)
        profile.newsletter_optin = newsletterOptin === "true" ? 'true' : 'false';
    let result = yield signonManager.registerWithYinzCamEmailPassword(email, password, profile);
    console.log('SSO: register result', result);
    if (result.success) {
        ycSSOSetState(result.data.AccountCreated === 'true', result.data.YinzId, parseInt(result.data.ValidSpan), result.data.Ticket);
        if (endWorkflow) {
            signonManager.fireWorkflowEndEvent();
        }
        else if (signonManager.skipCompleteProfilePage) {
            resolveUrl(`ConnectAccounts`).func();
        }
        else {
            resolveUrl(`CompleteProfile?firstName=${firstName}&lastName=${lastName}&email=${email}`).func();
        }
    }
    else {
        alert(((_o = (_m = result.data) === null || _m === void 0 ? void 0 : _m.UserMessage) === null || _o === void 0 ? void 0 : _o.Content) || 'An error occurred.');
    }
});
</script>
<CardBase {element}>
    <ActionButton onClick={handleClick} title="Next" disabled={!isValid}/>
</CardBase>