import { CardsDataSourceBase } from '../../common/CardsDataSourceBase'
import { injectable } from 'inversify'
import { injectToken } from 'inversify-token'
import { YinzCamAPIResponse } from 'yinzcam-api'
import { YinzCamAppServerToken } from 'yinzcam-appserver'
import _ from 'lodash'

@injectable()
export class YinzCamAppServerSearchResultsItems extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(
    response: YinzCamAPIResponse,
    specData?: { [key: string]: any },
  ): object | any[] {
    const data = response?.data as any

    let page, length
    try {
      if (response?.url) {
        let url = new URL(response.url)
        page = parseInt(url.searchParams?.get("page"))
        length = parseInt(url.searchParams?.get("item_length"))

        let filterKey = url.searchParams?.get("filterKey");
        let filterValue = url.searchParams?.get("filterValue");
        if (filterKey && filterValue.toLowerCase() !== "all" && filterKey !== filterValue) {
          return [];
        }
      }
    } catch (e) {
      // couldn't get page and legnth, ignore
    }

    let dataArr = []
    let heading: string = ''
    let limit:number = 0;
    /*
    if (specData.section === 'news') {
      dataArr = this.getNews(data)
      heading = "Noticias"
    } else if (specData.section === 'gallery') {
      dataArr = this.getPhotos(data)
      heading = "Galerías"
    } else if (specData.section === 'players') {
      dataArr = this.getPlayers(data)
      heading = "Jugadores"
    } else if (specData.section === 'pages') {
      dataArr = this.getPages(data)
      heading = "Páginas"
    } else if (specData.section === 'videos') {
      dataArr = this.getVideos(data)
      heading = "Videos"
    }
    */
    if (specData.section === 'news') {
      dataArr = this.getNews(data)
      heading = "News"
    } else if (specData.section === 'gallery') {
      dataArr = this.getPhotos(data)
      heading = "Photos"
    } else if (specData.section === 'players') {
      dataArr = this.getPlayers(data)
      heading = "Superstars"
    } else if (specData.section === 'pages') {
      dataArr = this.getPages(data)
      heading = "Pages"
    } else if (specData.section === 'videos') {
      dataArr = this.getVideos(data)
      heading = "Videos"
    }

    if (!_.isNil(page) && !_.isNaN(page) && !_.isNil(length) && !_.isNaN(length)) {
      dataArr = dataArr.slice(length*page, Math.min(dataArr.length, length*(page + 1)));
    }

    
    const itemCount = dataArr.length
    if (itemCount > 0) {
      dataArr.unshift({ heading, itemCount })
    }
    return dataArr
  }

  getPlayers(data: any): any {
    const jsonData = data?.SearchResults?.Players
    if (!jsonData) {
      return []
    }
    return []
      .concat(jsonData?.PlayerStats)
      .filter((item) => item?._attributes?.Id)
  }

  getNews(data: any): any {
    const jsonData = data?.SearchResults?.News
    if (!jsonData) {
      return []
    }
    return []
      .concat(jsonData?.Item)
      .filter((item) => item?._attributes?.Type == 'N')
  }

  getVideos(data: any): any {
    const jsonData = data?.SearchResults?.Videos
    if (!jsonData) {
      return []
    }
    return []
      .concat(jsonData?.Item)
      .filter((item) => item?._attributes?.Type == 'V')
  }

  getPhotos(data: any): any {
    const jsonData = data?.SearchResults?.Photos
    if (!jsonData) {
      return []
    }
    return []
      .concat(jsonData?.Item)
      .filter((item) => item?._attributes?.Type == 'G')
  }

  getPages(data: any): any {
    const jsonData = data?.SearchResults?.Pages
    if (!jsonData) {
      return []
    }
    return [].concat(jsonData?.Item).map((item) => {
      return item
    })
  }
}
