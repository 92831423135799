<Boundary>
  <CardsContainerWrapper {editorOptions}>
    {#each sections as section, i (section.id)}
      <CardsSection {parentSequenceId} sequenceId={generateSequenceId('SEC', parentSequenceId, i)} {section} updater={(section) => sections[i] = section} />
    {/each}
  </CardsContainerWrapper>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceSection } from "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsSection from "./CardsSection.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { CardsEditorContainerOptions } from "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
export let parentSequenceId = "";
export let sections;
export let updater;
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: sections,
    updater
};
</script>
