export interface YinzCamCardsPage {
  enableEditorMode(): void;
  disableEditorMode(): void;
}

export interface YinzCamCards {
  getCurrentPage(): YinzCamCardsPage;
  getPage(listName?: string): YinzCamCardsPage;
  registerPage(listName: string, page: YinzCamCardsPage)
  unregisterPage(listName: string);
}

export interface YinzCamGlobal {
  getCards(): YinzCamCards;
}

class YinzCamCardsImpl implements YinzCamCards {
  private pages: { [listName: string]: YinzCamCardsPage } = {};

  getCurrentPage(): YinzCamCardsPage {
    let keys = Object.keys(this.pages);
    if (keys.length !== 1) {
      console.log(`getCurrentPage: unable to enable default cards editor, zero or more than one cards pages found`);
      return;
    }
    return this.pages[keys[0]];
  }

  getPage(listName: string): YinzCamCardsPage {
    if (!this.pages.hasOwnProperty(listName)) {
      console.log(`enableCardsEditor: unable to enable cards editor for ${listName}, cards page not found`);
      return;
    }
    return this.pages[listName];
  }

  registerPage(listName: string, page: YinzCamCardsPage) {
    this.pages[listName] = page;
  }

  unregisterPage(listName: string) {
    delete this.pages[listName];
  }

}

class YinzCamGlobalImpl implements YinzCamGlobal {
  private cards: YinzCamCards = new YinzCamCardsImpl();
  
  public getCards() {
    return this.cards;
  }
}

export const yinzcam = new YinzCamGlobalImpl();

window.yc = yinzcam;
