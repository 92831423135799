import _ from 'lodash';
import ROOT from '../../../inversify.config';
import { CardsLibraryToken } from "./CardsLibraryToken";
import { getAllToken, getNamed } from 'inversify-token';
import { CardsElementDataSourceSpec } from './CardsElementInterfaces';
import { YinzCamCardsServiceSource } from 'yinzcam-cards';
import { CardsDataSourceToken } from './CardsDataSourceToken';
import { CardsDataSource } from './CardsDataSource';
import { it } from 'date-fns/locale';

export function generateElementDataSourcesSchema(spec: CardsElementDataSourceSpec, sources: YinzCamCardsServiceSource[]) {
  if (!spec?.sources?.length) {
    return undefined;
  }
  return {
    "title": "Data Sources",
    "type": "array",
    "description": "Configure the data sources used by this element, if required. Data sources can be defined on the tab containing this element.",
    "minItems": spec.sources.length,
    "maxItems": spec.sources.length,
    "items": spec.sources.map(s => {
      const allowedSources = (s.classes === '*')? sources : sources.filter(t => s.classes.includes(t.class));
      return {
        "type": "string",
        "title": s.title,
        "headerTemplate": s.title,
        "description": s.description,
        "enum": allowedSources.map(t => t.id),
        "options": {
          "enum_titles": allowedSources.map(t => t.name)
        }
      };
    })
  };
}

const repeat = {
  "title": "Repeat",
  "description": "The number of times this component is repeated in order.",
  "type": "integer",
  "minimum": 1
};

const padding = {
  "title": "Padding",
  "type": "object",
  "format": "normal",
  "properties": {
    "top": {
      "type": "string",
      "title": "Top",
      "description": "Top padding in standard CSS units."
    },
    "right": {
      "type": "string",
      "title": "Right",
      "description": "Right padding in standard CSS units."
    },
    "bottom": {
      "type": "string",
      "title": "Bottom",
      "description": "Bottom padding in standard CSS units."
    },
    "left": {
      "type": "string",
      "title": "Left",
      "description": "Left padding in standard CSS units."
    }
  }
};

const margin = {
  "title": "Margin",
  "type": "object",
  "format": "normal",
  "properties": {
    "top": {
      "type": "string",
      "title": "Top",
      "description": "Top margin in standard CSS units. Negative values are allowed."
    },
    "right": {
      "type": "string",
      "title": "Right",
      "description": "Right margin in standard CSS units. Negative values are allowed."
    },
    "bottom": {
      "type": "string",
      "title": "Bottom",
      "description": "Bottom margin in standard CSS units. Negative values are allowed."
    },
    "left": {
      "type": "string",
      "title": "Left",
      "description": "Left margin in standard CSS units. Negative values are allowed."
    }
  }
};

const border = {
  "title": "Border",
  "type": "object",
  "format": "normal",
  "properties": {
    "top": {
      "type": "string",
      "title": "Top",
      "description": "Top border in standard CSS units."
    },
    "right": {
      "type": "string",
      "title": "Right",
      "description": "Right border in standard CSS units."
    },
    "bottom": {
      "type": "string",
      "title": "Bottom",
      "description": "Bottom border in standard CSS units."
    },
    "left": {
      "type": "string",
      "title": "Left",
      "description": "Left border in standard CSS units."
    },
    "radius": {
      "type": "string",
      "title": "Radius",
      "description": "Border radius in standard CSS units."
    }
  }
};

const background = {
  "title": "Background",
  "type": "object",
  "format": "normal",
  "properties": {
    "color": {
      "title": "Color",
      "description": "A solid background color. Will be overridden if an image is specified.",
      "type": "string",
      "format": "color"
    },
    "url": {
      "type": "string",
      "format": "url",
      "title": "Image",
      "description": "The image for the background. This takes precedence over color.",
      "options": { "upload": {} },
      "links": [ { "href": "{{self}}" } ]
    },
    "size": {
      "type": "string",
      "title": "Size",
      "description": "Cover: The image will be resized to fill the area and cropped to maintain aspect ratio. Contain: The image will be scaled up or down so that the entire image fits within the area, maintaining aspect ratio.",
      "enum": [
        "COVER",
        "CONTAIN"
      ],
      "options": {
        "enum_titles": [
          "Cover",
          "Contain"
        ]
      }
    },
    "attachment": {
      "type": "string",
      "title": "Attachment",
      "description": "Fixed: The image will remain always in the same position on the screen. Local: The background image will scroll with the content. Scroll: The background image will scroll with the content's container.",
      "enum": [
        "FIXED",
        "LOCAL",
        "SCROLL"
      ],
      "options": {
        "enum_titles": [
          "Fixed",
          "Local",
          "Scroll"
        ]
      }
    },
    "position": {
      "type": "string",
      "title": "Position",
      "description": "The anchor point of the background for cropping. A combination of (top, center, bottom) and (left, center, right). For example, use \"center center\" to center crop.",
    },
    "repeat": {
      "type": "string",
      "title": "Repeat",
      "description": "Repeat: Enables repeating the background to cover the target area. No Repeat: Disables repeating the background (default).",
      "enum": [
        "REPEAT",
        "REPEAT-X",
        "REPEAT-Y",
        "NO-REPEAT",
        "SPACE",
        "ROUND"
      ],
      "options": {
        "enum_titles": [
          "Repeat",
          "Repeat left-to-right only",
          "Repeat top-to-bottom only",
          "No Repeat",
          "Space",
          "Round"
        ]
      }
    }
  }
};

const conditions = {
  "title": "Conditions",
  "type": "object",
  "format": "normal",
  "properties": {
    "isNative": {
      "type": "boolean",
      "title": "App Only?",
      "description": "Only show this component when displayed within a native mobile app."
    },
    "isDesktop": {
      "type": "boolean",
      "title": "Web Only?",
      "description": "Only show this component when displayed on a website inside a browser."
    },
    "not": {
      "type": "boolean",
      "title": "Invert Logic",
      "description": "Show this component when these conditions are NOT met."
    }
  }
};

export const CARDS_ELEMENT_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    "class": {
      "type": "string",
      "title": "Element Type",
      "description": "The type of element to place in this position.",
      "enum": [
      ],
      "options": {
        "enum_titles": [
        ]
      }
    },
    repeat,
    "data": {
      "title": "Type-Specific Data",
      "type": "object",
      "description": "Additional data specific to the element type.",
      "properties": {
      },
      "additionalProperties": true
    },
    "span": {
      "title": "Row/Column Span",
      "type": "object",
      "format": "normal",
      "description": "When placed in an array, specifies how many rows or columns this element should span. Defaults to 1 for both rows and columns.",
      "properties": {
        "rows": {
          "type": "integer",
          "title": "Rows",
          "description": "The number of rows this element should span. Defaults to 1.",
          "minimum": 1
        },
        "columns": {
          "type": "integer",
          "title": "Columns",
          "description": "The number of columns this element should span. Defaults to 1.",
          "minimum": 1
        }
      }
    },
    padding,
    border,
    background,
    /*conditions*/
    "sourceIds": {
      "title": "Data Sources",
      "type": "array",
      "description": "The IDs of data sources that this card uses.",
      "items": {
        "type": "string"
      }
    },
  },
  "required": [
    "class"
  ]
};

const cardLibraryRecords = getAllToken(ROOT, CardsLibraryToken);
const cardLibraryClassNamePairs: [string, string][] = [];
for (let rec of cardLibraryRecords) {
  const clazz = rec.clazz;
  const name = (_.isFunction(rec.getDisplayName))? rec.getDisplayName() : clazz;
  cardLibraryClassNamePairs.push([clazz, name]);
}
cardLibraryClassNamePairs.sort((a, b) => a[1].localeCompare(b[1]));
for (let pair of cardLibraryClassNamePairs) {
  CARDS_ELEMENT_SCHEMA.properties.class.enum.push(pair[0]);
  CARDS_ELEMENT_SCHEMA.properties.class.options.enum_titles.push(pair[1]);
}

export const CARDS_ARRAY_LAYOUT_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    "breakpoint": {
      "type": "integer",
      "title": "Breakpoint (pixels)",
      "description": "The minimum screen width in pixels to show this layout.",
      "default": 0
    },
    "type": {
      "type": "string",
      "title": "Grouping Style",
      "description": "How the cards elements will be grouped on the page. Single: A single full-wdith element. Grid: A grid of elements. Carousel: A horizontally scrolling carousel.",
      "enum": [
        "GRID",
        "SINGLE",
        "SWIPER"
      ],
      "options": {
        "enum_titles": [
          "Grid",
          "Single",
          "Carousel"
        ]  
      }
    },
    "maxColumns": {
      "type": "integer",
      "minimum": 1,
      "title": "Maximum Columns",
      "description": "The maximum number of columns visible on screen in a carousel or grid layout."
    },
    "gapPixels": {
      "type": "number",
      "title": "Element Gap (px)",
      "description": "Gap between elements in pixels."
    },
    "splitEqually": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Split Equally",
      "description": "In a grid layout, this forces the number of columns to be equal to the number of elements. If this is true, maxColumns is ignored."
    },
    "scrollSpeed": {
      "type": "number",
      "title": "Scroll Speed",
      "description": "The scrolling speed of a carousel in pixels per second. Default is 300."
    },
    "slideWidth": {
      "type": "string",
      "title": "Slide Width",
      "description": "The width of each slide in a carousel in standard CSS units."
    },
    "slideSnap": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Slide Snap",
      "description": "Should slides in a carousel snap into position (like a pager)?",
    },
    "swipeEffect": {
      "type": "string",
      "title": "Carousel Animation",
      "description": "The animation used when swiping between items in a carousel. The default sliding animiation is \"Slide\".",
      "enum": [
        "SLIDE",
        "FADE",
        "CUBE",
        "COVERFLOW",
        "FLIP"
      ],
      "options": {
        "enum_titles": [
          "Slide",
          "Fade",
          "Cube",
          "Coverflow",
          "Flip"
        ]
      }
    },
    "swiperPagination": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Carousel Pagination",
      "description": "Whether pagination dots are enabled on carousels.",
    },
    "swiperNavigation": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Carousel Navigation",
      "description": "Whether navigation arrows are enabled on carousels.",
    },
    "scrollToActive": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Scroll to Active",
      "description": "Whether a carousel should automatically scroll to the first active element (for example, an upcoming or live match). This requires the elements within the carousel to support that functionality.",
    },
    padding,
    margin,
    background
  },
  "required": [
    "breakpoint",
    "type"
  ]
};

export const CARDS_COLUMN_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    padding,
    margin,
    "width": {
      "type": "integer",
      "minimum": 0,
      "maximum": 100,
      "multipleOf": 1,
      "title": "Width (percent)",
      "description": "The percentage of the width of the parent section that this column will occupy. This can be left unspecified or set to 0, in which case this column will be automatically sized based on the size of its content."
    },
    "grow": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Allow Grow To Fit Content",
      "description": "Whether the width of this column will be allowed to grow beyond the width specified to fit the content. Ignored if width is unspecified or zero."
    },
    "shrink": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Allow Shrink To Fit Content",
      "description": "Whether the width of this column will be allowed to shrink below the width specified to tightly fit the content. Ignored if width is unspecified or zero."
    },
  },
  "required": [
  ]
};

export const CARDS_SECTION_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    padding,
    margin,
    background,
    "height": {
      "type": "integer",
      "minimum": 0,
      "title": "Fixed Height",
      "description": "Specifies a fixed height for the section in standard CSS units. If a fixed height is specified and the section's content is larger than the height specified (vertically), the section will scroll."
    },
    "autoscroll": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Auto Scroll",
      "description": "Auto-scroll the section to the first active element (for example, an upcoming or live match). Auto-scroll must be supported by elements within this section."
    }
  },
  "required": [
  ]
};

const cardDataSourceRecords = getAllToken(ROOT, CardsDataSourceToken);
const cardDataSourceClassNamePairs: [string, string][] = [['', 'Select Type']];
for (let rec of cardDataSourceRecords) {
  const clazz = (<any>rec).clazz;
  const name = (_.isFunction(rec.getDisplayName))? rec.getDisplayName() : clazz;
  cardDataSourceClassNamePairs.push([clazz, name]);
}
cardDataSourceClassNamePairs.sort((a, b) => a[1].localeCompare(b[1]));
const cardDataSourceEnumValues = cardDataSourceClassNamePairs.map(p => p[0]);
const cardDataSourceEnumTitles = cardDataSourceClassNamePairs.map(p => p[1]);

//console.log("DATA SOURCE RECORDS", cardDataSourceClassNamePairs);

const CARDS_TAB_DATA_SOURCE_DEFAULT_SCHEMA = {
  "type": "object",
  "format": "normal",
  "title": 'Undefined Data Source',
  "headerTemplate": 'Undefined Data Source',
  "description": '',
  "properties": {
    "id": {
      "type": "string",
      "format": "uuid",
      "title": "Identifier (UUID)",
      "description": "A unique identifier for this data source that is referenced by card elements."
    },
    "name": {
      "type": "string",
      "title": "Name",
      "description": "A human-readable name for the data source.",
      "default": "New Data Source"
    },
    "class": {
      "type": "string",
      "title": "Data Source Type",
      "description": "The type of the data source (must match a valid data source in the code).",
      "enum": cardDataSourceEnumValues,
      "options": {
        "enum_titles": cardDataSourceEnumTitles
      }
    },
    "data": {
      "type": "object",
      "format": "normal",
      "title": "Configuration Options",
      "description": "Configuration data for the source based on the source's type.",
      "additionalProperties": true,
      "options": {
        "disable_properties": false
      }
    },
    "path": {
      "type": "string",
      "title": "Endpoint",
      "description": "The API endpoint of the data source. Note that the valid options here may change based on the data source's type and configuration."
    },
  },
  "required": [
    "id",
    "class",
    "name"
  ]
};

const CARDS_TAB_DATA_SOURCE_NEW_SCHEMA = (function() {
  const item = _.cloneDeep(CARDS_TAB_DATA_SOURCE_DEFAULT_SCHEMA);
  item.title = item.headerTemplate = "New Data Source";
  item.description = "Select a type to begin creating this data source.";
  delete item.properties.data;
  delete item.properties.path;
  return item;
})();

const MAX_DATA_SOURCES_PER_TAB = 10;

export async function generateTabDataSourcesSchema(sources: YinzCamCardsServiceSource[]) {
  if (!sources?.length) {
    sources = [];
  }
  const items = [];
  for (let i = 0; i < sources.length && i < MAX_DATA_SOURCES_PER_TAB; i++) {
    const spec = sources[i];
    // console.log('SOURCE', i, spec);
    let item;
    if (!spec?.class) {
      item = CARDS_TAB_DATA_SOURCE_NEW_SCHEMA;
    } else {
      item = _.cloneDeep(CARDS_TAB_DATA_SOURCE_DEFAULT_SCHEMA);
      const source = getNamed(ROOT, CardsDataSourceToken, spec.class);
      if (source) {
        item.title = item.headerTemplate = source.getDisplayName();
        const paths = await source.getDataSourcePaths(spec.data);
        if (paths) {
          const pathSchema = item.properties.path as any;
          pathSchema.enum = paths.map(p => p.path);
          pathSchema.options = {
            enum_titles: paths.map(p => p.name)
          };
          item.required.push("path");
        } else if (_.isNull(paths)) {
          // if null set the enum to no options available, path is required still though (so this is an error)
          item.properties.path.enum = [];
          item.properties.path.description = "The data source indicated that a path is required but none are available with the current data-source configuration.";
          item.required.push("path");
        } // otherwise undefined, leave as is (path is optional)
        const dataSchema = await source.getDataSourceConfigSpec();
        if (dataSchema) {
          // if the source provided a schema, use it and make it required
          delete item.properties.data.additionalProperties;
          delete item.properties.data.options;
          item.properties.data = { ...item.properties.data, ...dataSchema };
          item.required.push("data");
        } else if (_.isNull(dataSchema)) {
          // if null delete the property (the source doesn't care about extra data)
          delete item.properties.data;
        } // otherwise undefined, leave as is
      }
    }
    items.push(item);
  }
  if (sources.length < MAX_DATA_SOURCES_PER_TAB) {
    items.push(CARDS_TAB_DATA_SOURCE_NEW_SCHEMA);
  }
  
  const schema = {
    "title": "Data Sources",
    "type": "array",
    "description": "Define data sources for use by cards within this tab.",
    "minItems": 0,
    "maxItems": MAX_DATA_SOURCES_PER_TAB,
    "items": items,
    "options": {
      "disable_array_reorder": true
    }
  };
  //console.log('SCHEMA', schema);
  return schema;
}

export const CARDS_TAB_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {    
    "default": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Is Default Tab?",
      "description": "If set to true, this tab will be the first tab shown on page load within this region.",
      "default": true
    },
    padding,
    margin,
    background,    
    /*sources: ...,*/
    /*
    "fragmentId": {
      "type": "string",
      "description": "If specified, this fragment ID will be used to display the contents of the tab."
    }
    */
  }
};

export const CARDS_PAGE_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    "analytics": {
      "type": "object",
      "title": "Analytics",
      "description": "Configuration of analytics tracking data for this page.",
      "properties": {
        "path": {
          "type": "string",
          "title": "Path",
          "description": "The page path to report for analytics tracking."
        },
        "name": {
          "type": "string",
          "title": "Name",
          "description": "The page name to report for analytics tracking."
        }
      },
      "required": [
        "path",
        "name"
      ]
    },
    "defaultParameters": {
      "type": "object",
      "title": "Default Parameters",
      "description": "Default parameters for this page.",
      "patternProperties": {
        "^.+$": { 
          "type": "string"
        }
      },
      "additionalProperties": true,
      "options": {
        "disable_properties": false
      }
    }
  }
};