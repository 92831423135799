import { JanusAnalyticsPageContext } from "../../../js/analytics";
import { Readable } from "svelte/store";
import { ReactiveMicrocomponent } from "yinzcam-rma";
import { CardsDataSourceManager } from "./CardsDataSourceManager";
import { CardsEditorModeManager } from "./CardsEditorModeManager";
import { Item } from "svelte-dnd-action";
import { YinzCamAPIRequestParameters } from "yinzcam-api";

export const CardsPageContextKey = {};
export const CardsTabContextKey = {};
export const PlayerDetailContextKey = {};
export const UserPreferencesContextKey = {};
export const CardsEditorSubtreeContextKey = {};

export interface CardsTabContext {
  dataSourceManager: CardsDataSourceManager;
  mergedParamsComp: ReactiveMicrocomponent<{ [key: string]: string }>;
  setParam: (key: string, value?: string) => void;
  scrollContainer: Readable<HTMLElement>;
}

export interface CardsPageContext {
  name: string;
  query: {[key: string]: string};
  discoModeClass: string;
  editorModeManager: CardsEditorModeManager;
  defaultParamsComp: ReactiveMicrocomponent<YinzCamAPIRequestParameters>;
  pathParamsMappingComp: ReactiveMicrocomponent<string[]>;
  getAnalytics(): JanusAnalyticsPageContext;
}

export interface UserPreferencesContext {
  country: string;
  team: string;
}

export interface CardsEditorSubtreeContext {
  componentId: string;
  component: Item;
  subtreeCloned: boolean;
  subtreePreview: boolean;
  moveUpChild: (childId: string) => void;
  moveDownChild: (childId: string) => void;
  cloneChild: (childId: string) => void;
  removeChild: (childId: string) => void;
  addChildBefore: (childId: string) => void;
  addChildAfter: (childId: string) => void;
}
