import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamSignonServerToken } from "yinzcam-signon";

@injectable()
export class YinzCamSignonServerLinks extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamSignonServerToken) server) {
    super({ server, defaultPath: '/links' });
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;    
    return data;
  }
}
