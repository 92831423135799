import { readable, derived } from 'svelte/store';

export function ycDismissWebView() {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycDismissWebView) {
    console.log('CALLING: window.webkit.messageHandlers.ycDismissWebView.postMessage({})');
    window.webkit.messageHandlers.ycDismissWebView.postMessage({});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycDismissWebView) {
    console.log('CALLING: window.YinzNativeApplication.ycDismissWebView()');
    window.YinzNativeApplication.ycDismissWebView();
  } else {
    console.log('Unable to call ycDismissWebView: no message handler found.')
  }
}

export function ycOnboardingTriggerLocation() {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycOnboardingTriggerLocation) {
    console.log('CALLING: window.webkit.messageHandlers.ycOnboardingTriggerLocation.postMessage({})');
    window.webkit.messageHandlers.ycOnboardingTriggerLocation.postMessage({});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycOnboardingTriggerLocation) {
    console.log('CALLING: window.YinzNativeApplication.ycOnboardingTriggerLocation()');
    window.YinzNativeApplication.ycOnboardingTriggerLocation();
  } else {
    console.error('Unable to call ycOnboardingTriggerLocation: no message handler found.')
  }
}

export function ycOnboardingTriggerNotification(tags) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycOnboardingTriggerNotification) {
    console.log('CALLING: window.webkit.messageHandlers.ycOnboardingTriggerNotification.postMessage({tags: tags})');
    window.webkit.messageHandlers.ycOnboardingTriggerNotification.postMessage({tags: tags});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycOnboardingTriggerNotification) {
    console.log('window.YinzNativeApplication.ycOnboardingTriggerNotification(tags)');
    window.YinzNativeApplication.ycOnboardingTriggerNotification(tags);
  } else {
    console.error('Unable to call ycOnboardingTriggerNotification: no message handler found.')
  }
}

export function ycOnboardingTriggerMicrophone() {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycOnboardingTriggerMicrophone) {
    console.log('CALLING: window.webkit.messageHandlers.ycOnboardingTriggerMicrophone.postMessage({})');
    window.webkit.messageHandlers.ycOnboardingTriggerMicrophone.postMessage({});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycOnboardingTriggerMicrophone) {
    console.log('CALLING: window.YinzNativeApplication.ycOnboardingTriggerMicrophone()');
    window.YinzNativeApplication.ycOnboardingTriggerMicrophone();
  } else {
    console.error('Unable to call ycOnboardingTriggerMicrophone: no message handler found.')
  }
}

export function ycSSOThirdParty(type) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOThirdParty) {
    window.webkit.messageHandlers.ycSSOThirdParty.postMessage({type: type});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOThirdParty) {
    window.YinzNativeApplication.ycSSOThirdParty(type);
  } else {
    console.error('Unable to call ycSSOThirdParty: no message handler found.')
  }
}

export function ycSSOStoreTicket(yinzID, accountCreated, yticket, yvalidSpan) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOStoreTicket) {
    window.webkit.messageHandlers.ycSSOStoreTicket.postMessage({yinzID: yinzID, accountCreated: accountCreated, yticket: yticket, yvalidSpan: yvalidSpan, lvalidSpanString: null, ltoken: null});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOStoreTicket) {
    window.YinzNativeApplication.ycSSOStoreTicket(yinzID, accountCreated, yticket, yvalidSpan, null, null);
  } else {
    console.error('Unable to call ycSSOStoreTicket: no message handler found.')
  }
}

export function ycSSOAuthFinished(success) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOAuthFinished) {
    window.webkit.messageHandlers.ycSSOAuthFinished.postMessage({success: success});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOStoreTicket) {
    window.YinzNativeApplication.ycSSOAuthFinished(success);
  } else {
    console.error('Unable to call ycSSOAuthFinished: no message handler found.')
  }
}

export function ycSSOSetState(accountCreated, yinzId, validSpan, ticket) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOSetState) {
    console.log(`CALLING: window.webkit.messageHandlers.ycSSOSetState.postMessage({accountCreated: ${accountCreated}, yinzID: ${yinzId}, yvalidSpan: ${validSpan}, yticket: ${ticket}})`);
    window.webkit.messageHandlers.ycSSOSetState.postMessage({accountCreated, yinzID: yinzId, yvalidSpan: validSpan, yticket: ticket});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOSetState) {
    console.log(`CALLING: window.YinzNativeApplication.ycSSOSetState(${accountCreated}, ${yinzId}, ${validSpan}, ${ticket})`);
    window.YinzNativeApplication.ycSSOSetState(accountCreated, yinzId, validSpan, ticket);
  } else {
    console.error('Unable to call ycSSOSetState: no message handler found.');
  }
}

export async function ycSSOGetCapabilities() {
  let ret = null;
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOGetCapabilities) {
    console.log(`CALLING: window.webkit.messageHandlers.ycSSOGetCapabilities.postMessage({})`);
    ret = await window.webkit.messageHandlers.ycSSOGetCapabilities.postMessage({});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOGetCapabilities) {
    console.log(`CALLING: window.YinzNativeApplication.ycSSOGetCapabilities()`);
    ret = window.YinzNativeApplication.ycSSOGetCapabilities();
    ret = JSON.parse(ret);
  } else {
    console.log('Unable to call ycSSOGetCapabilities: no message handler found.');
    return {};
  }
  //alert(`ycSSOGetCapabilities: ycSSOThirdPartyAuthAcceptsJSONArgument=${ret.ycSSOThirdPartyAuthAcceptsJSONArgument}`);
  //alert(`ycSSOGetCapabilities: ${JSON.stringify(ret)}`);
  return ret || {};
}

let ycSSOThirdPartyAuthCallbackFunction = null;
export async function ycSSOThirdPartyAuth(method, config, cb) {
  /*if (ycSSOThirdPartyAuthCallbackFunction) {
    console.error("Unable to call ycSSOThirdPartyAuth: outstanding async call in progress");
    return;
  }*/
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOThirdPartyAuth) {
    console.log(`CALLING: window.webkit.messageHandlers.ycSSOThirdPartyAuth.postMessage({method: ${method}, config: ${config}})`);
    ycSSOThirdPartyAuthCallbackFunction = cb;
    window.webkit.messageHandlers.ycSSOThirdPartyAuth.postMessage({method, config});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOThirdPartyAuth) {
    ycSSOThirdPartyAuthCallbackFunction = cb;
    if ((await ycSSOGetCapabilities()).ycSSOThirdPartyAuthAcceptsJSONArgument) {
      console.log(`CALLING: window.YinzNativeApplication.ycSSOThirdPartyAuth({method: ${method}, config: ${config}})`);
      window.YinzNativeApplication.ycSSOThirdPartyAuth(JSON.stringify({method, config}));
    } else {
      console.log(`CALLING: window.YinzNativeApplication.ycSSOThirdPartyAuth(${method}, ${config.androidHostKey}, ${config.hostKey}, ${config.teamDisplayName}, ${config.isAutoLogin})`);
      window.YinzNativeApplication.ycSSOThirdPartyAuth(method, config.androidHostKey, config.hostKey, config.teamDisplayName, config.isAutoLogin);
    }
  } else {
    console.log('Unable to call ycSSOThirdPartyAuth: no message handler found.');
  }
}

window.ycSSOThirdPartyAuthCallback = async function(method, resultJson) {
  console.log('ycSSOThirdPartyAuthCallback: ' + method, arguments);
  if (!ycSSOThirdPartyAuthCallbackFunction) {
    console.error("ycSSOThirdPartyAuthCallback: no callback function registered?");
    return;
  }
  let result = null;
  try {
    result = JSON.parse(resultJson);
  } catch (e) {
    console.error("ycSSOThirdPartyAuthCallback: unable to parse result json", e);
  }
  await ycSSOThirdPartyAuthCallbackFunction(method, result);
  ycSSOThirdPartyAuthCallbackFunction = null;
  //ycSSOEndDialog(false);
  return '{}'; // iOS requires a string return. Made it an empty JSON object in case we want to use this in the future.
}

export function ycSSOEndDialog(retry, dataChanged) {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycSSOEndDialog) {
    console.log(`CALLING: window.webkit.messageHandlers.ycSSOEndDialog.postMessage({retry: ${retry}, dataChanged: ${dataChanged}})`);
    window.webkit.messageHandlers.ycSSOEndDialog.postMessage({retry, dataChanged});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycSSOEndDialog) {
    console.log(`CALLING: window.YinzNativeApplication.ycSSOEndDialog(${retry}, ${dataChanged})`);
    window.YinzNativeApplication.ycSSOEndDialog(retry, dataChanged);
  } else {
    console.error('Unable to call ycSSOEndDialog: no message handler found.');
  }
}

export function ycPreloadComplete() {
  if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ycPreloadComplete) {
    console.log(`CALLING: window.webkit.messageHandlers.ycPreloadComplete.postMessage()`);
    window.webkit.messageHandlers.ycPreloadComplete.postMessage({});
  } else if (window.YinzNativeApplication && window.YinzNativeApplication.ycPreloadComplete) {
    console.log(`CALLING: window.YinzNativeApplication.ycPreloadComplete()`);
    window.YinzNativeApplication.ycPreloadComplete();
  } else {
    console.error('Unable to call ycPreloadComplete: no message handler found.');
  }
}

// onboarding callbacks
/*
window.ycOnboardingPermissionUpdate = function(type, value) {
  window.localStorage.setItem('yc-onboarding-permission-' + type, value);
  document.dispatchEvent(new Event('yc-onboarding-permission-' + type));
};
*/

// TODO, move this elsewhere?
window.ycSSOThirdPartyCallback = function(type, json) {
  //console.log(json);
  ycSSOAuthFinished(true);
};

export const ycTicket = readable({ticket: null, validSpan: null}, function start(set) {
  window.setHubValidTicket = function(ticket, validSpan) {
    console.log('Set ticket callback from app', ticket, validSpan);
    if (typeof validSpan === 'string') {
      validSpan = parseInt(validSpan)
    }
    set({ticket, validSpan});
  };
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.getAppValidTicket) {
    console.log('Set ticket for iOS');
    window.webkit.messageHandlers.getAppValidTicket.postMessage({});
    // for testing
    //setTimeout(() => window.setHubValidTicket('lBUzg5+WQMiHaezezUtwjQAAAXZJNC+zFSFWFOk4syTD/fnnx9Hi10DDIQ6X+qCHbN6f9femcotrfoHcey/zBxBkgYR6vT9QQfoWdvG832bU6Z6ZkbUb/rUDh71lPuhKtX6/e/+h4AKXlqzxhGjadodZQxEDhdF0', 31536000000), 500);
  } else if (typeof window.YinzNativeApplication != 'undefined') {
    console.log('Set ticket for ANDROID');
    window.YinzNativeApplication.getAppValidTicket();
  } else {
    // for testing
    //console.log('Set ticket for WEB (testing)');
    // window.setHubValidTicket('c99BiajpSzuDW232xhCMrAAAAXo+7ENN43EH9z5MIwdQFLoI8QkqEtFrqS70M9QWwksDwmcNCP/dXS/J5K+8Q5NiEMfxE85chXo6XQwrADLGCLwxcRXb4bfKGsm/8vboVURJpn69/+Mx4rSRJ4FtvU3HSyzAJJPN', 31536000000);
    //window.setHubValidTicket('+MLugCn+ShGcOmBfyTplOAAAAXwoZQK631BL9+dLxpYsUeixnWdjYTi04kRxbqWYAK9KNZOJAMdtmb+Hl9FlIbyd4jnLeX46iEfOVVynYwaAZ4Y5qqqH9S2aePD+InfEYtxBfVqXBKQqh9ektxNP170pxqEguhW4', 31536000000);
  }
  function stopHandler() {
    window.setHubValidTicket = () => {};
  }
  return stopHandler;
});