<script lang="ts">var _a;
import { onMount } from 'svelte';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { resolveUrl } from '../../../../../js/url';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
$: key = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.key;
const onLinkClick = () => {
    resolveUrl(`ResetPassword`).func();
    const navigationEvent = new CustomEvent('navigateToAnotherPage');
    window.dispatchEvent(navigationEvent);
};
let text = `Remember me`;
let checked = false;
const onClick = (e) => {
    console.log('rememberME: ', e.target.value);
    tabContext.setParam(key, checked + "");
};
</script>
  <CardBase {element}>
    <div class="rememberme-wrapper">
      <div class="round">
        <input type="checkbox" id="remember_me" bind:checked on:click={onClick} />
        <label for="remember_me" />
      </div>
      <label for="remember_me" class="text">
          {@html text}
      </label>
      <!-- <YCLink class="forgot-password" href="/">Forgot Password?</YCLink> -->
      <a 
        class="forgot-password" 
        on:click={onLinkClick}
        href=""
      >
        Forgot Password?
      </a>
    </div>
  </CardBase>
  <style>.rememberme-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.rememberme-wrapper :global(.embeded-link) {
  text-decoration: none;
  color: var(--alav-s-primary);
}

.text {
  text-align: left;
  margin-left: 11px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #898989;
}

.round {
  position: relative;
  height: 18px;
  width: 18px;
}

.round label {
  background-color: transparent;
  border: 1px solid #D8D8D8;
  border-radius: 6px;
  cursor: pointer;
  position: absolute;
  top: 0; left: 0;
  height: 18px;
  width: 18px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
  height: 5px;
  width: 9px;
  top: 3px;
  left: 3px;
}

.round input[type="checkbox"] {
  visibility: hidden;
  margin: 0;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--alav-s-primary);
  border-color: var(--alav-s-primary);
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.rememberme-wrapper :global(.forgot-password) {
  margin-left: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: var(--alav-s-secondary);
}</style>
  