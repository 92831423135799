<script lang="ts">var _a;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { getContext } from 'svelte';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
let profileStore;
$: profileStore = findSourceStore($sources, 'YinzCamSignonServerProfile');
$: profileSource = $sources.find((s) => { var _a; return ((_a = s.spec) === null || _a === void 0 ? void 0 : _a.class) === 'YinzCamSignonServerProfile'; });
$: linkStore = findSourceStore($sources, 'YinzCamSignonServerLinks');
$: entries = profileStore ? $profileStore === null || $profileStore === void 0 ? void 0 : $profileStore.Entry : [];
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
let profileRefreshed = false;
$: if (profileSource && !profileRefreshed) {
    profileSource.refresh();
    profileRefreshed = true;
}
let data = {};
$: {
    entries === null || entries === void 0 ? void 0 : entries.map(item => {
        data[item.Key] = item.Value;
    });
}
$: console.log('linkcompleteheader: ', data);
$: image = (data === null || data === void 0 ? void 0 : data.image_url) || '/static/thumbs/default-avatar.png';
$: linked_image = ($mergedParams || {})['linkedImage'] || '/static/thumbs/default-avatar.png';
</script>

<CardBase {element}>
  <div class="link-complete-header-wrapper">
    <img src={image} alt="avatar" class="avatar" />
    <img src={'/static/icons/icon-attach.png'} alt="attach" class="attach" />
    <img src={linked_image} alt="linked" class="linked-image" />
  </div>
</CardBase>

<style>.link-complete-header-wrapper {
  display: flex;
  width: 100%;
  height: 55px !important;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background: white;
}

.avatar {
  height: 100%;
  width: 55px;
  border-radius: 50%;
}

.attach {
  width: 32px;
  height: 32px;
}

.linked-image {
  height: 100%;
  width: auto;
  border-radius: 50%;
}</style>