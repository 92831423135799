<Boundary>
  <div
    class="yinzcam-cards-region" style="
    {buildCssPadding(region.padding)}
    {(!(region.background && region.background.attachment === 'FIXED' && Device.ios))? buildCssBackground(region.background) : ''}
    --cards-region-height: {(!region.scrollable)? '100%' : 'initial'}
    {buildCssMargin(region.margin)}
  ">
    <div class="yinzcam-cards-region-ios-fixed-bg" style="{
      (region.background && region.background.attachment === 'FIXED' && Device.ios)?
      `${buildCssBackground({...region.background, attachment: null})}
      width: 100%; height: 100%; position: fixed; z-index: -10;
      ` :
      "display: none;"
    }"></div>
    <!-- <div style="display: flex; justify-content: center; align-items: center; height: 20px;">{sequenceId}</div> -->
    {#if regions}
      <CardsRegions parentSequenceId={sequenceId} {regions} direction={region.direction} flexWrap={region.flexWrap} />
    {:else if tabs}
      <CardsTabs parentSequenceId={sequenceId} {tabs} />
    {/if}
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.yinzcam-cards-region {
  /* height 100% is needed to allow regions to have content that scrolls off the screen (to give a fixed header effect) */
  height: var(--cards-region-height);
  width: 100%;
}

/* Hide scrollbar for IE, Edge and Firefox */

/*
  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  */

/* Hide scrollbar for Chrome, Safari and Opera */

/*
  *::-webkit-scrollbar {
    display: none;
  }
  */</style>

<script lang="ts">import { Logger, LoggerToken } from 'yinzcam-log';
import { YinzCamCardsServiceBackground, YinzCamCardsServiceRegion, YinzCamCardsServiceTab } from "yinzcam-cards";
import ROOT from '../../../inversify.config';
import { getToken } from 'inversify-token';
import CardsRegions from './CardsRegions.svelte';
import CardsTabs from './CardsTabs.svelte';
import { buildCssPadding, buildCssBackground, buildCssMargin } from '../utilities/index';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { Device } from 'framework7';
export let parentSequenceId = "";
export let sequenceId = "";
export let region;
const log = getToken(ROOT, LoggerToken);
const defaultBackground = {
    color: 'white',
    position: 'center',
    attachment: 'SCROLL'
};
let regions = null;
let tabs = null;
$: if (region) {
    let err = false;
    if (!region.regions && !region.tabs) {
        log.error("Region data must include either an array of regions or an array of tabs.");
        err = true;
    }
    if (region.regions && region.tabs) {
        log.error("Region data cannot include both an array of regions and an array of tabs.");
        err = true;
    }
    if (!err) {
        //console.log('REGIONS UPDATE IN PARENT', region.regions);
        regions = region.regions;
        tabs = region.tabs;
    }
}
/*
let editorData: CardsEditorComponentActionOptions<YinzCamCardsServiceRegion>;
$: editorData = {
  parentSequenceId,
  sequenceId,
  component: region,
  componentTypeName: 'Region',
  color: 'red',
  children: region.regions || region.tabs,
  // TODO: when this is supported, will have to determine childrenKey regions or tabs dynamically
  configSpec: {
    options: [
      {
        key: 'background',
        type: 'background',
        label: 'Background',
        helpText: ''
      }
    ]
  },
  updater: (r: YinzCamCardsServiceRegion) => {
    region = r;
  },
};
*/
</script>