import Dict from "collections/dict";
import Set from "collections/set";
import SortedArrayMap from "collections/sorted-array-map";
import { writable } from "svelte/store";
import { CardsDataSourceRegistration } from "./CardsDataSourceRegistration";
import { CardsDataElementInfo } from "./CardsDataElementInfo";
import { YinzCamCardsServiceSource, YinzCamCardsServiceTab } from "yinzcam-cards";
import { CardsDataSourceInfo } from "./CardsDataSourceInfo";
import { Container } from "inversify";
import { getNamed, getToken } from "inversify-token";
import { CardsDataSourceToken } from "./CardsDataSourceToken";
import { CardsDataSourceOutput } from "./CardsDataSourceOutput";
import { Readable } from "svelte/store";
import { Logger } from "loglevel";
import { LoggerToken } from "yinzcam-log";
import _ from "lodash";
import { expandTemplateParams, expandTemplateParamsRecursive } from "../utilities";

export class CardsDataSourceManager {
  private readonly log: Logger;

  /* Map of data source IDs to a timer handle. Entries in this map indicate that an update is pending for that data source. */
  private readonly sourceMap: Dict<string, CardsDataSourceInfo>;

  /* Map of CardElement IDs to the element's current sequence ID and registered data sources. */
  private readonly elementMap: Dict<string, CardsDataElementInfo>;

  /* Map of data source IDs to a list of registered elements ordered by their sequence ID. */
  private readonly registrationMap: Dict<string, SortedArrayMap<string, CardsDataSourceRegistration>>;

  /* Last set of data sources passed into setDataSources */
  private sources: YinzCamCardsServiceSource[];

  // TODO: find another way to get the container here directly from inversify?
  public constructor(private readonly container: Container) {
    this.log = getNamed(container, LoggerToken, 'CardsDataSourceManager');

    this.elementMap = new Dict({}, function(elementId: string): CardsDataElementInfo {
      let val: CardsDataElementInfo = { elementId, sourceIdSet: new Set(), reg: writable([]) };
      this.set(elementId, val);
      return val;
    });

    const self = this;
    this.registrationMap = new Dict({}, function(sourceId: string): SortedArrayMap {
      let val1 = new SortedArrayMap({}, null, null, function(sequenceId: string): CardsDataSourceRegistration {
        //console.log("DSM AUTO REG IN MAP", sequenceId, sourceId);
        let val2: CardsDataSourceRegistration = { sourceId, sequenceId, store: writable(null), refresh: () => {
          const sourceInfo: CardsDataSourceInfo = self.sourceMap.get(sourceId);
          sourceInfo?.comp?.refresh();
        }};
        this.set(sequenceId, val2);
        return val2;
      });
      this.set(sourceId, val1);
      return val1;
    });

    this.sourceMap = new Dict();

    this.sources = [];
  }

  public getDataSources(): YinzCamCardsServiceSource[] {
    return this.sources;
  }

  public setDataSources(sources: YinzCamCardsServiceSource[], params?: { [key: string]: string }) {
    // setup
    sources = sources || [];
    params = params || {};
    let newSourceIds: Set = new Set(sources.map(source => source.id));
    let curSourceIds: Set = new Set(this.sourceMap.keys());

    // remove deleted data sources
    curSourceIds.difference(newSourceIds).forEach(sourceId => {
      try {
        let sourceInfo = this.sourceMap.get(sourceId);
        if (sourceInfo.external) {
          //console.log('REMOVE DELETED DATA SOURCE', sourceId);
          sourceInfo.pendingDelete = true;
          this.requestSourceUpdate(sourceId);  
        }
      } catch (err) {
        this.log.error(`Unable to remove data source ${sourceId}: ${err}`);
      }
    });

    // check for changes in remaining data sources
    curSourceIds.intersection(newSourceIds).forEach(sourceId => {
      try {
        //console.log('UPDATE DATA SOURCE', sourceId);
        let spec = sources.find(source => source.id === sourceId)
        let sourceInfo: CardsDataSourceInfo = this.sourceMap.get(sourceId);
        if (!spec || !sourceInfo) {
          this.log.error(`Unable to update data source, couldn't find in intersection ${sourceId}`);
          return;
        }
        // XXX: This expands the spec and does a deep equal every time the data sources are set, which might be slow.
        spec = this.expandSpec(spec, params);
        if (_.isEqual(spec, sourceInfo.spec)) {
          // spec is the same, nothing to do
          return;
        }
        this.log.warn(`Requesting reload of data source ${sourceId}.`);
        sourceInfo.spec = spec;
        sourceInfo.pendingReload = true;
        this.requestSourceUpdate(sourceId);
      } catch (err) {
        this.log.error(`Unable to check for changes to data source ${sourceId}: ${err}`);
      }
    });

    // add new data sources
    newSourceIds.difference(curSourceIds).forEach(sourceId => {
      try {
        //console.log('ADD NEW DATA SOURCE', sourceId);
        // TODO: make the spec lookup a map if there are a lot of data sources
        let spec = sources.find(source => source.id === sourceId)
        if (!spec) {
          return;
        }
        // if we have a path and a query object, and the path includes template tags, interpolate the string
        ////console.log("checking for subs");
        spec = this.expandSpec(spec, params);
        let source = getNamed(this.container, CardsDataSourceToken, spec.class);
        let sourceInfo: CardsDataSourceInfo = { sourceId, spec, source, params, external: true, pendingInit: true, pendingReload: false, pendingDelete: false };
        this.sourceMap.set(sourceId, sourceInfo);
        this.requestSourceUpdate(sourceId);
      } catch (err) {
        this.log.error(`Unable to add data source ${sourceId}: ${err}`);
      }
    });

    this.sources = sources;
  }

  private expandSpec(spec: YinzCamCardsServiceSource, params?: { [key: string]: string; }) {
    if (spec.path && params && (spec.path.includes('{{') || spec.data)) {
      spec = _.clone(spec); // make a copy so we can modify path
      spec.path = expandTemplateParams(spec.path, params);
      spec.data = expandTemplateParamsRecursive(spec.data, params);
    }
    return spec;
  }

  /* Registers an element for a data source.*/
  public register(elementId: string, sequenceId: string, sourceIds: string[], params?: { [key: string]: string; }, defaultSourceClasses?: string[]): Readable<CardsDataSourceRegistration[]> {
    try {
      //console.log(`CardsDataSourceManager.register(${elementId}, ${sequenceId}, ${JSON.stringify(sourceIds)}, ${JSON.stringify(defaultSourceClasses)})`);

      // setup
      params = params || {};
      let elInfo: CardsDataElementInfo = this.elementMap.get(elementId);
      let sourceIdSet: Set = new Set(sourceIds || []);
      let defaultSourceClassSet: Set = new Set(defaultSourceClasses || []);

      // if no explicit data sources are configured, but the card has specified default data sources, use those instead
      if (sourceIdSet.length == 0 && defaultSourceClassSet.length > 0) {
        defaultSourceClassSet.forEach(sourceClass => {
          // Python style :)
          let sourceId = `__Default_${sourceClass}__`;
          if (!this.sourceMap.has(sourceId)) {
            let source = getNamed(this.container, CardsDataSourceToken, sourceClass);
            let sourceInfo: CardsDataSourceInfo = { sourceId, spec: { id: sourceId, class: sourceClass }, source, params, external: false, pendingInit: true, pendingReload: false, pendingDelete: false };
            //console.log("SOURCE ADD", sourceId);
            this.sourceMap.set(sourceId, sourceInfo);
            // since the system hasn't seen this source before, this is a new source for this card,
            // therefore the if block below will handle queuing a source update for it (which will take care of the init)
            // so don't need requestSourceUpdate here
          }
          sourceIdSet.add(sourceId);
        });
      }

      // short circuit if no changes
      if (elInfo.sequenceId !== sequenceId || !sourceIdSet.equals(elInfo.sourceIdSet)) {
        // unregister old sequence ID from all previous data sources and trigger data source update
        elInfo.sourceIdSet.forEach(sourceId => {
          // Delete our previous sequence ID, **only if we're still the owner of that sequence ID**.
          // NOTE: for insertions (where there's no previous sequence ID), this could leave
          // element info (elInfo) objects with incorrect (orphaned) sequence IDs on them.
          // I don't think this is a problem since those aren't used by the registration system, but just a note.
          const sourceMap = this.registrationMap.get(sourceId);
          if (sourceMap.get(elInfo.sequenceId)?.elementId === elementId) {
            sourceMap.delete(elInfo.sequenceId);
            this.requestSourceUpdate(sourceId);
          }
        });

        // update sequence ID and data sources
        let prevSequenceId = elInfo.sequenceId;
        elInfo.sequenceId = sequenceId;
        elInfo.sourceIdSet = sourceIdSet;

        // record the element and spec for each of the new data sources and request a data source update
        elInfo.sourceIdSet.forEach(sourceId => {
          let sourceInfo: CardsDataSourceInfo = this.sourceMap.get(sourceId);
          let reg: CardsDataSourceRegistration = this.registrationMap.get(sourceId).get(sequenceId);          
          reg.elementId = elementId;
          if (sourceInfo) {
            reg.spec = sourceInfo.spec;
          }
          //console.log("DSM REG FOR SOURCE", sequenceId, sourceInfo, this.registrationMap.get(sourceId));
          this.requestSourceUpdate(sourceId);
        });

        /* /^DSM.*(-SEC0004|DesktopVideo)/ */
        //console.log(`DSM REG: ${elementId} => ${sequenceId}, ${sourceIdSet.toJSON()} (${sourceIdSet.length}) (prev: ${prevSequenceId})`);
      }

      // update the element's registration store
      this.updateRegistrationStore(elInfo);

      return elInfo.reg;
    } catch (err) {
      this.log.error(`Unable to register element ${elementId}: ${err}`);
    }
  }
  
  private updateRegistrationStore(element: CardsDataElementInfo | string) {
    try {
      let elInfo: CardsDataElementInfo = null;
      if (typeof element === "string") {
        elInfo = this.elementMap.get(element) as CardsDataElementInfo;
      } else {
        elInfo = element;
      }
      if (!elInfo) {
        this.log.warn(`unable to update registration store because element was not found`);
        return;
      }
      if (!elInfo.sequenceId) {
        this.log.warn(`unable to update registration store for element because sequenceId is missing: ${elInfo.elementId}`);
        return;
      }
      elInfo.reg.set(elInfo.sourceIdSet.map(sourceId => this.registrationMap.get(sourceId).get(elInfo.sequenceId)));  
    } catch (err) {
      this.log.error(`Unable to update registration store for ${element}: ${err}`);
    }
  }

  /* Unregisters an element from all data sources. */
  public unregister(elementId: string) {
    try {
      // setup
      let elInfo: CardsDataElementInfo = this.elementMap.get(elementId);

      // checks
      if (!elInfo.sequenceId) {
        // TODO: log a warning here or something
      }

      // unregister old sequence ID from all previous data sources
      elInfo.sourceIdSet.forEach(sourceId => {
        // TODO: Do we need to set the writable to null here?
        this.registrationMap.get(sourceId).delete(elInfo.sequenceId);
        this.requestSourceUpdate(sourceId);
      });

      // unregister element info for this element
      this.elementMap.delete(elementId);

      //console.log(`DSM UNR: ${elementId} (prev: ${elInfo.sequenceId})`);
    } catch (err) {
      this.log.error(`Unable to unregister element ${elementId}: ${err}`);
    }
  }

  private requestSourceUpdate(sourceId: string) {
    try {
      // setup
      let sourceInfo: CardsDataSourceInfo = this.sourceMap.get(sourceId);
      //console.log("DSM START REQUEST SOURCE UPDATE", sourceInfo);

      // if this source doesn't exist or we've already queued up an update for this data source, bail out
      if (!sourceInfo || sourceInfo.timerHandle) {
        return;
      }

      // request an update on the next iteration of the event loop
      ////console.log("QUEUE SOURCE UPDATE", sourceInfo);
      sourceInfo.timerHandle = setTimeout(() => {
        this.doSourceUpdate(sourceId);
        sourceInfo.timerHandle = null;
        ////console.log("END SOURCE UPDATE TIMER CB", sourceInfo.spec.path);
      }, 0);
    } catch (err) {
      this.log.error(`Unable to request update for source ${sourceId}: ${err}`);
    }
  }

  /* This is where the magic happens. */
  private doSourceUpdate(sourceId: string) {
    try {
      // setup
      const sourceInfo: CardsDataSourceInfo = this.sourceMap.get(sourceId);
      const registrations = this.registrationMap.get(sourceId);
      //console.log("DSM DO SOURCE UPDATE", sourceInfo, registrations);
  
      // if this source doesn't exist, bail out
      if (!sourceInfo) {
        return;
      }    
  
      // if this source is pending delete, update all of downstream writables to null and remove from maps
      if (sourceInfo.pendingDelete || sourceInfo.pendingReload) {
        registrations.forEach((reg: CardsDataSourceRegistration) => {
          reg.store.set(null);
          reg.spec = null;
          if (reg.elementId) {
            this.updateRegistrationStore(reg.elementId);
          }
        });
        if (sourceInfo.unsubscribe) {
          sourceInfo.unsubscribe();
        }
        sourceInfo.pendingDelete = false;
        if (!sourceInfo.pendingReload) {
          //console.log("SOURCE DELETE", sourceId);
          this.sourceMap.delete(sourceId);
          return;
        }
      }
  
      // if the source is pending init, subscribe to the data source and update all registrations
      if (sourceInfo.pendingInit || sourceInfo.pendingReload) {
        const comp = sourceInfo.source.getDataSourceComponent(sourceInfo.spec.path, sourceInfo.spec.data, sourceInfo.params);
        sourceInfo.unsubscribe = comp.store.subscribe((output: CardsDataSourceOutput) => {
          ////console.log("REQUEST SOURCE UPDATE", sourceInfo.spec.path, output);
          sourceInfo.output = output;
          this.requestSourceUpdate(sourceInfo.sourceId);
        });
        registrations.forEach((reg: CardsDataSourceRegistration) => {
          reg.spec = sourceInfo.spec;
          if (reg.elementId) {
            this.updateRegistrationStore(reg.elementId);
          }
        });
        sourceInfo.pendingInit = false;
        sourceInfo.pendingReload = false;
        sourceInfo.comp = comp;
        //console.log("SOURCE INIT", sourceId);
        // intentional pass through
      }
  
      // otherwise, use the data source to set the writables on all of registered cards
      // TODO: use ids to skip updates if content hasn't changed; might have to hash the object though
      if (!sourceInfo.output || !sourceInfo.output.data) {
        registrations.forEach((reg: CardsDataSourceRegistration) => {
          reg.store.set(null);
        });
      } else if (Array.isArray(sourceInfo.output.data)) {
        let i = 0;
        let arr = sourceInfo.output.data;
        registrations.forEach((reg: CardsDataSourceRegistration) => {
          if (i < arr.length) {
            reg.store.set(arr[i++]);
          } else {
            reg.store.set(null);
          }
        });
      } else {
        ////console.log('PUSH DATA', sourceInfo.spec.path, sourceInfo.output.data);
        registrations.forEach((reg: CardsDataSourceRegistration) => {
          reg.store.set(sourceInfo.output.data);
        });
      }
    } catch (err) {
      this.log.error(`Unable to update data source ${sourceId}: ${err}`);
    }
  }
}
