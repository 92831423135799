import { Device } from "framework7";
import { f7ready, f7 } from "framework7-svelte";
import { Route } from "./routes";

const IS_ABS_URL = new RegExp('^(?:[a-z]+:)?//', 'i');

function isAbsoluteUrl(url: string): boolean {
  return IS_ABS_URL.test(url);
}

export interface ResolvedUrl {
  href: string;
  external: boolean;
  func: () => void;
  error?: boolean;
}

export function resolveUrl(url: string, currentRoute?: Route): ResolvedUrl {
  const err: ResolvedUrl = { href: '#', external: false, func: () => {}, error: true };
  if (!url) {
    //console.log('resolveUrl', 'missing URL', url);
    return err;
  }

  // convert relative paths to absolute paths based on the current path
  // TODO: If we introduce a yc://pages/{slug} URL format in the future, we may want to rewrite this
  // to generate one of those URLs instead, and have it resolved below.
  const relToAbsPath = (origUrl: string) => {
    let retUrl = origUrl;
    if (!isAbsoluteUrl(origUrl)) {
      // if the page has a slug on the end, remove the slug before resolving the relative URL
      let currentUrl: string | URL = window.location.href;
      if (currentRoute?.path) {
        let path = currentRoute.path;
        // TODO: I don't like this hard coded page-with-slug, need to make it dynamic based on the route somehow
        // maybe a reason to introduce yc://pages/{slug}
        if (currentRoute?.name === 'page-with-slug') {
          path = path.substring(0, path.lastIndexOf('/'));
        }
        currentUrl = new URL(path, window.location.origin);
      }
      const dummyUrl = new URL(retUrl, currentUrl);
      retUrl = dummyUrl.pathname + dummyUrl.search + dummyUrl.hash;
      //console.log('relToAbsPath', origUrl, currentRoute, currentUrl, retUrl);
    }
    return retUrl;
  }
  url = relToAbsPath(url);

  // convert absolute path URLs into yc://router/ URLs
  // note that this doesn't modify URLs that specify scheme
  let obj: URL = null;
  try {
    obj = new URL(url, 'yc://router/');
  } catch (err) {
    console.log('resolveUrl', 'error parsing URL', url, err);
    return err;
  }

  if (!obj?.protocol) {
    console.log('resolveUrl', 'missing protocol after parsing URL', url, obj);
    return err;
  }
  if (obj.protocol.startsWith('yc')) { // handles yc:// and yc-league-team://
    if (!obj?.pathname) {
      console.log('resolveUrl', 'invalid pathname', obj?.pathname);
      return err;
    }
    let toks = obj.pathname.split('/').filter(t => t); // removes empty tokens
    if (!toks) {
      console.log('resolveUrl', 'unable to split pathname', obj.pathname);
      return err;
    }
    // iOS (possibly all Apple browsers too?) consider the first component of the path as the hostname for yc urls
    if (obj.hostname) {
      toks.unshift(obj.hostname);
    }
    if (!toks[0]) {
      console.log('resolveUrl', 'null first component of ycURL path', obj.pathname);
      return err;
    }
    switch (toks[0].toLowerCase()) {
      case 'router':
        let path = toks.slice(1).join('/');
        let href = `/${path}${obj.search}${obj.hash}`;
        return { href, external: false, func: () => f7ready((f7) => f7.views.main.router.navigate(href)) };
      case 'feature':
        if (!toks[1]) {
          console.log('resolveUrl', 'null second component of feature ycURL path', obj.pathname);
          return err;
        }
        let featureId = toks[1].toUpperCase();
        switch (featureId) {
          case 'GT_BOX':
          case 'GT_PRE':
            let gameId = obj.searchParams.get('id');
            let slug = obj.searchParams.get('slug');
            if (!gameId && !slug) {
              console.log('resolveUrl', 'feature/GT_BOX ycURL missing id or slug param', url);
              return err;
            }
            let isPregame = obj.searchParams.get('isPregame') === 'true' || featureId === 'GT_PRE';
            let suffix = ''
            if (slug) {
              suffix = `/${encodeURIComponent(slug)}?isPregame=${isPregame}`
            } else {
              suffix = `?gameId=${encodeURIComponent(gameId)}&isPregame=${isPregame}`
            }
            let href = relToAbsPath(`MatchCenter${suffix}`);
            return { href, external: false, func: () => f7ready((f7) => f7.views.main.router.navigate(href))};
          case 'WEBSITE':
            let webUrl = obj.searchParams.get('url');
            if (!webUrl) {
              console.log('resolveUrl', 'feature/WEBSITE ycURL missing url param', webUrl);
              return err;
            }
            // in a mobile app, open in a webview, but in a browser open normally
            if (Device.cordova) {
              let title = obj.searchParams.get('title');
              if (!title) {
                console.log('resolveUrl', 'WARNING: feature/WEBSITE ycURL missing title param', url);
              }
              let href = `/webview?url=${encodeURIComponent(webUrl)}`;
              if (title) {
                href = `${href}&title=${encodeURIComponent(title)}`;
              }
              return { href, external: false, func: () => f7ready((f7) => f7.views.main.router.navigate(href)) };
            } else {
              return { href: webUrl, external: true, func: () => window.open(webUrl, "_blank") };
            }
          case 'INTERNAL_BROWSER':
            let ibUrl = obj.searchParams.get('url');
            if (!ibUrl) {
              console.log('resolveUrl', 'feature/INTERNAL_BROWSER ycURL missing url param', ibUrl);
              return err;
            }
            if (Device.cordova) {
              return {
                href: `javascript:cordova.InAppBrowser.open('${ibUrl}', '_blank', 'location=no,mediaPlaybackRequiresUserAction=no,allowInlineMediaPlayback=yes')`,
                external: false,
                func: () => cordova.InAppBrowser.open(ibUrl, '_blank', 'location=no,mediaPlaybackRequiresUserAction=no,allowInlineMediaPlayback=yes')
              };
            } else {
              return { href: webUrl, external: true, func: () => window.open(ibUrl, "_blank") };
            }
          default:
            console.log('resolveUrl', 'unrecognized second component of feature ycURL path', obj.pathname);
            return err;
        }
        break;
      case 'action':
        if (!toks[1]) {
          console.log('resolveUrl', 'null second component of action ycURL path', obj.pathname);
          return err;
        }
        switch (toks[1].toUpperCase()) {
          case 'EXTERNAL_URL':
            let extUrl = obj.searchParams.get('url');
            if (!extUrl) {
              console.log('resolveUrl', 'action/EXTERNAL_URL ycURL missing url param', extUrl);
              return err;
            }
            if (Device.cordova) {
              return {
                href: `javascript:cordova.InAppBrowser.open('${extUrl}', '_system', 'location=no,hidden=yes')`,
                external: false,
                func: () => cordova.InAppBrowser.open(extUrl, '_system', 'location=no,hidden=yes')
              };
            } else {
              return { href: extUrl, external: true, func: () => window.open(extUrl, "_blank") };
            }
          default:
            console.log('resolveUrl', 'unrecognized second component of action ycURL path', obj.pathname);
            return err;
        }
        break;
    }
    console.log('resolveUrl', 'ycURL processing did not resolve URL', url);
    return err;
  } else if (obj.protocol.startsWith('http') && Device.cordova) {
    // open http links externally if inside a mobile app
    return {
      href: `javascript:cordova.InAppBrowser.open('${url}', '_blank', 'location=no,mediaPlaybackRequiresUserAction=no,allowInlineMediaPlayback=yes')`,
      external: false,
      func: () => cordova.InAppBrowser.open(url, '_blank', 'location=no,mediaPlaybackRequiresUserAction=no,allowInlineMediaPlayback=yes')
    };
  }
  // external http, https, other
  return { href: url, external: true, func: () => window.open(url, "_blank") };
}
