import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServer, YinzCamAppServerToken } from "yinzcam-appserver";
import { JSONSchema4 } from "json-schema";

@injectable()
export class YinzCamAppServerAuxiliaryStandings extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) private readonly server: YinzCamAppServer) {
    super({ server })
  }

  public getDisplayName(): string {
    return 'YinzCam Sports Data - Secondary Competition Standings';
  }

  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    const rsp = await this.server.singleRequest({ path: "/Auxiliary/Competitions" });
    return [].concat((<any>rsp?.data)?.ArrayOfAuxCompetitionXml?.AuxCompetitionXml)?.filter(o => o).map((o) => ({
      name: 'Second Team Competition: ' + (o.CompetitionName?._text || o.Id?._text || ''),
      path: `/Auxiliary/Standings?compId=${o.Id._text}`
    }));
  }

  public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
    return null;
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;
    return {
      standings: [].concat(data?.ArrayOfAuxStandingsXml?.AuxStandingsXml).filter(obj => obj.Id)
    };
  }
}
