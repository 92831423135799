export const dummy_rounds_data = {
    "rounds": [{
            "id": "27d6cdb8-6070-4441-9b17-629f3b51c267",
            "type": "WEEKLY",
            "title": "TOURNAMENT",
            "dayOfWeek": "Valspar Championship",
            "startTime": 1647388740000,
            "endTime": 1847820740000,
            "open": true,
            "description": "100 POINT PICK",
            "questions": [{
                    "id": "718b054c-d5a6-47c0-9dd3-07dc7f8f013d",
                    "prompt": "To Win",
                    "startTime": 1647388740000,
                    "endTime": 1847820740000,
                    "title": "To Win",
                    "pointValue": 100,
                    "description": "To Win - 11:59 Tee Time",
                    "showTeeTime": false,
                    "players": [{
                            "id": "Sam Burns",
                            "name": "Sam Burns",
                            "selectedText": "Sam Burns",
                            "firstName": "Sam",
                            "lastName": "Burns",
                            "dob": "1996-07-23",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "3c90876d1d44359cfeeb3b866e8c62af",
                            "bettingOdds": -151.0,
                            "placedBet": false
                        }, {
                            "id": "Xander Schauffele",
                            "name": "Xander Schauffele",
                            "selectedText": "Xander Schauffele",
                            "firstName": "Xander",
                            "lastName": "Schauffele",
                            "dob": "1993-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "fa74fb28467869add123aa574505a1d3",
                            "bettingOdds": 0.0,
                            "placedBet": true,
                            "betPlacedAt": 2000.0,
                            "potentialWinnings": 2000.0
                        }, {
                            "id": "Davis Riley",
                            "name": "Davis Riley",
                            "selectedText": "Davis Riley",
                            "firstName": "Davis",
                            "lastName": "Riley",
                            "dob": "1996-12-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a5b96145135d5398b4c2f355c6c56c80",
                            "bettingOdds": 100.0,
                            "placedBet": false
                        }
                    ]
                }, {
                    "id": "9b6970b1-8558-4abf-8e8c-c91a4fa8cd18",
                    "prompt": "Top 10",
                    "startTime": 1647388740000,
                    "endTime": 1647777300000,
                    "title": "Top 10",
                    "pointValue": 100,
                    "description": "Top 10 - 10:19 Tee Time",
                    "showTeeTime": false,
                    "players": [{
                            "id": "Sam Burns",
                            "name": "Sam Burns",
                            "selectedText": "Sam Burns",
                            "firstName": "Sam",
                            "lastName": "Burns",
                            "dob": "1996-07-23",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f0e006c937eda64c22a8cbfee2eac505",
                            "bettingOdds": -9999.0,
                            "placedBet": true,
                            "betPlacedAt": 235.0,
                            "potentialWinnings": 235.0
                        }, {
                            "id": "Matthew NeSmith",
                            "name": "Matthew NeSmith",
                            "selectedText": "Matthew NeSmith",
                            "firstName": "Matthew",
                            "lastName": "NeSmith",
                            "dob": "1993-09-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a4e028a4d053774a2d2ab683bad32405",
                            "bettingOdds": -9999.0,
                            "placedBet": false
                        }, {
                            "id": "Adam Hadwin",
                            "name": "Adam Hadwin",
                            "selectedText": "Adam Hadwin",
                            "firstName": "Adam",
                            "lastName": "Hadwin",
                            "dob": "1987-11-02",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "ec2eb3c9eec2a92d0287b8d6563274e6",
                            "bettingOdds": -599.0,
                            "placedBet": false
                        }, {
                            "id": "Xander Schauffele",
                            "name": "Xander Schauffele",
                            "selectedText": "Xander Schauffele",
                            "firstName": "Xander",
                            "lastName": "Schauffele",
                            "dob": "1993-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "11e0116e2bd6c3243fe39bfc86694173",
                            "bettingOdds": -250.0,
                            "placedBet": false
                        }, {
                            "id": "Matthew Fitzpatrick",
                            "name": "Matthew Fitzpatrick",
                            "selectedText": "Matthew Fitzpatrick",
                            "firstName": "Matt",
                            "lastName": "Fitzpatrick",
                            "dob": "1994-09-01",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "18a038281c3753f353f937abbcac48e5",
                            "bettingOdds": -224.0,
                            "placedBet": false
                        }, {
                            "id": "Brian Harman",
                            "name": "Brian Harman",
                            "selectedText": "Brian Harman",
                            "firstName": "Brian",
                            "lastName": "Harman",
                            "dob": "1987-01-19",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "ed19fb241759bfeb93586cf055b744cd",
                            "bettingOdds": -134.0,
                            "placedBet": false
                        }, {
                            "id": "Troy Merritt",
                            "name": "Troy Merritt",
                            "selectedText": "Troy Merritt",
                            "firstName": "Troy",
                            "lastName": "Merritt",
                            "dob": "1985-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32640.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "481e77682219f122c17b7b85f0db3065",
                            "bettingOdds": 100.0,
                            "placedBet": false
                        }, {
                            "id": "Tommy Fleetwood",
                            "name": "Tommy Fleetwood",
                            "selectedText": "Tommy Fleetwood",
                            "firstName": "Tommy",
                            "lastName": "Fleetwood",
                            "dob": "1991-01-19",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "89a0fb42b3b2f8393786ff866d8d5ae3",
                            "bettingOdds": 110.0,
                            "placedBet": false
                        }, {
                            "id": "Alexander Noren",
                            "name": "Alexander Noren",
                            "selectedText": "Alexander Noren",
                            "firstName": "Alex",
                            "lastName": "Noren",
                            "dob": "1982-07-12",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "af10014fdd93f29c062027f011726246",
                            "bettingOdds": 150.0,
                            "placedBet": false
                        }, {
                            "id": "Robert Streb",
                            "name": "Robert Streb",
                            "selectedText": "Robert Streb",
                            "firstName": "Robert",
                            "lastName": "Streb",
                            "dob": "1987-04-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c673d8c0631b6e134255dc2c2f12dc0f",
                            "bettingOdds": 165.0,
                            "placedBet": false
                        }, {
                            "id": "Tyler Duncan",
                            "name": "Tyler Duncan",
                            "selectedText": "Tyler Duncan",
                            "firstName": "Tyler",
                            "lastName": "Duncan",
                            "dob": "1989-07-13",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "bca05084a91fe02c161c7bb4d1035eec",
                            "bettingOdds": 250.0,
                            "placedBet": false
                        }, {
                            "id": "Shane Lowry",
                            "name": "Shane Lowry",
                            "selectedText": "Shane Lowry",
                            "firstName": "Shane",
                            "lastName": "Lowry",
                            "dob": "1987-04-02",
                            "gender": "Male",
                            "country": "IRL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                            "oddsId": "2683c4594233f9136586527e358c4507",
                            "bettingOdds": 360.0,
                            "placedBet": false
                        }, {
                            "id": "Kevin Streelman",
                            "name": "Kevin Streelman",
                            "selectedText": "Kevin Streelman",
                            "firstName": "Kevin",
                            "lastName": "Streelman",
                            "dob": "1978-11-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "0e84fece53e1fc312afae76dce57e5a1",
                            "bettingOdds": 360.0,
                            "placedBet": false
                        }, {
                            "id": "Stewart Cink",
                            "name": "Stewart Cink",
                            "selectedText": "Stewart Cink",
                            "firstName": "Stewart",
                            "lastName": "Cink",
                            "dob": "1973-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/20229.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "25cb7e211eb1334c56828bbe8480f605",
                            "bettingOdds": 400.0,
                            "placedBet": false
                        }, {
                            "id": "Tyrrell Hatton",
                            "name": "Tyrrell Hatton",
                            "selectedText": "Tyrrell Hatton",
                            "firstName": "Tyrrell",
                            "lastName": "Hatton",
                            "dob": "1991-10-14",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "b3a25e087edf4a24759449078cea3492",
                            "bettingOdds": 650.0,
                            "placedBet": false
                        }, {
                            "id": "Scott Stallings",
                            "name": "Scott Stallings",
                            "selectedText": "Scott Stallings",
                            "firstName": "Scott",
                            "lastName": "Stallings",
                            "dob": "1985-03-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30692.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a089a2f1b7d2c8f6cd57b9afc4d4a28e",
                            "bettingOdds": 700.0,
                            "placedBet": false
                        }, {
                            "id": "Bernd Wiesberger",
                            "name": "Bernd Wiesberger",
                            "selectedText": "Bernd Wiesberger",
                            "firstName": "Bernd",
                            "lastName": "Wiesberger",
                            "dob": "1985-10-08",
                            "gender": "Male",
                            "country": "AUT",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                            "oddsId": "95902bd631d485a07176b71984d44eeb",
                            "bettingOdds": 750.0,
                            "placedBet": false
                        }, {
                            "id": "Sahith Theegala",
                            "name": "Sahith Theegala",
                            "selectedText": "Sahith Theegala",
                            "firstName": "Sahith",
                            "lastName": "Theegala",
                            "dob": "1997-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "16123bc2912320247590ac4844c6380d",
                            "bettingOdds": 800.0,
                            "placedBet": false
                        }, {
                            "id": "Gary Woodland",
                            "name": "Gary Woodland",
                            "selectedText": "Gary Woodland",
                            "firstName": "Gary",
                            "lastName": "Woodland",
                            "dob": "1984-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b6a0b2e83ee846ed8c1c6fd7ff64205b",
                            "bettingOdds": 800.0,
                            "placedBet": false
                        }, {
                            "id": "Matt Kuchar",
                            "name": "Matt Kuchar",
                            "selectedText": "Matt Kuchar",
                            "firstName": "Matt",
                            "lastName": "Kuchar",
                            "dob": "1978-06-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "38c134982e6603b7cabb9ab29dba0baf",
                            "bettingOdds": 800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Viktor Hovland",
                            "name": "Viktor Hovland",
                            "selectedText": "Viktor Hovland",
                            "firstName": "Viktor",
                            "lastName": "Hovland",
                            "dob": "1997-09-18",
                            "gender": "Male",
                            "country": "NOR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                            "oddsId": "b0f4fd17436a623a5f2714d961bb8570",
                            "bettingOdds": 1100.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Russell Knox",
                            "name": "Russell Knox",
                            "selectedText": "Russell Knox",
                            "firstName": "Russell",
                            "lastName": "Knox",
                            "dob": "1985-06-21",
                            "gender": "Male",
                            "country": "SCO",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                            "oddsId": "8baedda5c82a775eb5a8e5cf6cfea46a",
                            "bettingOdds": 1100.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Patton Kizzire",
                            "name": "Patton Kizzire",
                            "selectedText": "Patton Kizzire",
                            "firstName": "Patton",
                            "lastName": "Kizzire",
                            "dob": "1986-03-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f5a8e55dd036dfbb4b13558e94dfea54",
                            "bettingOdds": 1200.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brian Stuard",
                            "name": "Brian Stuard",
                            "selectedText": "Brian Stuard",
                            "firstName": "Brian",
                            "lastName": "Stuard",
                            "dob": "1982-12-10",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31560.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "23f0beb5115c76195faaf38096db12fd",
                            "bettingOdds": 1500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Greyson Sigg",
                            "name": "Greyson Sigg",
                            "selectedText": "Greyson Sigg",
                            "firstName": "Greyson",
                            "lastName": "Sigg",
                            "dob": "1995-02-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51890.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "6c8d33b19127b2aad8b5db45a42f17a5",
                            "bettingOdds": 1600.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Nate Lashley",
                            "name": "Nate Lashley",
                            "selectedText": "Nate Lashley",
                            "firstName": "Nate",
                            "lastName": "Lashley",
                            "dob": "1982-12-12",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b8b910119e9e569678ff196625fa8851",
                            "bettingOdds": 1600.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brandon Wu",
                            "name": "Brandon Wu",
                            "selectedText": "Brandon Wu",
                            "firstName": "Brandon",
                            "lastName": "Wu",
                            "dob": "1997-02-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/52374.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "35daa3c7189170b53a837c9004f3ae6c",
                            "bettingOdds": 1600.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Harry Higgs",
                            "name": "Harry Higgs",
                            "selectedText": "Harry Higgs",
                            "firstName": "Harry",
                            "lastName": "Higgs",
                            "dob": "1991-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "95404e991d81766b3a0bccff2b2798be",
                            "bettingOdds": 1800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brice Garnett",
                            "name": "Brice Garnett",
                            "selectedText": "Brice Garnett",
                            "firstName": "Brice",
                            "lastName": "Garnett",
                            "dob": "1983-09-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29535.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "2eb56f645cf0b206f20af0ee6df7a1b8",
                            "bettingOdds": 2000.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Luke Donald",
                            "name": "Luke Donald",
                            "selectedText": "Luke Donald",
                            "firstName": "Luke",
                            "lastName": "Donald",
                            "dob": "1977-12-07",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "0d0e225f445983009e5db991bcb3893a",
                            "bettingOdds": 2200.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Jhonattan Vegas",
                            "name": "Jhonattan Vegas",
                            "selectedText": "Jhonattan Vegas",
                            "firstName": "Jhonattan",
                            "lastName": "Vegas",
                            "dob": "1984-08-19",
                            "gender": "Male",
                            "country": "VEN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27064.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_VEN.png",
                            "oddsId": "810cc4f1903964e16d6aea528bcb4826",
                            "bettingOdds": 2200.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Joel Dahmen",
                            "name": "Joel Dahmen",
                            "selectedText": "Joel Dahmen",
                            "firstName": "Joel",
                            "lastName": "Dahmen",
                            "dob": "1987-11-11",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34076.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "897233fee3cff17912058473dc2535c0",
                            "bettingOdds": 2200.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Joseph Bramlett",
                            "name": "Joseph Bramlett",
                            "selectedText": "Joseph Bramlett",
                            "firstName": "Joseph",
                            "lastName": "Bramlett",
                            "dob": "1988-04-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34255.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f40e7f5c611923ddd87eed1d7ced12e4",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Richy Werenski",
                            "name": "Richy Werenski",
                            "selectedText": "Richy Werenski",
                            "firstName": "Richy",
                            "lastName": "Werenski",
                            "dob": "1991-12-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47128.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c24416bd5e7ffbbb9bc735bb424af0f6",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brooks Koepka",
                            "name": "Brooks Koepka",
                            "selectedText": "Brooks Koepka",
                            "firstName": "Brooks",
                            "lastName": "Koepka",
                            "dob": "1990-05-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "6b6ddbdb38ca7ad59f42a6fabf61625a",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Nick Taylor",
                            "name": "Nick Taylor",
                            "selectedText": "Nick Taylor",
                            "firstName": "Nick",
                            "lastName": "Taylor",
                            "dob": "1988-04-14",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25493.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "72bd519fa1fb05b2b47ab048d034db3b",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Mito Guillermo Pereira",
                            "name": "Mito Guillermo Pereira",
                            "selectedText": "Mito Guillermo Pereira",
                            "firstName": "Mito",
                            "lastName": "Pereira",
                            "dob": "1995-03-31",
                            "gender": "Male",
                            "country": "CHI",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                            "oddsId": "0520acac8645a713c69ef0ae79050f8d",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "J. J. Spaun",
                            "name": "J. J. Spaun",
                            "selectedText": "J. J. Spaun",
                            "firstName": "J.J.",
                            "lastName": "Spaun",
                            "dob": "1990-08-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "1418eea091a9cfc47edf1751bd8cfebf",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Chez Reavie",
                            "name": "Chez Reavie",
                            "selectedText": "Chez Reavie",
                            "firstName": "Chez",
                            "lastName": "Reavie",
                            "dob": "1981-11-12",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26476.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "22d142a7ec4dc43fa94761e69753751f",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Bill Haas",
                            "name": "Bill Haas",
                            "selectedText": "Bill Haas",
                            "firstName": "Bill",
                            "lastName": "Haas",
                            "dob": "1982-05-24",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24924.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "e6b5ad2f71e3277de419d6efbb10de0f",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Danny Lee",
                            "name": "Danny Lee",
                            "selectedText": "Danny Lee",
                            "firstName": "Danny",
                            "lastName": "Lee",
                            "dob": "1990-07-24",
                            "gender": "Male",
                            "country": "NZL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                            "oddsId": "399a1c719891c376f067733080b612cd",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brandon Hagy",
                            "name": "Brandon Hagy",
                            "selectedText": "Brandon Hagy",
                            "firstName": "Brandon",
                            "lastName": "Hagy",
                            "dob": "1991-03-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46550.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f116f32dd2b380fa2751f2293b4b8fe8",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Kiradech Aphibarnrat",
                            "name": "Kiradech Aphibarnrat",
                            "selectedText": "Kiradech Aphibarnrat",
                            "firstName": "Kiradech",
                            "lastName": "Aphibarnrat",
                            "dob": "1989-07-23",
                            "gender": "Male",
                            "country": "THA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30978.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_THA.png",
                            "oddsId": "c1cf1442ce1f5dc04f7fdbafd106bee8",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Louis Oosthuizen",
                            "name": "Louis Oosthuizen",
                            "selectedText": "Louis Oosthuizen",
                            "firstName": "Louis",
                            "lastName": "Oosthuizen",
                            "dob": "1982-10-19",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "bd717ce2459e624e14a4f90565500e85",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Kevin Kisner",
                            "name": "Kevin Kisner",
                            "selectedText": "Kevin Kisner",
                            "firstName": "Kevin",
                            "lastName": "Kisner",
                            "dob": "1984-02-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "8e2927d5c015d6b5ff02698fbb46dd8e",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Harold Varner III",
                            "name": "Harold Varner III",
                            "selectedText": "Harold Varner III",
                            "firstName": "Harold",
                            "lastName": "Varner III",
                            "dob": "1990-08-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "ac729d025a2aed24ef3c931471f865dd",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Kramer Hickok",
                            "name": "Kramer Hickok",
                            "selectedText": "Kramer Hickok",
                            "firstName": "Kramer",
                            "lastName": "Hickok",
                            "dob": "1992-04-14",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49298.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "90310607eb89d523aa3531f3c03c2421",
                            "bettingOdds": 2800.0,
                            "placedBet": false
                            
                        }
                    ]
                }, {
                    "id": "d19d3ec0-2384-4dcf-bc58-77e682e57db0",
                    "prompt": "Top 20",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Top 20",
                    "pointValue": 100,
                    "description": "Top 20 - 10:19 Tee Time",
                    "showTeeTime": false,
                    "players": [{
                            "id": "Justin Thomas",
                            "name": "Justin Thomas",
                            "selectedText": "Justin Thomas",
                            "firstName": "Justin",
                            "lastName": "Thomas",
                            "dob": "1993-04-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "26c44a2bba4d03610e2e081bb0a3c3c1",
                            "bettingOdds": -100000.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Sam Burns",
                            "name": "Sam Burns",
                            "selectedText": "Sam Burns",
                            "firstName": "Sam",
                            "lastName": "Burns",
                            "dob": "1996-07-23",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "eda35f2ad4b5950cc713c965740e7244",
                            "bettingOdds": -100000.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Davis Riley",
                            "name": "Davis Riley",
                            "selectedText": "Davis Riley",
                            "firstName": "Davis",
                            "lastName": "Riley",
                            "dob": "1996-12-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "812595acc92e01f6c83612ec1cf45685",
                            "bettingOdds": -100000.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Matthew NeSmith",
                            "name": "Matthew NeSmith",
                            "selectedText": "Matthew NeSmith",
                            "firstName": "Matthew",
                            "lastName": "NeSmith",
                            "dob": "1993-09-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "43c1df625bc3ce2071b158cc76d6bb79",
                            "bettingOdds": -100000.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Adam Hadwin",
                            "name": "Adam Hadwin",
                            "selectedText": "Adam Hadwin",
                            "firstName": "Adam",
                            "lastName": "Hadwin",
                            "dob": "1987-11-02",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "3c0ddfd9568843eef2cf71284a209085",
                            "bettingOdds": -9999.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Xander Schauffele",
                            "name": "Xander Schauffele",
                            "selectedText": "Xander Schauffele",
                            "firstName": "Xander",
                            "lastName": "Schauffele",
                            "dob": "1993-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a9795784cb011be33d440f38b2f4053f",
                            "bettingOdds": -1999.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Matthew Fitzpatrick",
                            "name": "Matthew Fitzpatrick",
                            "selectedText": "Matthew Fitzpatrick",
                            "firstName": "Matt",
                            "lastName": "Fitzpatrick",
                            "dob": "1994-09-01",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "9f396ab5039a7d9ab32c8f814e63b85a",
                            "bettingOdds": -1249.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brian Harman",
                            "name": "Brian Harman",
                            "selectedText": "Brian Harman",
                            "firstName": "Brian",
                            "lastName": "Harman",
                            "dob": "1987-01-19",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b61ec6d8a544fe47514b671b51f13c79",
                            "bettingOdds": -714.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Troy Merritt",
                            "name": "Troy Merritt",
                            "selectedText": "Troy Merritt",
                            "firstName": "Troy",
                            "lastName": "Merritt",
                            "dob": "1985-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32640.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "968e51ecfa2b9ed37147a770d5e083cd",
                            "bettingOdds": -500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Tommy Fleetwood",
                            "name": "Tommy Fleetwood",
                            "selectedText": "Tommy Fleetwood",
                            "firstName": "Tommy",
                            "lastName": "Fleetwood",
                            "dob": "1991-01-19",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "40c3f95b8ab13e072a441bc1dc14a7e4",
                            "bettingOdds": -454.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Alexander Noren",
                            "name": "Alexander Noren",
                            "selectedText": "Alexander Noren",
                            "firstName": "Alex",
                            "lastName": "Noren",
                            "dob": "1982-07-12",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "348774cc26897daeb7d59717699e2614",
                            "bettingOdds": -357.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Robert Streb",
                            "name": "Robert Streb",
                            "selectedText": "Robert Streb",
                            "firstName": "Robert",
                            "lastName": "Streb",
                            "dob": "1987-04-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5fb1a6e2f21d4bf0192722e47c365f52",
                            "bettingOdds": -303.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Tyler Duncan",
                            "name": "Tyler Duncan",
                            "selectedText": "Tyler Duncan",
                            "firstName": "Tyler",
                            "lastName": "Duncan",
                            "dob": "1989-07-13",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "4684450c5c280fe0d91001002a26544a",
                            "bettingOdds": -188.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Kevin Streelman",
                            "name": "Kevin Streelman",
                            "selectedText": "Kevin Streelman",
                            "firstName": "Kevin",
                            "lastName": "Streelman",
                            "dob": "1978-11-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5c6437f57dc63d65aa7e08e3adac1a5e",
                            "bettingOdds": -161.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Shane Lowry",
                            "name": "Shane Lowry",
                            "selectedText": "Shane Lowry",
                            "firstName": "Shane",
                            "lastName": "Lowry",
                            "dob": "1987-04-02",
                            "gender": "Male",
                            "country": "IRL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                            "oddsId": "4df93c00d3d0c305efa4705c22358d34",
                            "bettingOdds": -136.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Stewart Cink",
                            "name": "Stewart Cink",
                            "selectedText": "Stewart Cink",
                            "firstName": "Stewart",
                            "lastName": "Cink",
                            "dob": "1973-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/20229.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "d60ea12f1f17d3282c76c9d12becd34d",
                            "bettingOdds": -136.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Tyrrell Hatton",
                            "name": "Tyrrell Hatton",
                            "selectedText": "Tyrrell Hatton",
                            "firstName": "Tyrrell",
                            "lastName": "Hatton",
                            "dob": "1991-10-14",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "e64af559ec1a4005c69ea675e4a8199b",
                            "bettingOdds": 110.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Bernd Wiesberger",
                            "name": "Bernd Wiesberger",
                            "selectedText": "Bernd Wiesberger",
                            "firstName": "Bernd",
                            "lastName": "Wiesberger",
                            "dob": "1985-10-08",
                            "gender": "Male",
                            "country": "AUT",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                            "oddsId": "178c8d893b57bde2805554f1246163fd",
                            "bettingOdds": 125.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Scott Stallings",
                            "name": "Scott Stallings",
                            "selectedText": "Scott Stallings",
                            "firstName": "Scott",
                            "lastName": "Stallings",
                            "dob": "1985-03-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30692.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "6dd1e93e062204b97bad3589e89e2c9a",
                            "bettingOdds": 130.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Gary Woodland",
                            "name": "Gary Woodland",
                            "selectedText": "Gary Woodland",
                            "firstName": "Gary",
                            "lastName": "Woodland",
                            "dob": "1984-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c25d34b33cf0fdd33763723e444f4b52",
                            "bettingOdds": 140.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Sahith Theegala",
                            "name": "Sahith Theegala",
                            "selectedText": "Sahith Theegala",
                            "firstName": "Sahith",
                            "lastName": "Theegala",
                            "dob": "1997-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "6f852174d74d751f5aec2e7181c5502b",
                            "bettingOdds": 140.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Matt Kuchar",
                            "name": "Matt Kuchar",
                            "selectedText": "Matt Kuchar",
                            "firstName": "Matt",
                            "lastName": "Kuchar",
                            "dob": "1978-06-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "2be5764a1a9ebc92011cd5546ef85c2c",
                            "bettingOdds": 150.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Viktor Hovland",
                            "name": "Viktor Hovland",
                            "selectedText": "Viktor Hovland",
                            "firstName": "Viktor",
                            "lastName": "Hovland",
                            "dob": "1997-09-18",
                            "gender": "Male",
                            "country": "NOR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                            "oddsId": "b33ada1b2c98e20d8212a48d738845a2",
                            "bettingOdds": 175.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Russell Knox",
                            "name": "Russell Knox",
                            "selectedText": "Russell Knox",
                            "firstName": "Russell",
                            "lastName": "Knox",
                            "dob": "1985-06-21",
                            "gender": "Male",
                            "country": "SCO",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                            "oddsId": "6e5d64506d71c7fc7ab4f8e16c7147fa",
                            "bettingOdds": 188.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Patton Kizzire",
                            "name": "Patton Kizzire",
                            "selectedText": "Patton Kizzire",
                            "firstName": "Patton",
                            "lastName": "Kizzire",
                            "dob": "1986-03-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "fc7c97a08c49acb13c545259ac1644d3",
                            "bettingOdds": 200.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Adam Svensson",
                            "name": "Adam Svensson",
                            "selectedText": "Adam Svensson",
                            "firstName": "Adam",
                            "lastName": "Svensson",
                            "dob": "1993-12-31",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40115.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "a3993d59763b99538eebd198963123c6",
                            "bettingOdds": 230.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Cheng-Tsung Pan",
                            "name": "Cheng-Tsung Pan",
                            "selectedText": "Cheng-Tsung Pan",
                            "firstName": "C.T.",
                            "lastName": "Pan",
                            "dob": "1991-11-12",
                            "gender": "Male",
                            "country": "TPE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29908.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_TPE.png",
                            "oddsId": "3371ab43e36abdaa4cf3291f0d76a94b",
                            "bettingOdds": 230.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brian Stuard",
                            "name": "Brian Stuard",
                            "selectedText": "Brian Stuard",
                            "firstName": "Brian",
                            "lastName": "Stuard",
                            "dob": "1982-12-10",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31560.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b44a9cb46ba98e01233c8e093cfc58a9",
                            "bettingOdds": 275.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Greyson Sigg",
                            "name": "Greyson Sigg",
                            "selectedText": "Greyson Sigg",
                            "firstName": "Greyson",
                            "lastName": "Sigg",
                            "dob": "1995-02-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51890.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "8c56d667cb143145df3f018acd86e017",
                            "bettingOdds": 275.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Nate Lashley",
                            "name": "Nate Lashley",
                            "selectedText": "Nate Lashley",
                            "firstName": "Nate",
                            "lastName": "Lashley",
                            "dob": "1982-12-12",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "dfb4574d8c29f601a07747336b3cd2fd",
                            "bettingOdds": 300.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brandon Wu",
                            "name": "Brandon Wu",
                            "selectedText": "Brandon Wu",
                            "firstName": "Brandon",
                            "lastName": "Wu",
                            "dob": "1997-02-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/52374.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "4c5081885b8e3185d442694699aa7fda",
                            "bettingOdds": 300.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brice Garnett",
                            "name": "Brice Garnett",
                            "selectedText": "Brice Garnett",
                            "firstName": "Brice",
                            "lastName": "Garnett",
                            "dob": "1983-09-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29535.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "26af10e5fe9b4f18b2f12ab549fc7b64",
                            "bettingOdds": 333.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Harry Higgs",
                            "name": "Harry Higgs",
                            "selectedText": "Harry Higgs",
                            "firstName": "Harry",
                            "lastName": "Higgs",
                            "dob": "1991-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "ad0298d3efa67cbd77b66dd4b8c85a0c",
                            "bettingOdds": 333.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Jhonattan Vegas",
                            "name": "Jhonattan Vegas",
                            "selectedText": "Jhonattan Vegas",
                            "firstName": "Jhonattan",
                            "lastName": "Vegas",
                            "dob": "1984-08-19",
                            "gender": "Male",
                            "country": "VEN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27064.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_VEN.png",
                            "oddsId": "80e729ba1f334bd995ef34262a997373",
                            "bettingOdds": 350.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Luke Donald",
                            "name": "Luke Donald",
                            "selectedText": "Luke Donald",
                            "firstName": "Luke",
                            "lastName": "Donald",
                            "dob": "1977-12-07",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "d2ff3170a049f156cdd8fa4978b16ec6",
                            "bettingOdds": 350.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Joel Dahmen",
                            "name": "Joel Dahmen",
                            "selectedText": "Joel Dahmen",
                            "firstName": "Joel",
                            "lastName": "Dahmen",
                            "dob": "1987-11-11",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34076.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "591b7d07273563d18e1c9733e701058f",
                            "bettingOdds": 400.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brooks Koepka",
                            "name": "Brooks Koepka",
                            "selectedText": "Brooks Koepka",
                            "firstName": "Brooks",
                            "lastName": "Koepka",
                            "dob": "1990-05-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "920ca6741bf5fc45836fe56f32ca35da",
                            "bettingOdds": 450.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Nick Taylor",
                            "name": "Nick Taylor",
                            "selectedText": "Nick Taylor",
                            "firstName": "Nick",
                            "lastName": "Taylor",
                            "dob": "1988-04-14",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25493.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "dd200a799328615283f7f4049f80b1e1",
                            "bettingOdds": 500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Joseph Bramlett",
                            "name": "Joseph Bramlett",
                            "selectedText": "Joseph Bramlett",
                            "firstName": "Joseph",
                            "lastName": "Bramlett",
                            "dob": "1988-04-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34255.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "d1385fa75d291a5d657bfa6928a14334",
                            "bettingOdds": 500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Mito Guillermo Pereira",
                            "name": "Mito Guillermo Pereira",
                            "selectedText": "Mito Guillermo Pereira",
                            "firstName": "Mito",
                            "lastName": "Pereira",
                            "dob": "1995-03-31",
                            "gender": "Male",
                            "country": "CHI",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                            "oddsId": "1d85e2efa19f8c66e225129aa8ee504f",
                            "bettingOdds": 500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Kramer Hickok",
                            "name": "Kramer Hickok",
                            "selectedText": "Kramer Hickok",
                            "firstName": "Kramer",
                            "lastName": "Hickok",
                            "dob": "1992-04-14",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49298.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "8513cf78abe4183980898906fbc39593",
                            "bettingOdds": 550.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Richy Werenski",
                            "name": "Richy Werenski",
                            "selectedText": "Richy Werenski",
                            "firstName": "Richy",
                            "lastName": "Werenski",
                            "dob": "1991-12-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47128.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "df2cc96d149c9510ca580a0bb190fab8",
                            "bettingOdds": 550.0,
                            "placedBet": false
                            
                        }, {
                            "id": "J. J. Spaun",
                            "name": "J. J. Spaun",
                            "selectedText": "J. J. Spaun",
                            "firstName": "J.J.",
                            "lastName": "Spaun",
                            "dob": "1990-08-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "d93693125071d4edc485ee5d8cc66c03",
                            "bettingOdds": 550.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Danny Lee",
                            "name": "Danny Lee",
                            "selectedText": "Danny Lee",
                            "firstName": "Danny",
                            "lastName": "Lee",
                            "dob": "1990-07-24",
                            "gender": "Male",
                            "country": "NZL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                            "oddsId": "6d9ced365fc8a8828d5f29851728b1e0",
                            "bettingOdds": 650.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Brandon Hagy",
                            "name": "Brandon Hagy",
                            "selectedText": "Brandon Hagy",
                            "firstName": "Brandon",
                            "lastName": "Hagy",
                            "dob": "1991-03-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46550.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "84112af5e7d7119d5bce69b7c7540af1",
                            "bettingOdds": 650.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Louis Oosthuizen",
                            "name": "Louis Oosthuizen",
                            "selectedText": "Louis Oosthuizen",
                            "firstName": "Louis",
                            "lastName": "Oosthuizen",
                            "dob": "1982-10-19",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "d3cbb05c52f3aa2f714584e5b6eadf92",
                            "bettingOdds": 750.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Kiradech Aphibarnrat",
                            "name": "Kiradech Aphibarnrat",
                            "selectedText": "Kiradech Aphibarnrat",
                            "firstName": "Kiradech",
                            "lastName": "Aphibarnrat",
                            "dob": "1989-07-23",
                            "gender": "Male",
                            "country": "THA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30978.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_THA.png",
                            "oddsId": "290dd42e7a0dd2bd2b1d8d3a88f11e96",
                            "bettingOdds": 800.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Chez Reavie",
                            "name": "Chez Reavie",
                            "selectedText": "Chez Reavie",
                            "firstName": "Chez",
                            "lastName": "Reavie",
                            "dob": "1981-11-12",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26476.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "397586e64e5676719fd6ef15ce472026",
                            "bettingOdds": 1000.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Bill Haas",
                            "name": "Bill Haas",
                            "selectedText": "Bill Haas",
                            "firstName": "Bill",
                            "lastName": "Haas",
                            "dob": "1982-05-24",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24924.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c679f2cd5487f6dc887ccf54470a96b9",
                            "bettingOdds": 1000.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Kevin Kisner",
                            "name": "Kevin Kisner",
                            "selectedText": "Kevin Kisner",
                            "firstName": "Kevin",
                            "lastName": "Kisner",
                            "dob": "1984-02-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f31954bcd18c86e326d789b055cfe7b2",
                            "bettingOdds": 1300.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Harold Varner III",
                            "name": "Harold Varner III",
                            "selectedText": "Harold Varner III",
                            "firstName": "Harold",
                            "lastName": "Varner III",
                            "dob": "1990-08-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "48da7184aa1a8e839fdfe1daac965896",
                            "bettingOdds": 1400.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Dustin Johnson",
                            "name": "Dustin Johnson",
                            "selectedText": "Dustin Johnson",
                            "firstName": "Dustin",
                            "lastName": "Johnson",
                            "dob": "1984-06-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "aa652565e5c84d1916c16aecef328218",
                            "bettingOdds": 1500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Cameron Tringale",
                            "name": "Cameron Tringale",
                            "selectedText": "Cameron Tringale",
                            "firstName": "Cameron",
                            "lastName": "Tringale",
                            "dob": "1987-08-24",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33419.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "928da8b5ef63ef6dd0fdf65c14b0d525",
                            "bettingOdds": 1600.0,
                            "placedBet": true,
                            "betPlacedAt": 320.0,
                            "potentialWinnings": 320.0,
                            
                        }, {
                            "id": "Denny McCarthy",
                            "name": "Denny McCarthy",
                            "selectedText": "Denny McCarthy",
                            "firstName": "Denny",
                            "lastName": "McCarthy",
                            "dob": "1993-03-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47993.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b34fd34a459386afcdfc5981c5c3905a",
                            "bettingOdds": 1700.0,
                            "placedBet": false
                            
                        }, {
                            "id": "David Lipsky",
                            "name": "David Lipsky",
                            "selectedText": "David Lipsky",
                            "firstName": "David",
                            "lastName": "Lipsky",
                            "dob": "1988-07-14",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36326.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "9d6c6593b9591e6c6f07136b05215208",
                            "bettingOdds": 1700.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Doc Redman",
                            "name": "Doc Redman",
                            "selectedText": "Doc Redman",
                            "firstName": "Doc",
                            "lastName": "Redman",
                            "dob": "1997-12-27",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/53165.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "832f7e7c171c9618fc4f2c1045924dd6",
                            "bettingOdds": 1900.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Martin Kaymer",
                            "name": "Martin Kaymer",
                            "selectedText": "Martin Kaymer",
                            "firstName": "Martin",
                            "lastName": "Kaymer",
                            "dob": "1984-12-28",
                            "gender": "Male",
                            "country": "GER",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27408.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_GER.png",
                            "oddsId": "d65ec25a8866e75995e70b16722963e1",
                            "bettingOdds": 1900.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Henrik Stenson",
                            "name": "Henrik Stenson",
                            "selectedText": "Henrik Stenson",
                            "firstName": "Henrik",
                            "lastName": "Stenson",
                            "dob": "1976-04-05",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/21528.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "3b6b15fd0f1987b214117d51c86a9995",
                            "bettingOdds": 2000.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Webb Simpson",
                            "name": "Webb Simpson",
                            "selectedText": "Webb Simpson",
                            "firstName": "Webb",
                            "lastName": "Simpson",
                            "dob": "1985-08-08",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29221.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "e42063316443f197f513af637a9fef27",
                            "bettingOdds": 2100.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Austin Smotherman",
                            "name": "Austin Smotherman",
                            "selectedText": "Austin Smotherman",
                            "firstName": "Austin",
                            "lastName": "Smotherman",
                            "dob": "1994-05-19",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50095.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "36915104a3d4ca00209ec958f569d946",
                            "bettingOdds": 2300.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Collin Morikawa",
                            "name": "Collin Morikawa",
                            "selectedText": "Collin Morikawa",
                            "firstName": "Collin",
                            "lastName": "Morikawa",
                            "dob": "1997-02-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "fc9b76e66f37402e1ac8224a4e94ea29",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "John Huh",
                            "name": "John Huh",
                            "selectedText": "John Huh",
                            "firstName": "John",
                            "lastName": "Huh",
                            "dob": "1990-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34174.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "d33efb0e532f3757bcbf4f753959757b",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Max McGreevy",
                            "name": "Max McGreevy",
                            "selectedText": "Max McGreevy",
                            "firstName": "Max",
                            "lastName": "McGreevy",
                            "dob": "1995-05-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51950.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "1d8f2a75a22d641262a186be3181a33b",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Seung-Yul Noh",
                            "name": "Seung-Yul Noh",
                            "selectedText": "Seung-Yul Noh",
                            "firstName": "Seung-Yul",
                            "lastName": "Noh",
                            "dob": "1991-05-29",
                            "gender": "Male",
                            "country": "KOR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29289.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_KOR.png",
                            "oddsId": "8b95db3cc25a3a8d092394d7f14c8948",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Paul Barjon",
                            "name": "Paul Barjon",
                            "selectedText": "Paul Barjon",
                            "firstName": "Paul",
                            "lastName": "Barjon",
                            "dob": "1992-09-19",
                            "gender": "Male",
                            "country": "FRA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37212.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_FRA.png",
                            "oddsId": "8b7e9990379f796716c3c2ab5a91e649",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }, {
                            "id": "Wesley Bryan",
                            "name": "Wesley Bryan",
                            "selectedText": "Wesley Bryan",
                            "firstName": "Wesley",
                            "lastName": "Bryan",
                            "dob": "1990-03-26",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48084.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "9669d8737b3598ee31d14a22d14ddf5d",
                            "bettingOdds": 2500.0,
                            "placedBet": false
                            
                        }
                    ]
                }
            ],
            "groups": [{
                    "id": "718b054c-d5a6-47c0-9dd3-07dc7f8f013d",
                    "prompt": "To Win",
                    "startTime": 1647388740000,
                    "endTime": 1847820740000,
                    "title": "To Win",
                    "description": "To Win - 11:59 Tee Time",
                    "limit": 1,
                    "questions": [{
                            "id": "718b054c-d5a6-47c0-9dd3-07dc7f8f013d",
                            "prompt": "To Win",
                            "startTime": 1647388740000,
                            "endTime": 1847820740000,
                            "title": "To Win",
                            "pointValue": 100,
                            "description": "To Win - 11:59 Tee Time",
                            "showTeeTime": false,
                            "players": [{
                                    "id": "Sam Burns",
                                    "name": "Sam Burns",
                                    "selectedText": "Sam Burns",
                                    "firstName": "Sam",
                                    "lastName": "Burns",
                                    "dob": "1996-07-23",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "3c90876d1d44359cfeeb3b866e8c62af",
                                    "bettingOdds": -151.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Xander Schauffele",
                                    "name": "Xander Schauffele",
                                    "selectedText": "Xander Schauffele",
                                    "firstName": "Xander",
                                    "lastName": "Schauffele",
                                    "dob": "1993-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "fa74fb28467869add123aa574505a1d3",
                                    "bettingOdds": 0.0,
                                    "placedBet": true,
                                    "betPlacedAt": 2000.0,
                                    "potentialWinnings": 2000.0,
                                    
                                }, {
                                    "id": "Davis Riley",
                                    "name": "Davis Riley",
                                    "selectedText": "Davis Riley",
                                    "firstName": "Davis",
                                    "lastName": "Riley",
                                    "dob": "1996-12-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a5b96145135d5398b4c2f355c6c56c80",
                                    "bettingOdds": 100.0,
                                    "placedBet": false
                                    
                                }
                            ]
                        }
                    ]
                }, {
                    "id": "9b6970b1-8558-4abf-8e8c-c91a4fa8cd18",
                    "prompt": "Top 10",
                    "startTime": 1647388740000,
                    "endTime": 1647777300000,
                    "title": "Top 10",
                    "description": "Top 10 - 10:19 Tee Time",
                    "limit": 1,
                    "questions": [{
                            "id": "9b6970b1-8558-4abf-8e8c-c91a4fa8cd18",
                            "prompt": "Top 10",
                            "startTime": 1647388740000,
                            "endTime": 1647777300000,
                            "title": "Top 10",
                            "pointValue": 100,
                            "description": "Top 10 - 10:19 Tee Time",
                            "showTeeTime": false,
                            "players": [{
                                    "id": "Sam Burns",
                                    "name": "Sam Burns",
                                    "selectedText": "Sam Burns",
                                    "firstName": "Sam",
                                    "lastName": "Burns",
                                    "dob": "1996-07-23",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f0e006c937eda64c22a8cbfee2eac505",
                                    "bettingOdds": -9999.0,
                                    "placedBet": true,
                                    "betPlacedAt": 235.0,
                                    "potentialWinnings": 235.0,
                                    
                                }, {
                                    "id": "Matthew NeSmith",
                                    "name": "Matthew NeSmith",
                                    "selectedText": "Matthew NeSmith",
                                    "firstName": "Matthew",
                                    "lastName": "NeSmith",
                                    "dob": "1993-09-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a4e028a4d053774a2d2ab683bad32405",
                                    "bettingOdds": -9999.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Adam Hadwin",
                                    "name": "Adam Hadwin",
                                    "selectedText": "Adam Hadwin",
                                    "firstName": "Adam",
                                    "lastName": "Hadwin",
                                    "dob": "1987-11-02",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "ec2eb3c9eec2a92d0287b8d6563274e6",
                                    "bettingOdds": -599.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Xander Schauffele",
                                    "name": "Xander Schauffele",
                                    "selectedText": "Xander Schauffele",
                                    "firstName": "Xander",
                                    "lastName": "Schauffele",
                                    "dob": "1993-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "11e0116e2bd6c3243fe39bfc86694173",
                                    "bettingOdds": -250.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Matthew Fitzpatrick",
                                    "name": "Matthew Fitzpatrick",
                                    "selectedText": "Matthew Fitzpatrick",
                                    "firstName": "Matt",
                                    "lastName": "Fitzpatrick",
                                    "dob": "1994-09-01",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "18a038281c3753f353f937abbcac48e5",
                                    "bettingOdds": -224.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brian Harman",
                                    "name": "Brian Harman",
                                    "selectedText": "Brian Harman",
                                    "firstName": "Brian",
                                    "lastName": "Harman",
                                    "dob": "1987-01-19",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "ed19fb241759bfeb93586cf055b744cd",
                                    "bettingOdds": -134.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Troy Merritt",
                                    "name": "Troy Merritt",
                                    "selectedText": "Troy Merritt",
                                    "firstName": "Troy",
                                    "lastName": "Merritt",
                                    "dob": "1985-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32640.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "481e77682219f122c17b7b85f0db3065",
                                    "bettingOdds": 100.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Tommy Fleetwood",
                                    "name": "Tommy Fleetwood",
                                    "selectedText": "Tommy Fleetwood",
                                    "firstName": "Tommy",
                                    "lastName": "Fleetwood",
                                    "dob": "1991-01-19",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "89a0fb42b3b2f8393786ff866d8d5ae3",
                                    "bettingOdds": 110.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Alexander Noren",
                                    "name": "Alexander Noren",
                                    "selectedText": "Alexander Noren",
                                    "firstName": "Alex",
                                    "lastName": "Noren",
                                    "dob": "1982-07-12",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "af10014fdd93f29c062027f011726246",
                                    "bettingOdds": 150.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Robert Streb",
                                    "name": "Robert Streb",
                                    "selectedText": "Robert Streb",
                                    "firstName": "Robert",
                                    "lastName": "Streb",
                                    "dob": "1987-04-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c673d8c0631b6e134255dc2c2f12dc0f",
                                    "bettingOdds": 165.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Tyler Duncan",
                                    "name": "Tyler Duncan",
                                    "selectedText": "Tyler Duncan",
                                    "firstName": "Tyler",
                                    "lastName": "Duncan",
                                    "dob": "1989-07-13",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "bca05084a91fe02c161c7bb4d1035eec",
                                    "bettingOdds": 250.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Shane Lowry",
                                    "name": "Shane Lowry",
                                    "selectedText": "Shane Lowry",
                                    "firstName": "Shane",
                                    "lastName": "Lowry",
                                    "dob": "1987-04-02",
                                    "gender": "Male",
                                    "country": "IRL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                                    "oddsId": "2683c4594233f9136586527e358c4507",
                                    "bettingOdds": 360.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Kevin Streelman",
                                    "name": "Kevin Streelman",
                                    "selectedText": "Kevin Streelman",
                                    "firstName": "Kevin",
                                    "lastName": "Streelman",
                                    "dob": "1978-11-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "0e84fece53e1fc312afae76dce57e5a1",
                                    "bettingOdds": 360.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Stewart Cink",
                                    "name": "Stewart Cink",
                                    "selectedText": "Stewart Cink",
                                    "firstName": "Stewart",
                                    "lastName": "Cink",
                                    "dob": "1973-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/20229.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "25cb7e211eb1334c56828bbe8480f605",
                                    "bettingOdds": 400.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Tyrrell Hatton",
                                    "name": "Tyrrell Hatton",
                                    "selectedText": "Tyrrell Hatton",
                                    "firstName": "Tyrrell",
                                    "lastName": "Hatton",
                                    "dob": "1991-10-14",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "b3a25e087edf4a24759449078cea3492",
                                    "bettingOdds": 650.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Scott Stallings",
                                    "name": "Scott Stallings",
                                    "selectedText": "Scott Stallings",
                                    "firstName": "Scott",
                                    "lastName": "Stallings",
                                    "dob": "1985-03-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30692.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a089a2f1b7d2c8f6cd57b9afc4d4a28e",
                                    "bettingOdds": 700.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Bernd Wiesberger",
                                    "name": "Bernd Wiesberger",
                                    "selectedText": "Bernd Wiesberger",
                                    "firstName": "Bernd",
                                    "lastName": "Wiesberger",
                                    "dob": "1985-10-08",
                                    "gender": "Male",
                                    "country": "AUT",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                                    "oddsId": "95902bd631d485a07176b71984d44eeb",
                                    "bettingOdds": 750.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Sahith Theegala",
                                    "name": "Sahith Theegala",
                                    "selectedText": "Sahith Theegala",
                                    "firstName": "Sahith",
                                    "lastName": "Theegala",
                                    "dob": "1997-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "16123bc2912320247590ac4844c6380d",
                                    "bettingOdds": 800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Gary Woodland",
                                    "name": "Gary Woodland",
                                    "selectedText": "Gary Woodland",
                                    "firstName": "Gary",
                                    "lastName": "Woodland",
                                    "dob": "1984-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b6a0b2e83ee846ed8c1c6fd7ff64205b",
                                    "bettingOdds": 800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Matt Kuchar",
                                    "name": "Matt Kuchar",
                                    "selectedText": "Matt Kuchar",
                                    "firstName": "Matt",
                                    "lastName": "Kuchar",
                                    "dob": "1978-06-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "38c134982e6603b7cabb9ab29dba0baf",
                                    "bettingOdds": 800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Viktor Hovland",
                                    "name": "Viktor Hovland",
                                    "selectedText": "Viktor Hovland",
                                    "firstName": "Viktor",
                                    "lastName": "Hovland",
                                    "dob": "1997-09-18",
                                    "gender": "Male",
                                    "country": "NOR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                                    "oddsId": "b0f4fd17436a623a5f2714d961bb8570",
                                    "bettingOdds": 1100.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Russell Knox",
                                    "name": "Russell Knox",
                                    "selectedText": "Russell Knox",
                                    "firstName": "Russell",
                                    "lastName": "Knox",
                                    "dob": "1985-06-21",
                                    "gender": "Male",
                                    "country": "SCO",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                                    "oddsId": "8baedda5c82a775eb5a8e5cf6cfea46a",
                                    "bettingOdds": 1100.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Patton Kizzire",
                                    "name": "Patton Kizzire",
                                    "selectedText": "Patton Kizzire",
                                    "firstName": "Patton",
                                    "lastName": "Kizzire",
                                    "dob": "1986-03-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f5a8e55dd036dfbb4b13558e94dfea54",
                                    "bettingOdds": 1200.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brian Stuard",
                                    "name": "Brian Stuard",
                                    "selectedText": "Brian Stuard",
                                    "firstName": "Brian",
                                    "lastName": "Stuard",
                                    "dob": "1982-12-10",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31560.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "23f0beb5115c76195faaf38096db12fd",
                                    "bettingOdds": 1500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Greyson Sigg",
                                    "name": "Greyson Sigg",
                                    "selectedText": "Greyson Sigg",
                                    "firstName": "Greyson",
                                    "lastName": "Sigg",
                                    "dob": "1995-02-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51890.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "6c8d33b19127b2aad8b5db45a42f17a5",
                                    "bettingOdds": 1600.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Nate Lashley",
                                    "name": "Nate Lashley",
                                    "selectedText": "Nate Lashley",
                                    "firstName": "Nate",
                                    "lastName": "Lashley",
                                    "dob": "1982-12-12",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b8b910119e9e569678ff196625fa8851",
                                    "bettingOdds": 1600.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brandon Wu",
                                    "name": "Brandon Wu",
                                    "selectedText": "Brandon Wu",
                                    "firstName": "Brandon",
                                    "lastName": "Wu",
                                    "dob": "1997-02-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/52374.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "35daa3c7189170b53a837c9004f3ae6c",
                                    "bettingOdds": 1600.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Harry Higgs",
                                    "name": "Harry Higgs",
                                    "selectedText": "Harry Higgs",
                                    "firstName": "Harry",
                                    "lastName": "Higgs",
                                    "dob": "1991-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "95404e991d81766b3a0bccff2b2798be",
                                    "bettingOdds": 1800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brice Garnett",
                                    "name": "Brice Garnett",
                                    "selectedText": "Brice Garnett",
                                    "firstName": "Brice",
                                    "lastName": "Garnett",
                                    "dob": "1983-09-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29535.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "2eb56f645cf0b206f20af0ee6df7a1b8",
                                    "bettingOdds": 2000.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Luke Donald",
                                    "name": "Luke Donald",
                                    "selectedText": "Luke Donald",
                                    "firstName": "Luke",
                                    "lastName": "Donald",
                                    "dob": "1977-12-07",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "0d0e225f445983009e5db991bcb3893a",
                                    "bettingOdds": 2200.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Jhonattan Vegas",
                                    "name": "Jhonattan Vegas",
                                    "selectedText": "Jhonattan Vegas",
                                    "firstName": "Jhonattan",
                                    "lastName": "Vegas",
                                    "dob": "1984-08-19",
                                    "gender": "Male",
                                    "country": "VEN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27064.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_VEN.png",
                                    "oddsId": "810cc4f1903964e16d6aea528bcb4826",
                                    "bettingOdds": 2200.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Joel Dahmen",
                                    "name": "Joel Dahmen",
                                    "selectedText": "Joel Dahmen",
                                    "firstName": "Joel",
                                    "lastName": "Dahmen",
                                    "dob": "1987-11-11",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34076.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "897233fee3cff17912058473dc2535c0",
                                    "bettingOdds": 2200.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Joseph Bramlett",
                                    "name": "Joseph Bramlett",
                                    "selectedText": "Joseph Bramlett",
                                    "firstName": "Joseph",
                                    "lastName": "Bramlett",
                                    "dob": "1988-04-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34255.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f40e7f5c611923ddd87eed1d7ced12e4",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Richy Werenski",
                                    "name": "Richy Werenski",
                                    "selectedText": "Richy Werenski",
                                    "firstName": "Richy",
                                    "lastName": "Werenski",
                                    "dob": "1991-12-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47128.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c24416bd5e7ffbbb9bc735bb424af0f6",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brooks Koepka",
                                    "name": "Brooks Koepka",
                                    "selectedText": "Brooks Koepka",
                                    "firstName": "Brooks",
                                    "lastName": "Koepka",
                                    "dob": "1990-05-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "6b6ddbdb38ca7ad59f42a6fabf61625a",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Nick Taylor",
                                    "name": "Nick Taylor",
                                    "selectedText": "Nick Taylor",
                                    "firstName": "Nick",
                                    "lastName": "Taylor",
                                    "dob": "1988-04-14",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25493.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "72bd519fa1fb05b2b47ab048d034db3b",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Mito Guillermo Pereira",
                                    "name": "Mito Guillermo Pereira",
                                    "selectedText": "Mito Guillermo Pereira",
                                    "firstName": "Mito",
                                    "lastName": "Pereira",
                                    "dob": "1995-03-31",
                                    "gender": "Male",
                                    "country": "CHI",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                                    "oddsId": "0520acac8645a713c69ef0ae79050f8d",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "J. J. Spaun",
                                    "name": "J. J. Spaun",
                                    "selectedText": "J. J. Spaun",
                                    "firstName": "J.J.",
                                    "lastName": "Spaun",
                                    "dob": "1990-08-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "1418eea091a9cfc47edf1751bd8cfebf",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Chez Reavie",
                                    "name": "Chez Reavie",
                                    "selectedText": "Chez Reavie",
                                    "firstName": "Chez",
                                    "lastName": "Reavie",
                                    "dob": "1981-11-12",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26476.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "22d142a7ec4dc43fa94761e69753751f",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Bill Haas",
                                    "name": "Bill Haas",
                                    "selectedText": "Bill Haas",
                                    "firstName": "Bill",
                                    "lastName": "Haas",
                                    "dob": "1982-05-24",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24924.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "e6b5ad2f71e3277de419d6efbb10de0f",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Danny Lee",
                                    "name": "Danny Lee",
                                    "selectedText": "Danny Lee",
                                    "firstName": "Danny",
                                    "lastName": "Lee",
                                    "dob": "1990-07-24",
                                    "gender": "Male",
                                    "country": "NZL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                                    "oddsId": "399a1c719891c376f067733080b612cd",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brandon Hagy",
                                    "name": "Brandon Hagy",
                                    "selectedText": "Brandon Hagy",
                                    "firstName": "Brandon",
                                    "lastName": "Hagy",
                                    "dob": "1991-03-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46550.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f116f32dd2b380fa2751f2293b4b8fe8",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Kiradech Aphibarnrat",
                                    "name": "Kiradech Aphibarnrat",
                                    "selectedText": "Kiradech Aphibarnrat",
                                    "firstName": "Kiradech",
                                    "lastName": "Aphibarnrat",
                                    "dob": "1989-07-23",
                                    "gender": "Male",
                                    "country": "THA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30978.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_THA.png",
                                    "oddsId": "c1cf1442ce1f5dc04f7fdbafd106bee8",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Louis Oosthuizen",
                                    "name": "Louis Oosthuizen",
                                    "selectedText": "Louis Oosthuizen",
                                    "firstName": "Louis",
                                    "lastName": "Oosthuizen",
                                    "dob": "1982-10-19",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "bd717ce2459e624e14a4f90565500e85",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Kevin Kisner",
                                    "name": "Kevin Kisner",
                                    "selectedText": "Kevin Kisner",
                                    "firstName": "Kevin",
                                    "lastName": "Kisner",
                                    "dob": "1984-02-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "8e2927d5c015d6b5ff02698fbb46dd8e",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Harold Varner III",
                                    "name": "Harold Varner III",
                                    "selectedText": "Harold Varner III",
                                    "firstName": "Harold",
                                    "lastName": "Varner III",
                                    "dob": "1990-08-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "ac729d025a2aed24ef3c931471f865dd",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Kramer Hickok",
                                    "name": "Kramer Hickok",
                                    "selectedText": "Kramer Hickok",
                                    "firstName": "Kramer",
                                    "lastName": "Hickok",
                                    "dob": "1992-04-14",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49298.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "90310607eb89d523aa3531f3c03c2421",
                                    "bettingOdds": 2800.0,
                                    "placedBet": false
                                    
                                }
                            ]
                        }
                    ]
                }, {
                    "id": "d19d3ec0-2384-4dcf-bc58-77e682e57db0",
                    "prompt": "Top 20",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Top 20",
                    "description": "Top 20 - 10:19 Tee Time",
                    "limit": 1,
                    "questions": [{
                            "id": "d19d3ec0-2384-4dcf-bc58-77e682e57db0",
                            "prompt": "Top 20",
                            "startTime": 1647388740000,
                            "endTime": 1647814776000,
                            "title": "Top 20",
                            "pointValue": 100,
                            "description": "Top 20 - 10:19 Tee Time",
                            "showTeeTime": false,
                            "players": [{
                                    "id": "Justin Thomas",
                                    "name": "Justin Thomas",
                                    "selectedText": "Justin Thomas",
                                    "firstName": "Justin",
                                    "lastName": "Thomas",
                                    "dob": "1993-04-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "26c44a2bba4d03610e2e081bb0a3c3c1",
                                    "bettingOdds": -100000.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Sam Burns",
                                    "name": "Sam Burns",
                                    "selectedText": "Sam Burns",
                                    "firstName": "Sam",
                                    "lastName": "Burns",
                                    "dob": "1996-07-23",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "eda35f2ad4b5950cc713c965740e7244",
                                    "bettingOdds": -100000.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Davis Riley",
                                    "name": "Davis Riley",
                                    "selectedText": "Davis Riley",
                                    "firstName": "Davis",
                                    "lastName": "Riley",
                                    "dob": "1996-12-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "812595acc92e01f6c83612ec1cf45685",
                                    "bettingOdds": -100000.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Matthew NeSmith",
                                    "name": "Matthew NeSmith",
                                    "selectedText": "Matthew NeSmith",
                                    "firstName": "Matthew",
                                    "lastName": "NeSmith",
                                    "dob": "1993-09-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "43c1df625bc3ce2071b158cc76d6bb79",
                                    "bettingOdds": -100000.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Adam Hadwin",
                                    "name": "Adam Hadwin",
                                    "selectedText": "Adam Hadwin",
                                    "firstName": "Adam",
                                    "lastName": "Hadwin",
                                    "dob": "1987-11-02",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "3c0ddfd9568843eef2cf71284a209085",
                                    "bettingOdds": -9999.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Xander Schauffele",
                                    "name": "Xander Schauffele",
                                    "selectedText": "Xander Schauffele",
                                    "firstName": "Xander",
                                    "lastName": "Schauffele",
                                    "dob": "1993-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a9795784cb011be33d440f38b2f4053f",
                                    "bettingOdds": -1999.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Matthew Fitzpatrick",
                                    "name": "Matthew Fitzpatrick",
                                    "selectedText": "Matthew Fitzpatrick",
                                    "firstName": "Matt",
                                    "lastName": "Fitzpatrick",
                                    "dob": "1994-09-01",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "9f396ab5039a7d9ab32c8f814e63b85a",
                                    "bettingOdds": -1249.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brian Harman",
                                    "name": "Brian Harman",
                                    "selectedText": "Brian Harman",
                                    "firstName": "Brian",
                                    "lastName": "Harman",
                                    "dob": "1987-01-19",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b61ec6d8a544fe47514b671b51f13c79",
                                    "bettingOdds": -714.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Troy Merritt",
                                    "name": "Troy Merritt",
                                    "selectedText": "Troy Merritt",
                                    "firstName": "Troy",
                                    "lastName": "Merritt",
                                    "dob": "1985-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32640.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "968e51ecfa2b9ed37147a770d5e083cd",
                                    "bettingOdds": -500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Tommy Fleetwood",
                                    "name": "Tommy Fleetwood",
                                    "selectedText": "Tommy Fleetwood",
                                    "firstName": "Tommy",
                                    "lastName": "Fleetwood",
                                    "dob": "1991-01-19",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "40c3f95b8ab13e072a441bc1dc14a7e4",
                                    "bettingOdds": -454.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Alexander Noren",
                                    "name": "Alexander Noren",
                                    "selectedText": "Alexander Noren",
                                    "firstName": "Alex",
                                    "lastName": "Noren",
                                    "dob": "1982-07-12",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "348774cc26897daeb7d59717699e2614",
                                    "bettingOdds": -357.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Robert Streb",
                                    "name": "Robert Streb",
                                    "selectedText": "Robert Streb",
                                    "firstName": "Robert",
                                    "lastName": "Streb",
                                    "dob": "1987-04-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5fb1a6e2f21d4bf0192722e47c365f52",
                                    "bettingOdds": -303.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Tyler Duncan",
                                    "name": "Tyler Duncan",
                                    "selectedText": "Tyler Duncan",
                                    "firstName": "Tyler",
                                    "lastName": "Duncan",
                                    "dob": "1989-07-13",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "4684450c5c280fe0d91001002a26544a",
                                    "bettingOdds": -188.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Kevin Streelman",
                                    "name": "Kevin Streelman",
                                    "selectedText": "Kevin Streelman",
                                    "firstName": "Kevin",
                                    "lastName": "Streelman",
                                    "dob": "1978-11-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5c6437f57dc63d65aa7e08e3adac1a5e",
                                    "bettingOdds": -161.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Shane Lowry",
                                    "name": "Shane Lowry",
                                    "selectedText": "Shane Lowry",
                                    "firstName": "Shane",
                                    "lastName": "Lowry",
                                    "dob": "1987-04-02",
                                    "gender": "Male",
                                    "country": "IRL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                                    "oddsId": "4df93c00d3d0c305efa4705c22358d34",
                                    "bettingOdds": -136.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Stewart Cink",
                                    "name": "Stewart Cink",
                                    "selectedText": "Stewart Cink",
                                    "firstName": "Stewart",
                                    "lastName": "Cink",
                                    "dob": "1973-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/20229.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "d60ea12f1f17d3282c76c9d12becd34d",
                                    "bettingOdds": -136.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Tyrrell Hatton",
                                    "name": "Tyrrell Hatton",
                                    "selectedText": "Tyrrell Hatton",
                                    "firstName": "Tyrrell",
                                    "lastName": "Hatton",
                                    "dob": "1991-10-14",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "e64af559ec1a4005c69ea675e4a8199b",
                                    "bettingOdds": 110.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Bernd Wiesberger",
                                    "name": "Bernd Wiesberger",
                                    "selectedText": "Bernd Wiesberger",
                                    "firstName": "Bernd",
                                    "lastName": "Wiesberger",
                                    "dob": "1985-10-08",
                                    "gender": "Male",
                                    "country": "AUT",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                                    "oddsId": "178c8d893b57bde2805554f1246163fd",
                                    "bettingOdds": 125.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Scott Stallings",
                                    "name": "Scott Stallings",
                                    "selectedText": "Scott Stallings",
                                    "firstName": "Scott",
                                    "lastName": "Stallings",
                                    "dob": "1985-03-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30692.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "6dd1e93e062204b97bad3589e89e2c9a",
                                    "bettingOdds": 130.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Gary Woodland",
                                    "name": "Gary Woodland",
                                    "selectedText": "Gary Woodland",
                                    "firstName": "Gary",
                                    "lastName": "Woodland",
                                    "dob": "1984-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c25d34b33cf0fdd33763723e444f4b52",
                                    "bettingOdds": 140.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Sahith Theegala",
                                    "name": "Sahith Theegala",
                                    "selectedText": "Sahith Theegala",
                                    "firstName": "Sahith",
                                    "lastName": "Theegala",
                                    "dob": "1997-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "6f852174d74d751f5aec2e7181c5502b",
                                    "bettingOdds": 140.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Matt Kuchar",
                                    "name": "Matt Kuchar",
                                    "selectedText": "Matt Kuchar",
                                    "firstName": "Matt",
                                    "lastName": "Kuchar",
                                    "dob": "1978-06-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "2be5764a1a9ebc92011cd5546ef85c2c",
                                    "bettingOdds": 150.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Viktor Hovland",
                                    "name": "Viktor Hovland",
                                    "selectedText": "Viktor Hovland",
                                    "firstName": "Viktor",
                                    "lastName": "Hovland",
                                    "dob": "1997-09-18",
                                    "gender": "Male",
                                    "country": "NOR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                                    "oddsId": "b33ada1b2c98e20d8212a48d738845a2",
                                    "bettingOdds": 175.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Russell Knox",
                                    "name": "Russell Knox",
                                    "selectedText": "Russell Knox",
                                    "firstName": "Russell",
                                    "lastName": "Knox",
                                    "dob": "1985-06-21",
                                    "gender": "Male",
                                    "country": "SCO",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                                    "oddsId": "6e5d64506d71c7fc7ab4f8e16c7147fa",
                                    "bettingOdds": 188.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Patton Kizzire",
                                    "name": "Patton Kizzire",
                                    "selectedText": "Patton Kizzire",
                                    "firstName": "Patton",
                                    "lastName": "Kizzire",
                                    "dob": "1986-03-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "fc7c97a08c49acb13c545259ac1644d3",
                                    "bettingOdds": 200.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Adam Svensson",
                                    "name": "Adam Svensson",
                                    "selectedText": "Adam Svensson",
                                    "firstName": "Adam",
                                    "lastName": "Svensson",
                                    "dob": "1993-12-31",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40115.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "a3993d59763b99538eebd198963123c6",
                                    "bettingOdds": 230.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Cheng-Tsung Pan",
                                    "name": "Cheng-Tsung Pan",
                                    "selectedText": "Cheng-Tsung Pan",
                                    "firstName": "C.T.",
                                    "lastName": "Pan",
                                    "dob": "1991-11-12",
                                    "gender": "Male",
                                    "country": "TPE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29908.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_TPE.png",
                                    "oddsId": "3371ab43e36abdaa4cf3291f0d76a94b",
                                    "bettingOdds": 230.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brian Stuard",
                                    "name": "Brian Stuard",
                                    "selectedText": "Brian Stuard",
                                    "firstName": "Brian",
                                    "lastName": "Stuard",
                                    "dob": "1982-12-10",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31560.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b44a9cb46ba98e01233c8e093cfc58a9",
                                    "bettingOdds": 275.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Greyson Sigg",
                                    "name": "Greyson Sigg",
                                    "selectedText": "Greyson Sigg",
                                    "firstName": "Greyson",
                                    "lastName": "Sigg",
                                    "dob": "1995-02-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51890.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "8c56d667cb143145df3f018acd86e017",
                                    "bettingOdds": 275.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Nate Lashley",
                                    "name": "Nate Lashley",
                                    "selectedText": "Nate Lashley",
                                    "firstName": "Nate",
                                    "lastName": "Lashley",
                                    "dob": "1982-12-12",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "dfb4574d8c29f601a07747336b3cd2fd",
                                    "bettingOdds": 300.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brandon Wu",
                                    "name": "Brandon Wu",
                                    "selectedText": "Brandon Wu",
                                    "firstName": "Brandon",
                                    "lastName": "Wu",
                                    "dob": "1997-02-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/52374.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "4c5081885b8e3185d442694699aa7fda",
                                    "bettingOdds": 300.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brice Garnett",
                                    "name": "Brice Garnett",
                                    "selectedText": "Brice Garnett",
                                    "firstName": "Brice",
                                    "lastName": "Garnett",
                                    "dob": "1983-09-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29535.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "26af10e5fe9b4f18b2f12ab549fc7b64",
                                    "bettingOdds": 333.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Harry Higgs",
                                    "name": "Harry Higgs",
                                    "selectedText": "Harry Higgs",
                                    "firstName": "Harry",
                                    "lastName": "Higgs",
                                    "dob": "1991-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "ad0298d3efa67cbd77b66dd4b8c85a0c",
                                    "bettingOdds": 333.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Jhonattan Vegas",
                                    "name": "Jhonattan Vegas",
                                    "selectedText": "Jhonattan Vegas",
                                    "firstName": "Jhonattan",
                                    "lastName": "Vegas",
                                    "dob": "1984-08-19",
                                    "gender": "Male",
                                    "country": "VEN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27064.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_VEN.png",
                                    "oddsId": "80e729ba1f334bd995ef34262a997373",
                                    "bettingOdds": 350.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Luke Donald",
                                    "name": "Luke Donald",
                                    "selectedText": "Luke Donald",
                                    "firstName": "Luke",
                                    "lastName": "Donald",
                                    "dob": "1977-12-07",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "d2ff3170a049f156cdd8fa4978b16ec6",
                                    "bettingOdds": 350.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Joel Dahmen",
                                    "name": "Joel Dahmen",
                                    "selectedText": "Joel Dahmen",
                                    "firstName": "Joel",
                                    "lastName": "Dahmen",
                                    "dob": "1987-11-11",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34076.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "591b7d07273563d18e1c9733e701058f",
                                    "bettingOdds": 400.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brooks Koepka",
                                    "name": "Brooks Koepka",
                                    "selectedText": "Brooks Koepka",
                                    "firstName": "Brooks",
                                    "lastName": "Koepka",
                                    "dob": "1990-05-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "920ca6741bf5fc45836fe56f32ca35da",
                                    "bettingOdds": 450.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Nick Taylor",
                                    "name": "Nick Taylor",
                                    "selectedText": "Nick Taylor",
                                    "firstName": "Nick",
                                    "lastName": "Taylor",
                                    "dob": "1988-04-14",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25493.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "dd200a799328615283f7f4049f80b1e1",
                                    "bettingOdds": 500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Joseph Bramlett",
                                    "name": "Joseph Bramlett",
                                    "selectedText": "Joseph Bramlett",
                                    "firstName": "Joseph",
                                    "lastName": "Bramlett",
                                    "dob": "1988-04-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34255.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "d1385fa75d291a5d657bfa6928a14334",
                                    "bettingOdds": 500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Mito Guillermo Pereira",
                                    "name": "Mito Guillermo Pereira",
                                    "selectedText": "Mito Guillermo Pereira",
                                    "firstName": "Mito",
                                    "lastName": "Pereira",
                                    "dob": "1995-03-31",
                                    "gender": "Male",
                                    "country": "CHI",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                                    "oddsId": "1d85e2efa19f8c66e225129aa8ee504f",
                                    "bettingOdds": 500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Kramer Hickok",
                                    "name": "Kramer Hickok",
                                    "selectedText": "Kramer Hickok",
                                    "firstName": "Kramer",
                                    "lastName": "Hickok",
                                    "dob": "1992-04-14",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49298.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "8513cf78abe4183980898906fbc39593",
                                    "bettingOdds": 550.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Richy Werenski",
                                    "name": "Richy Werenski",
                                    "selectedText": "Richy Werenski",
                                    "firstName": "Richy",
                                    "lastName": "Werenski",
                                    "dob": "1991-12-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47128.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "df2cc96d149c9510ca580a0bb190fab8",
                                    "bettingOdds": 550.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "J. J. Spaun",
                                    "name": "J. J. Spaun",
                                    "selectedText": "J. J. Spaun",
                                    "firstName": "J.J.",
                                    "lastName": "Spaun",
                                    "dob": "1990-08-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "d93693125071d4edc485ee5d8cc66c03",
                                    "bettingOdds": 550.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Danny Lee",
                                    "name": "Danny Lee",
                                    "selectedText": "Danny Lee",
                                    "firstName": "Danny",
                                    "lastName": "Lee",
                                    "dob": "1990-07-24",
                                    "gender": "Male",
                                    "country": "NZL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                                    "oddsId": "6d9ced365fc8a8828d5f29851728b1e0",
                                    "bettingOdds": 650.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Brandon Hagy",
                                    "name": "Brandon Hagy",
                                    "selectedText": "Brandon Hagy",
                                    "firstName": "Brandon",
                                    "lastName": "Hagy",
                                    "dob": "1991-03-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46550.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "84112af5e7d7119d5bce69b7c7540af1",
                                    "bettingOdds": 650.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Louis Oosthuizen",
                                    "name": "Louis Oosthuizen",
                                    "selectedText": "Louis Oosthuizen",
                                    "firstName": "Louis",
                                    "lastName": "Oosthuizen",
                                    "dob": "1982-10-19",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "d3cbb05c52f3aa2f714584e5b6eadf92",
                                    "bettingOdds": 750.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Kiradech Aphibarnrat",
                                    "name": "Kiradech Aphibarnrat",
                                    "selectedText": "Kiradech Aphibarnrat",
                                    "firstName": "Kiradech",
                                    "lastName": "Aphibarnrat",
                                    "dob": "1989-07-23",
                                    "gender": "Male",
                                    "country": "THA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30978.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_THA.png",
                                    "oddsId": "290dd42e7a0dd2bd2b1d8d3a88f11e96",
                                    "bettingOdds": 800.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Chez Reavie",
                                    "name": "Chez Reavie",
                                    "selectedText": "Chez Reavie",
                                    "firstName": "Chez",
                                    "lastName": "Reavie",
                                    "dob": "1981-11-12",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26476.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "397586e64e5676719fd6ef15ce472026",
                                    "bettingOdds": 1000.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Bill Haas",
                                    "name": "Bill Haas",
                                    "selectedText": "Bill Haas",
                                    "firstName": "Bill",
                                    "lastName": "Haas",
                                    "dob": "1982-05-24",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24924.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c679f2cd5487f6dc887ccf54470a96b9",
                                    "bettingOdds": 1000.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Kevin Kisner",
                                    "name": "Kevin Kisner",
                                    "selectedText": "Kevin Kisner",
                                    "firstName": "Kevin",
                                    "lastName": "Kisner",
                                    "dob": "1984-02-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f31954bcd18c86e326d789b055cfe7b2",
                                    "bettingOdds": 1300.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Harold Varner III",
                                    "name": "Harold Varner III",
                                    "selectedText": "Harold Varner III",
                                    "firstName": "Harold",
                                    "lastName": "Varner III",
                                    "dob": "1990-08-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "48da7184aa1a8e839fdfe1daac965896",
                                    "bettingOdds": 1400.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Dustin Johnson",
                                    "name": "Dustin Johnson",
                                    "selectedText": "Dustin Johnson",
                                    "firstName": "Dustin",
                                    "lastName": "Johnson",
                                    "dob": "1984-06-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "aa652565e5c84d1916c16aecef328218",
                                    "bettingOdds": 1500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Cameron Tringale",
                                    "name": "Cameron Tringale",
                                    "selectedText": "Cameron Tringale",
                                    "firstName": "Cameron",
                                    "lastName": "Tringale",
                                    "dob": "1987-08-24",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33419.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "928da8b5ef63ef6dd0fdf65c14b0d525",
                                    "bettingOdds": 1600.0,
                                    "placedBet": true,
                                    "betPlacedAt": 320.0,
                                    "potentialWinnings": 320.0,
                                    
                                }, {
                                    "id": "Denny McCarthy",
                                    "name": "Denny McCarthy",
                                    "selectedText": "Denny McCarthy",
                                    "firstName": "Denny",
                                    "lastName": "McCarthy",
                                    "dob": "1993-03-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47993.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b34fd34a459386afcdfc5981c5c3905a",
                                    "bettingOdds": 1700.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "David Lipsky",
                                    "name": "David Lipsky",
                                    "selectedText": "David Lipsky",
                                    "firstName": "David",
                                    "lastName": "Lipsky",
                                    "dob": "1988-07-14",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36326.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "9d6c6593b9591e6c6f07136b05215208",
                                    "bettingOdds": 1700.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Doc Redman",
                                    "name": "Doc Redman",
                                    "selectedText": "Doc Redman",
                                    "firstName": "Doc",
                                    "lastName": "Redman",
                                    "dob": "1997-12-27",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/53165.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "832f7e7c171c9618fc4f2c1045924dd6",
                                    "bettingOdds": 1900.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Martin Kaymer",
                                    "name": "Martin Kaymer",
                                    "selectedText": "Martin Kaymer",
                                    "firstName": "Martin",
                                    "lastName": "Kaymer",
                                    "dob": "1984-12-28",
                                    "gender": "Male",
                                    "country": "GER",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27408.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_GER.png",
                                    "oddsId": "d65ec25a8866e75995e70b16722963e1",
                                    "bettingOdds": 1900.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Henrik Stenson",
                                    "name": "Henrik Stenson",
                                    "selectedText": "Henrik Stenson",
                                    "firstName": "Henrik",
                                    "lastName": "Stenson",
                                    "dob": "1976-04-05",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/21528.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "3b6b15fd0f1987b214117d51c86a9995",
                                    "bettingOdds": 2000.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Webb Simpson",
                                    "name": "Webb Simpson",
                                    "selectedText": "Webb Simpson",
                                    "firstName": "Webb",
                                    "lastName": "Simpson",
                                    "dob": "1985-08-08",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29221.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "e42063316443f197f513af637a9fef27",
                                    "bettingOdds": 2100.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Austin Smotherman",
                                    "name": "Austin Smotherman",
                                    "selectedText": "Austin Smotherman",
                                    "firstName": "Austin",
                                    "lastName": "Smotherman",
                                    "dob": "1994-05-19",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50095.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "36915104a3d4ca00209ec958f569d946",
                                    "bettingOdds": 2300.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Collin Morikawa",
                                    "name": "Collin Morikawa",
                                    "selectedText": "Collin Morikawa",
                                    "firstName": "Collin",
                                    "lastName": "Morikawa",
                                    "dob": "1997-02-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "fc9b76e66f37402e1ac8224a4e94ea29",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "John Huh",
                                    "name": "John Huh",
                                    "selectedText": "John Huh",
                                    "firstName": "John",
                                    "lastName": "Huh",
                                    "dob": "1990-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34174.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "d33efb0e532f3757bcbf4f753959757b",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Max McGreevy",
                                    "name": "Max McGreevy",
                                    "selectedText": "Max McGreevy",
                                    "firstName": "Max",
                                    "lastName": "McGreevy",
                                    "dob": "1995-05-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51950.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "1d8f2a75a22d641262a186be3181a33b",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Seung-Yul Noh",
                                    "name": "Seung-Yul Noh",
                                    "selectedText": "Seung-Yul Noh",
                                    "firstName": "Seung-Yul",
                                    "lastName": "Noh",
                                    "dob": "1991-05-29",
                                    "gender": "Male",
                                    "country": "KOR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29289.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_KOR.png",
                                    "oddsId": "8b95db3cc25a3a8d092394d7f14c8948",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Paul Barjon",
                                    "name": "Paul Barjon",
                                    "selectedText": "Paul Barjon",
                                    "firstName": "Paul",
                                    "lastName": "Barjon",
                                    "dob": "1992-09-19",
                                    "gender": "Male",
                                    "country": "FRA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37212.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_FRA.png",
                                    "oddsId": "8b7e9990379f796716c3c2ab5a91e649",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }, {
                                    "id": "Wesley Bryan",
                                    "name": "Wesley Bryan",
                                    "selectedText": "Wesley Bryan",
                                    "firstName": "Wesley",
                                    "lastName": "Bryan",
                                    "dob": "1990-03-26",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48084.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "9669d8737b3598ee31d14a22d14ddf5d",
                                    "bettingOdds": 2500.0,
                                    "placedBet": false
                                    
                                }
                            ]
                        }
                    ]
                }
            ]
        }, {
            "id": "27d6cdb8-6070-4441-9b17-629f3b51c267",
            "type": "ROUND",
            "title": "ROUND 1",
            "dayOfWeek": "Thursday",
            "startTime": 1647388740000,
            "endTime": 1847820740000,
            "open": true,
            "description": "25 POINT PICK",
            "questions": [{
                    "id": "108ebb55-b9be-4064-bf65-bf0b696fbc3a",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647517860000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 11:51 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Cameron Tringale",
                            "name": "Cameron Tringale",
                            "selectedText": "Cameron Tringale",
                            "firstName": "Cameron",
                            "lastName": "Tringale",
                            "dob": "1987-08-24",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33419.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "59b37c9d89871d3f30168ce9757d1e83",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Denny McCarthy",
                            "name": "Denny McCarthy",
                            "selectedText": "Denny McCarthy",
                            "firstName": "Denny",
                            "lastName": "McCarthy",
                            "dob": "1993-03-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47993.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "9189fc2b0a02d6c4efe403078a8b7227",
                            "bettingOdds": 160.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Matthew NeSmith",
                            "name": "Matthew NeSmith",
                            "selectedText": "Matthew NeSmith",
                            "firstName": "Matthew",
                            "lastName": "NeSmith",
                            "dob": "1993-09-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b6492540c67c61b3718dfc2eb3c08758",
                            "bettingOdds": 230.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "a0e08df5-08d8-4ac8-9915-3dde8c7c8799",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647519180000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 12:13 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Dustin Johnson",
                            "name": "Dustin Johnson",
                            "selectedText": "Dustin Johnson",
                            "firstName": "Dustin",
                            "lastName": "Johnson",
                            "dob": "1984-06-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "be1641f6e3e00e38a746f315ef5eb6dd",
                            "bettingOdds": 120.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Bubba Watson",
                            "name": "Bubba Watson",
                            "selectedText": "Bubba Watson",
                            "firstName": "Bubba",
                            "lastName": "Watson",
                            "dob": "1978-11-05",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25804.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "fdd56b802fd2b5f0ff843b273d90fd55",
                            "bettingOdds": 210.0,
                            "placedBet": true,
                            "betPlacedAt": 210.0,
                            "potentialWinnings": 53.0,
                            "winner": true
                        }, {
                            "id": "Jason Day",
                            "name": "Jason Day",
                            "selectedText": "Jason Day",
                            "firstName": "Jason",
                            "lastName": "Day",
                            "dob": "1987-11-12",
                            "gender": "Male",
                            "country": "AUS",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28089.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUS.png",
                            "oddsId": "97348e9a47b4891d6d137b9d83d9fdd6",
                            "bettingOdds": 220.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "c44f7039-9b94-4003-9bcd-a3cf1796a40f",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647519840000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 12:24 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Viktor Hovland",
                            "name": "Viktor Hovland",
                            "selectedText": "Viktor Hovland",
                            "firstName": "Viktor",
                            "lastName": "Hovland",
                            "dob": "1997-09-18",
                            "gender": "Male",
                            "country": "NOR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                            "oddsId": "e63821b0d2f54f44c0a1c0e162d9b941",
                            "bettingOdds": 140.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Collin Morikawa",
                            "name": "Collin Morikawa",
                            "selectedText": "Collin Morikawa",
                            "firstName": "Collin",
                            "lastName": "Morikawa",
                            "dob": "1997-02-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "800b9cd702c0aa539080629b13827333",
                            "bettingOdds": 155.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Abraham Ancer",
                            "name": "Abraham Ancer",
                            "selectedText": "Abraham Ancer",
                            "firstName": "Abraham",
                            "lastName": "Ancer",
                            "dob": "1991-02-27",
                            "gender": "Male",
                            "country": "MEX",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45526.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                            "oddsId": "37a1c4cab41981eafcc6b85a4cb398a2",
                            "bettingOdds": 250.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "5668abda-b616-4011-8564-64d7fb96bd02",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647519840000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 12:24 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Lanto Griffin",
                            "name": "Lanto Griffin",
                            "selectedText": "Lanto Griffin",
                            "firstName": "Lanto",
                            "lastName": "Griffin",
                            "dob": "1988-06-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35310.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "076afad25ed5cb6265c98c144b6a88a9",
                            "bettingOdds": 155.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Brendon Todd",
                            "name": "Brendon Todd",
                            "selectedText": "Brendon Todd",
                            "firstName": "Brendon",
                            "lastName": "Todd",
                            "dob": "1985-07-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30927.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "536a959c4f73a568b187baef89a16229",
                            "bettingOdds": 185.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Zach Johnson",
                            "name": "Zach Johnson",
                            "selectedText": "Zach Johnson",
                            "firstName": "Zach",
                            "lastName": "Johnson",
                            "dob": "1976-02-24",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24024.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "293a9c7e766d17508433d3cdf44c0583",
                            "bettingOdds": 185.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "794d8c5d-268a-4d7b-8947-12d2e30f747a",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647520500000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 12:35 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Louis Oosthuizen",
                            "name": "Louis Oosthuizen",
                            "selectedText": "Louis Oosthuizen",
                            "firstName": "Louis",
                            "lastName": "Oosthuizen",
                            "dob": "1982-10-19",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "9f34d10c54bfb2c8f2cbac3820646019",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Brooks Koepka",
                            "name": "Brooks Koepka",
                            "selectedText": "Brooks Koepka",
                            "firstName": "Brooks",
                            "lastName": "Koepka",
                            "dob": "1990-05-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "2e013f0c6597e31f66e69f7fa8bd66ec",
                            "bettingOdds": 185.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Jason Kokrak",
                            "name": "Jason Kokrak",
                            "selectedText": "Jason Kokrak",
                            "firstName": "Jason",
                            "lastName": "Kokrak",
                            "dob": "1985-05-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30944.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "45b81ffb71c09e73e31db17f4b32d310",
                            "bettingOdds": 200.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "17ec19d2-6de8-4fdf-8920-c1cf53ee51c8",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647521160000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 12:46 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Branden Grace",
                            "name": "Branden Grace",
                            "selectedText": "Branden Grace",
                            "firstName": "Branden",
                            "lastName": "Grace",
                            "dob": "1988-05-20",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29974.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "c2f0a395ab49a3b3e6f8e5c8fb1e3974",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tyler Duncan",
                            "name": "Tyler Duncan",
                            "selectedText": "Tyler Duncan",
                            "firstName": "Tyler",
                            "lastName": "Duncan",
                            "dob": "1989-07-13",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "06423dceff80f1755ec0518caa75c47a",
                            "bettingOdds": 175.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Luke Donald",
                            "name": "Luke Donald",
                            "selectedText": "Luke Donald",
                            "firstName": "Luke",
                            "lastName": "Donald",
                            "dob": "1977-12-07",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "49b46659ea9f2638645d01ab09dc72e9",
                            "bettingOdds": 210.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "df46b883-4cc5-42c9-bd11-f14b0c43bfca",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647521160000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 12:46 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Patton Kizzire",
                            "name": "Patton Kizzire",
                            "selectedText": "Patton Kizzire",
                            "firstName": "Patton",
                            "lastName": "Kizzire",
                            "dob": "1986-03-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "80a0f62b6e3936293bd259dcbbc08bbf",
                            "bettingOdds": 130.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Matt Kuchar",
                            "name": "Matt Kuchar",
                            "selectedText": "Matt Kuchar",
                            "firstName": "Matt",
                            "lastName": "Kuchar",
                            "dob": "1978-06-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b25f5eadb2c6e575dbd218a001c08f0f",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Martin Trainer",
                            "name": "Martin Trainer",
                            "selectedText": "Martin Trainer",
                            "firstName": "Martin",
                            "lastName": "Trainer",
                            "dob": "1991-04-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35617.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "82a2f472a79f5182f240559c4eccadb6",
                            "bettingOdds": 300.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "64d92039-99c2-440d-b992-0ea0f5f62d37",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647521820000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 12:57 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Keegan Bradley",
                            "name": "Keegan Bradley",
                            "selectedText": "Keegan Bradley",
                            "firstName": "Keegan",
                            "lastName": "Bradley",
                            "dob": "1986-06-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33141.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "881179214df81e5471280524f2be2af5",
                            "bettingOdds": 100.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "JT Poston",
                            "name": "JT Poston",
                            "selectedText": "JT Poston",
                            "firstName": "J.T.",
                            "lastName": "Poston",
                            "dob": "1993-06-01",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49771.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "bcea4a5f9f6217d103930ec6250fa89d",
                            "bettingOdds": 230.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Nate Lashley",
                            "name": "Nate Lashley",
                            "selectedText": "Nate Lashley",
                            "firstName": "Nate",
                            "lastName": "Lashley",
                            "dob": "1982-12-12",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "9d0b53676d9993eb31603d24fe0222a4",
                            "bettingOdds": 240.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "4c861977-910a-4f25-baec-f788cfd750ea",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647522480000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 01:08 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Matthew Fitzpatrick",
                            "name": "Matthew Fitzpatrick",
                            "selectedText": "Matthew Fitzpatrick",
                            "firstName": "Matt",
                            "lastName": "Fitzpatrick",
                            "dob": "1994-09-01",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "c10c71426c03d0fceb885c5aad8f6843",
                            "bettingOdds": 110.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Alexander Noren",
                            "name": "Alexander Noren",
                            "selectedText": "Alexander Noren",
                            "firstName": "Alex",
                            "lastName": "Noren",
                            "dob": "1982-07-12",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "a395b3af7723cf8740bb5b0c02610d1c",
                            "bettingOdds": 155.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Adam Schenk",
                            "name": "Adam Schenk",
                            "selectedText": "Adam Schenk",
                            "firstName": "Adam",
                            "lastName": "Schenk",
                            "dob": "1992-01-26",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47347.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "97decc08026f829efc2d94e17fbd7a9d",
                            "bettingOdds": 340.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "a12d9e18-c8bc-4c3d-9aad-fb6364114914",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647523140000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 01:19 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Mackenzie Hughes",
                            "name": "Mackenzie Hughes",
                            "selectedText": "Mackenzie Hughes",
                            "firstName": "Mackenzie",
                            "lastName": "Hughes",
                            "dob": "1990-11-23",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35506.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "555132e30ebebf00f3b95905102856aa",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Kevin Streelman",
                            "name": "Kevin Streelman",
                            "selectedText": "Kevin Streelman",
                            "firstName": "Kevin",
                            "lastName": "Streelman",
                            "dob": "1978-11-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "6fc487c71aeb647852c50110728fd211",
                            "bettingOdds": 180.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Doc Redman",
                            "name": "Doc Redman",
                            "selectedText": "Doc Redman",
                            "firstName": "Doc",
                            "lastName": "Redman",
                            "dob": "1997-12-27",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/53165.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5e9b0d82ea534789bb52f3befa2c06c9",
                            "bettingOdds": 200.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "4086e406-1d8b-462e-82eb-9d0aa3ec4a2b",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647523140000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 01:19 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Aaron Wise",
                            "name": "Aaron Wise",
                            "selectedText": "Aaron Wise",
                            "firstName": "Aaron",
                            "lastName": "Wise",
                            "dob": "1996-06-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49964.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "47b35b8e0da15f8b22acb7bd08869b5b",
                            "bettingOdds": 130.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "J. J. Spaun",
                            "name": "J. J. Spaun",
                            "selectedText": "J. J. Spaun",
                            "firstName": "J.J.",
                            "lastName": "Spaun",
                            "dob": "1990-08-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "ae05dea3b0a64b0d5dd7ddca190ed027",
                            "bettingOdds": 195.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Danny Lee",
                            "name": "Danny Lee",
                            "selectedText": "Danny Lee",
                            "firstName": "Danny",
                            "lastName": "Lee",
                            "dob": "1990-07-24",
                            "gender": "Male",
                            "country": "NZL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                            "oddsId": "99ee47e985ff286a792c38a3c17b242f",
                            "bettingOdds": 210.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "dd500b94-958e-461d-b285-df594f4a0bac",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647535920000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 04:52 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Mito Guillermo Pereira",
                            "name": "Mito Guillermo Pereira",
                            "selectedText": "Mito Guillermo Pereira",
                            "firstName": "Mito",
                            "lastName": "Pereira",
                            "dob": "1995-03-31",
                            "gender": "Male",
                            "country": "CHI",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                            "oddsId": "107676a40282d8ba2c6c0a6584584fd1",
                            "bettingOdds": 160.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Pat Perez",
                            "name": "Pat Perez",
                            "selectedText": "Pat Perez",
                            "firstName": "Pat",
                            "lastName": "Perez",
                            "dob": "1976-03-01",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24361.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "fe361863ccbce53b0dc671d8c79a3f7f",
                            "bettingOdds": 175.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Martin Kaymer",
                            "name": "Martin Kaymer",
                            "selectedText": "Martin Kaymer",
                            "firstName": "Martin",
                            "lastName": "Kaymer",
                            "dob": "1984-12-28",
                            "gender": "Male",
                            "country": "GER",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27408.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_GER.png",
                            "oddsId": "1c81b3489b589e55f6c46d61438ba6e5",
                            "bettingOdds": 190.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "139d9c84-e05e-44e4-8bfc-e10a605b3e4d",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647536580000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 05:03 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Justin Thomas",
                            "name": "Justin Thomas",
                            "selectedText": "Justin Thomas",
                            "firstName": "Justin",
                            "lastName": "Thomas",
                            "dob": "1993-04-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c3cbe3a655dd7ca1837fcdaab5a8527c",
                            "bettingOdds": -114.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Kevin Kisner",
                            "name": "Kevin Kisner",
                            "selectedText": "Kevin Kisner",
                            "firstName": "Kevin",
                            "lastName": "Kisner",
                            "dob": "1984-02-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "1d44c56918448b1529fc36fa31eceb04",
                            "bettingOdds": 225.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Carlos Ortiz",
                            "name": "Carlos Ortiz",
                            "selectedText": "Carlos Ortiz",
                            "firstName": "Carlos",
                            "lastName": "Ortiz",
                            "dob": "1991-04-24",
                            "gender": "Male",
                            "country": "MEX",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33667.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                            "oddsId": "e44fef8a086ebed7eb7936525fb0d0f6",
                            "bettingOdds": 300.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "6136af56-a832-4b08-b3f3-e505375bf5ad",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647537240000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 05:14 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Shane Lowry",
                            "name": "Shane Lowry",
                            "selectedText": "Shane Lowry",
                            "firstName": "Shane",
                            "lastName": "Lowry",
                            "dob": "1987-04-02",
                            "gender": "Male",
                            "country": "IRL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                            "oddsId": "1e2a9d3f6eaaee6237df835660a075d9",
                            "bettingOdds": -117.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Graeme McDowell",
                            "name": "Graeme McDowell",
                            "selectedText": "Graeme McDowell",
                            "firstName": "Graeme",
                            "lastName": "McDowell",
                            "dob": "1979-07-30",
                            "gender": "Male",
                            "country": "NIR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25572.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NIR.png",
                            "oddsId": "5e77a426e67cbc42aab970f224e7c654",
                            "bettingOdds": 250.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Henrik Stenson",
                            "name": "Henrik Stenson",
                            "selectedText": "Henrik Stenson",
                            "firstName": "Henrik",
                            "lastName": "Stenson",
                            "dob": "1976-04-05",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/21528.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "bd3d5f9d0da12070c541dfad0a5902f5",
                            "bettingOdds": 275.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "16ba09be-8482-4ff6-82f8-24c8cbb80521",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647537900000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 05:25 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Xander Schauffele",
                            "name": "Xander Schauffele",
                            "selectedText": "Xander Schauffele",
                            "firstName": "Xander",
                            "lastName": "Schauffele",
                            "dob": "1993-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "80cd1b1c354d985dbaeb052657f7b93d",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Sam Burns",
                            "name": "Sam Burns",
                            "selectedText": "Sam Burns",
                            "firstName": "Sam",
                            "lastName": "Burns",
                            "dob": "1996-07-23",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "d0c6b20d6ecab3c8bfa4fb131a1acbf9",
                            "bettingOdds": 155.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Gary Woodland",
                            "name": "Gary Woodland",
                            "selectedText": "Gary Woodland",
                            "firstName": "Gary",
                            "lastName": "Woodland",
                            "dob": "1984-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "95b5dd829a123b59d2cc6b74f63f52c1",
                            "bettingOdds": 230.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "2160a8bf-43d1-43ac-ac94-00a3609d4506",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647537900000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 05:25 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Webb Simpson",
                            "name": "Webb Simpson",
                            "selectedText": "Webb Simpson",
                            "firstName": "Webb",
                            "lastName": "Simpson",
                            "dob": "1985-08-08",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29221.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f1aee473207ad66a186ecbec2cfcce61",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Francesco Molinari",
                            "name": "Francesco Molinari",
                            "selectedText": "Francesco Molinari",
                            "firstName": "Francesco",
                            "lastName": "Molinari",
                            "dob": "1982-11-08",
                            "gender": "Male",
                            "country": "ITA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25198.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ITA.png",
                            "oddsId": "d881c783bebfc2f38099a5de67e43c85",
                            "bettingOdds": 185.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Martin Laird",
                            "name": "Martin Laird",
                            "selectedText": "Martin Laird",
                            "firstName": "Martin",
                            "lastName": "Laird",
                            "dob": "1982-12-29",
                            "gender": "Male",
                            "country": "SCO",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27936.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                            "oddsId": "0db9b00a0e04435e140faf85514a6808",
                            "bettingOdds": 200.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "5c485233-ce38-44cd-8009-ca5cb9eec4ee",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647538560000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 05:36 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Tyrrell Hatton",
                            "name": "Tyrrell Hatton",
                            "selectedText": "Tyrrell Hatton",
                            "firstName": "Tyrrell",
                            "lastName": "Hatton",
                            "dob": "1991-10-14",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "ac7e59e59fc4ebf2a89c08a8718c3173",
                            "bettingOdds": -113.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Adam Long",
                            "name": "Adam Long",
                            "selectedText": "Adam Long",
                            "firstName": "Adam",
                            "lastName": "Long",
                            "dob": "1987-09-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35449.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "211537d4d23d1388d974c0b94f461827",
                            "bettingOdds": 220.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Robert Streb",
                            "name": "Robert Streb",
                            "selectedText": "Robert Streb",
                            "firstName": "Robert",
                            "lastName": "Streb",
                            "dob": "1987-04-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "17ebf3a8dcbb5d16462a0d48cb95d5f8",
                            "bettingOdds": 300.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "720d1192-7388-49c4-b359-11d3b6644f3f",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647539220000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 05:47 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Adam Hadwin",
                            "name": "Adam Hadwin",
                            "selectedText": "Adam Hadwin",
                            "firstName": "Adam",
                            "lastName": "Hadwin",
                            "dob": "1987-11-02",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "d0243540e2a6a65a290d33f485ead7f6",
                            "bettingOdds": 110.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Lee Hodges",
                            "name": "Lee Hodges",
                            "selectedText": "Lee Hodges",
                            "firstName": "Lee",
                            "lastName": "Hodges",
                            "dob": "1995-06-14",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/54628.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "257a9c85a246c753e8896768191a9188",
                            "bettingOdds": 190.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Charl Schwartzel",
                            "name": "Charl Schwartzel",
                            "selectedText": "Charl Schwartzel",
                            "firstName": "Charl",
                            "lastName": "Schwartzel",
                            "dob": "1984-08-31",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26331.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "b99f35de8b87d011b5cc4934ddb01405",
                            "bettingOdds": 260.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "701980a7-4c5d-420c-ad67-06befcb0c345",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647539220000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 05:47 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Tommy Fleetwood",
                            "name": "Tommy Fleetwood",
                            "selectedText": "Tommy Fleetwood",
                            "firstName": "Tommy",
                            "lastName": "Fleetwood",
                            "dob": "1991-01-19",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "9fccb93d2ab9cf3bd1bfa52ca1a1c106",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Russell Knox",
                            "name": "Russell Knox",
                            "selectedText": "Russell Knox",
                            "firstName": "Russell",
                            "lastName": "Knox",
                            "dob": "1985-06-21",
                            "gender": "Male",
                            "country": "SCO",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                            "oddsId": "c6c5d829c53f5aca50f13dd95457529a",
                            "bettingOdds": 185.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Harold Varner III",
                            "name": "Harold Varner III",
                            "selectedText": "Harold Varner III",
                            "firstName": "Harold",
                            "lastName": "Varner III",
                            "dob": "1990-08-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "1986658b2b108025200f5efaa1579079",
                            "bettingOdds": 190.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "ea64f71c-4beb-4dcb-902e-434feec080cc",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647539880000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 05:58 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Brian Harman",
                            "name": "Brian Harman",
                            "selectedText": "Brian Harman",
                            "firstName": "Brian",
                            "lastName": "Harman",
                            "dob": "1987-01-19",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "2474b8a246989bcf48d06d5920d3d342",
                            "bettingOdds": 125.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Emiliano Grillo",
                            "name": "Emiliano Grillo",
                            "selectedText": "Emiliano Grillo",
                            "firstName": "Emiliano",
                            "lastName": "Grillo",
                            "dob": "1992-09-14",
                            "gender": "Male",
                            "country": "ARG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31646.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ARG.png",
                            "oddsId": "7c50f0c72c325ca299380322012a3c6e",
                            "bettingOdds": 190.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Harry Higgs",
                            "name": "Harry Higgs",
                            "selectedText": "Harry Higgs",
                            "firstName": "Harry",
                            "lastName": "Higgs",
                            "dob": "1991-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "eb5b85293443d8a6784bac6a8ec07405",
                            "bettingOdds": 225.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "d1c4e782-d6e2-4302-807b-c6e2be373a3a",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647540540000,
                    "title": "3 Ball",
                    "pointValue": 25,
                    "description": "3 Ball - 06:09 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Bernd Wiesberger",
                            "name": "Bernd Wiesberger",
                            "selectedText": "Bernd Wiesberger",
                            "firstName": "Bernd",
                            "lastName": "Wiesberger",
                            "dob": "1985-10-08",
                            "gender": "Male",
                            "country": "AUT",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                            "oddsId": "ea3ac9e439b6cd0c9cdaae4bbcc6389e",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "David Lipsky",
                            "name": "David Lipsky",
                            "selectedText": "David Lipsky",
                            "firstName": "David",
                            "lastName": "Lipsky",
                            "dob": "1988-07-14",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36326.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "fdd1da3fada7a9935f337a495e14b492",
                            "bettingOdds": 185.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Sahith Theegala",
                            "name": "Sahith Theegala",
                            "selectedText": "Sahith Theegala",
                            "firstName": "Sahith",
                            "lastName": "Theegala",
                            "dob": "1997-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "ce573721e5a81550535bb90b9712fd8b",
                            "bettingOdds": 190.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "572e35b1-db5f-4c18-981a-7f0a24aa8e0a",
                    "prompt": "Round 1 Leader",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Round 1 Leader",
                    "pointValue": 25,
                    "description": "Round 1 Leader - 10:19 Tee Time",
                    "showTeeTime": false,
                    "players": [{
                            "id": "Viktor Hovland",
                            "name": "Viktor Hovland",
                            "selectedText": "Viktor Hovland",
                            "firstName": "Viktor",
                            "lastName": "Hovland",
                            "dob": "1997-09-18",
                            "gender": "Male",
                            "country": "NOR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                            "oddsId": "955daa9aff4ff072dd61b58626b8207d",
                            "bettingOdds": 0.0,
                            "placedBet": true,
                            "betPlacedAt": 2500.0,
                            "potentialWinnings": 625.0,
                            "winner": false
                        }, {
                            "id": "Kevin Kisner",
                            "name": "Kevin Kisner",
                            "selectedText": "Kevin Kisner",
                            "firstName": "Kevin",
                            "lastName": "Kisner",
                            "dob": "1984-02-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "97a0c776a08ec77e19d9d354d24a2eb9",
                            "bettingOdds": 8000.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Mito Pereira",
                            "name": "Mito Pereira",
                            "selectedText": "Mito Pereira",
                            "firstName": "Mito",
                            "lastName": "Pereira",
                            "dob": "1995-03-31",
                            "gender": "Male",
                            "country": "CHI",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                            "oddsId": "f6728a89f9852122c03245300453b284",
                            "bettingOdds": 10000.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tommy Gainey",
                            "name": "Tommy Gainey",
                            "selectedText": "Tommy Gainey",
                            "firstName": "Tommy",
                            "lastName": "Gainey",
                            "dob": "1975-08-13",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30750.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "fc4374c14a658036717bf158c4a446fe",
                            "bettingOdds": 10000.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Brian Gay",
                            "name": "Brian Gay",
                            "selectedText": "Brian Gay",
                            "firstName": "Brian",
                            "lastName": "Gay",
                            "dob": "1971-12-14",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/19846.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b03a9cd9464afcc80f5ed6c8bbcfdd75",
                            "bettingOdds": 15000.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "03a89e25-db52-4034-8211-00df40ee7d04",
                    "prompt": "Make The Cut",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Make The Cut",
                    "pointValue": 25,
                    "description": "Make The Cut - 10:19 Tee Time",
                    "showTeeTime": false,
                    "players": [{
                            "id": "Collin Morikawa",
                            "name": "Collin Morikawa",
                            "selectedText": "Collin Morikawa",
                            "firstName": "Collin",
                            "lastName": "Morikawa",
                            "dob": "1997-02-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "3b09a8ecd4aae6ce907252833952c0af",
                            "bettingOdds": -699.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Justin Thomas",
                            "name": "Justin Thomas",
                            "selectedText": "Justin Thomas",
                            "firstName": "Justin",
                            "lastName": "Thomas",
                            "dob": "1993-04-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5aecc0886d8d99d8c5a3a0116e37f917",
                            "bettingOdds": -699.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Viktor Hovland",
                            "name": "Viktor Hovland",
                            "selectedText": "Viktor Hovland",
                            "firstName": "Viktor",
                            "lastName": "Hovland",
                            "dob": "1997-09-18",
                            "gender": "Male",
                            "country": "NOR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                            "oddsId": "232766fe5b7dcee13c64daf728b34bdb",
                            "bettingOdds": -699.0,
                            "placedBet": true,
                            "betPlacedAt": -699.0,
                            "potentialWinnings": 4.0,
                            "winner": true
                        }, {
                            "id": "Dustin Johnson",
                            "name": "Dustin Johnson",
                            "selectedText": "Dustin Johnson",
                            "firstName": "Dustin",
                            "lastName": "Johnson",
                            "dob": "1984-06-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "7407a9ac257c91a405d35b95de178b44",
                            "bettingOdds": -549.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Sam Burns",
                            "name": "Sam Burns",
                            "selectedText": "Sam Burns",
                            "firstName": "Sam",
                            "lastName": "Burns",
                            "dob": "1996-07-23",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "50f004023e7cab53baeedd9b944b5fe1",
                            "bettingOdds": -500.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Louis Oosthuizen",
                            "name": "Louis Oosthuizen",
                            "selectedText": "Louis Oosthuizen",
                            "firstName": "Louis",
                            "lastName": "Oosthuizen",
                            "dob": "1982-10-19",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "1a2ed7ef9e8c986611e6feeba64aedc1",
                            "bettingOdds": -449.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Xander Schauffele",
                            "name": "Xander Schauffele",
                            "selectedText": "Xander Schauffele",
                            "firstName": "Xander",
                            "lastName": "Schauffele",
                            "dob": "1993-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "9b6c804385600efdf44cd8006ccdd34f",
                            "bettingOdds": -449.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Shane Lowry",
                            "name": "Shane Lowry",
                            "selectedText": "Shane Lowry",
                            "firstName": "Shane",
                            "lastName": "Lowry",
                            "dob": "1987-04-02",
                            "gender": "Male",
                            "country": "IRL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                            "oddsId": "e4222fcf21d348ea100f1a0828028c38",
                            "bettingOdds": -400.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tyrrell Hatton",
                            "name": "Tyrrell Hatton",
                            "selectedText": "Tyrrell Hatton",
                            "firstName": "Tyrrell",
                            "lastName": "Hatton",
                            "dob": "1991-10-14",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "30551ef8396ee372d48c38c8adef6648",
                            "bettingOdds": -400.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Abraham Ancer",
                            "name": "Abraham Ancer",
                            "selectedText": "Abraham Ancer",
                            "firstName": "Abraham",
                            "lastName": "Ancer",
                            "dob": "1991-02-27",
                            "gender": "Male",
                            "country": "MEX",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45526.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                            "oddsId": "c0a98f177b52cdabd7c36becbd6724fe",
                            "bettingOdds": -324.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Brooks Koepka",
                            "name": "Brooks Koepka",
                            "selectedText": "Brooks Koepka",
                            "firstName": "Brooks",
                            "lastName": "Koepka",
                            "dob": "1990-05-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5be5591a819c3c02608919d1b67f5995",
                            "bettingOdds": -324.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Jason Kokrak",
                            "name": "Jason Kokrak",
                            "selectedText": "Jason Kokrak",
                            "firstName": "Jason",
                            "lastName": "Kokrak",
                            "dob": "1985-05-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30944.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "e4cf38549031c8a353fc366ea619663c",
                            "bettingOdds": -324.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Matthew Fitzpatrick",
                            "name": "Matthew Fitzpatrick",
                            "selectedText": "Matthew Fitzpatrick",
                            "firstName": "Matt",
                            "lastName": "Fitzpatrick",
                            "dob": "1994-09-01",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "5f365fb94ff6a6ca47b38719c448d1ea",
                            "bettingOdds": -324.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tommy Fleetwood",
                            "name": "Tommy Fleetwood",
                            "selectedText": "Tommy Fleetwood",
                            "firstName": "Tommy",
                            "lastName": "Fleetwood",
                            "dob": "1991-01-19",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "719b4fb48402eba923430997a16a11d5",
                            "bettingOdds": -289.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Jason Day",
                            "name": "Jason Day",
                            "selectedText": "Jason Day",
                            "firstName": "Jason",
                            "lastName": "Day",
                            "dob": "1987-11-12",
                            "gender": "Male",
                            "country": "AUS",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28089.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUS.png",
                            "oddsId": "7877628e00346b94defffea3c15d5d78",
                            "bettingOdds": -259.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Gary Woodland",
                            "name": "Gary Woodland",
                            "selectedText": "Gary Woodland",
                            "firstName": "Gary",
                            "lastName": "Woodland",
                            "dob": "1984-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "9255fc7499863328a666338c86736187",
                            "bettingOdds": -239.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Keegan Bradley",
                            "name": "Keegan Bradley",
                            "selectedText": "Keegan Bradley",
                            "firstName": "Keegan",
                            "lastName": "Bradley",
                            "dob": "1986-06-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33141.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c809bf32a71a62aba2d656a239406598",
                            "bettingOdds": -239.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Christiaan Bezuidenhout",
                            "name": "Christiaan Bezuidenhout",
                            "selectedText": "Christiaan Bezuidenhout",
                            "firstName": "Christiaan",
                            "lastName": "Bezuidenhout",
                            "dob": "1994-05-18",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45522.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "4c4c8c5052203066c8827da4820e89e2",
                            "bettingOdds": -224.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Russell Knox",
                            "name": "Russell Knox",
                            "selectedText": "Russell Knox",
                            "firstName": "Russell",
                            "lastName": "Knox",
                            "dob": "1985-06-21",
                            "gender": "Male",
                            "country": "SCO",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                            "oddsId": "d4b0b08130e0db2674e9fe9ee997243d",
                            "bettingOdds": -224.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "MacKenzie Hughes",
                            "name": "MacKenzie Hughes",
                            "selectedText": "MacKenzie Hughes",
                            "firstName": "Mackenzie",
                            "lastName": "Hughes",
                            "dob": "1990-11-23",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35506.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "25c90d951f0ea89c37b717d3c36fdc47",
                            "bettingOdds": -204.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Adam Hadwin",
                            "name": "Adam Hadwin",
                            "selectedText": "Adam Hadwin",
                            "firstName": "Adam",
                            "lastName": "Hadwin",
                            "dob": "1987-11-02",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "d77fe4e4dc6cc5d9936d731daedd7ddb",
                            "bettingOdds": -200.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Alexander Noren",
                            "name": "Alexander Noren",
                            "selectedText": "Alexander Noren",
                            "firstName": "Alex",
                            "lastName": "Noren",
                            "dob": "1982-07-12",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "82de3a18595928a751d28a70f108adc6",
                            "bettingOdds": -200.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Brian Harman",
                            "name": "Brian Harman",
                            "selectedText": "Brian Harman",
                            "firstName": "Brian",
                            "lastName": "Harman",
                            "dob": "1987-01-19",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "57494b52d870bc8ec2bd25eb3eb216b8",
                            "bettingOdds": -200.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Harold Varner",
                            "name": "Harold Varner",
                            "selectedText": "Harold Varner",
                            "oddsId": "2308db763862499bcd5958116007cf8b",
                            "bettingOdds": -200.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Kevin Kisner",
                            "name": "Kevin Kisner",
                            "selectedText": "Kevin Kisner",
                            "firstName": "Kevin",
                            "lastName": "Kisner",
                            "dob": "1984-02-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "7c19823909d9896ac0aa313c7e3a4b18",
                            "bettingOdds": -179.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }
            ],
            "groups": [{
                    "id": "108ebb55-b9be-4064-bf65-bf0b696fbc3a",
                    "prompt": "3 Ball",
                    "startTime": 1647388740000,
                    "endTime": 1647517860000,
                    "title": "3 Ball",
                    "description": "3 Ball - 11:51 Tee Time",
                    "limit": 1,
                    "questions": [{
                            "id": "108ebb55-b9be-4064-bf65-bf0b696fbc3a",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647517860000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 11:51 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Cameron Tringale",
                                    "name": "Cameron Tringale",
                                    "selectedText": "Cameron Tringale",
                                    "firstName": "Cameron",
                                    "lastName": "Tringale",
                                    "dob": "1987-08-24",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33419.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "59b37c9d89871d3f30168ce9757d1e83",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Denny McCarthy",
                                    "name": "Denny McCarthy",
                                    "selectedText": "Denny McCarthy",
                                    "firstName": "Denny",
                                    "lastName": "McCarthy",
                                    "dob": "1993-03-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47993.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "9189fc2b0a02d6c4efe403078a8b7227",
                                    "bettingOdds": 160.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Matthew NeSmith",
                                    "name": "Matthew NeSmith",
                                    "selectedText": "Matthew NeSmith",
                                    "firstName": "Matthew",
                                    "lastName": "NeSmith",
                                    "dob": "1993-09-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b6492540c67c61b3718dfc2eb3c08758",
                                    "bettingOdds": 230.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "a0e08df5-08d8-4ac8-9915-3dde8c7c8799",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647519180000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 12:13 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Dustin Johnson",
                                    "name": "Dustin Johnson",
                                    "selectedText": "Dustin Johnson",
                                    "firstName": "Dustin",
                                    "lastName": "Johnson",
                                    "dob": "1984-06-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "be1641f6e3e00e38a746f315ef5eb6dd",
                                    "bettingOdds": 120.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Bubba Watson",
                                    "name": "Bubba Watson",
                                    "selectedText": "Bubba Watson",
                                    "firstName": "Bubba",
                                    "lastName": "Watson",
                                    "dob": "1978-11-05",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25804.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "fdd56b802fd2b5f0ff843b273d90fd55",
                                    "bettingOdds": 210.0,
                                    "placedBet": true,
                                    "betPlacedAt": 210.0,
                                    "potentialWinnings": 53.0,
                                    "winner": false
                                }, {
                                    "id": "Jason Day",
                                    "name": "Jason Day",
                                    "selectedText": "Jason Day",
                                    "firstName": "Jason",
                                    "lastName": "Day",
                                    "dob": "1987-11-12",
                                    "gender": "Male",
                                    "country": "AUS",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28089.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUS.png",
                                    "oddsId": "97348e9a47b4891d6d137b9d83d9fdd6",
                                    "bettingOdds": 220.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "c44f7039-9b94-4003-9bcd-a3cf1796a40f",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647519840000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 12:24 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Viktor Hovland",
                                    "name": "Viktor Hovland",
                                    "selectedText": "Viktor Hovland",
                                    "firstName": "Viktor",
                                    "lastName": "Hovland",
                                    "dob": "1997-09-18",
                                    "gender": "Male",
                                    "country": "NOR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                                    "oddsId": "e63821b0d2f54f44c0a1c0e162d9b941",
                                    "bettingOdds": 140.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Collin Morikawa",
                                    "name": "Collin Morikawa",
                                    "selectedText": "Collin Morikawa",
                                    "firstName": "Collin",
                                    "lastName": "Morikawa",
                                    "dob": "1997-02-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "800b9cd702c0aa539080629b13827333",
                                    "bettingOdds": 155.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Abraham Ancer",
                                    "name": "Abraham Ancer",
                                    "selectedText": "Abraham Ancer",
                                    "firstName": "Abraham",
                                    "lastName": "Ancer",
                                    "dob": "1991-02-27",
                                    "gender": "Male",
                                    "country": "MEX",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45526.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                                    "oddsId": "37a1c4cab41981eafcc6b85a4cb398a2",
                                    "bettingOdds": 250.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "5668abda-b616-4011-8564-64d7fb96bd02",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647519840000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 12:24 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Lanto Griffin",
                                    "name": "Lanto Griffin",
                                    "selectedText": "Lanto Griffin",
                                    "firstName": "Lanto",
                                    "lastName": "Griffin",
                                    "dob": "1988-06-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35310.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "076afad25ed5cb6265c98c144b6a88a9",
                                    "bettingOdds": 155.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Brendon Todd",
                                    "name": "Brendon Todd",
                                    "selectedText": "Brendon Todd",
                                    "firstName": "Brendon",
                                    "lastName": "Todd",
                                    "dob": "1985-07-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30927.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "536a959c4f73a568b187baef89a16229",
                                    "bettingOdds": 185.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Zach Johnson",
                                    "name": "Zach Johnson",
                                    "selectedText": "Zach Johnson",
                                    "firstName": "Zach",
                                    "lastName": "Johnson",
                                    "dob": "1976-02-24",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24024.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "293a9c7e766d17508433d3cdf44c0583",
                                    "bettingOdds": 185.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "794d8c5d-268a-4d7b-8947-12d2e30f747a",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647520500000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 12:35 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Louis Oosthuizen",
                                    "name": "Louis Oosthuizen",
                                    "selectedText": "Louis Oosthuizen",
                                    "firstName": "Louis",
                                    "lastName": "Oosthuizen",
                                    "dob": "1982-10-19",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "9f34d10c54bfb2c8f2cbac3820646019",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Brooks Koepka",
                                    "name": "Brooks Koepka",
                                    "selectedText": "Brooks Koepka",
                                    "firstName": "Brooks",
                                    "lastName": "Koepka",
                                    "dob": "1990-05-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "2e013f0c6597e31f66e69f7fa8bd66ec",
                                    "bettingOdds": 185.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Jason Kokrak",
                                    "name": "Jason Kokrak",
                                    "selectedText": "Jason Kokrak",
                                    "firstName": "Jason",
                                    "lastName": "Kokrak",
                                    "dob": "1985-05-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30944.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "45b81ffb71c09e73e31db17f4b32d310",
                                    "bettingOdds": 200.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "17ec19d2-6de8-4fdf-8920-c1cf53ee51c8",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647521160000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 12:46 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Branden Grace",
                                    "name": "Branden Grace",
                                    "selectedText": "Branden Grace",
                                    "firstName": "Branden",
                                    "lastName": "Grace",
                                    "dob": "1988-05-20",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29974.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "c2f0a395ab49a3b3e6f8e5c8fb1e3974",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tyler Duncan",
                                    "name": "Tyler Duncan",
                                    "selectedText": "Tyler Duncan",
                                    "firstName": "Tyler",
                                    "lastName": "Duncan",
                                    "dob": "1989-07-13",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "06423dceff80f1755ec0518caa75c47a",
                                    "bettingOdds": 175.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Luke Donald",
                                    "name": "Luke Donald",
                                    "selectedText": "Luke Donald",
                                    "firstName": "Luke",
                                    "lastName": "Donald",
                                    "dob": "1977-12-07",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "49b46659ea9f2638645d01ab09dc72e9",
                                    "bettingOdds": 210.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "df46b883-4cc5-42c9-bd11-f14b0c43bfca",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647521160000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 12:46 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Patton Kizzire",
                                    "name": "Patton Kizzire",
                                    "selectedText": "Patton Kizzire",
                                    "firstName": "Patton",
                                    "lastName": "Kizzire",
                                    "dob": "1986-03-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "80a0f62b6e3936293bd259dcbbc08bbf",
                                    "bettingOdds": 130.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Matt Kuchar",
                                    "name": "Matt Kuchar",
                                    "selectedText": "Matt Kuchar",
                                    "firstName": "Matt",
                                    "lastName": "Kuchar",
                                    "dob": "1978-06-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b25f5eadb2c6e575dbd218a001c08f0f",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Martin Trainer",
                                    "name": "Martin Trainer",
                                    "selectedText": "Martin Trainer",
                                    "firstName": "Martin",
                                    "lastName": "Trainer",
                                    "dob": "1991-04-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35617.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "82a2f472a79f5182f240559c4eccadb6",
                                    "bettingOdds": 300.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "64d92039-99c2-440d-b992-0ea0f5f62d37",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647521820000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 12:57 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Keegan Bradley",
                                    "name": "Keegan Bradley",
                                    "selectedText": "Keegan Bradley",
                                    "firstName": "Keegan",
                                    "lastName": "Bradley",
                                    "dob": "1986-06-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33141.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "881179214df81e5471280524f2be2af5",
                                    "bettingOdds": 100.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "JT Poston",
                                    "name": "JT Poston",
                                    "selectedText": "JT Poston",
                                    "firstName": "J.T.",
                                    "lastName": "Poston",
                                    "dob": "1993-06-01",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49771.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "bcea4a5f9f6217d103930ec6250fa89d",
                                    "bettingOdds": 230.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Nate Lashley",
                                    "name": "Nate Lashley",
                                    "selectedText": "Nate Lashley",
                                    "firstName": "Nate",
                                    "lastName": "Lashley",
                                    "dob": "1982-12-12",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "9d0b53676d9993eb31603d24fe0222a4",
                                    "bettingOdds": 240.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "4c861977-910a-4f25-baec-f788cfd750ea",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647522480000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 01:08 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Matthew Fitzpatrick",
                                    "name": "Matthew Fitzpatrick",
                                    "selectedText": "Matthew Fitzpatrick",
                                    "firstName": "Matt",
                                    "lastName": "Fitzpatrick",
                                    "dob": "1994-09-01",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "c10c71426c03d0fceb885c5aad8f6843",
                                    "bettingOdds": 110.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Alexander Noren",
                                    "name": "Alexander Noren",
                                    "selectedText": "Alexander Noren",
                                    "firstName": "Alex",
                                    "lastName": "Noren",
                                    "dob": "1982-07-12",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "a395b3af7723cf8740bb5b0c02610d1c",
                                    "bettingOdds": 155.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Adam Schenk",
                                    "name": "Adam Schenk",
                                    "selectedText": "Adam Schenk",
                                    "firstName": "Adam",
                                    "lastName": "Schenk",
                                    "dob": "1992-01-26",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47347.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "97decc08026f829efc2d94e17fbd7a9d",
                                    "bettingOdds": 340.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "a12d9e18-c8bc-4c3d-9aad-fb6364114914",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647523140000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 01:19 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Mackenzie Hughes",
                                    "name": "Mackenzie Hughes",
                                    "selectedText": "Mackenzie Hughes",
                                    "firstName": "Mackenzie",
                                    "lastName": "Hughes",
                                    "dob": "1990-11-23",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35506.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "555132e30ebebf00f3b95905102856aa",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Kevin Streelman",
                                    "name": "Kevin Streelman",
                                    "selectedText": "Kevin Streelman",
                                    "firstName": "Kevin",
                                    "lastName": "Streelman",
                                    "dob": "1978-11-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "6fc487c71aeb647852c50110728fd211",
                                    "bettingOdds": 180.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Doc Redman",
                                    "name": "Doc Redman",
                                    "selectedText": "Doc Redman",
                                    "firstName": "Doc",
                                    "lastName": "Redman",
                                    "dob": "1997-12-27",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/53165.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5e9b0d82ea534789bb52f3befa2c06c9",
                                    "bettingOdds": 200.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "4086e406-1d8b-462e-82eb-9d0aa3ec4a2b",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647523140000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 01:19 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Aaron Wise",
                                    "name": "Aaron Wise",
                                    "selectedText": "Aaron Wise",
                                    "firstName": "Aaron",
                                    "lastName": "Wise",
                                    "dob": "1996-06-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49964.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "47b35b8e0da15f8b22acb7bd08869b5b",
                                    "bettingOdds": 130.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "J. J. Spaun",
                                    "name": "J. J. Spaun",
                                    "selectedText": "J. J. Spaun",
                                    "firstName": "J.J.",
                                    "lastName": "Spaun",
                                    "dob": "1990-08-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "ae05dea3b0a64b0d5dd7ddca190ed027",
                                    "bettingOdds": 195.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Danny Lee",
                                    "name": "Danny Lee",
                                    "selectedText": "Danny Lee",
                                    "firstName": "Danny",
                                    "lastName": "Lee",
                                    "dob": "1990-07-24",
                                    "gender": "Male",
                                    "country": "NZL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                                    "oddsId": "99ee47e985ff286a792c38a3c17b242f",
                                    "bettingOdds": 210.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "dd500b94-958e-461d-b285-df594f4a0bac",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647535920000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 04:52 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Mito Guillermo Pereira",
                                    "name": "Mito Guillermo Pereira",
                                    "selectedText": "Mito Guillermo Pereira",
                                    "firstName": "Mito",
                                    "lastName": "Pereira",
                                    "dob": "1995-03-31",
                                    "gender": "Male",
                                    "country": "CHI",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                                    "oddsId": "107676a40282d8ba2c6c0a6584584fd1",
                                    "bettingOdds": 160.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Pat Perez",
                                    "name": "Pat Perez",
                                    "selectedText": "Pat Perez",
                                    "firstName": "Pat",
                                    "lastName": "Perez",
                                    "dob": "1976-03-01",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24361.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "fe361863ccbce53b0dc671d8c79a3f7f",
                                    "bettingOdds": 175.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Martin Kaymer",
                                    "name": "Martin Kaymer",
                                    "selectedText": "Martin Kaymer",
                                    "firstName": "Martin",
                                    "lastName": "Kaymer",
                                    "dob": "1984-12-28",
                                    "gender": "Male",
                                    "country": "GER",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27408.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_GER.png",
                                    "oddsId": "1c81b3489b589e55f6c46d61438ba6e5",
                                    "bettingOdds": 190.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "139d9c84-e05e-44e4-8bfc-e10a605b3e4d",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647536580000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 05:03 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Justin Thomas",
                                    "name": "Justin Thomas",
                                    "selectedText": "Justin Thomas",
                                    "firstName": "Justin",
                                    "lastName": "Thomas",
                                    "dob": "1993-04-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c3cbe3a655dd7ca1837fcdaab5a8527c",
                                    "bettingOdds": -114.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Kevin Kisner",
                                    "name": "Kevin Kisner",
                                    "selectedText": "Kevin Kisner",
                                    "firstName": "Kevin",
                                    "lastName": "Kisner",
                                    "dob": "1984-02-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "1d44c56918448b1529fc36fa31eceb04",
                                    "bettingOdds": 225.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Carlos Ortiz",
                                    "name": "Carlos Ortiz",
                                    "selectedText": "Carlos Ortiz",
                                    "firstName": "Carlos",
                                    "lastName": "Ortiz",
                                    "dob": "1991-04-24",
                                    "gender": "Male",
                                    "country": "MEX",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33667.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                                    "oddsId": "e44fef8a086ebed7eb7936525fb0d0f6",
                                    "bettingOdds": 300.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "6136af56-a832-4b08-b3f3-e505375bf5ad",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647537240000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 05:14 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Shane Lowry",
                                    "name": "Shane Lowry",
                                    "selectedText": "Shane Lowry",
                                    "firstName": "Shane",
                                    "lastName": "Lowry",
                                    "dob": "1987-04-02",
                                    "gender": "Male",
                                    "country": "IRL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                                    "oddsId": "1e2a9d3f6eaaee6237df835660a075d9",
                                    "bettingOdds": -117.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Graeme McDowell",
                                    "name": "Graeme McDowell",
                                    "selectedText": "Graeme McDowell",
                                    "firstName": "Graeme",
                                    "lastName": "McDowell",
                                    "dob": "1979-07-30",
                                    "gender": "Male",
                                    "country": "NIR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25572.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NIR.png",
                                    "oddsId": "5e77a426e67cbc42aab970f224e7c654",
                                    "bettingOdds": 250.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Henrik Stenson",
                                    "name": "Henrik Stenson",
                                    "selectedText": "Henrik Stenson",
                                    "firstName": "Henrik",
                                    "lastName": "Stenson",
                                    "dob": "1976-04-05",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/21528.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "bd3d5f9d0da12070c541dfad0a5902f5",
                                    "bettingOdds": 275.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "16ba09be-8482-4ff6-82f8-24c8cbb80521",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647537900000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 05:25 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Xander Schauffele",
                                    "name": "Xander Schauffele",
                                    "selectedText": "Xander Schauffele",
                                    "firstName": "Xander",
                                    "lastName": "Schauffele",
                                    "dob": "1993-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "80cd1b1c354d985dbaeb052657f7b93d",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Sam Burns",
                                    "name": "Sam Burns",
                                    "selectedText": "Sam Burns",
                                    "firstName": "Sam",
                                    "lastName": "Burns",
                                    "dob": "1996-07-23",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "d0c6b20d6ecab3c8bfa4fb131a1acbf9",
                                    "bettingOdds": 155.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Gary Woodland",
                                    "name": "Gary Woodland",
                                    "selectedText": "Gary Woodland",
                                    "firstName": "Gary",
                                    "lastName": "Woodland",
                                    "dob": "1984-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "95b5dd829a123b59d2cc6b74f63f52c1",
                                    "bettingOdds": 230.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "2160a8bf-43d1-43ac-ac94-00a3609d4506",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647537900000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 05:25 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Webb Simpson",
                                    "name": "Webb Simpson",
                                    "selectedText": "Webb Simpson",
                                    "firstName": "Webb",
                                    "lastName": "Simpson",
                                    "dob": "1985-08-08",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29221.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f1aee473207ad66a186ecbec2cfcce61",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Francesco Molinari",
                                    "name": "Francesco Molinari",
                                    "selectedText": "Francesco Molinari",
                                    "firstName": "Francesco",
                                    "lastName": "Molinari",
                                    "dob": "1982-11-08",
                                    "gender": "Male",
                                    "country": "ITA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25198.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ITA.png",
                                    "oddsId": "d881c783bebfc2f38099a5de67e43c85",
                                    "bettingOdds": 185.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Martin Laird",
                                    "name": "Martin Laird",
                                    "selectedText": "Martin Laird",
                                    "firstName": "Martin",
                                    "lastName": "Laird",
                                    "dob": "1982-12-29",
                                    "gender": "Male",
                                    "country": "SCO",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27936.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                                    "oddsId": "0db9b00a0e04435e140faf85514a6808",
                                    "bettingOdds": 200.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "5c485233-ce38-44cd-8009-ca5cb9eec4ee",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647538560000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 05:36 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Tyrrell Hatton",
                                    "name": "Tyrrell Hatton",
                                    "selectedText": "Tyrrell Hatton",
                                    "firstName": "Tyrrell",
                                    "lastName": "Hatton",
                                    "dob": "1991-10-14",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "ac7e59e59fc4ebf2a89c08a8718c3173",
                                    "bettingOdds": -113.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Adam Long",
                                    "name": "Adam Long",
                                    "selectedText": "Adam Long",
                                    "firstName": "Adam",
                                    "lastName": "Long",
                                    "dob": "1987-09-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35449.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "211537d4d23d1388d974c0b94f461827",
                                    "bettingOdds": 220.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Robert Streb",
                                    "name": "Robert Streb",
                                    "selectedText": "Robert Streb",
                                    "firstName": "Robert",
                                    "lastName": "Streb",
                                    "dob": "1987-04-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "17ebf3a8dcbb5d16462a0d48cb95d5f8",
                                    "bettingOdds": 300.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "720d1192-7388-49c4-b359-11d3b6644f3f",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647539220000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 05:47 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Adam Hadwin",
                                    "name": "Adam Hadwin",
                                    "selectedText": "Adam Hadwin",
                                    "firstName": "Adam",
                                    "lastName": "Hadwin",
                                    "dob": "1987-11-02",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "d0243540e2a6a65a290d33f485ead7f6",
                                    "bettingOdds": 110.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Lee Hodges",
                                    "name": "Lee Hodges",
                                    "selectedText": "Lee Hodges",
                                    "firstName": "Lee",
                                    "lastName": "Hodges",
                                    "dob": "1995-06-14",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/54628.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "257a9c85a246c753e8896768191a9188",
                                    "bettingOdds": 190.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Charl Schwartzel",
                                    "name": "Charl Schwartzel",
                                    "selectedText": "Charl Schwartzel",
                                    "firstName": "Charl",
                                    "lastName": "Schwartzel",
                                    "dob": "1984-08-31",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26331.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "b99f35de8b87d011b5cc4934ddb01405",
                                    "bettingOdds": 260.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "701980a7-4c5d-420c-ad67-06befcb0c345",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647539220000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 05:47 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Tommy Fleetwood",
                                    "name": "Tommy Fleetwood",
                                    "selectedText": "Tommy Fleetwood",
                                    "firstName": "Tommy",
                                    "lastName": "Fleetwood",
                                    "dob": "1991-01-19",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "9fccb93d2ab9cf3bd1bfa52ca1a1c106",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Russell Knox",
                                    "name": "Russell Knox",
                                    "selectedText": "Russell Knox",
                                    "firstName": "Russell",
                                    "lastName": "Knox",
                                    "dob": "1985-06-21",
                                    "gender": "Male",
                                    "country": "SCO",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                                    "oddsId": "c6c5d829c53f5aca50f13dd95457529a",
                                    "bettingOdds": 185.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Harold Varner III",
                                    "name": "Harold Varner III",
                                    "selectedText": "Harold Varner III",
                                    "firstName": "Harold",
                                    "lastName": "Varner III",
                                    "dob": "1990-08-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "1986658b2b108025200f5efaa1579079",
                                    "bettingOdds": 190.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "ea64f71c-4beb-4dcb-902e-434feec080cc",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647539880000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 05:58 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Brian Harman",
                                    "name": "Brian Harman",
                                    "selectedText": "Brian Harman",
                                    "firstName": "Brian",
                                    "lastName": "Harman",
                                    "dob": "1987-01-19",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "2474b8a246989bcf48d06d5920d3d342",
                                    "bettingOdds": 125.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Emiliano Grillo",
                                    "name": "Emiliano Grillo",
                                    "selectedText": "Emiliano Grillo",
                                    "firstName": "Emiliano",
                                    "lastName": "Grillo",
                                    "dob": "1992-09-14",
                                    "gender": "Male",
                                    "country": "ARG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31646.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ARG.png",
                                    "oddsId": "7c50f0c72c325ca299380322012a3c6e",
                                    "bettingOdds": 190.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Harry Higgs",
                                    "name": "Harry Higgs",
                                    "selectedText": "Harry Higgs",
                                    "firstName": "Harry",
                                    "lastName": "Higgs",
                                    "dob": "1991-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "eb5b85293443d8a6784bac6a8ec07405",
                                    "bettingOdds": 225.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "d1c4e782-d6e2-4302-807b-c6e2be373a3a",
                            "prompt": "3 Ball",
                            "startTime": 1647388740000,
                            "endTime": 1647540540000,
                            "title": "3 Ball",
                            "pointValue": 25,
                            "description": "3 Ball - 06:09 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Bernd Wiesberger",
                                    "name": "Bernd Wiesberger",
                                    "selectedText": "Bernd Wiesberger",
                                    "firstName": "Bernd",
                                    "lastName": "Wiesberger",
                                    "dob": "1985-10-08",
                                    "gender": "Male",
                                    "country": "AUT",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                                    "oddsId": "ea3ac9e439b6cd0c9cdaae4bbcc6389e",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "David Lipsky",
                                    "name": "David Lipsky",
                                    "selectedText": "David Lipsky",
                                    "firstName": "David",
                                    "lastName": "Lipsky",
                                    "dob": "1988-07-14",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36326.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "fdd1da3fada7a9935f337a495e14b492",
                                    "bettingOdds": 185.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Sahith Theegala",
                                    "name": "Sahith Theegala",
                                    "selectedText": "Sahith Theegala",
                                    "firstName": "Sahith",
                                    "lastName": "Theegala",
                                    "dob": "1997-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "ce573721e5a81550535bb90b9712fd8b",
                                    "bettingOdds": 190.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }
                    ]
                }, {
                    "id": "572e35b1-db5f-4c18-981a-7f0a24aa8e0a",
                    "prompt": "Round 1 Leader",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Round 1 Leader",
                    "description": "Round 1 Leader - 10:19 Tee Time",
                    "limit": 1,
                    "questions": [{
                            "id": "572e35b1-db5f-4c18-981a-7f0a24aa8e0a",
                            "prompt": "Round 1 Leader",
                            "startTime": 1647388740000,
                            "endTime": 1647814776000,
                            "title": "Round 1 Leader",
                            "pointValue": 25,
                            "description": "Round 1 Leader - 10:19 Tee Time",
                            "showTeeTime": false,
                            "players": [{
                                    "id": "Viktor Hovland",
                                    "name": "Viktor Hovland",
                                    "selectedText": "Viktor Hovland",
                                    "firstName": "Viktor",
                                    "lastName": "Hovland",
                                    "dob": "1997-09-18",
                                    "gender": "Male",
                                    "country": "NOR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                                    "oddsId": "955daa9aff4ff072dd61b58626b8207d",
                                    "bettingOdds": 0.0,
                                    "placedBet": true,
                                    "betPlacedAt": 2500.0,
                                    "potentialWinnings": 625.0,
                                    "winner": true
                                }, {
                                    "id": "Kevin Kisner",
                                    "name": "Kevin Kisner",
                                    "selectedText": "Kevin Kisner",
                                    "firstName": "Kevin",
                                    "lastName": "Kisner",
                                    "dob": "1984-02-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "97a0c776a08ec77e19d9d354d24a2eb9",
                                    "bettingOdds": 8000.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Mito Pereira",
                                    "name": "Mito Pereira",
                                    "selectedText": "Mito Pereira",
                                    "firstName": "Mito",
                                    "lastName": "Pereira",
                                    "dob": "1995-03-31",
                                    "gender": "Male",
                                    "country": "CHI",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                                    "oddsId": "f6728a89f9852122c03245300453b284",
                                    "bettingOdds": 10000.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tommy Gainey",
                                    "name": "Tommy Gainey",
                                    "selectedText": "Tommy Gainey",
                                    "firstName": "Tommy",
                                    "lastName": "Gainey",
                                    "dob": "1975-08-13",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30750.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "fc4374c14a658036717bf158c4a446fe",
                                    "bettingOdds": 10000.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Brian Gay",
                                    "name": "Brian Gay",
                                    "selectedText": "Brian Gay",
                                    "firstName": "Brian",
                                    "lastName": "Gay",
                                    "dob": "1971-12-14",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/19846.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b03a9cd9464afcc80f5ed6c8bbcfdd75",
                                    "bettingOdds": 15000.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }
                    ]
                }, {
                    "id": "03a89e25-db52-4034-8211-00df40ee7d04",
                    "prompt": "Make The Cut",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Make The Cut",
                    "description": "Make The Cut - 10:19 Tee Time",
                    "limit": 1,
                    "questions": [{
                            "id": "03a89e25-db52-4034-8211-00df40ee7d04",
                            "prompt": "Make The Cut",
                            "startTime": 1647388740000,
                            "endTime": 1647814776000,
                            "title": "Make The Cut",
                            "pointValue": 25,
                            "description": "Make The Cut - 10:19 Tee Time",
                            "showTeeTime": false,
                            "players": [{
                                    "id": "Collin Morikawa",
                                    "name": "Collin Morikawa",
                                    "selectedText": "Collin Morikawa",
                                    "firstName": "Collin",
                                    "lastName": "Morikawa",
                                    "dob": "1997-02-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "3b09a8ecd4aae6ce907252833952c0af",
                                    "bettingOdds": -699.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Justin Thomas",
                                    "name": "Justin Thomas",
                                    "selectedText": "Justin Thomas",
                                    "firstName": "Justin",
                                    "lastName": "Thomas",
                                    "dob": "1993-04-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5aecc0886d8d99d8c5a3a0116e37f917",
                                    "bettingOdds": -699.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Viktor Hovland",
                                    "name": "Viktor Hovland",
                                    "selectedText": "Viktor Hovland",
                                    "firstName": "Viktor",
                                    "lastName": "Hovland",
                                    "dob": "1997-09-18",
                                    "gender": "Male",
                                    "country": "NOR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                                    "oddsId": "232766fe5b7dcee13c64daf728b34bdb",
                                    "bettingOdds": -699.0,
                                    "placedBet": false,
                                    "potentialWinnings": 4.0,
                                    "winner": true
                                }, {
                                    "id": "Dustin Johnson",
                                    "name": "Dustin Johnson",
                                    "selectedText": "Dustin Johnson",
                                    "firstName": "Dustin",
                                    "lastName": "Johnson",
                                    "dob": "1984-06-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "7407a9ac257c91a405d35b95de178b44",
                                    "bettingOdds": -549.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Sam Burns",
                                    "name": "Sam Burns",
                                    "selectedText": "Sam Burns",
                                    "firstName": "Sam",
                                    "lastName": "Burns",
                                    "dob": "1996-07-23",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "50f004023e7cab53baeedd9b944b5fe1",
                                    "bettingOdds": -500.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Louis Oosthuizen",
                                    "name": "Louis Oosthuizen",
                                    "selectedText": "Louis Oosthuizen",
                                    "firstName": "Louis",
                                    "lastName": "Oosthuizen",
                                    "dob": "1982-10-19",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "1a2ed7ef9e8c986611e6feeba64aedc1",
                                    "bettingOdds": -449.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Xander Schauffele",
                                    "name": "Xander Schauffele",
                                    "selectedText": "Xander Schauffele",
                                    "firstName": "Xander",
                                    "lastName": "Schauffele",
                                    "dob": "1993-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "9b6c804385600efdf44cd8006ccdd34f",
                                    "bettingOdds": -449.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Shane Lowry",
                                    "name": "Shane Lowry",
                                    "selectedText": "Shane Lowry",
                                    "firstName": "Shane",
                                    "lastName": "Lowry",
                                    "dob": "1987-04-02",
                                    "gender": "Male",
                                    "country": "IRL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                                    "oddsId": "e4222fcf21d348ea100f1a0828028c38",
                                    "bettingOdds": -400.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tyrrell Hatton",
                                    "name": "Tyrrell Hatton",
                                    "selectedText": "Tyrrell Hatton",
                                    "firstName": "Tyrrell",
                                    "lastName": "Hatton",
                                    "dob": "1991-10-14",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "30551ef8396ee372d48c38c8adef6648",
                                    "bettingOdds": -400.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Abraham Ancer",
                                    "name": "Abraham Ancer",
                                    "selectedText": "Abraham Ancer",
                                    "firstName": "Abraham",
                                    "lastName": "Ancer",
                                    "dob": "1991-02-27",
                                    "gender": "Male",
                                    "country": "MEX",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45526.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                                    "oddsId": "c0a98f177b52cdabd7c36becbd6724fe",
                                    "bettingOdds": -324.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Brooks Koepka",
                                    "name": "Brooks Koepka",
                                    "selectedText": "Brooks Koepka",
                                    "firstName": "Brooks",
                                    "lastName": "Koepka",
                                    "dob": "1990-05-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5be5591a819c3c02608919d1b67f5995",
                                    "bettingOdds": -324.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Jason Kokrak",
                                    "name": "Jason Kokrak",
                                    "selectedText": "Jason Kokrak",
                                    "firstName": "Jason",
                                    "lastName": "Kokrak",
                                    "dob": "1985-05-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30944.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "e4cf38549031c8a353fc366ea619663c",
                                    "bettingOdds": -324.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Matthew Fitzpatrick",
                                    "name": "Matthew Fitzpatrick",
                                    "selectedText": "Matthew Fitzpatrick",
                                    "firstName": "Matt",
                                    "lastName": "Fitzpatrick",
                                    "dob": "1994-09-01",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "5f365fb94ff6a6ca47b38719c448d1ea",
                                    "bettingOdds": -324.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tommy Fleetwood",
                                    "name": "Tommy Fleetwood",
                                    "selectedText": "Tommy Fleetwood",
                                    "firstName": "Tommy",
                                    "lastName": "Fleetwood",
                                    "dob": "1991-01-19",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "719b4fb48402eba923430997a16a11d5",
                                    "bettingOdds": -289.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Jason Day",
                                    "name": "Jason Day",
                                    "selectedText": "Jason Day",
                                    "firstName": "Jason",
                                    "lastName": "Day",
                                    "dob": "1987-11-12",
                                    "gender": "Male",
                                    "country": "AUS",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28089.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUS.png",
                                    "oddsId": "7877628e00346b94defffea3c15d5d78",
                                    "bettingOdds": -259.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Gary Woodland",
                                    "name": "Gary Woodland",
                                    "selectedText": "Gary Woodland",
                                    "firstName": "Gary",
                                    "lastName": "Woodland",
                                    "dob": "1984-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "9255fc7499863328a666338c86736187",
                                    "bettingOdds": -239.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Keegan Bradley",
                                    "name": "Keegan Bradley",
                                    "selectedText": "Keegan Bradley",
                                    "firstName": "Keegan",
                                    "lastName": "Bradley",
                                    "dob": "1986-06-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33141.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c809bf32a71a62aba2d656a239406598",
                                    "bettingOdds": -239.0,
                                    "placedBet": true,
									"betPlacedAt": -239.0,
                                    "winner": false
                                }, {
                                    "id": "Christiaan Bezuidenhout",
                                    "name": "Christiaan Bezuidenhout",
                                    "selectedText": "Christiaan Bezuidenhout",
                                    "firstName": "Christiaan",
                                    "lastName": "Bezuidenhout",
                                    "dob": "1994-05-18",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45522.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "4c4c8c5052203066c8827da4820e89e2",
                                    "bettingOdds": -224.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Russell Knox",
                                    "name": "Russell Knox",
                                    "selectedText": "Russell Knox",
                                    "firstName": "Russell",
                                    "lastName": "Knox",
                                    "dob": "1985-06-21",
                                    "gender": "Male",
                                    "country": "SCO",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                                    "oddsId": "d4b0b08130e0db2674e9fe9ee997243d",
                                    "bettingOdds": -224.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "MacKenzie Hughes",
                                    "name": "MacKenzie Hughes",
                                    "selectedText": "MacKenzie Hughes",
                                    "firstName": "Mackenzie",
                                    "lastName": "Hughes",
                                    "dob": "1990-11-23",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35506.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "25c90d951f0ea89c37b717d3c36fdc47",
                                    "bettingOdds": -204.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Adam Hadwin",
                                    "name": "Adam Hadwin",
                                    "selectedText": "Adam Hadwin",
                                    "firstName": "Adam",
                                    "lastName": "Hadwin",
                                    "dob": "1987-11-02",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "d77fe4e4dc6cc5d9936d731daedd7ddb",
                                    "bettingOdds": -200.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Alexander Noren",
                                    "name": "Alexander Noren",
                                    "selectedText": "Alexander Noren",
                                    "firstName": "Alex",
                                    "lastName": "Noren",
                                    "dob": "1982-07-12",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "82de3a18595928a751d28a70f108adc6",
                                    "bettingOdds": -200.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Brian Harman",
                                    "name": "Brian Harman",
                                    "selectedText": "Brian Harman",
                                    "firstName": "Brian",
                                    "lastName": "Harman",
                                    "dob": "1987-01-19",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "57494b52d870bc8ec2bd25eb3eb216b8",
                                    "bettingOdds": -200.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Harold Varner",
                                    "name": "Harold Varner",
                                    "selectedText": "Harold Varner",
                                    "oddsId": "2308db763862499bcd5958116007cf8b",
                                    "bettingOdds": -200.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Kevin Kisner",
                                    "name": "Kevin Kisner",
                                    "selectedText": "Kevin Kisner",
                                    "firstName": "Kevin",
                                    "lastName": "Kisner",
                                    "dob": "1984-02-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "7c19823909d9896ac0aa313c7e3a4b18",
                                    "bettingOdds": -179.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }
                    ]
                }
            ]
        }, {
            "id": "27d6cdb8-6070-4441-9b17-629f3b51c267",
            "type": "ROUND",
            "title": "ROUND 2",
            "dayOfWeek": "Friday",
            "startTime": 1647216000000,
            "endTime": 1647814776000,
            "open": false,
            "description": "50 POINT PICK",
            "questions": [{
                    "id": "0f1daf45-2226-4f94-983b-38648fdbe51f",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647604920000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 12:02 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Mito Guillermo Pereira",
                            "name": "Mito Guillermo Pereira",
                            "selectedText": "Mito Guillermo Pereira",
                            "firstName": "Mito",
                            "lastName": "Pereira",
                            "dob": "1995-03-31",
                            "gender": "Male",
                            "country": "CHI",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                            "oddsId": "cc77cb937f7c3800c7e71b07265c26a1",
                            "bettingOdds": 160.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Pat Perez",
                            "name": "Pat Perez",
                            "selectedText": "Pat Perez",
                            "firstName": "Pat",
                            "lastName": "Perez",
                            "dob": "1976-03-01",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24361.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f793b59e5c099da12bcc6ee17683ec40",
                            "bettingOdds": 165.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Martin Kaymer",
                            "name": "Martin Kaymer",
                            "selectedText": "Martin Kaymer",
                            "firstName": "Martin",
                            "lastName": "Kaymer",
                            "dob": "1984-12-28",
                            "gender": "Male",
                            "country": "GER",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27408.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_GER.png",
                            "oddsId": "1e9d6d403e324d255b075014055182e9",
                            "bettingOdds": 200.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "50c9890c-1a22-4be9-8ec0-ddbc888b3f78",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647605580000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 12:13 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Justin Thomas",
                            "name": "Justin Thomas",
                            "selectedText": "Justin Thomas",
                            "firstName": "Justin",
                            "lastName": "Thomas",
                            "dob": "1993-04-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "39c5bb56bd151d341ab93fe0ecb612f1",
                            "bettingOdds": -120.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Kevin Kisner",
                            "name": "Kevin Kisner",
                            "selectedText": "Kevin Kisner",
                            "firstName": "Kevin",
                            "lastName": "Kisner",
                            "dob": "1984-02-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "78e5e177d6252b1bde46c788dbb0672e",
                            "bettingOdds": 240.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Carlos Ortiz",
                            "name": "Carlos Ortiz",
                            "selectedText": "Carlos Ortiz",
                            "firstName": "Carlos",
                            "lastName": "Ortiz",
                            "dob": "1991-04-24",
                            "gender": "Male",
                            "country": "MEX",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33667.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                            "oddsId": "bf30af9efac3efdcdcac3e8acbeb67a3",
                            "bettingOdds": 300.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "727c31c5-a974-4fbe-8c8f-a33a805de62b",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647605580000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 12:13 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Joel Dahmen",
                            "name": "Joel Dahmen",
                            "selectedText": "Joel Dahmen",
                            "firstName": "Joel",
                            "lastName": "Dahmen",
                            "dob": "1987-11-11",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34076.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "743f149a831cd53ac4e7b1b79f3672ea",
                            "bettingOdds": 117.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Michael Thompson",
                            "name": "Michael Thompson",
                            "selectedText": "Michael Thompson",
                            "firstName": "Michael",
                            "lastName": "Thompson",
                            "dob": "1985-04-16",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32150.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "e7cc384837e8c5a12682ed5cfaa4e440",
                            "bettingOdds": 170.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Andrew Landry",
                            "name": "Andrew Landry",
                            "selectedText": "Andrew Landry",
                            "firstName": "Andrew",
                            "lastName": "Landry",
                            "dob": "1987-08-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33410.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "59ba83c7d0ce5eed7f7328a73319906d",
                            "bettingOdds": 275.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "688dcecf-871f-4ebf-95f7-5761d33780e5",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647606240000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 12:24 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Shane Lowry",
                            "name": "Shane Lowry",
                            "selectedText": "Shane Lowry",
                            "firstName": "Shane",
                            "lastName": "Lowry",
                            "dob": "1987-04-02",
                            "gender": "Male",
                            "country": "IRL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                            "oddsId": "15166f35cf30a70c5618c2dfc44c66d1",
                            "bettingOdds": -120.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Graeme McDowell",
                            "name": "Graeme McDowell",
                            "selectedText": "Graeme McDowell",
                            "firstName": "Graeme",
                            "lastName": "McDowell",
                            "dob": "1979-07-30",
                            "gender": "Male",
                            "country": "NIR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25572.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NIR.png",
                            "oddsId": "2af1c7773493dac97e083d958a723382",
                            "bettingOdds": 260.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Henrik Stenson",
                            "name": "Henrik Stenson",
                            "selectedText": "Henrik Stenson",
                            "firstName": "Henrik",
                            "lastName": "Stenson",
                            "dob": "1976-04-05",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/21528.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "0e3747b5d6af15145a99862525d7de89",
                            "bettingOdds": 275.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "2277acb3-64fa-4f5c-b934-539aef775a65",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647606900000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 12:35 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Sam Burns",
                            "name": "Sam Burns",
                            "selectedText": "Sam Burns",
                            "firstName": "Sam",
                            "lastName": "Burns",
                            "dob": "1996-07-23",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "1c7fdfec637320a3d0450d94891b1954",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Xander Schauffele",
                            "name": "Xander Schauffele",
                            "selectedText": "Xander Schauffele",
                            "firstName": "Xander",
                            "lastName": "Schauffele",
                            "dob": "1993-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "253b3c364a3cd8cf8284ba1235a02c3b",
                            "bettingOdds": 160.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Gary Woodland",
                            "name": "Gary Woodland",
                            "selectedText": "Gary Woodland",
                            "firstName": "Gary",
                            "lastName": "Woodland",
                            "dob": "1984-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5b4f9793867a57d73838df3231c6b02f",
                            "bettingOdds": 225.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "69284509-5ce7-4c3e-8c8d-1cf225b55ede",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647606900000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 12:35 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Webb Simpson",
                            "name": "Webb Simpson",
                            "selectedText": "Webb Simpson",
                            "firstName": "Webb",
                            "lastName": "Simpson",
                            "dob": "1985-08-08",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29221.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f6167e716b2b7668988681097a6c6def",
                            "bettingOdds": 115.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Martin Laird",
                            "name": "Martin Laird",
                            "selectedText": "Martin Laird",
                            "firstName": "Martin",
                            "lastName": "Laird",
                            "dob": "1982-12-29",
                            "gender": "Male",
                            "country": "SCO",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27936.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                            "oddsId": "bb8a6c84e9cb2bc5b8468d67dc867375",
                            "bettingOdds": 215.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Francesco Molinari",
                            "name": "Francesco Molinari",
                            "selectedText": "Francesco Molinari",
                            "firstName": "Francesco",
                            "lastName": "Molinari",
                            "dob": "1982-11-08",
                            "gender": "Male",
                            "country": "ITA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25198.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ITA.png",
                            "oddsId": "ca99e3c9a68f0d64dd7321578f043772",
                            "bettingOdds": 220.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "a09883a0-441b-4c9a-8042-19b0736c7447",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647607560000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 12:46 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Tyrrell Hatton",
                            "name": "Tyrrell Hatton",
                            "selectedText": "Tyrrell Hatton",
                            "firstName": "Tyrrell",
                            "lastName": "Hatton",
                            "dob": "1991-10-14",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "036877e517611ef8f8136e068a05458a",
                            "bettingOdds": -107.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Adam Long",
                            "name": "Adam Long",
                            "selectedText": "Adam Long",
                            "firstName": "Adam",
                            "lastName": "Long",
                            "dob": "1987-09-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35449.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "72bcdb09dcfb07a6f0ec2ae626b417e0",
                            "bettingOdds": 225.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Robert Streb",
                            "name": "Robert Streb",
                            "selectedText": "Robert Streb",
                            "firstName": "Robert",
                            "lastName": "Streb",
                            "dob": "1987-04-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "046e530456ea2db0ce78cf4db2aec58c",
                            "bettingOdds": 275.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "e4f33835-1b7f-4581-b628-4c1155ec9007",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647608220000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 12:57 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Tommy Fleetwood",
                            "name": "Tommy Fleetwood",
                            "selectedText": "Tommy Fleetwood",
                            "firstName": "Tommy",
                            "lastName": "Fleetwood",
                            "dob": "1991-01-19",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "d713811822dc5320765c46009e2130f5",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Russell Knox",
                            "name": "Russell Knox",
                            "selectedText": "Russell Knox",
                            "firstName": "Russell",
                            "lastName": "Knox",
                            "dob": "1985-06-21",
                            "gender": "Male",
                            "country": "SCO",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                            "oddsId": "eeb4fe53f362dcb6b4eb5d499ee8ca00",
                            "bettingOdds": 185.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Harold Varner III",
                            "name": "Harold Varner III",
                            "selectedText": "Harold Varner III",
                            "firstName": "Harold",
                            "lastName": "Varner III",
                            "dob": "1990-08-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "0bc200f3ca1bf9cf0c5293bf7ff2323d",
                            "bettingOdds": 190.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "5ef3e5ac-61c4-46ce-98ce-f8fc5c334fb6",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647608880000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 01:08 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Brian Harman",
                            "name": "Brian Harman",
                            "selectedText": "Brian Harman",
                            "firstName": "Brian",
                            "lastName": "Harman",
                            "dob": "1987-01-19",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "4dd7b214523348bdf18ce62b02a9a7d1",
                            "bettingOdds": 120.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Harry Higgs",
                            "name": "Harry Higgs",
                            "selectedText": "Harry Higgs",
                            "firstName": "Harry",
                            "lastName": "Higgs",
                            "dob": "1991-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "195da1aafafe05aef2dd017f0575f8c6",
                            "bettingOdds": 210.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Emiliano Grillo",
                            "name": "Emiliano Grillo",
                            "selectedText": "Emiliano Grillo",
                            "firstName": "Emiliano",
                            "lastName": "Grillo",
                            "dob": "1992-09-14",
                            "gender": "Male",
                            "country": "ARG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31646.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ARG.png",
                            "oddsId": "f9c5396d926190449b73be0c6540b410",
                            "bettingOdds": 215.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "95de56be-2ed5-4092-a60f-27d1d9e964bd",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647609540000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 01:19 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Bernd Wiesberger",
                            "name": "Bernd Wiesberger",
                            "selectedText": "Bernd Wiesberger",
                            "firstName": "Bernd",
                            "lastName": "Wiesberger",
                            "dob": "1985-10-08",
                            "gender": "Male",
                            "country": "AUT",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                            "oddsId": "228109ac8b1b749624f97e36ca41a69e",
                            "bettingOdds": 165.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Sahith Theegala",
                            "name": "Sahith Theegala",
                            "selectedText": "Sahith Theegala",
                            "firstName": "Sahith",
                            "lastName": "Theegala",
                            "dob": "1997-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "9727e67cd1656c782f7cddcf358fbce7",
                            "bettingOdds": 180.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "David Lipsky",
                            "name": "David Lipsky",
                            "selectedText": "David Lipsky",
                            "firstName": "David",
                            "lastName": "Lipsky",
                            "dob": "1988-07-14",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36326.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f14c758aa81ca2336280269a217b5a8d",
                            "bettingOdds": 180.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "c457b158-c824-4dd8-bc8b-c9156ad486c7",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647621660000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 04:41 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Cameron Tringale",
                            "name": "Cameron Tringale",
                            "selectedText": "Cameron Tringale",
                            "firstName": "Cameron",
                            "lastName": "Tringale",
                            "dob": "1987-08-24",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33419.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "dc0ec21afbf5dc4cabeb79e9dc214ac5",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Denny McCarthy",
                            "name": "Denny McCarthy",
                            "selectedText": "Denny McCarthy",
                            "firstName": "Denny",
                            "lastName": "McCarthy",
                            "dob": "1993-03-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47993.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5e7b3dfbdc8ea2ed9acc2fefdac9ad62",
                            "bettingOdds": 160.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Matthew NeSmith",
                            "name": "Matthew NeSmith",
                            "selectedText": "Matthew NeSmith",
                            "firstName": "Matthew",
                            "lastName": "NeSmith",
                            "dob": "1993-09-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "9b2a4dd1c6a7a5cde534e2dce9141f4b",
                            "bettingOdds": 220.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "5d0bcf38-d88b-46d0-8682-bd81ec6fbba7",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647622320000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 04:52 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Jhonattan Vegas",
                            "name": "Jhonattan Vegas",
                            "selectedText": "Jhonattan Vegas",
                            "firstName": "Jhonattan",
                            "lastName": "Vegas",
                            "dob": "1984-08-19",
                            "gender": "Male",
                            "country": "VEN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27064.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_VEN.png",
                            "oddsId": "66f0564d70b7e0a4b53948e396b2659c",
                            "bettingOdds": 115.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Sam Ryder",
                            "name": "Sam Ryder",
                            "selectedText": "Sam Ryder",
                            "firstName": "Sam",
                            "lastName": "Ryder",
                            "dob": "1989-12-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37275.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "3f7528a7417185233a25958ccaadaf5e",
                            "bettingOdds": 185.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Scott Piercy",
                            "name": "Scott Piercy",
                            "selectedText": "Scott Piercy",
                            "firstName": "Scott",
                            "lastName": "Piercy",
                            "dob": "1978-11-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25818.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c86a6f90a90c292930373f07118cb96a",
                            "bettingOdds": 260.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "105a28d7-9489-47f8-8bd5-e1f260bbf7a9",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647622980000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 05:03 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Dustin Johnson",
                            "name": "Dustin Johnson",
                            "selectedText": "Dustin Johnson",
                            "firstName": "Dustin",
                            "lastName": "Johnson",
                            "dob": "1984-06-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "ae940b4aeffe8b529f8c6c3a8e7b431e",
                            "bettingOdds": 115.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Bubba Watson",
                            "name": "Bubba Watson",
                            "selectedText": "Bubba Watson",
                            "firstName": "Bubba",
                            "lastName": "Watson",
                            "dob": "1978-11-05",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25804.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "d14d63f2cc10144b68d35e97735e4752",
                            "bettingOdds": 200.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Jason Day",
                            "name": "Jason Day",
                            "selectedText": "Jason Day",
                            "firstName": "Jason",
                            "lastName": "Day",
                            "dob": "1987-11-12",
                            "gender": "Male",
                            "country": "AUS",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28089.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUS.png",
                            "oddsId": "2433add646c909542f56aa12e0ddd8a3",
                            "bettingOdds": 240.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "5e552fa7-9095-4c72-a000-983097caa69e",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647623640000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 05:14 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Viktor Hovland",
                            "name": "Viktor Hovland",
                            "selectedText": "Viktor Hovland",
                            "firstName": "Viktor",
                            "lastName": "Hovland",
                            "dob": "1997-09-18",
                            "gender": "Male",
                            "country": "NOR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                            "oddsId": "36c2f5c6d59a3ff0160343e00a1cfb94",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Collin Morikawa",
                            "name": "Collin Morikawa",
                            "selectedText": "Collin Morikawa",
                            "firstName": "Collin",
                            "lastName": "Morikawa",
                            "dob": "1997-02-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "6a3bc19f9cedc73e73118e29d35f1b11",
                            "bettingOdds": 155.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Abraham Ancer",
                            "name": "Abraham Ancer",
                            "selectedText": "Abraham Ancer",
                            "firstName": "Abraham",
                            "lastName": "Ancer",
                            "dob": "1991-02-27",
                            "gender": "Male",
                            "country": "MEX",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45526.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                            "oddsId": "780957ed2f446439f72e4d7776e8a2c8",
                            "bettingOdds": 240.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "abf4d9e9-248c-421a-aede-7f79ba24e52d",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647623640000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 05:14 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Lanto Griffin",
                            "name": "Lanto Griffin",
                            "selectedText": "Lanto Griffin",
                            "firstName": "Lanto",
                            "lastName": "Griffin",
                            "dob": "1988-06-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35310.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "3c1ee126e2bc2b99adbcddcf78bacc57",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Brendon Todd",
                            "name": "Brendon Todd",
                            "selectedText": "Brendon Todd",
                            "firstName": "Brendon",
                            "lastName": "Todd",
                            "dob": "1985-07-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30927.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5cc08f5bbd6963a94a4f781322289195",
                            "bettingOdds": 180.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Zach Johnson",
                            "name": "Zach Johnson",
                            "selectedText": "Zach Johnson",
                            "firstName": "Zach",
                            "lastName": "Johnson",
                            "dob": "1976-02-24",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24024.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "782758dbd89a6876cea578b384af3bf4",
                            "bettingOdds": 195.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "9674d7a2-548b-4110-afd5-aee69681da53",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647624300000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 05:25 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Louis Oosthuizen",
                            "name": "Louis Oosthuizen",
                            "selectedText": "Louis Oosthuizen",
                            "firstName": "Louis",
                            "lastName": "Oosthuizen",
                            "dob": "1982-10-19",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "6a84ea8a02819d34a9a4bbe5b30a5711",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Brooks Koepka",
                            "name": "Brooks Koepka",
                            "selectedText": "Brooks Koepka",
                            "firstName": "Brooks",
                            "lastName": "Koepka",
                            "dob": "1990-05-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "61dd04da0d2f5445bdf55c24a571ccf5",
                            "bettingOdds": 180.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Jason Kokrak",
                            "name": "Jason Kokrak",
                            "selectedText": "Jason Kokrak",
                            "firstName": "Jason",
                            "lastName": "Kokrak",
                            "dob": "1985-05-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30944.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "af50aeadb9b2d8c2c10f2d7bf3fbd13c",
                            "bettingOdds": 200.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "f4fd1538-34e0-44de-86e3-13847f9653f3",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647624960000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 05:36 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Patton Kizzire",
                            "name": "Patton Kizzire",
                            "selectedText": "Patton Kizzire",
                            "firstName": "Patton",
                            "lastName": "Kizzire",
                            "dob": "1986-03-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c7f0a5673836e5da6ea29954ecbe91b4",
                            "bettingOdds": 130.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Matt Kuchar",
                            "name": "Matt Kuchar",
                            "selectedText": "Matt Kuchar",
                            "firstName": "Matt",
                            "lastName": "Kuchar",
                            "dob": "1978-06-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "67480dd8e2f98a14b3c782e8f64ee5f3",
                            "bettingOdds": 135.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Martin Trainer",
                            "name": "Martin Trainer",
                            "selectedText": "Martin Trainer",
                            "firstName": "Martin",
                            "lastName": "Trainer",
                            "dob": "1991-04-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35617.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "06327480f6fe71841a1dead1aeee0422",
                            "bettingOdds": 320.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "a4f7be20-3026-42c4-9384-28b03061316d",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647624960000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 05:36 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Branden Grace",
                            "name": "Branden Grace",
                            "selectedText": "Branden Grace",
                            "firstName": "Branden",
                            "lastName": "Grace",
                            "dob": "1988-05-20",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29974.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "9fe972e494ed4022164586e2fae70965",
                            "bettingOdds": 160.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tyler Duncan",
                            "name": "Tyler Duncan",
                            "selectedText": "Tyler Duncan",
                            "firstName": "Tyler",
                            "lastName": "Duncan",
                            "dob": "1989-07-13",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "626c76d20b84096ec628a5c82bf3037c",
                            "bettingOdds": 170.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Luke Donald",
                            "name": "Luke Donald",
                            "selectedText": "Luke Donald",
                            "firstName": "Luke",
                            "lastName": "Donald",
                            "dob": "1977-12-07",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "27c229443938690ff8580d61d513eaaa",
                            "bettingOdds": 195.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "e5a37d26-0d65-40ab-8287-9210b12a9ff2",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647625620000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 05:47 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Keegan Bradley",
                            "name": "Keegan Bradley",
                            "selectedText": "Keegan Bradley",
                            "firstName": "Keegan",
                            "lastName": "Bradley",
                            "dob": "1986-06-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33141.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "bfb044c2201d56db2e6be5a0b244b5f2",
                            "bettingOdds": 110.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "JT Poston",
                            "name": "JT Poston",
                            "selectedText": "JT Poston",
                            "firstName": "J.T.",
                            "lastName": "Poston",
                            "dob": "1993-06-01",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49771.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "548f6ad529b0e76b84cc061533f30388",
                            "bettingOdds": 210.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Nate Lashley",
                            "name": "Nate Lashley",
                            "selectedText": "Nate Lashley",
                            "firstName": "Nate",
                            "lastName": "Lashley",
                            "dob": "1982-12-12",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "8d390064e4213cb23df8b4d5cef8ec63",
                            "bettingOdds": 240.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "d762122c-72c3-49bb-9768-0c536b44db2c",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647625620000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 05:47 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Chez Reavie",
                            "name": "Chez Reavie",
                            "selectedText": "Chez Reavie",
                            "firstName": "Chez",
                            "lastName": "Reavie",
                            "dob": "1981-11-12",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26476.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "db9b82ba6d98b776e3c7a3cfced2fd21",
                            "bettingOdds": 155.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Cheng-Tsung Pan",
                            "name": "Cheng-Tsung Pan",
                            "selectedText": "Cheng-Tsung Pan",
                            "firstName": "C.T.",
                            "lastName": "Pan",
                            "dob": "1991-11-12",
                            "gender": "Male",
                            "country": "TPE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29908.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_TPE.png",
                            "oddsId": "54a9b59af831e63dfc422ff7e31dc928",
                            "bettingOdds": 160.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Ryan Brehm",
                            "name": "Ryan Brehm",
                            "selectedText": "Ryan Brehm",
                            "firstName": "Ryan",
                            "lastName": "Brehm",
                            "dob": "1986-04-05",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28420.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "e0e3ee82e02f08b97911c8a3c4c6b6e3",
                            "bettingOdds": 215.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "855d8734-34bb-43f9-9bdc-6c39142e551e",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647626280000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 05:58 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Matthew Fitzpatrick",
                            "name": "Matthew Fitzpatrick",
                            "selectedText": "Matthew Fitzpatrick",
                            "firstName": "Matt",
                            "lastName": "Fitzpatrick",
                            "dob": "1994-09-01",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "9334731540d29c73530b69f5388853fe",
                            "bettingOdds": 110.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Alexander Noren",
                            "name": "Alexander Noren",
                            "selectedText": "Alexander Noren",
                            "firstName": "Alex",
                            "lastName": "Noren",
                            "dob": "1982-07-12",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "17178e7eae818dd589089443b54e48e5",
                            "bettingOdds": 155.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Adam Schenk",
                            "name": "Adam Schenk",
                            "selectedText": "Adam Schenk",
                            "firstName": "Adam",
                            "lastName": "Schenk",
                            "dob": "1992-01-26",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47347.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "0382e21841f66f8cfb5b625a78f77007",
                            "bettingOdds": 340.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "83293664-8a09-4be5-8499-4b23d84fa69d",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647626940000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 06:09 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Aaron Wise",
                            "name": "Aaron Wise",
                            "selectedText": "Aaron Wise",
                            "firstName": "Aaron",
                            "lastName": "Wise",
                            "dob": "1996-06-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49964.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "72af15bbbd379ef187893fb3f0fdf7ba",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Danny Lee",
                            "name": "Danny Lee",
                            "selectedText": "Danny Lee",
                            "firstName": "Danny",
                            "lastName": "Lee",
                            "dob": "1990-07-24",
                            "gender": "Male",
                            "country": "NZL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                            "oddsId": "d2082e376395f9cf8e6abb7a3c23350a",
                            "bettingOdds": 175.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "J. J. Spaun",
                            "name": "J. J. Spaun",
                            "selectedText": "J. J. Spaun",
                            "firstName": "J.J.",
                            "lastName": "Spaun",
                            "dob": "1990-08-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "93eafe243f19b449a3ed617471339756",
                            "bettingOdds": 200.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "6e3be091-1a79-490f-a7bd-c9aac2af04df",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647626940000,
                    "title": "3 Ball",
                    "pointValue": 50,
                    "description": "3 Ball - 06:09 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Mackenzie Hughes",
                            "name": "Mackenzie Hughes",
                            "selectedText": "Mackenzie Hughes",
                            "firstName": "Mackenzie",
                            "lastName": "Hughes",
                            "dob": "1990-11-23",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35506.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "d866a36897ae8bdad921c6bbcfcbf4e5",
                            "bettingOdds": 160.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Kevin Streelman",
                            "name": "Kevin Streelman",
                            "selectedText": "Kevin Streelman",
                            "firstName": "Kevin",
                            "lastName": "Streelman",
                            "dob": "1978-11-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "77cca8e207c8c23ac952fd3a1a5c4e0d",
                            "bettingOdds": 170.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Doc Redman",
                            "name": "Doc Redman",
                            "selectedText": "Doc Redman",
                            "firstName": "Doc",
                            "lastName": "Redman",
                            "dob": "1997-12-27",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/53165.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a793b3547d5143e3d08b9e7c60373414",
                            "bettingOdds": 195.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "0c4941b3-ce19-48d1-896a-ad5b0ab4bf72",
                    "prompt": "Round 2 Leader",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Round 2 Leader",
                    "pointValue": 50,
                    "description": "Round 2 Leader - 10:19 Tee Time",
                    "showTeeTime": false,
                    "players": [{
                            "id": "Matthew NeSmith",
                            "name": "Matthew NeSmith",
                            "selectedText": "Matthew NeSmith",
                            "firstName": "Matthew",
                            "lastName": "NeSmith",
                            "dob": "1993-09-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "2a183004831a4295e6e19322be683ca6",
                            "bettingOdds": -949.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Collin Morikawa",
                            "name": "Collin Morikawa",
                            "selectedText": "Collin Morikawa",
                            "firstName": "Collin",
                            "lastName": "Morikawa",
                            "dob": "1997-02-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "255dae33c60ba7db94ae15bd9738e91d",
                            "bettingOdds": 0.0,
                            "placedBet": true,
                            "betPlacedAt": 1800.0,
                            "potentialWinnings": 900.0,
                            "winner": false
                        }, {
                            "id": "Adam Hadwin",
                            "name": "Adam Hadwin",
                            "selectedText": "Adam Hadwin",
                            "firstName": "Adam",
                            "lastName": "Hadwin",
                            "dob": "1987-11-02",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "cbeaedee5a5b19af79935251e85bfb81",
                            "bettingOdds": 350.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }
            ],
            "groups": [{
                    "id": "0f1daf45-2226-4f94-983b-38648fdbe51f",
                    "prompt": "3 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647604920000,
                    "title": "3 Ball",
                    "description": "3 Ball - 12:02 Tee Time",
                    "limit": 2,
                    "questions": [{
                            "id": "0f1daf45-2226-4f94-983b-38648fdbe51f",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647604920000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 12:02 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Mito Guillermo Pereira",
                                    "name": "Mito Guillermo Pereira",
                                    "selectedText": "Mito Guillermo Pereira",
                                    "firstName": "Mito",
                                    "lastName": "Pereira",
                                    "dob": "1995-03-31",
                                    "gender": "Male",
                                    "country": "CHI",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                                    "oddsId": "cc77cb937f7c3800c7e71b07265c26a1",
                                    "bettingOdds": 160.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Pat Perez",
                                    "name": "Pat Perez",
                                    "selectedText": "Pat Perez",
                                    "firstName": "Pat",
                                    "lastName": "Perez",
                                    "dob": "1976-03-01",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24361.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f793b59e5c099da12bcc6ee17683ec40",
                                    "bettingOdds": 165.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Martin Kaymer",
                                    "name": "Martin Kaymer",
                                    "selectedText": "Martin Kaymer",
                                    "firstName": "Martin",
                                    "lastName": "Kaymer",
                                    "dob": "1984-12-28",
                                    "gender": "Male",
                                    "country": "GER",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27408.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_GER.png",
                                    "oddsId": "1e9d6d403e324d255b075014055182e9",
                                    "bettingOdds": 200.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "50c9890c-1a22-4be9-8ec0-ddbc888b3f78",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647605580000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 12:13 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Justin Thomas",
                                    "name": "Justin Thomas",
                                    "selectedText": "Justin Thomas",
                                    "firstName": "Justin",
                                    "lastName": "Thomas",
                                    "dob": "1993-04-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "39c5bb56bd151d341ab93fe0ecb612f1",
                                    "bettingOdds": -120.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Kevin Kisner",
                                    "name": "Kevin Kisner",
                                    "selectedText": "Kevin Kisner",
                                    "firstName": "Kevin",
                                    "lastName": "Kisner",
                                    "dob": "1984-02-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "78e5e177d6252b1bde46c788dbb0672e",
                                    "bettingOdds": 240.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Carlos Ortiz",
                                    "name": "Carlos Ortiz",
                                    "selectedText": "Carlos Ortiz",
                                    "firstName": "Carlos",
                                    "lastName": "Ortiz",
                                    "dob": "1991-04-24",
                                    "gender": "Male",
                                    "country": "MEX",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33667.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                                    "oddsId": "bf30af9efac3efdcdcac3e8acbeb67a3",
                                    "bettingOdds": 300.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "727c31c5-a974-4fbe-8c8f-a33a805de62b",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647605580000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 12:13 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Joel Dahmen",
                                    "name": "Joel Dahmen",
                                    "selectedText": "Joel Dahmen",
                                    "firstName": "Joel",
                                    "lastName": "Dahmen",
                                    "dob": "1987-11-11",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34076.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "743f149a831cd53ac4e7b1b79f3672ea",
                                    "bettingOdds": 117.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Michael Thompson",
                                    "name": "Michael Thompson",
                                    "selectedText": "Michael Thompson",
                                    "firstName": "Michael",
                                    "lastName": "Thompson",
                                    "dob": "1985-04-16",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32150.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "e7cc384837e8c5a12682ed5cfaa4e440",
                                    "bettingOdds": 170.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Andrew Landry",
                                    "name": "Andrew Landry",
                                    "selectedText": "Andrew Landry",
                                    "firstName": "Andrew",
                                    "lastName": "Landry",
                                    "dob": "1987-08-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33410.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "59ba83c7d0ce5eed7f7328a73319906d",
                                    "bettingOdds": 275.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "688dcecf-871f-4ebf-95f7-5761d33780e5",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647606240000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 12:24 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Shane Lowry",
                                    "name": "Shane Lowry",
                                    "selectedText": "Shane Lowry",
                                    "firstName": "Shane",
                                    "lastName": "Lowry",
                                    "dob": "1987-04-02",
                                    "gender": "Male",
                                    "country": "IRL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                                    "oddsId": "15166f35cf30a70c5618c2dfc44c66d1",
                                    "bettingOdds": -120.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Graeme McDowell",
                                    "name": "Graeme McDowell",
                                    "selectedText": "Graeme McDowell",
                                    "firstName": "Graeme",
                                    "lastName": "McDowell",
                                    "dob": "1979-07-30",
                                    "gender": "Male",
                                    "country": "NIR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25572.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NIR.png",
                                    "oddsId": "2af1c7773493dac97e083d958a723382",
                                    "bettingOdds": 260.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Henrik Stenson",
                                    "name": "Henrik Stenson",
                                    "selectedText": "Henrik Stenson",
                                    "firstName": "Henrik",
                                    "lastName": "Stenson",
                                    "dob": "1976-04-05",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/21528.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "0e3747b5d6af15145a99862525d7de89",
                                    "bettingOdds": 275.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "2277acb3-64fa-4f5c-b934-539aef775a65",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647606900000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 12:35 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Sam Burns",
                                    "name": "Sam Burns",
                                    "selectedText": "Sam Burns",
                                    "firstName": "Sam",
                                    "lastName": "Burns",
                                    "dob": "1996-07-23",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "1c7fdfec637320a3d0450d94891b1954",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Xander Schauffele",
                                    "name": "Xander Schauffele",
                                    "selectedText": "Xander Schauffele",
                                    "firstName": "Xander",
                                    "lastName": "Schauffele",
                                    "dob": "1993-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "253b3c364a3cd8cf8284ba1235a02c3b",
                                    "bettingOdds": 160.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Gary Woodland",
                                    "name": "Gary Woodland",
                                    "selectedText": "Gary Woodland",
                                    "firstName": "Gary",
                                    "lastName": "Woodland",
                                    "dob": "1984-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5b4f9793867a57d73838df3231c6b02f",
                                    "bettingOdds": 225.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "69284509-5ce7-4c3e-8c8d-1cf225b55ede",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647606900000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 12:35 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Webb Simpson",
                                    "name": "Webb Simpson",
                                    "selectedText": "Webb Simpson",
                                    "firstName": "Webb",
                                    "lastName": "Simpson",
                                    "dob": "1985-08-08",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29221.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f6167e716b2b7668988681097a6c6def",
                                    "bettingOdds": 115.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Martin Laird",
                                    "name": "Martin Laird",
                                    "selectedText": "Martin Laird",
                                    "firstName": "Martin",
                                    "lastName": "Laird",
                                    "dob": "1982-12-29",
                                    "gender": "Male",
                                    "country": "SCO",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27936.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                                    "oddsId": "bb8a6c84e9cb2bc5b8468d67dc867375",
                                    "bettingOdds": 215.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Francesco Molinari",
                                    "name": "Francesco Molinari",
                                    "selectedText": "Francesco Molinari",
                                    "firstName": "Francesco",
                                    "lastName": "Molinari",
                                    "dob": "1982-11-08",
                                    "gender": "Male",
                                    "country": "ITA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25198.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ITA.png",
                                    "oddsId": "ca99e3c9a68f0d64dd7321578f043772",
                                    "bettingOdds": 220.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "a09883a0-441b-4c9a-8042-19b0736c7447",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647607560000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 12:46 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Tyrrell Hatton",
                                    "name": "Tyrrell Hatton",
                                    "selectedText": "Tyrrell Hatton",
                                    "firstName": "Tyrrell",
                                    "lastName": "Hatton",
                                    "dob": "1991-10-14",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "036877e517611ef8f8136e068a05458a",
                                    "bettingOdds": -107.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Adam Long",
                                    "name": "Adam Long",
                                    "selectedText": "Adam Long",
                                    "firstName": "Adam",
                                    "lastName": "Long",
                                    "dob": "1987-09-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35449.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "72bcdb09dcfb07a6f0ec2ae626b417e0",
                                    "bettingOdds": 225.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Robert Streb",
                                    "name": "Robert Streb",
                                    "selectedText": "Robert Streb",
                                    "firstName": "Robert",
                                    "lastName": "Streb",
                                    "dob": "1987-04-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "046e530456ea2db0ce78cf4db2aec58c",
                                    "bettingOdds": 275.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "e4f33835-1b7f-4581-b628-4c1155ec9007",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647608220000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 12:57 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Tommy Fleetwood",
                                    "name": "Tommy Fleetwood",
                                    "selectedText": "Tommy Fleetwood",
                                    "firstName": "Tommy",
                                    "lastName": "Fleetwood",
                                    "dob": "1991-01-19",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "d713811822dc5320765c46009e2130f5",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Russell Knox",
                                    "name": "Russell Knox",
                                    "selectedText": "Russell Knox",
                                    "firstName": "Russell",
                                    "lastName": "Knox",
                                    "dob": "1985-06-21",
                                    "gender": "Male",
                                    "country": "SCO",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                                    "oddsId": "eeb4fe53f362dcb6b4eb5d499ee8ca00",
                                    "bettingOdds": 185.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Harold Varner III",
                                    "name": "Harold Varner III",
                                    "selectedText": "Harold Varner III",
                                    "firstName": "Harold",
                                    "lastName": "Varner III",
                                    "dob": "1990-08-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "0bc200f3ca1bf9cf0c5293bf7ff2323d",
                                    "bettingOdds": 190.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "5ef3e5ac-61c4-46ce-98ce-f8fc5c334fb6",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647608880000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 01:08 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Brian Harman",
                                    "name": "Brian Harman",
                                    "selectedText": "Brian Harman",
                                    "firstName": "Brian",
                                    "lastName": "Harman",
                                    "dob": "1987-01-19",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "4dd7b214523348bdf18ce62b02a9a7d1",
                                    "bettingOdds": 120.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Harry Higgs",
                                    "name": "Harry Higgs",
                                    "selectedText": "Harry Higgs",
                                    "firstName": "Harry",
                                    "lastName": "Higgs",
                                    "dob": "1991-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "195da1aafafe05aef2dd017f0575f8c6",
                                    "bettingOdds": 210.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Emiliano Grillo",
                                    "name": "Emiliano Grillo",
                                    "selectedText": "Emiliano Grillo",
                                    "firstName": "Emiliano",
                                    "lastName": "Grillo",
                                    "dob": "1992-09-14",
                                    "gender": "Male",
                                    "country": "ARG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31646.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ARG.png",
                                    "oddsId": "f9c5396d926190449b73be0c6540b410",
                                    "bettingOdds": 215.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "95de56be-2ed5-4092-a60f-27d1d9e964bd",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647609540000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 01:19 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Bernd Wiesberger",
                                    "name": "Bernd Wiesberger",
                                    "selectedText": "Bernd Wiesberger",
                                    "firstName": "Bernd",
                                    "lastName": "Wiesberger",
                                    "dob": "1985-10-08",
                                    "gender": "Male",
                                    "country": "AUT",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                                    "oddsId": "228109ac8b1b749624f97e36ca41a69e",
                                    "bettingOdds": 165.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Sahith Theegala",
                                    "name": "Sahith Theegala",
                                    "selectedText": "Sahith Theegala",
                                    "firstName": "Sahith",
                                    "lastName": "Theegala",
                                    "dob": "1997-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "9727e67cd1656c782f7cddcf358fbce7",
                                    "bettingOdds": 180.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "David Lipsky",
                                    "name": "David Lipsky",
                                    "selectedText": "David Lipsky",
                                    "firstName": "David",
                                    "lastName": "Lipsky",
                                    "dob": "1988-07-14",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36326.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f14c758aa81ca2336280269a217b5a8d",
                                    "bettingOdds": 180.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "c457b158-c824-4dd8-bc8b-c9156ad486c7",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647621660000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 04:41 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Cameron Tringale",
                                    "name": "Cameron Tringale",
                                    "selectedText": "Cameron Tringale",
                                    "firstName": "Cameron",
                                    "lastName": "Tringale",
                                    "dob": "1987-08-24",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33419.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "dc0ec21afbf5dc4cabeb79e9dc214ac5",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Denny McCarthy",
                                    "name": "Denny McCarthy",
                                    "selectedText": "Denny McCarthy",
                                    "firstName": "Denny",
                                    "lastName": "McCarthy",
                                    "dob": "1993-03-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47993.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5e7b3dfbdc8ea2ed9acc2fefdac9ad62",
                                    "bettingOdds": 160.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Matthew NeSmith",
                                    "name": "Matthew NeSmith",
                                    "selectedText": "Matthew NeSmith",
                                    "firstName": "Matthew",
                                    "lastName": "NeSmith",
                                    "dob": "1993-09-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "9b2a4dd1c6a7a5cde534e2dce9141f4b",
                                    "bettingOdds": 220.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "5d0bcf38-d88b-46d0-8682-bd81ec6fbba7",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647622320000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 04:52 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Jhonattan Vegas",
                                    "name": "Jhonattan Vegas",
                                    "selectedText": "Jhonattan Vegas",
                                    "firstName": "Jhonattan",
                                    "lastName": "Vegas",
                                    "dob": "1984-08-19",
                                    "gender": "Male",
                                    "country": "VEN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27064.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_VEN.png",
                                    "oddsId": "66f0564d70b7e0a4b53948e396b2659c",
                                    "bettingOdds": 115.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Sam Ryder",
                                    "name": "Sam Ryder",
                                    "selectedText": "Sam Ryder",
                                    "firstName": "Sam",
                                    "lastName": "Ryder",
                                    "dob": "1989-12-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37275.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "3f7528a7417185233a25958ccaadaf5e",
                                    "bettingOdds": 185.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Scott Piercy",
                                    "name": "Scott Piercy",
                                    "selectedText": "Scott Piercy",
                                    "firstName": "Scott",
                                    "lastName": "Piercy",
                                    "dob": "1978-11-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25818.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c86a6f90a90c292930373f07118cb96a",
                                    "bettingOdds": 260.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "105a28d7-9489-47f8-8bd5-e1f260bbf7a9",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647622980000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 05:03 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Dustin Johnson",
                                    "name": "Dustin Johnson",
                                    "selectedText": "Dustin Johnson",
                                    "firstName": "Dustin",
                                    "lastName": "Johnson",
                                    "dob": "1984-06-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "ae940b4aeffe8b529f8c6c3a8e7b431e",
                                    "bettingOdds": 115.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Bubba Watson",
                                    "name": "Bubba Watson",
                                    "selectedText": "Bubba Watson",
                                    "firstName": "Bubba",
                                    "lastName": "Watson",
                                    "dob": "1978-11-05",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25804.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "d14d63f2cc10144b68d35e97735e4752",
                                    "bettingOdds": 200.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Jason Day",
                                    "name": "Jason Day",
                                    "selectedText": "Jason Day",
                                    "firstName": "Jason",
                                    "lastName": "Day",
                                    "dob": "1987-11-12",
                                    "gender": "Male",
                                    "country": "AUS",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28089.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUS.png",
                                    "oddsId": "2433add646c909542f56aa12e0ddd8a3",
                                    "bettingOdds": 240.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "5e552fa7-9095-4c72-a000-983097caa69e",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647623640000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 05:14 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Viktor Hovland",
                                    "name": "Viktor Hovland",
                                    "selectedText": "Viktor Hovland",
                                    "firstName": "Viktor",
                                    "lastName": "Hovland",
                                    "dob": "1997-09-18",
                                    "gender": "Male",
                                    "country": "NOR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                                    "oddsId": "36c2f5c6d59a3ff0160343e00a1cfb94",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Collin Morikawa",
                                    "name": "Collin Morikawa",
                                    "selectedText": "Collin Morikawa",
                                    "firstName": "Collin",
                                    "lastName": "Morikawa",
                                    "dob": "1997-02-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "6a3bc19f9cedc73e73118e29d35f1b11",
                                    "bettingOdds": 155.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Abraham Ancer",
                                    "name": "Abraham Ancer",
                                    "selectedText": "Abraham Ancer",
                                    "firstName": "Abraham",
                                    "lastName": "Ancer",
                                    "dob": "1991-02-27",
                                    "gender": "Male",
                                    "country": "MEX",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45526.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_MEX.png",
                                    "oddsId": "780957ed2f446439f72e4d7776e8a2c8",
                                    "bettingOdds": 240.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "abf4d9e9-248c-421a-aede-7f79ba24e52d",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647623640000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 05:14 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Lanto Griffin",
                                    "name": "Lanto Griffin",
                                    "selectedText": "Lanto Griffin",
                                    "firstName": "Lanto",
                                    "lastName": "Griffin",
                                    "dob": "1988-06-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35310.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "3c1ee126e2bc2b99adbcddcf78bacc57",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Brendon Todd",
                                    "name": "Brendon Todd",
                                    "selectedText": "Brendon Todd",
                                    "firstName": "Brendon",
                                    "lastName": "Todd",
                                    "dob": "1985-07-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30927.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5cc08f5bbd6963a94a4f781322289195",
                                    "bettingOdds": 180.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Zach Johnson",
                                    "name": "Zach Johnson",
                                    "selectedText": "Zach Johnson",
                                    "firstName": "Zach",
                                    "lastName": "Johnson",
                                    "dob": "1976-02-24",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24024.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "782758dbd89a6876cea578b384af3bf4",
                                    "bettingOdds": 195.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "9674d7a2-548b-4110-afd5-aee69681da53",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647624300000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 05:25 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Louis Oosthuizen",
                                    "name": "Louis Oosthuizen",
                                    "selectedText": "Louis Oosthuizen",
                                    "firstName": "Louis",
                                    "lastName": "Oosthuizen",
                                    "dob": "1982-10-19",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "6a84ea8a02819d34a9a4bbe5b30a5711",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Brooks Koepka",
                                    "name": "Brooks Koepka",
                                    "selectedText": "Brooks Koepka",
                                    "firstName": "Brooks",
                                    "lastName": "Koepka",
                                    "dob": "1990-05-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "61dd04da0d2f5445bdf55c24a571ccf5",
                                    "bettingOdds": 180.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Jason Kokrak",
                                    "name": "Jason Kokrak",
                                    "selectedText": "Jason Kokrak",
                                    "firstName": "Jason",
                                    "lastName": "Kokrak",
                                    "dob": "1985-05-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30944.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "af50aeadb9b2d8c2c10f2d7bf3fbd13c",
                                    "bettingOdds": 200.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "f4fd1538-34e0-44de-86e3-13847f9653f3",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647624960000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 05:36 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Patton Kizzire",
                                    "name": "Patton Kizzire",
                                    "selectedText": "Patton Kizzire",
                                    "firstName": "Patton",
                                    "lastName": "Kizzire",
                                    "dob": "1986-03-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c7f0a5673836e5da6ea29954ecbe91b4",
                                    "bettingOdds": 130.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Matt Kuchar",
                                    "name": "Matt Kuchar",
                                    "selectedText": "Matt Kuchar",
                                    "firstName": "Matt",
                                    "lastName": "Kuchar",
                                    "dob": "1978-06-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "67480dd8e2f98a14b3c782e8f64ee5f3",
                                    "bettingOdds": 135.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Martin Trainer",
                                    "name": "Martin Trainer",
                                    "selectedText": "Martin Trainer",
                                    "firstName": "Martin",
                                    "lastName": "Trainer",
                                    "dob": "1991-04-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35617.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "06327480f6fe71841a1dead1aeee0422",
                                    "bettingOdds": 320.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "a4f7be20-3026-42c4-9384-28b03061316d",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647624960000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 05:36 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Branden Grace",
                                    "name": "Branden Grace",
                                    "selectedText": "Branden Grace",
                                    "firstName": "Branden",
                                    "lastName": "Grace",
                                    "dob": "1988-05-20",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29974.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "9fe972e494ed4022164586e2fae70965",
                                    "bettingOdds": 160.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tyler Duncan",
                                    "name": "Tyler Duncan",
                                    "selectedText": "Tyler Duncan",
                                    "firstName": "Tyler",
                                    "lastName": "Duncan",
                                    "dob": "1989-07-13",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "626c76d20b84096ec628a5c82bf3037c",
                                    "bettingOdds": 170.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Luke Donald",
                                    "name": "Luke Donald",
                                    "selectedText": "Luke Donald",
                                    "firstName": "Luke",
                                    "lastName": "Donald",
                                    "dob": "1977-12-07",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "27c229443938690ff8580d61d513eaaa",
                                    "bettingOdds": 195.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "e5a37d26-0d65-40ab-8287-9210b12a9ff2",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647625620000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 05:47 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Keegan Bradley",
                                    "name": "Keegan Bradley",
                                    "selectedText": "Keegan Bradley",
                                    "firstName": "Keegan",
                                    "lastName": "Bradley",
                                    "dob": "1986-06-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33141.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "bfb044c2201d56db2e6be5a0b244b5f2",
                                    "bettingOdds": 110.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "JT Poston",
                                    "name": "JT Poston",
                                    "selectedText": "JT Poston",
                                    "firstName": "J.T.",
                                    "lastName": "Poston",
                                    "dob": "1993-06-01",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49771.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "548f6ad529b0e76b84cc061533f30388",
                                    "bettingOdds": 210.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Nate Lashley",
                                    "name": "Nate Lashley",
                                    "selectedText": "Nate Lashley",
                                    "firstName": "Nate",
                                    "lastName": "Lashley",
                                    "dob": "1982-12-12",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "8d390064e4213cb23df8b4d5cef8ec63",
                                    "bettingOdds": 240.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "d762122c-72c3-49bb-9768-0c536b44db2c",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647625620000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 05:47 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Chez Reavie",
                                    "name": "Chez Reavie",
                                    "selectedText": "Chez Reavie",
                                    "firstName": "Chez",
                                    "lastName": "Reavie",
                                    "dob": "1981-11-12",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26476.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "db9b82ba6d98b776e3c7a3cfced2fd21",
                                    "bettingOdds": 155.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Cheng-Tsung Pan",
                                    "name": "Cheng-Tsung Pan",
                                    "selectedText": "Cheng-Tsung Pan",
                                    "firstName": "C.T.",
                                    "lastName": "Pan",
                                    "dob": "1991-11-12",
                                    "gender": "Male",
                                    "country": "TPE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29908.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_TPE.png",
                                    "oddsId": "54a9b59af831e63dfc422ff7e31dc928",
                                    "bettingOdds": 160.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Ryan Brehm",
                                    "name": "Ryan Brehm",
                                    "selectedText": "Ryan Brehm",
                                    "firstName": "Ryan",
                                    "lastName": "Brehm",
                                    "dob": "1986-04-05",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28420.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "e0e3ee82e02f08b97911c8a3c4c6b6e3",
                                    "bettingOdds": 215.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "855d8734-34bb-43f9-9bdc-6c39142e551e",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647626280000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 05:58 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Matthew Fitzpatrick",
                                    "name": "Matthew Fitzpatrick",
                                    "selectedText": "Matthew Fitzpatrick",
                                    "firstName": "Matt",
                                    "lastName": "Fitzpatrick",
                                    "dob": "1994-09-01",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "9334731540d29c73530b69f5388853fe",
                                    "bettingOdds": 110.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Alexander Noren",
                                    "name": "Alexander Noren",
                                    "selectedText": "Alexander Noren",
                                    "firstName": "Alex",
                                    "lastName": "Noren",
                                    "dob": "1982-07-12",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "17178e7eae818dd589089443b54e48e5",
                                    "bettingOdds": 155.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Adam Schenk",
                                    "name": "Adam Schenk",
                                    "selectedText": "Adam Schenk",
                                    "firstName": "Adam",
                                    "lastName": "Schenk",
                                    "dob": "1992-01-26",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47347.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "0382e21841f66f8cfb5b625a78f77007",
                                    "bettingOdds": 340.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "83293664-8a09-4be5-8499-4b23d84fa69d",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647626940000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 06:09 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Aaron Wise",
                                    "name": "Aaron Wise",
                                    "selectedText": "Aaron Wise",
                                    "firstName": "Aaron",
                                    "lastName": "Wise",
                                    "dob": "1996-06-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49964.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "72af15bbbd379ef187893fb3f0fdf7ba",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Danny Lee",
                                    "name": "Danny Lee",
                                    "selectedText": "Danny Lee",
                                    "firstName": "Danny",
                                    "lastName": "Lee",
                                    "dob": "1990-07-24",
                                    "gender": "Male",
                                    "country": "NZL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                                    "oddsId": "d2082e376395f9cf8e6abb7a3c23350a",
                                    "bettingOdds": 175.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "J. J. Spaun",
                                    "name": "J. J. Spaun",
                                    "selectedText": "J. J. Spaun",
                                    "firstName": "J.J.",
                                    "lastName": "Spaun",
                                    "dob": "1990-08-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "93eafe243f19b449a3ed617471339756",
                                    "bettingOdds": 200.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "6e3be091-1a79-490f-a7bd-c9aac2af04df",
                            "prompt": "3 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647626940000,
                            "title": "3 Ball",
                            "pointValue": 50,
                            "description": "3 Ball - 06:09 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Mackenzie Hughes",
                                    "name": "Mackenzie Hughes",
                                    "selectedText": "Mackenzie Hughes",
                                    "firstName": "Mackenzie",
                                    "lastName": "Hughes",
                                    "dob": "1990-11-23",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/35506.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "d866a36897ae8bdad921c6bbcfcbf4e5",
                                    "bettingOdds": 160.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Kevin Streelman",
                                    "name": "Kevin Streelman",
                                    "selectedText": "Kevin Streelman",
                                    "firstName": "Kevin",
                                    "lastName": "Streelman",
                                    "dob": "1978-11-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "77cca8e207c8c23ac952fd3a1a5c4e0d",
                                    "bettingOdds": 170.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Doc Redman",
                                    "name": "Doc Redman",
                                    "selectedText": "Doc Redman",
                                    "firstName": "Doc",
                                    "lastName": "Redman",
                                    "dob": "1997-12-27",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/53165.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a793b3547d5143e3d08b9e7c60373414",
                                    "bettingOdds": 195.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }
                    ]
                }, {
                    "id": "0c4941b3-ce19-48d1-896a-ad5b0ab4bf72",
                    "prompt": "Round 2 Leader",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Round 2 Leader",
                    "description": "Round 2 Leader - 10:19 Tee Time",
                    "limit": 1,
                    "questions": [{
                            "id": "0c4941b3-ce19-48d1-896a-ad5b0ab4bf72",
                            "prompt": "Round 2 Leader",
                            "startTime": 1647388740000,
                            "endTime": 1647814776000,
                            "title": "Round 2 Leader",
                            "pointValue": 50,
                            "description": "Round 2 Leader - 10:19 Tee Time",
                            "showTeeTime": false,
                            "players": [{
                                    "id": "Matthew NeSmith",
                                    "name": "Matthew NeSmith",
                                    "selectedText": "Matthew NeSmith",
                                    "firstName": "Matthew",
                                    "lastName": "NeSmith",
                                    "dob": "1993-09-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "2a183004831a4295e6e19322be683ca6",
                                    "bettingOdds": -949.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Collin Morikawa",
                                    "name": "Collin Morikawa",
                                    "selectedText": "Collin Morikawa",
                                    "firstName": "Collin",
                                    "lastName": "Morikawa",
                                    "dob": "1997-02-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "255dae33c60ba7db94ae15bd9738e91d",
                                    "bettingOdds": 0.0,
                                    "placedBet": true,
                                    "betPlacedAt": 1800.0,
                                    "potentialWinnings": 900.0,
                                    "winner": false
                                }, {
                                    "id": "Adam Hadwin",
                                    "name": "Adam Hadwin",
                                    "selectedText": "Adam Hadwin",
                                    "firstName": "Adam",
                                    "lastName": "Hadwin",
                                    "dob": "1987-11-02",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "cbeaedee5a5b19af79935251e85bfb81",
                                    "bettingOdds": 350.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }
                    ]
                }
            ]
        }, {
            "id": "27d6cdb8-6070-4441-9b17-629f3b51c267",
            "type": "ROUND",
            "title": "ROUND 3",
            "dayOfWeek": "Saturday",
            "startTime": 1647216000000,
            "endTime": 1647814776000,
            "open": false,
            "description": "75 POINT PICK",
            "questions": [{
                    "id": "da8da28d-d907-412f-be53-5789cb39e49c",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647690900000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 11:55 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Greyson Sigg",
                            "name": "Greyson Sigg",
                            "selectedText": "Greyson Sigg",
                            "firstName": "Greyson",
                            "lastName": "Sigg",
                            "dob": "1995-02-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51890.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "3eb92ef54a1fed673aa65d01e0ed0386",
                            "bettingOdds": -102.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Sigg, G v Smotherman, A)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "f8aeac49b2fc48b60e9d17a42f7d32c1",
                            "bettingOdds": 725.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Austin Smotherman",
                            "name": "Austin Smotherman",
                            "selectedText": "Austin Smotherman",
                            "firstName": "Austin",
                            "lastName": "Smotherman",
                            "dob": "1994-05-19",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50095.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "96f7bf320b3cc4109367ca975d8f7500",
                            "bettingOdds": 125.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "98416f80-5b38-49f0-a424-82a1dca69036",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647692100000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 12:15 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Chez Reavie",
                            "name": "Chez Reavie",
                            "selectedText": "Chez Reavie",
                            "firstName": "Chez",
                            "lastName": "Reavie",
                            "dob": "1981-11-12",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26476.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "090783c4243db4b1dcda574057c07374",
                            "bettingOdds": 110.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Reavie, C v Lee, D)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "f71ee4166972bc5a67002d5fcb76fd75",
                            "bettingOdds": 725.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Danny Lee",
                            "name": "Danny Lee",
                            "selectedText": "Danny Lee",
                            "firstName": "Danny",
                            "lastName": "Lee",
                            "dob": "1990-07-24",
                            "gender": "Male",
                            "country": "NZL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                            "oddsId": "d52321c7a96e21ceaab844b2a4218e31",
                            "bettingOdds": 112.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "d1fe18a8-00bb-4bf1-9294-bf7545ef2525",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647692700000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 12:25 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Nate Lashley",
                            "name": "Nate Lashley",
                            "selectedText": "Nate Lashley",
                            "firstName": "Nate",
                            "lastName": "Lashley",
                            "dob": "1982-12-12",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b74a1750dc8df86d94430e4654fc6069",
                            "bettingOdds": 110.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Lashley, N v Brehm, R)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "e26038a3e24635ae8a6c17e437a4b539",
                            "bettingOdds": 725.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Ryan Brehm",
                            "name": "Ryan Brehm",
                            "selectedText": "Ryan Brehm",
                            "firstName": "Ryan",
                            "lastName": "Brehm",
                            "dob": "1986-04-05",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28420.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "54a5ab6ad98ca01406f452d2a331cf78",
                            "bettingOdds": 112.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "605ba337-f157-4fbe-9094-660c2cc9480d",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647693300000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 12:35 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Matt Kuchar",
                            "name": "Matt Kuchar",
                            "selectedText": "Matt Kuchar",
                            "firstName": "Matt",
                            "lastName": "Kuchar",
                            "dob": "1978-06-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "7e0ff407438fdd50900fc326f412a787",
                            "bettingOdds": -125.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Kuchar, M v Donald, L)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "2fc886906bf0c7551a512304cd9d7e64",
                            "bettingOdds": 750.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Luke Donald",
                            "name": "Luke Donald",
                            "selectedText": "Luke Donald",
                            "firstName": "Luke",
                            "lastName": "Donald",
                            "dob": "1977-12-07",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "211c24a995372ab48f4d50a969f04fc9",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "fff5be6f-027b-4c7a-9a97-e836e82a5091",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647693900000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 12:45 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Viktor Hovland",
                            "name": "Viktor Hovland",
                            "selectedText": "Viktor Hovland",
                            "firstName": "Viktor",
                            "lastName": "Hovland",
                            "dob": "1997-09-18",
                            "gender": "Male",
                            "country": "NOR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                            "oddsId": "43ee5b74e34057bd7fdd8de9b399ff5b",
                            "bettingOdds": -263.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Hovland, V v Bryan, W)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "c9efbf393581dc37943dcf09c942c670",
                            "bettingOdds": 850.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Wesley Bryan",
                            "name": "Wesley Bryan",
                            "selectedText": "Wesley Bryan",
                            "firstName": "Wesley",
                            "lastName": "Bryan",
                            "dob": "1990-03-26",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48084.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c004a4c970527acfff567cca28993c96",
                            "bettingOdds": 320.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "296ff0aa-2f2a-4eca-b3c8-318891afa585",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647694500000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 12:55 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Cameron Tringale",
                            "name": "Cameron Tringale",
                            "selectedText": "Cameron Tringale",
                            "firstName": "Cameron",
                            "lastName": "Tringale",
                            "dob": "1987-08-24",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33419.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "933d80e986cbafbf79a012cb869ee9eb",
                            "bettingOdds": 110.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Tringale, C v McCarthy, Denny)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "84115f2f0e568d0771a01f5c2383464c",
                            "bettingOdds": 725.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Denny McCarthy",
                            "name": "Denny McCarthy",
                            "selectedText": "Denny McCarthy",
                            "firstName": "Denny",
                            "lastName": "McCarthy",
                            "dob": "1993-03-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47993.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "0d4382f272cde448fddf0686b729d36c",
                            "bettingOdds": 112.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "261b79b2-3033-428a-8643-5b3a1bc9cd81",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647695100000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 01:05 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Adam Svensson",
                            "name": "Adam Svensson",
                            "selectedText": "Adam Svensson",
                            "firstName": "Adam",
                            "lastName": "Svensson",
                            "dob": "1993-12-31",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40115.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "415f7c954c85aa419cbf11783cb583fd",
                            "bettingOdds": -120.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Thompson, Curtis v Svensson, A)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "99b48f90b6b35e8df80280b4673b555b",
                            "bettingOdds": 750.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Curtis Thompson",
                            "name": "Curtis Thompson",
                            "selectedText": "Curtis Thompson",
                            "firstName": "Curtis",
                            "lastName": "Thompson",
                            "dob": "1992-12-13",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/10381.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "87eab5a18e82fc038706ae314eafd63a",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "5380b3c5-d972-4759-98d3-fcb36a66e15d",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647695700000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 01:15 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Bernd Wiesberger",
                            "name": "Bernd Wiesberger",
                            "selectedText": "Bernd Wiesberger",
                            "firstName": "Bernd",
                            "lastName": "Wiesberger",
                            "dob": "1985-10-08",
                            "gender": "Male",
                            "country": "AUT",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                            "oddsId": "0be28afa333da689323c1e99eedd7efc",
                            "bettingOdds": -128.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Wiesberger, B v McGreevy, M)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "99524441accb7e2eb5fac9ce4e3c15c0",
                            "bettingOdds": 750.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Max McGreevy",
                            "name": "Max McGreevy",
                            "selectedText": "Max McGreevy",
                            "firstName": "Max",
                            "lastName": "McGreevy",
                            "dob": "1995-05-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51950.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "6b40e3568a692fdfae3edbf038a9b850",
                            "bettingOdds": 155.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "3f557499-3334-4454-a8ea-25e8657a2239",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647696600000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 01:30 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Harold Varner III",
                            "name": "Harold Varner III",
                            "selectedText": "Harold Varner III",
                            "firstName": "Harold",
                            "lastName": "Varner III",
                            "dob": "1990-08-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "7628a47954a407e06d39a9fc31d629b4",
                            "bettingOdds": -178.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Varner III, H v Barjon, P)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "1de813d96dd26fad48d5537ff6473ceb",
                            "bettingOdds": 750.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Paul Barjon",
                            "name": "Paul Barjon",
                            "selectedText": "Paul Barjon",
                            "firstName": "Paul",
                            "lastName": "Barjon",
                            "dob": "1992-09-19",
                            "gender": "Male",
                            "country": "FRA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37212.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_FRA.png",
                            "oddsId": "73b2fe2557bd7bbe72288abf595714f4",
                            "bettingOdds": 220.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "7bf7a2d5-3341-4132-b6cf-84172617a084",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647697200000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 01:40 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Russell Knox",
                            "name": "Russell Knox",
                            "selectedText": "Russell Knox",
                            "firstName": "Russell",
                            "lastName": "Knox",
                            "dob": "1985-06-21",
                            "gender": "Male",
                            "country": "SCO",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                            "oddsId": "6284eb7e34290aa54e7b7af9483b12ac",
                            "bettingOdds": -111.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Taylor, N v Knox, R)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "60a3bcaa8c7747b3d7bf0c56191ff7ae",
                            "bettingOdds": 725.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Nick Taylor",
                            "name": "Nick Taylor",
                            "selectedText": "Nick Taylor",
                            "firstName": "Nick",
                            "lastName": "Taylor",
                            "dob": "1988-04-14",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25493.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "0270d393cec5b7e3f0f4856e1433c014",
                            "bettingOdds": 135.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "0cb8529e-9db8-4ea9-975c-fdf985b05599",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647697800000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 01:50 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Mito Guillermo Pereira",
                            "name": "Mito Guillermo Pereira",
                            "selectedText": "Mito Guillermo Pereira",
                            "firstName": "Mito",
                            "lastName": "Pereira",
                            "dob": "1995-03-31",
                            "gender": "Male",
                            "country": "CHI",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                            "oddsId": "3fb648dcf90d0db4ea2bbed26c791169",
                            "bettingOdds": -120.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Pereira, Mito Guillermo v Stenson, H)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "d48a0f00a9c4f39522bf8bda74a614b6",
                            "bettingOdds": 750.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Henrik Stenson",
                            "name": "Henrik Stenson",
                            "selectedText": "Henrik Stenson",
                            "firstName": "Henrik",
                            "lastName": "Stenson",
                            "dob": "1976-04-05",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/21528.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "f6ff5fdff04ed29e8f73ce3dd739f57c",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "0cda5b96-ddde-41cf-aadf-f65e559ad86c",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647698400000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 02:00 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Doc Redman",
                            "name": "Doc Redman",
                            "selectedText": "Doc Redman",
                            "firstName": "Doc",
                            "lastName": "Redman",
                            "dob": "1997-12-27",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/53165.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "4301dd982de9b9d361988f9459f7a613",
                            "bettingOdds": 105.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Spaun, JJ v Redman, D)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "2cd4ee4036037dd274dcd0b26a8ab91e",
                            "bettingOdds": 725.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "J. J. Spaun",
                            "name": "J. J. Spaun",
                            "selectedText": "J. J. Spaun",
                            "firstName": "J.J.",
                            "lastName": "Spaun",
                            "dob": "1990-08-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "8fcc38bac562ad206f3a6095eca2040e",
                            "bettingOdds": 117.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "56a20d94-5741-4c1c-98f6-ce8865c5eea8",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647699000000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 02:10 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Alexander Noren",
                            "name": "Alexander Noren",
                            "selectedText": "Alexander Noren",
                            "firstName": "Alex",
                            "lastName": "Noren",
                            "dob": "1982-07-12",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "193a5ee2f306c41bd7d8b77ee92b64c7",
                            "bettingOdds": -142.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Duncan, T v Noren, A)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "11bb0bdcbf7b2b27932902edae1cf784",
                            "bettingOdds": 750.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tyler Duncan",
                            "name": "Tyler Duncan",
                            "selectedText": "Tyler Duncan",
                            "firstName": "Tyler",
                            "lastName": "Duncan",
                            "dob": "1989-07-13",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a141292f10c75d03ee080a37585ff3e9",
                            "bettingOdds": 175.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "dc482b3c-c49c-4c0e-85ab-7c1cdcbc77ed",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647699600000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 02:20 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Collin Morikawa",
                            "name": "Collin Morikawa",
                            "selectedText": "Collin Morikawa",
                            "firstName": "Collin",
                            "lastName": "Morikawa",
                            "dob": "1997-02-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "d873c7198c610eedaa8f3fa1664a62d0",
                            "bettingOdds": 108.0,
                            "placedBet": true,
                            "betPlacedAt": 105.0,
                            "potentialWinnings": 79.0,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Johnson, Dustin v Morikawa, C)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "4539aebba0e475802eddc2314505b0b2",
                            "bettingOdds": 750.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Dustin Johnson",
                            "name": "Dustin Johnson",
                            "selectedText": "Dustin Johnson",
                            "firstName": "Dustin",
                            "lastName": "Johnson",
                            "dob": "1984-06-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a03fc528f730669dfab1d786c05dae09",
                            "bettingOdds": 112.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "cfb9ee9a-d064-465d-b18d-1173a89affd0",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647700200000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 02:30 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Sahith Theegala",
                            "name": "Sahith Theegala",
                            "selectedText": "Sahith Theegala",
                            "firstName": "Sahith",
                            "lastName": "Theegala",
                            "dob": "1997-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "cee1763eea21ead5b24e00220ed5b9e8",
                            "bettingOdds": 105.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Theegala, S v Garnett, B)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "55e043e3f75cefc837b816be9d304100",
                            "bettingOdds": 725.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Brice Garnett",
                            "name": "Brice Garnett",
                            "selectedText": "Brice Garnett",
                            "firstName": "Brice",
                            "lastName": "Garnett",
                            "dob": "1983-09-06",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29535.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "2726dd2a0b4b0ff6de4e1db5cc199b54",
                            "bettingOdds": 117.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "242897e2-7a3c-4633-9091-8c2780fe32dc",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647700800000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 02:40 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Michael Thompson",
                            "name": "Michael Thompson",
                            "selectedText": "Michael Thompson",
                            "firstName": "Michael",
                            "lastName": "Thompson",
                            "dob": "1985-04-16",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32150.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5ccc44a29bfd968270fdfb991ab3eac1",
                            "bettingOdds": 108.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Thompson, Michael v Higgs, H)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "df76c44ec939cff4160d952fa93564b3",
                            "bettingOdds": 725.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Harry Higgs",
                            "name": "Harry Higgs",
                            "selectedText": "Harry Higgs",
                            "firstName": "Harry",
                            "lastName": "Higgs",
                            "dob": "1991-12-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "3aca2988bc0cb1cdf824eb1c0c679d48",
                            "bettingOdds": 115.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "048823c4-c64d-49ba-8c4b-aaca7761535d",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647701400000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 02:50 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "John Huh",
                            "name": "John Huh",
                            "selectedText": "John Huh",
                            "firstName": "John",
                            "lastName": "Huh",
                            "dob": "1990-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34174.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "bc2e63a759d07a6cac3de8fbaa3fe074",
                            "bettingOdds": 100.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Wu, Brandon v Huh, J)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "2704908751c9270b6e512790cb1987d1",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Brandon Wu",
                            "name": "Brandon Wu",
                            "selectedText": "Brandon Wu",
                            "firstName": "Brandon",
                            "lastName": "Wu",
                            "dob": "1997-02-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/52374.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "ad6be24908865ea12d7aadea06073dac",
                            "bettingOdds": 120.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "df65be31-4e58-488a-8469-e0738322a6cb",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647702300000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 03:05 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Kiradech Aphibarnrat",
                            "name": "Kiradech Aphibarnrat",
                            "selectedText": "Kiradech Aphibarnrat",
                            "firstName": "Kiradech",
                            "lastName": "Aphibarnrat",
                            "dob": "1989-07-23",
                            "gender": "Male",
                            "country": "THA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30978.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_THA.png",
                            "oddsId": "07f62536803a5d7526278341639ec323",
                            "bettingOdds": 108.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Haas, B v Aphibarnrat, K)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "d4afec58e66814b269105c958e19bc9e",
                            "bettingOdds": 750.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Bill Haas",
                            "name": "Bill Haas",
                            "selectedText": "Bill Haas",
                            "firstName": "Bill",
                            "lastName": "Haas",
                            "dob": "1982-05-24",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24924.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "d816c7caf8873e2f0e1ddbdae592ab01",
                            "bettingOdds": 112.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "532e5514-62e4-4586-a485-c9d38c6405f0",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647702900000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 03:15 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Brooks Koepka",
                            "name": "Brooks Koepka",
                            "selectedText": "Brooks Koepka",
                            "firstName": "Brooks",
                            "lastName": "Koepka",
                            "dob": "1990-05-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "9cfb7b150f46c7433151a4fb398a6e20",
                            "bettingOdds": -128.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Koepka, B v Pan, Cheng-Tsung)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "b8df2a23cdee1a59650ebc919ed04a95",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Cheng-Tsung Pan",
                            "name": "Cheng-Tsung Pan",
                            "selectedText": "Cheng-Tsung Pan",
                            "firstName": "C.T.",
                            "lastName": "Pan",
                            "dob": "1991-11-12",
                            "gender": "Male",
                            "country": "TPE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29908.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_TPE.png",
                            "oddsId": "023fdad7ba976930bf04c761ae08dfcf",
                            "bettingOdds": 155.0,
                            "placedBet": true,
                            "betPlacedAt": 180.0,
                            "potentialWinnings": 135.0,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "512b7077-ccce-40fd-ae39-d339d8c4a259",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647703500000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 03:25 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Kramer Hickok",
                            "name": "Kramer Hickok",
                            "selectedText": "Kramer Hickok",
                            "firstName": "Kramer",
                            "lastName": "Hickok",
                            "dob": "1992-04-14",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49298.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a5723d12f733c19c6c742adc256e0155",
                            "bettingOdds": -125.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Hickok, K v Noh, S)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "ea02a3fc2768524fd5145b6842a72dfa",
                            "bettingOdds": 750.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Seung-yul Noh",
                            "name": "Seung-yul Noh",
                            "selectedText": "Seung-yul Noh",
                            "firstName": "Seung-Yul",
                            "lastName": "Noh",
                            "dob": "1991-05-29",
                            "gender": "Male",
                            "country": "KOR",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29289.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_KOR.png",
                            "oddsId": "5c0a91f92f8faa53d70a6277789b9da6",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "7a3f2f81-915b-4af9-939e-fb55814ec3ee",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647704100000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 03:35 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Robert Streb",
                            "name": "Robert Streb",
                            "selectedText": "Robert Streb",
                            "firstName": "Robert",
                            "lastName": "Streb",
                            "dob": "1987-04-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "3ab538ffb2d08e6525a7a94ca4f71ef6",
                            "bettingOdds": -107.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Streb, R v Hagy, B)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "f2ebb3f20efd7bde81957fb9fd503bdf",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Brandon Hagy",
                            "name": "Brandon Hagy",
                            "selectedText": "Brandon Hagy",
                            "firstName": "Brandon",
                            "lastName": "Hagy",
                            "dob": "1991-03-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46550.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f04402961c507327a70b5fdf304fac74",
                            "bettingOdds": 130.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "2a64a37f-513e-4e77-ad08-da96542f7628",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647704700000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 03:45 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Shane Lowry",
                            "name": "Shane Lowry",
                            "selectedText": "Shane Lowry",
                            "firstName": "Shane",
                            "lastName": "Lowry",
                            "dob": "1987-04-02",
                            "gender": "Male",
                            "country": "IRL",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                            "oddsId": "1670667d5f2f3757326e8ecd6f807a87",
                            "bettingOdds": -128.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Dahmen, J v Lowry, S)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "a69cb46e76128e278e0f59bd9aedf667",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Joel Dahmen",
                            "name": "Joel Dahmen",
                            "selectedText": "Joel Dahmen",
                            "firstName": "Joel",
                            "lastName": "Dahmen",
                            "dob": "1987-11-11",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34076.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "44683c0553659ac564a9f4f90da15bfa",
                            "bettingOdds": 155.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "9682b4b1-d2c5-4eeb-acc1-0595cd976984",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647705300000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 03:55 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Pat Perez",
                            "name": "Pat Perez",
                            "selectedText": "Pat Perez",
                            "firstName": "Pat",
                            "lastName": "Perez",
                            "dob": "1976-03-01",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24361.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "77b492aecacb4357681733bfd019885d",
                            "bettingOdds": -105.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Stuard, B v Perez, P)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "53a9061c0d1430db995ae0e283aa20cf",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Brian Stuard",
                            "name": "Brian Stuard",
                            "selectedText": "Brian Stuard",
                            "firstName": "Brian",
                            "lastName": "Stuard",
                            "dob": "1982-12-10",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31560.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "243d31060fe9b8dbd2963e66a6d65053",
                            "bettingOdds": 125.0,
                            "placedBet": true,
                            "betPlacedAt": 125.0,
                            "potentialWinnings": 94.0,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "b485c4dc-1bf7-4494-945f-d9845687c5a6",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647705900000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 04:05 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Patton Kizzire",
                            "name": "Patton Kizzire",
                            "selectedText": "Patton Kizzire",
                            "firstName": "Patton",
                            "lastName": "Kizzire",
                            "dob": "1986-03-03",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "e766b62babb8203e88b4ee97f969c261",
                            "bettingOdds": 108.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Kizzire, P v Streelman, K)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "8e3d8e998c52ff852f6fea5b337fceff",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Kevin Streelman",
                            "name": "Kevin Streelman",
                            "selectedText": "Kevin Streelman",
                            "firstName": "Kevin",
                            "lastName": "Streelman",
                            "dob": "1978-11-04",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5047522046a5e66ac5784df2bf8a5832",
                            "bettingOdds": 110.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "37f909df-fb62-4dfe-8989-7c94545219f1",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647706500000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 04:15 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Tyrrell Hatton",
                            "name": "Tyrrell Hatton",
                            "selectedText": "Tyrrell Hatton",
                            "firstName": "Tyrrell",
                            "lastName": "Hatton",
                            "dob": "1991-10-14",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "236b9e664fc1a053e051abebcd727845",
                            "bettingOdds": -172.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Hatton, T v Bramlett, J)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "112ccd11ef15cab6b3b0d0d47b939732",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Joseph Bramlett",
                            "name": "Joseph Bramlett",
                            "selectedText": "Joseph Bramlett",
                            "firstName": "Joseph",
                            "lastName": "Bramlett",
                            "dob": "1988-04-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34255.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "72f8f8109c38266adcf5df2762233e34",
                            "bettingOdds": 210.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "61b3f748-7df4-4df4-960e-ef4c181cd672",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647707100000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 04:25 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Matthew Fitzpatrick",
                            "name": "Matthew Fitzpatrick",
                            "selectedText": "Matthew Fitzpatrick",
                            "firstName": "Matt",
                            "lastName": "Fitzpatrick",
                            "dob": "1994-09-01",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "6bc8b74905078d97cd82c6cd251b48e7",
                            "bettingOdds": -120.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Fitzpatrick, M v Kisner, K)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "df3bc6203bcdeb4d856b778ac9936964",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Kevin Kisner",
                            "name": "Kevin Kisner",
                            "selectedText": "Kevin Kisner",
                            "firstName": "Kevin",
                            "lastName": "Kisner",
                            "dob": "1984-02-15",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "2e30a3355c8602cf8121fd056c41c4ae",
                            "bettingOdds": 145.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "1f76d244-1a4a-417b-a205-d769067ea2aa",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647708000000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 04:40 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Louis Oosthuizen",
                            "name": "Louis Oosthuizen",
                            "selectedText": "Louis Oosthuizen",
                            "firstName": "Louis",
                            "lastName": "Oosthuizen",
                            "dob": "1982-10-19",
                            "gender": "Male",
                            "country": "RSA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                            "oddsId": "86452521e00cd27368c94a32e960217c",
                            "bettingOdds": -156.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Cink, S v Oosthuizen, L)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "8a8f4bbffa13e3875f63287dc0479bf0",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Stewart Cink",
                            "name": "Stewart Cink",
                            "selectedText": "Stewart Cink",
                            "firstName": "Stewart",
                            "lastName": "Cink",
                            "dob": "1973-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/20229.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "c66df80f217ae9be786a16d0b2752d22",
                            "bettingOdds": 190.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "3d8c0313-7c2e-42b3-97c3-38accdf4290a",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647708600000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 04:50 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Jhonattan Vegas",
                            "name": "Jhonattan Vegas",
                            "selectedText": "Jhonattan Vegas",
                            "firstName": "Jhonattan",
                            "lastName": "Vegas",
                            "dob": "1984-08-19",
                            "gender": "Male",
                            "country": "VEN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27064.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_VEN.png",
                            "oddsId": "f05c5fed7907945804e5368087d00b45",
                            "bettingOdds": -107.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Lipsky, D v Vegas, J)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "871baabcf490cb521f6a41519d422f19",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "David Lipsky",
                            "name": "David Lipsky",
                            "selectedText": "David Lipsky",
                            "firstName": "David",
                            "lastName": "Lipsky",
                            "dob": "1988-07-14",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36326.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "7bfefec18b362ff9c0b05dd66c61ab2d",
                            "bettingOdds": 130.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "330099c5-a817-43ac-bdb1-47e55a4f0c29",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647709200000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 05:00 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Tommy Fleetwood",
                            "name": "Tommy Fleetwood",
                            "selectedText": "Tommy Fleetwood",
                            "firstName": "Tommy",
                            "lastName": "Fleetwood",
                            "dob": "1991-01-19",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "f960448f0d9474fcab4a71a63010ba80",
                            "bettingOdds": 108.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Woodland, G v Fleetwood, T)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "36df21167bc8f21d2914cec48bfb7004",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Gary Woodland",
                            "name": "Gary Woodland",
                            "selectedText": "Gary Woodland",
                            "firstName": "Gary",
                            "lastName": "Woodland",
                            "dob": "1984-05-21",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "1d3f16b01207653ab1b0fae7a2629980",
                            "bettingOdds": 112.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "9253de3d-6c87-4379-97a2-0dd089c5b87d",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647709800000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 05:10 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Martin Kaymer",
                            "name": "Martin Kaymer",
                            "selectedText": "Martin Kaymer",
                            "firstName": "Martin",
                            "lastName": "Kaymer",
                            "dob": "1984-12-28",
                            "gender": "Male",
                            "country": "GER",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27408.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_GER.png",
                            "oddsId": "468043ff60f79ce42f259e0de06e0147",
                            "bettingOdds": -128.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Kaymer, M v Werenski, R)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "42645158e322354daa65e71cab494df0",
                            "bettingOdds": 800.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Richy Werenski",
                            "name": "Richy Werenski",
                            "selectedText": "Richy Werenski",
                            "firstName": "Richy",
                            "lastName": "Werenski",
                            "dob": "1991-12-22",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47128.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "feff02b6ee430cdfeb498d5c5b711162",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "568a5d78-80fe-4150-a955-293fcca7cf88",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647710400000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 05:20 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Brian Harman",
                            "name": "Brian Harman",
                            "selectedText": "Brian Harman",
                            "firstName": "Brian",
                            "lastName": "Harman",
                            "dob": "1987-01-19",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "f3351773e6675cb4eb06d9a8b671c63d",
                            "bettingOdds": -105.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Harman, B v Merritt, T)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "af9aedab14f4dff0a74106887b5c597a",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Troy Merritt",
                            "name": "Troy Merritt",
                            "selectedText": "Troy Merritt",
                            "firstName": "Troy",
                            "lastName": "Merritt",
                            "dob": "1985-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32640.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "6b138ff938d1af1c550c47d30bca67eb",
                            "bettingOdds": 125.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "6fed19fc-6f50-438e-9a97-6c1e186d3afe",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647711000000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 05:30 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Xander Schauffele",
                            "name": "Xander Schauffele",
                            "selectedText": "Xander Schauffele",
                            "firstName": "Xander",
                            "lastName": "Schauffele",
                            "dob": "1993-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "390941730e64cb202bf9166330543df2",
                            "bettingOdds": -117.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Simpson, W v Schauffele, X)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "6d8a355703f812e91b17d5cd596b71d7",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Webb Simpson",
                            "name": "Webb Simpson",
                            "selectedText": "Webb Simpson",
                            "firstName": "Webb",
                            "lastName": "Simpson",
                            "dob": "1985-08-08",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29221.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "942e1daee3bf15c1889e30db470d1c43",
                            "bettingOdds": 140.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "97fdde37-bd1a-40cc-99d9-50c8e93d8b19",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647711600000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 05:40 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Justin Thomas",
                            "name": "Justin Thomas",
                            "selectedText": "Justin Thomas",
                            "firstName": "Justin",
                            "lastName": "Thomas",
                            "dob": "1993-04-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "e1b9fcf3fe0cc9f9510f87290855519c",
                            "bettingOdds": -222.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Thomas, J v Riley, Davis)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "1b6c992a90f7ac71d04638a634a2eb8e",
                            "bettingOdds": 800.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Davis Riley",
                            "name": "Davis Riley",
                            "selectedText": "Davis Riley",
                            "firstName": "Davis",
                            "lastName": "Riley",
                            "dob": "1996-12-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "7779cbd574bfdecd6e5cae12edf9f1e8",
                            "bettingOdds": 275.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "d396d4e1-576c-492f-9330-663e84526ae6",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647712200000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 05:50 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Sam Burns",
                            "name": "Sam Burns",
                            "selectedText": "Sam Burns",
                            "firstName": "Sam",
                            "lastName": "Burns",
                            "dob": "1996-07-23",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "ceec19941af1e17b50c265c58b7c1d6a",
                            "bettingOdds": -147.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Stallings, S v Burns, S)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "d6c39afd314f07b0647af42a97f8ab7d",
                            "bettingOdds": 800.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Scott Stallings",
                            "name": "Scott Stallings",
                            "selectedText": "Scott Stallings",
                            "firstName": "Scott",
                            "lastName": "Stallings",
                            "dob": "1985-03-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30692.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "96b40014b057d9764e0ba5ee51be679a",
                            "bettingOdds": 175.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "3901b3ad-a97e-4642-b2f7-d56b5adb0e46",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647712800000,
                    "title": "2 Ball",
                    "pointValue": 75,
                    "description": "2 Ball - 06:00 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Adam Hadwin",
                            "name": "Adam Hadwin",
                            "selectedText": "Adam Hadwin",
                            "firstName": "Adam",
                            "lastName": "Hadwin",
                            "dob": "1987-11-02",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "e3ffd0af7d48c92aea61f4ed2a95cb8b",
                            "bettingOdds": -125.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (NeSmith, M v Hadwin, A)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "22a0eaf69974a983e1665eab3594b93f",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Matthew NeSmith",
                            "name": "Matthew NeSmith",
                            "selectedText": "Matthew NeSmith",
                            "firstName": "Matthew",
                            "lastName": "NeSmith",
                            "dob": "1993-09-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "5131d7a84251ed57507e5b565ac1a6ae",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "082479c6-b31a-4285-b593-b717b2cc5518",
                    "prompt": "Round 3 Leader",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Round 3 Leader",
                    "pointValue": 75,
                    "description": "Round 3 Leader - 10:19 Tee Time",
                    "showTeeTime": false,
                    "players": [{
                            "id": "Matthew NeSmith",
                            "name": "Matthew NeSmith",
                            "selectedText": "Matthew NeSmith",
                            "firstName": "Matthew",
                            "lastName": "NeSmith",
                            "dob": "1993-09-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "bbfec8a7a3022020baed7151095b8565",
                            "bettingOdds": -149.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Davis Riley",
                            "name": "Davis Riley",
                            "selectedText": "Davis Riley",
                            "firstName": "Davis",
                            "lastName": "Riley",
                            "dob": "1996-12-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a7e889f2b5642d3aec8f4a4791cf49ca",
                            "bettingOdds": 110.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Sam Burns",
                            "name": "Sam Burns",
                            "selectedText": "Sam Burns",
                            "firstName": "Sam",
                            "lastName": "Burns",
                            "dob": "1996-07-23",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "bb16f008ec8e9a3175c605098356d085",
                            "bettingOdds": 15000.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Justin Thomas",
                            "name": "Justin Thomas",
                            "selectedText": "Justin Thomas",
                            "firstName": "Justin",
                            "lastName": "Thomas",
                            "dob": "1993-04-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b0c6f60e382b38bd7fa6e6b4abe4ba35",
                            "bettingOdds": 20000.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }
            ],
            "groups": [{
                    "id": "da8da28d-d907-412f-be53-5789cb39e49c",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647690900000,
                    "title": "2 Ball",
                    "description": "2 Ball - 11:55 Tee Time",
                    "limit": 2,
                    "questions": [{
                            "id": "da8da28d-d907-412f-be53-5789cb39e49c",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647690900000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 11:55 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Greyson Sigg",
                                    "name": "Greyson Sigg",
                                    "selectedText": "Greyson Sigg",
                                    "firstName": "Greyson",
                                    "lastName": "Sigg",
                                    "dob": "1995-02-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51890.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "3eb92ef54a1fed673aa65d01e0ed0386",
                                    "bettingOdds": -102.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Sigg, G v Smotherman, A)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "f8aeac49b2fc48b60e9d17a42f7d32c1",
                                    "bettingOdds": 725.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Austin Smotherman",
                                    "name": "Austin Smotherman",
                                    "selectedText": "Austin Smotherman",
                                    "firstName": "Austin",
                                    "lastName": "Smotherman",
                                    "dob": "1994-05-19",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50095.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "96f7bf320b3cc4109367ca975d8f7500",
                                    "bettingOdds": 125.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "98416f80-5b38-49f0-a424-82a1dca69036",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647692100000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 12:15 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Chez Reavie",
                                    "name": "Chez Reavie",
                                    "selectedText": "Chez Reavie",
                                    "firstName": "Chez",
                                    "lastName": "Reavie",
                                    "dob": "1981-11-12",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26476.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "090783c4243db4b1dcda574057c07374",
                                    "bettingOdds": 110.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Reavie, C v Lee, D)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "f71ee4166972bc5a67002d5fcb76fd75",
                                    "bettingOdds": 725.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Danny Lee",
                                    "name": "Danny Lee",
                                    "selectedText": "Danny Lee",
                                    "firstName": "Danny",
                                    "lastName": "Lee",
                                    "dob": "1990-07-24",
                                    "gender": "Male",
                                    "country": "NZL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29926.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NZL.png",
                                    "oddsId": "d52321c7a96e21ceaab844b2a4218e31",
                                    "bettingOdds": 112.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "d1fe18a8-00bb-4bf1-9294-bf7545ef2525",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647692700000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 12:25 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Nate Lashley",
                                    "name": "Nate Lashley",
                                    "selectedText": "Nate Lashley",
                                    "firstName": "Nate",
                                    "lastName": "Lashley",
                                    "dob": "1982-12-12",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28775.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b74a1750dc8df86d94430e4654fc6069",
                                    "bettingOdds": 110.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Lashley, N v Brehm, R)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "e26038a3e24635ae8a6c17e437a4b539",
                                    "bettingOdds": 725.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Ryan Brehm",
                                    "name": "Ryan Brehm",
                                    "selectedText": "Ryan Brehm",
                                    "firstName": "Ryan",
                                    "lastName": "Brehm",
                                    "dob": "1986-04-05",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/28420.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "54a5ab6ad98ca01406f452d2a331cf78",
                                    "bettingOdds": 112.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "605ba337-f157-4fbe-9094-660c2cc9480d",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647693300000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 12:35 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Matt Kuchar",
                                    "name": "Matt Kuchar",
                                    "selectedText": "Matt Kuchar",
                                    "firstName": "Matt",
                                    "lastName": "Kuchar",
                                    "dob": "1978-06-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23108.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "7e0ff407438fdd50900fc326f412a787",
                                    "bettingOdds": -125.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Kuchar, M v Donald, L)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "2fc886906bf0c7551a512304cd9d7e64",
                                    "bettingOdds": 750.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Luke Donald",
                                    "name": "Luke Donald",
                                    "selectedText": "Luke Donald",
                                    "firstName": "Luke",
                                    "lastName": "Donald",
                                    "dob": "1977-12-07",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/23983.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "211c24a995372ab48f4d50a969f04fc9",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "fff5be6f-027b-4c7a-9a97-e836e82a5091",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647693900000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 12:45 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Viktor Hovland",
                                    "name": "Viktor Hovland",
                                    "selectedText": "Viktor Hovland",
                                    "firstName": "Viktor",
                                    "lastName": "Hovland",
                                    "dob": "1997-09-18",
                                    "gender": "Male",
                                    "country": "NOR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46717.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_NOR.png",
                                    "oddsId": "43ee5b74e34057bd7fdd8de9b399ff5b",
                                    "bettingOdds": -263.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Hovland, V v Bryan, W)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "c9efbf393581dc37943dcf09c942c670",
                                    "bettingOdds": 850.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Wesley Bryan",
                                    "name": "Wesley Bryan",
                                    "selectedText": "Wesley Bryan",
                                    "firstName": "Wesley",
                                    "lastName": "Bryan",
                                    "dob": "1990-03-26",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48084.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c004a4c970527acfff567cca28993c96",
                                    "bettingOdds": 320.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "296ff0aa-2f2a-4eca-b3c8-318891afa585",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647694500000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 12:55 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Cameron Tringale",
                                    "name": "Cameron Tringale",
                                    "selectedText": "Cameron Tringale",
                                    "firstName": "Cameron",
                                    "lastName": "Tringale",
                                    "dob": "1987-08-24",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33419.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "933d80e986cbafbf79a012cb869ee9eb",
                                    "bettingOdds": 110.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Tringale, C v McCarthy, Denny)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "84115f2f0e568d0771a01f5c2383464c",
                                    "bettingOdds": 725.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Denny McCarthy",
                                    "name": "Denny McCarthy",
                                    "selectedText": "Denny McCarthy",
                                    "firstName": "Denny",
                                    "lastName": "McCarthy",
                                    "dob": "1993-03-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47993.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "0d4382f272cde448fddf0686b729d36c",
                                    "bettingOdds": 112.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "261b79b2-3033-428a-8643-5b3a1bc9cd81",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647695100000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 01:05 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Adam Svensson",
                                    "name": "Adam Svensson",
                                    "selectedText": "Adam Svensson",
                                    "firstName": "Adam",
                                    "lastName": "Svensson",
                                    "dob": "1993-12-31",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40115.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "415f7c954c85aa419cbf11783cb583fd",
                                    "bettingOdds": -120.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Thompson, Curtis v Svensson, A)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "99b48f90b6b35e8df80280b4673b555b",
                                    "bettingOdds": 750.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Curtis Thompson",
                                    "name": "Curtis Thompson",
                                    "selectedText": "Curtis Thompson",
                                    "firstName": "Curtis",
                                    "lastName": "Thompson",
                                    "dob": "1992-12-13",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/10381.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "87eab5a18e82fc038706ae314eafd63a",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "5380b3c5-d972-4759-98d3-fcb36a66e15d",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647695700000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 01:15 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Bernd Wiesberger",
                                    "name": "Bernd Wiesberger",
                                    "selectedText": "Bernd Wiesberger",
                                    "firstName": "Bernd",
                                    "lastName": "Wiesberger",
                                    "dob": "1985-10-08",
                                    "gender": "Male",
                                    "country": "AUT",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29454.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_AUT.png",
                                    "oddsId": "0be28afa333da689323c1e99eedd7efc",
                                    "bettingOdds": -128.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Wiesberger, B v McGreevy, M)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "99524441accb7e2eb5fac9ce4e3c15c0",
                                    "bettingOdds": 750.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Max McGreevy",
                                    "name": "Max McGreevy",
                                    "selectedText": "Max McGreevy",
                                    "firstName": "Max",
                                    "lastName": "McGreevy",
                                    "dob": "1995-05-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51950.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "6b40e3568a692fdfae3edbf038a9b850",
                                    "bettingOdds": 155.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "3f557499-3334-4454-a8ea-25e8657a2239",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647696600000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 01:30 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Harold Varner III",
                                    "name": "Harold Varner III",
                                    "selectedText": "Harold Varner III",
                                    "firstName": "Harold",
                                    "lastName": "Varner III",
                                    "dob": "1990-08-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37189.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "7628a47954a407e06d39a9fc31d629b4",
                                    "bettingOdds": -178.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Varner III, H v Barjon, P)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "1de813d96dd26fad48d5537ff6473ceb",
                                    "bettingOdds": 750.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Paul Barjon",
                                    "name": "Paul Barjon",
                                    "selectedText": "Paul Barjon",
                                    "firstName": "Paul",
                                    "lastName": "Barjon",
                                    "dob": "1992-09-19",
                                    "gender": "Male",
                                    "country": "FRA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/37212.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_FRA.png",
                                    "oddsId": "73b2fe2557bd7bbe72288abf595714f4",
                                    "bettingOdds": 220.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "7bf7a2d5-3341-4132-b6cf-84172617a084",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647697200000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 01:40 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Russell Knox",
                                    "name": "Russell Knox",
                                    "selectedText": "Russell Knox",
                                    "firstName": "Russell",
                                    "lastName": "Knox",
                                    "dob": "1985-06-21",
                                    "gender": "Male",
                                    "country": "SCO",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33122.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SCO.png",
                                    "oddsId": "6284eb7e34290aa54e7b7af9483b12ac",
                                    "bettingOdds": -111.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Taylor, N v Knox, R)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "60a3bcaa8c7747b3d7bf0c56191ff7ae",
                                    "bettingOdds": 725.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Nick Taylor",
                                    "name": "Nick Taylor",
                                    "selectedText": "Nick Taylor",
                                    "firstName": "Nick",
                                    "lastName": "Taylor",
                                    "dob": "1988-04-14",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/25493.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "0270d393cec5b7e3f0f4856e1433c014",
                                    "bettingOdds": 135.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "0cb8529e-9db8-4ea9-975c-fdf985b05599",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647697800000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 01:50 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Mito Guillermo Pereira",
                                    "name": "Mito Guillermo Pereira",
                                    "selectedText": "Mito Guillermo Pereira",
                                    "firstName": "Mito",
                                    "lastName": "Pereira",
                                    "dob": "1995-03-31",
                                    "gender": "Male",
                                    "country": "CHI",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47529.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CHI.png",
                                    "oddsId": "3fb648dcf90d0db4ea2bbed26c791169",
                                    "bettingOdds": -120.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Pereira, Mito Guillermo v Stenson, H)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "d48a0f00a9c4f39522bf8bda74a614b6",
                                    "bettingOdds": 750.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Henrik Stenson",
                                    "name": "Henrik Stenson",
                                    "selectedText": "Henrik Stenson",
                                    "firstName": "Henrik",
                                    "lastName": "Stenson",
                                    "dob": "1976-04-05",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/21528.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "f6ff5fdff04ed29e8f73ce3dd739f57c",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "0cda5b96-ddde-41cf-aadf-f65e559ad86c",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647698400000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 02:00 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Doc Redman",
                                    "name": "Doc Redman",
                                    "selectedText": "Doc Redman",
                                    "firstName": "Doc",
                                    "lastName": "Redman",
                                    "dob": "1997-12-27",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/53165.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "4301dd982de9b9d361988f9459f7a613",
                                    "bettingOdds": 105.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Spaun, JJ v Redman, D)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "2cd4ee4036037dd274dcd0b26a8ab91e",
                                    "bettingOdds": 725.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "J. J. Spaun",
                                    "name": "J. J. Spaun",
                                    "selectedText": "J. J. Spaun",
                                    "firstName": "J.J.",
                                    "lastName": "Spaun",
                                    "dob": "1990-08-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/39324.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "8fcc38bac562ad206f3a6095eca2040e",
                                    "bettingOdds": 117.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "56a20d94-5741-4c1c-98f6-ce8865c5eea8",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647699000000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 02:10 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Alexander Noren",
                                    "name": "Alexander Noren",
                                    "selectedText": "Alexander Noren",
                                    "firstName": "Alex",
                                    "lastName": "Noren",
                                    "dob": "1982-07-12",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "193a5ee2f306c41bd7d8b77ee92b64c7",
                                    "bettingOdds": -142.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Duncan, T v Noren, A)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "11bb0bdcbf7b2b27932902edae1cf784",
                                    "bettingOdds": 750.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tyler Duncan",
                                    "name": "Tyler Duncan",
                                    "selectedText": "Tyler Duncan",
                                    "firstName": "Tyler",
                                    "lastName": "Duncan",
                                    "dob": "1989-07-13",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a141292f10c75d03ee080a37585ff3e9",
                                    "bettingOdds": 175.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "dc482b3c-c49c-4c0e-85ab-7c1cdcbc77ed",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647699600000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 02:20 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Collin Morikawa",
                                    "name": "Collin Morikawa",
                                    "selectedText": "Collin Morikawa",
                                    "firstName": "Collin",
                                    "lastName": "Morikawa",
                                    "dob": "1997-02-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/50525.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "d873c7198c610eedaa8f3fa1664a62d0",
                                    "bettingOdds": 108.0,
                                    "placedBet": true,
                                    "betPlacedAt": 105.0,
                                    "potentialWinnings": 79.0,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Johnson, Dustin v Morikawa, C)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "4539aebba0e475802eddc2314505b0b2",
                                    "bettingOdds": 750.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Dustin Johnson",
                                    "name": "Dustin Johnson",
                                    "selectedText": "Dustin Johnson",
                                    "firstName": "Dustin",
                                    "lastName": "Johnson",
                                    "dob": "1984-06-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30925.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a03fc528f730669dfab1d786c05dae09",
                                    "bettingOdds": 112.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "cfb9ee9a-d064-465d-b18d-1173a89affd0",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647700200000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 02:30 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Sahith Theegala",
                                    "name": "Sahith Theegala",
                                    "selectedText": "Sahith Theegala",
                                    "firstName": "Sahith",
                                    "lastName": "Theegala",
                                    "dob": "1997-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/51634.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "cee1763eea21ead5b24e00220ed5b9e8",
                                    "bettingOdds": 105.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Theegala, S v Garnett, B)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "55e043e3f75cefc837b816be9d304100",
                                    "bettingOdds": 725.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Brice Garnett",
                                    "name": "Brice Garnett",
                                    "selectedText": "Brice Garnett",
                                    "firstName": "Brice",
                                    "lastName": "Garnett",
                                    "dob": "1983-09-06",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29535.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "2726dd2a0b4b0ff6de4e1db5cc199b54",
                                    "bettingOdds": 117.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "242897e2-7a3c-4633-9091-8c2780fe32dc",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647700800000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 02:40 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Michael Thompson",
                                    "name": "Michael Thompson",
                                    "selectedText": "Michael Thompson",
                                    "firstName": "Michael",
                                    "lastName": "Thompson",
                                    "dob": "1985-04-16",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32150.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5ccc44a29bfd968270fdfb991ab3eac1",
                                    "bettingOdds": 108.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Thompson, Michael v Higgs, H)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "df76c44ec939cff4160d952fa93564b3",
                                    "bettingOdds": 725.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Harry Higgs",
                                    "name": "Harry Higgs",
                                    "selectedText": "Harry Higgs",
                                    "firstName": "Harry",
                                    "lastName": "Higgs",
                                    "dob": "1991-12-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33597.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "3aca2988bc0cb1cdf824eb1c0c679d48",
                                    "bettingOdds": 115.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "048823c4-c64d-49ba-8c4b-aaca7761535d",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647701400000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 02:50 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "John Huh",
                                    "name": "John Huh",
                                    "selectedText": "John Huh",
                                    "firstName": "John",
                                    "lastName": "Huh",
                                    "dob": "1990-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34174.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "bc2e63a759d07a6cac3de8fbaa3fe074",
                                    "bettingOdds": 100.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Wu, Brandon v Huh, J)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "2704908751c9270b6e512790cb1987d1",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Brandon Wu",
                                    "name": "Brandon Wu",
                                    "selectedText": "Brandon Wu",
                                    "firstName": "Brandon",
                                    "lastName": "Wu",
                                    "dob": "1997-02-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/52374.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "ad6be24908865ea12d7aadea06073dac",
                                    "bettingOdds": 120.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "df65be31-4e58-488a-8469-e0738322a6cb",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647702300000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 03:05 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Kiradech Aphibarnrat",
                                    "name": "Kiradech Aphibarnrat",
                                    "selectedText": "Kiradech Aphibarnrat",
                                    "firstName": "Kiradech",
                                    "lastName": "Aphibarnrat",
                                    "dob": "1989-07-23",
                                    "gender": "Male",
                                    "country": "THA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30978.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_THA.png",
                                    "oddsId": "07f62536803a5d7526278341639ec323",
                                    "bettingOdds": 108.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Haas, B v Aphibarnrat, K)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "d4afec58e66814b269105c958e19bc9e",
                                    "bettingOdds": 750.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Bill Haas",
                                    "name": "Bill Haas",
                                    "selectedText": "Bill Haas",
                                    "firstName": "Bill",
                                    "lastName": "Haas",
                                    "dob": "1982-05-24",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24924.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "d816c7caf8873e2f0e1ddbdae592ab01",
                                    "bettingOdds": 112.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "532e5514-62e4-4586-a485-c9d38c6405f0",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647702900000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 03:15 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Brooks Koepka",
                                    "name": "Brooks Koepka",
                                    "selectedText": "Brooks Koepka",
                                    "firstName": "Brooks",
                                    "lastName": "Koepka",
                                    "dob": "1990-05-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36689.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "9cfb7b150f46c7433151a4fb398a6e20",
                                    "bettingOdds": -128.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Koepka, B v Pan, Cheng-Tsung)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "b8df2a23cdee1a59650ebc919ed04a95",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Cheng-Tsung Pan",
                                    "name": "Cheng-Tsung Pan",
                                    "selectedText": "Cheng-Tsung Pan",
                                    "firstName": "C.T.",
                                    "lastName": "Pan",
                                    "dob": "1991-11-12",
                                    "gender": "Male",
                                    "country": "TPE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29908.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_TPE.png",
                                    "oddsId": "023fdad7ba976930bf04c761ae08dfcf",
                                    "bettingOdds": 155.0,
                                    "placedBet": true,
                                    "betPlacedAt": 180.0,
                                    "potentialWinnings": 135.0,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "512b7077-ccce-40fd-ae39-d339d8c4a259",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647703500000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 03:25 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Kramer Hickok",
                                    "name": "Kramer Hickok",
                                    "selectedText": "Kramer Hickok",
                                    "firstName": "Kramer",
                                    "lastName": "Hickok",
                                    "dob": "1992-04-14",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/49298.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a5723d12f733c19c6c742adc256e0155",
                                    "bettingOdds": -125.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Hickok, K v Noh, S)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "ea02a3fc2768524fd5145b6842a72dfa",
                                    "bettingOdds": 750.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Seung-yul Noh",
                                    "name": "Seung-yul Noh",
                                    "selectedText": "Seung-yul Noh",
                                    "firstName": "Seung-Yul",
                                    "lastName": "Noh",
                                    "dob": "1991-05-29",
                                    "gender": "Male",
                                    "country": "KOR",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29289.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_KOR.png",
                                    "oddsId": "5c0a91f92f8faa53d70a6277789b9da6",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "7a3f2f81-915b-4af9-939e-fb55814ec3ee",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647704100000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 03:35 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Robert Streb",
                                    "name": "Robert Streb",
                                    "selectedText": "Robert Streb",
                                    "firstName": "Robert",
                                    "lastName": "Streb",
                                    "dob": "1987-04-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "3ab538ffb2d08e6525a7a94ca4f71ef6",
                                    "bettingOdds": -107.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Streb, R v Hagy, B)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "f2ebb3f20efd7bde81957fb9fd503bdf",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Brandon Hagy",
                                    "name": "Brandon Hagy",
                                    "selectedText": "Brandon Hagy",
                                    "firstName": "Brandon",
                                    "lastName": "Hagy",
                                    "dob": "1991-03-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/46550.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f04402961c507327a70b5fdf304fac74",
                                    "bettingOdds": 130.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "2a64a37f-513e-4e77-ad08-da96542f7628",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647704700000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 03:45 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Shane Lowry",
                                    "name": "Shane Lowry",
                                    "selectedText": "Shane Lowry",
                                    "firstName": "Shane",
                                    "lastName": "Lowry",
                                    "dob": "1987-04-02",
                                    "gender": "Male",
                                    "country": "IRL",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33204.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_IRL.png",
                                    "oddsId": "1670667d5f2f3757326e8ecd6f807a87",
                                    "bettingOdds": -128.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Dahmen, J v Lowry, S)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "a69cb46e76128e278e0f59bd9aedf667",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Joel Dahmen",
                                    "name": "Joel Dahmen",
                                    "selectedText": "Joel Dahmen",
                                    "firstName": "Joel",
                                    "lastName": "Dahmen",
                                    "dob": "1987-11-11",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34076.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "44683c0553659ac564a9f4f90da15bfa",
                                    "bettingOdds": 155.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "9682b4b1-d2c5-4eeb-acc1-0595cd976984",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647705300000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 03:55 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Pat Perez",
                                    "name": "Pat Perez",
                                    "selectedText": "Pat Perez",
                                    "firstName": "Pat",
                                    "lastName": "Perez",
                                    "dob": "1976-03-01",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/24361.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "77b492aecacb4357681733bfd019885d",
                                    "bettingOdds": -105.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Stuard, B v Perez, P)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "53a9061c0d1430db995ae0e283aa20cf",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Brian Stuard",
                                    "name": "Brian Stuard",
                                    "selectedText": "Brian Stuard",
                                    "firstName": "Brian",
                                    "lastName": "Stuard",
                                    "dob": "1982-12-10",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31560.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "243d31060fe9b8dbd2963e66a6d65053",
                                    "bettingOdds": 125.0,
                                    "placedBet": true,
                                    "betPlacedAt": 125.0,
                                    "potentialWinnings": 94.0,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "b485c4dc-1bf7-4494-945f-d9845687c5a6",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647705900000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 04:05 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Patton Kizzire",
                                    "name": "Patton Kizzire",
                                    "selectedText": "Patton Kizzire",
                                    "firstName": "Patton",
                                    "lastName": "Kizzire",
                                    "dob": "1986-03-03",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32757.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "e766b62babb8203e88b4ee97f969c261",
                                    "bettingOdds": 108.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Kizzire, P v Streelman, K)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "8e3d8e998c52ff852f6fea5b337fceff",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Kevin Streelman",
                                    "name": "Kevin Streelman",
                                    "selectedText": "Kevin Streelman",
                                    "firstName": "Kevin",
                                    "lastName": "Streelman",
                                    "dob": "1978-11-04",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27214.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5047522046a5e66ac5784df2bf8a5832",
                                    "bettingOdds": 110.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "37f909df-fb62-4dfe-8989-7c94545219f1",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647706500000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 04:15 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Tyrrell Hatton",
                                    "name": "Tyrrell Hatton",
                                    "selectedText": "Tyrrell Hatton",
                                    "firstName": "Tyrrell",
                                    "lastName": "Hatton",
                                    "dob": "1991-10-14",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34363.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "236b9e664fc1a053e051abebcd727845",
                                    "bettingOdds": -172.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Hatton, T v Bramlett, J)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "112ccd11ef15cab6b3b0d0d47b939732",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Joseph Bramlett",
                                    "name": "Joseph Bramlett",
                                    "selectedText": "Joseph Bramlett",
                                    "firstName": "Joseph",
                                    "lastName": "Bramlett",
                                    "dob": "1988-04-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34255.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "72f8f8109c38266adcf5df2762233e34",
                                    "bettingOdds": 210.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "61b3f748-7df4-4df4-960e-ef4c181cd672",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647707100000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 04:25 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Matthew Fitzpatrick",
                                    "name": "Matthew Fitzpatrick",
                                    "selectedText": "Matthew Fitzpatrick",
                                    "firstName": "Matt",
                                    "lastName": "Fitzpatrick",
                                    "dob": "1994-09-01",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "6bc8b74905078d97cd82c6cd251b48e7",
                                    "bettingOdds": -120.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Fitzpatrick, M v Kisner, K)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "df3bc6203bcdeb4d856b778ac9936964",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Kevin Kisner",
                                    "name": "Kevin Kisner",
                                    "selectedText": "Kevin Kisner",
                                    "firstName": "Kevin",
                                    "lastName": "Kisner",
                                    "dob": "1984-02-15",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29478.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "2e30a3355c8602cf8121fd056c41c4ae",
                                    "bettingOdds": 145.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "1f76d244-1a4a-417b-a205-d769067ea2aa",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647708000000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 04:40 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Louis Oosthuizen",
                                    "name": "Louis Oosthuizen",
                                    "selectedText": "Louis Oosthuizen",
                                    "firstName": "Louis",
                                    "lastName": "Oosthuizen",
                                    "dob": "1982-10-19",
                                    "gender": "Male",
                                    "country": "RSA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/26329.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_RSA.png",
                                    "oddsId": "86452521e00cd27368c94a32e960217c",
                                    "bettingOdds": -156.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Cink, S v Oosthuizen, L)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "8a8f4bbffa13e3875f63287dc0479bf0",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Stewart Cink",
                                    "name": "Stewart Cink",
                                    "selectedText": "Stewart Cink",
                                    "firstName": "Stewart",
                                    "lastName": "Cink",
                                    "dob": "1973-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/20229.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "c66df80f217ae9be786a16d0b2752d22",
                                    "bettingOdds": 190.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "3d8c0313-7c2e-42b3-97c3-38accdf4290a",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647708600000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 04:50 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Jhonattan Vegas",
                                    "name": "Jhonattan Vegas",
                                    "selectedText": "Jhonattan Vegas",
                                    "firstName": "Jhonattan",
                                    "lastName": "Vegas",
                                    "dob": "1984-08-19",
                                    "gender": "Male",
                                    "country": "VEN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27064.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_VEN.png",
                                    "oddsId": "f05c5fed7907945804e5368087d00b45",
                                    "bettingOdds": -107.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Lipsky, D v Vegas, J)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "871baabcf490cb521f6a41519d422f19",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "David Lipsky",
                                    "name": "David Lipsky",
                                    "selectedText": "David Lipsky",
                                    "firstName": "David",
                                    "lastName": "Lipsky",
                                    "dob": "1988-07-14",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36326.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "7bfefec18b362ff9c0b05dd66c61ab2d",
                                    "bettingOdds": 130.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "330099c5-a817-43ac-bdb1-47e55a4f0c29",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647709200000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 05:00 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Tommy Fleetwood",
                                    "name": "Tommy Fleetwood",
                                    "selectedText": "Tommy Fleetwood",
                                    "firstName": "Tommy",
                                    "lastName": "Fleetwood",
                                    "dob": "1991-01-19",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30911.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "f960448f0d9474fcab4a71a63010ba80",
                                    "bettingOdds": 108.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Woodland, G v Fleetwood, T)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "36df21167bc8f21d2914cec48bfb7004",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Gary Woodland",
                                    "name": "Gary Woodland",
                                    "selectedText": "Gary Woodland",
                                    "firstName": "Gary",
                                    "lastName": "Woodland",
                                    "dob": "1984-05-21",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/31323.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "1d3f16b01207653ab1b0fae7a2629980",
                                    "bettingOdds": 112.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "9253de3d-6c87-4379-97a2-0dd089c5b87d",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647709800000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 05:10 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Martin Kaymer",
                                    "name": "Martin Kaymer",
                                    "selectedText": "Martin Kaymer",
                                    "firstName": "Martin",
                                    "lastName": "Kaymer",
                                    "dob": "1984-12-28",
                                    "gender": "Male",
                                    "country": "GER",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27408.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_GER.png",
                                    "oddsId": "468043ff60f79ce42f259e0de06e0147",
                                    "bettingOdds": -128.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Kaymer, M v Werenski, R)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "42645158e322354daa65e71cab494df0",
                                    "bettingOdds": 800.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Richy Werenski",
                                    "name": "Richy Werenski",
                                    "selectedText": "Richy Werenski",
                                    "firstName": "Richy",
                                    "lastName": "Werenski",
                                    "dob": "1991-12-22",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47128.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "feff02b6ee430cdfeb498d5c5b711162",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "568a5d78-80fe-4150-a955-293fcca7cf88",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647710400000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 05:20 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Brian Harman",
                                    "name": "Brian Harman",
                                    "selectedText": "Brian Harman",
                                    "firstName": "Brian",
                                    "lastName": "Harman",
                                    "dob": "1987-01-19",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "f3351773e6675cb4eb06d9a8b671c63d",
                                    "bettingOdds": -105.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Harman, B v Merritt, T)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "af9aedab14f4dff0a74106887b5c597a",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Troy Merritt",
                                    "name": "Troy Merritt",
                                    "selectedText": "Troy Merritt",
                                    "firstName": "Troy",
                                    "lastName": "Merritt",
                                    "dob": "1985-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32640.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "6b138ff938d1af1c550c47d30bca67eb",
                                    "bettingOdds": 125.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "6fed19fc-6f50-438e-9a97-6c1e186d3afe",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647711000000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 05:30 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Xander Schauffele",
                                    "name": "Xander Schauffele",
                                    "selectedText": "Xander Schauffele",
                                    "firstName": "Xander",
                                    "lastName": "Schauffele",
                                    "dob": "1993-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "390941730e64cb202bf9166330543df2",
                                    "bettingOdds": -117.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Simpson, W v Schauffele, X)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "6d8a355703f812e91b17d5cd596b71d7",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Webb Simpson",
                                    "name": "Webb Simpson",
                                    "selectedText": "Webb Simpson",
                                    "firstName": "Webb",
                                    "lastName": "Simpson",
                                    "dob": "1985-08-08",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/29221.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "942e1daee3bf15c1889e30db470d1c43",
                                    "bettingOdds": 140.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "97fdde37-bd1a-40cc-99d9-50c8e93d8b19",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647711600000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 05:40 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Justin Thomas",
                                    "name": "Justin Thomas",
                                    "selectedText": "Justin Thomas",
                                    "firstName": "Justin",
                                    "lastName": "Thomas",
                                    "dob": "1993-04-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "e1b9fcf3fe0cc9f9510f87290855519c",
                                    "bettingOdds": -222.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Thomas, J v Riley, Davis)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "1b6c992a90f7ac71d04638a634a2eb8e",
                                    "bettingOdds": 800.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Davis Riley",
                                    "name": "Davis Riley",
                                    "selectedText": "Davis Riley",
                                    "firstName": "Davis",
                                    "lastName": "Riley",
                                    "dob": "1996-12-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "7779cbd574bfdecd6e5cae12edf9f1e8",
                                    "bettingOdds": 275.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "d396d4e1-576c-492f-9330-663e84526ae6",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647712200000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 05:50 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Sam Burns",
                                    "name": "Sam Burns",
                                    "selectedText": "Sam Burns",
                                    "firstName": "Sam",
                                    "lastName": "Burns",
                                    "dob": "1996-07-23",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "ceec19941af1e17b50c265c58b7c1d6a",
                                    "bettingOdds": -147.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Stallings, S v Burns, S)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "d6c39afd314f07b0647af42a97f8ab7d",
                                    "bettingOdds": 800.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Scott Stallings",
                                    "name": "Scott Stallings",
                                    "selectedText": "Scott Stallings",
                                    "firstName": "Scott",
                                    "lastName": "Stallings",
                                    "dob": "1985-03-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/30692.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "96b40014b057d9764e0ba5ee51be679a",
                                    "bettingOdds": 175.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "3901b3ad-a97e-4642-b2f7-d56b5adb0e46",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647712800000,
                            "title": "2 Ball",
                            "pointValue": 75,
                            "description": "2 Ball - 06:00 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Adam Hadwin",
                                    "name": "Adam Hadwin",
                                    "selectedText": "Adam Hadwin",
                                    "firstName": "Adam",
                                    "lastName": "Hadwin",
                                    "dob": "1987-11-02",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "e3ffd0af7d48c92aea61f4ed2a95cb8b",
                                    "bettingOdds": -125.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (NeSmith, M v Hadwin, A)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "22a0eaf69974a983e1665eab3594b93f",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Matthew NeSmith",
                                    "name": "Matthew NeSmith",
                                    "selectedText": "Matthew NeSmith",
                                    "firstName": "Matthew",
                                    "lastName": "NeSmith",
                                    "dob": "1993-09-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "5131d7a84251ed57507e5b565ac1a6ae",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }
                    ]
                }, {
                    "id": "082479c6-b31a-4285-b593-b717b2cc5518",
                    "prompt": "Round 3 Leader",
                    "startTime": 1647388740000,
                    "endTime": 1647814776000,
                    "title": "Round 3 Leader",
                    "description": "Round 3 Leader - 10:19 Tee Time",
                    "limit": 1,
                    "questions": [{
                            "id": "082479c6-b31a-4285-b593-b717b2cc5518",
                            "prompt": "Round 3 Leader",
                            "startTime": 1647388740000,
                            "endTime": 1647814776000,
                            "title": "Round 3 Leader",
                            "pointValue": 75,
                            "description": "Round 3 Leader - 10:19 Tee Time",
                            "showTeeTime": false,
                            "players": [{
                                    "id": "Matthew NeSmith",
                                    "name": "Matthew NeSmith",
                                    "selectedText": "Matthew NeSmith",
                                    "firstName": "Matthew",
                                    "lastName": "NeSmith",
                                    "dob": "1993-09-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "bbfec8a7a3022020baed7151095b8565",
                                    "bettingOdds": -149.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Davis Riley",
                                    "name": "Davis Riley",
                                    "selectedText": "Davis Riley",
                                    "firstName": "Davis",
                                    "lastName": "Riley",
                                    "dob": "1996-12-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a7e889f2b5642d3aec8f4a4791cf49ca",
                                    "bettingOdds": 110.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Sam Burns",
                                    "name": "Sam Burns",
                                    "selectedText": "Sam Burns",
                                    "firstName": "Sam",
                                    "lastName": "Burns",
                                    "dob": "1996-07-23",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "bb16f008ec8e9a3175c605098356d085",
                                    "bettingOdds": 15000.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Justin Thomas",
                                    "name": "Justin Thomas",
                                    "selectedText": "Justin Thomas",
                                    "firstName": "Justin",
                                    "lastName": "Thomas",
                                    "dob": "1993-04-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b0c6f60e382b38bd7fa6e6b4abe4ba35",
                                    "bettingOdds": 20000.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }
                    ]
                }
            ]
        }, {
            "id": "27d6cdb8-6070-4441-9b17-629f3b51c267",
            "type": "ROUND",
            "title": "ROUND 4",
            "dayOfWeek": "Sunday",
            "startTime": 1647216000000,
            "endTime": 1647799200000,
            "open": false,
            "description": "100 POINT PICK",
            "questions": [{
                    "id": "3c55f80f-6321-4b20-ac86-c8177a01e386",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647796200000,
                    "title": "2 Ball",
                    "pointValue": 100,
                    "description": "2 Ball - 05:10 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Alexander Noren",
                            "name": "Alexander Noren",
                            "selectedText": "Alexander Noren",
                            "firstName": "Alex",
                            "lastName": "Noren",
                            "dob": "1982-07-12",
                            "gender": "Male",
                            "country": "SWE",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                            "oddsId": "0062dd1f3985ac661b1116f28b43dd80",
                            "bettingOdds": -147.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Duncan, T v Noren, A)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "48da983c799a20fedd85dd88ea9d01bd",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tyler Duncan",
                            "name": "Tyler Duncan",
                            "selectedText": "Tyler Duncan",
                            "firstName": "Tyler",
                            "lastName": "Duncan",
                            "dob": "1989-07-13",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a79f41488b4e9202811c8720808f4987",
                            "bettingOdds": 175.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "aba410df-6a36-4a8b-bb83-fbcda0957eea",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647796800000,
                    "title": "2 Ball",
                    "pointValue": 100,
                    "description": "2 Ball - 05:20 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Xander Schauffele",
                            "name": "Xander Schauffele",
                            "selectedText": "Xander Schauffele",
                            "firstName": "Xander",
                            "lastName": "Schauffele",
                            "dob": "1993-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b5b2e83a06001fef9aa403d66f1338c0",
                            "bettingOdds": -147.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Merritt, T v Schauffele, X)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "aff247d3c80264f23d930c63d4fef3a6",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Troy Merritt",
                            "name": "Troy Merritt",
                            "selectedText": "Troy Merritt",
                            "firstName": "Troy",
                            "lastName": "Merritt",
                            "dob": "1985-10-25",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32640.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "a03e0048ee861165339debbb915fc31e",
                            "bettingOdds": 175.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "84acaccf-f10e-4ee1-ad00-3333f1f05899",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647797400000,
                    "title": "2 Ball",
                    "pointValue": 100,
                    "description": "2 Ball - 05:30 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Matthew Fitzpatrick",
                            "name": "Matthew Fitzpatrick",
                            "selectedText": "Matthew Fitzpatrick",
                            "firstName": "Matt",
                            "lastName": "Fitzpatrick",
                            "dob": "1994-09-01",
                            "gender": "Male",
                            "country": "ENG",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                            "oddsId": "b8f29cc1eaab0685c7fc5b697b727bbb",
                            "bettingOdds": -125.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Fitzpatrick, M v Harman, B)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "d9db91477378882e6a1264f94200a2a2",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Brian Harman",
                            "name": "Brian Harman",
                            "selectedText": "Brian Harman",
                            "firstName": "Brian",
                            "lastName": "Harman",
                            "dob": "1987-01-19",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "48a299e76dfe3dfe32c4e8203c25cf5c",
                            "bettingOdds": 150.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }, {
                    "id": "b04bb9cb-ac9c-4e3e-a0c8-b8c3587a8010",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647798000000,
                    "title": "2 Ball",
                    "pointValue": 100,
                    "description": "2 Ball - 05:40 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Adam Hadwin",
                            "name": "Adam Hadwin",
                            "selectedText": "Adam Hadwin",
                            "firstName": "Adam",
                            "lastName": "Hadwin",
                            "dob": "1987-11-02",
                            "gender": "Male",
                            "country": "CAN",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                            "oddsId": "0da123d886c00a322bd8a035a6c16c35",
                            "bettingOdds": -149.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Hadwin, A v Streb, R)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "130713286b2eebed1aacfef0ec626fce",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Robert Streb",
                            "name": "Robert Streb",
                            "selectedText": "Robert Streb",
                            "firstName": "Robert",
                            "lastName": "Streb",
                            "dob": "1987-04-07",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b0859c28d753af1a0e33a492d32c9e76",
                            "bettingOdds": 180.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "89820ae6-2dc8-4fcb-8407-fee1866184d8",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647798600000,
                    "title": "2 Ball",
                    "pointValue": 100,
                    "description": "2 Ball - 05:50 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Justin Thomas",
                            "name": "Justin Thomas",
                            "selectedText": "Justin Thomas",
                            "firstName": "Justin",
                            "lastName": "Thomas",
                            "dob": "1993-04-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "58e84425446e77c8bd90cd0e46e5247d",
                            "bettingOdds": -117.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Thomas, J v Burns, S)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "1744527f86c96daafe417bb98ff5a69a",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Sam Burns",
                            "name": "Sam Burns",
                            "selectedText": "Sam Burns",
                            "firstName": "Sam",
                            "lastName": "Burns",
                            "dob": "1996-07-23",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "b222c3b3d7646b648fec0dfa8a9179bb",
                            "bettingOdds": 140.0,
                            "placedBet": false,
                            "winner": true
                        }
                    ]
                }, {
                    "id": "c3458fe0-6f2d-4966-a1f3-fe58920f0c85",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647799200000,
                    "title": "2 Ball",
                    "pointValue": 100,
                    "description": "2 Ball - 06:00 Tee Time",
                    "showTeeTime": true,
                    "players": [{
                            "id": "Matthew NeSmith",
                            "name": "Matthew NeSmith",
                            "selectedText": "Matthew NeSmith",
                            "firstName": "Matthew",
                            "lastName": "NeSmith",
                            "dob": "1993-09-29",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "dd6ef41f0dd425974556401fac63d1f2",
                            "bettingOdds": -105.0,
                            "placedBet": false,
                            "winner": true
                        }, {
                            "id": "Tie",
                            "name": "Tie",
                            "selectedText": "Tie (Riley, Davis v NeSmith, M)",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                            "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                            "oddsId": "771d455798f562b73b119a041e50f338",
                            "bettingOdds": 775.0,
                            "placedBet": false,
                            "winner": false
                        }, {
                            "id": "Davis Riley",
                            "name": "Davis Riley",
                            "selectedText": "Davis Riley",
                            "firstName": "Davis",
                            "lastName": "Riley",
                            "dob": "1996-12-17",
                            "gender": "Male",
                            "country": "USA",
                            "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                            "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                            "oddsId": "7d77b3ad7bf1b766642127713b3ec8d6",
                            "bettingOdds": 125.0,
                            "placedBet": false,
                            "winner": false
                        }
                    ]
                }
            ],
            "groups": [{
                    "id": "3c55f80f-6321-4b20-ac86-c8177a01e386",
                    "prompt": "2 Ball",
                    "startTime": 1647216000000,
                    "endTime": 1647796200000,
                    "title": "2 Ball",
                    "description": "2 Ball - 05:10 Tee Time",
                    "limit": 3,
                    "questions": [{
                            "id": "3c55f80f-6321-4b20-ac86-c8177a01e386",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647796200000,
                            "title": "2 Ball",
                            "pointValue": 100,
                            "description": "2 Ball - 05:10 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Alexander Noren",
                                    "name": "Alexander Noren",
                                    "selectedText": "Alexander Noren",
                                    "firstName": "Alex",
                                    "lastName": "Noren",
                                    "dob": "1982-07-12",
                                    "gender": "Male",
                                    "country": "SWE",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27349.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_SWE.png",
                                    "oddsId": "0062dd1f3985ac661b1116f28b43dd80",
                                    "bettingOdds": -147.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Duncan, T v Noren, A)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "48da983c799a20fedd85dd88ea9d01bd",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tyler Duncan",
                                    "name": "Tyler Duncan",
                                    "selectedText": "Tyler Duncan",
                                    "firstName": "Tyler",
                                    "lastName": "Duncan",
                                    "dob": "1989-07-13",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/45609.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a79f41488b4e9202811c8720808f4987",
                                    "bettingOdds": 175.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "aba410df-6a36-4a8b-bb83-fbcda0957eea",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647796800000,
                            "title": "2 Ball",
                            "pointValue": 100,
                            "description": "2 Ball - 05:20 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Xander Schauffele",
                                    "name": "Xander Schauffele",
                                    "selectedText": "Xander Schauffele",
                                    "firstName": "Xander",
                                    "lastName": "Schauffele",
                                    "dob": "1993-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/48081.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b5b2e83a06001fef9aa403d66f1338c0",
                                    "bettingOdds": -147.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Merritt, T v Schauffele, X)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "aff247d3c80264f23d930c63d4fef3a6",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Troy Merritt",
                                    "name": "Troy Merritt",
                                    "selectedText": "Troy Merritt",
                                    "firstName": "Troy",
                                    "lastName": "Merritt",
                                    "dob": "1985-10-25",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/32640.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "a03e0048ee861165339debbb915fc31e",
                                    "bettingOdds": 175.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "84acaccf-f10e-4ee1-ad00-3333f1f05899",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647797400000,
                            "title": "2 Ball",
                            "pointValue": 100,
                            "description": "2 Ball - 05:30 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Matthew Fitzpatrick",
                                    "name": "Matthew Fitzpatrick",
                                    "selectedText": "Matthew Fitzpatrick",
                                    "firstName": "Matt",
                                    "lastName": "Fitzpatrick",
                                    "dob": "1994-09-01",
                                    "gender": "Male",
                                    "country": "ENG",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/40098.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_ENG.png",
                                    "oddsId": "b8f29cc1eaab0685c7fc5b697b727bbb",
                                    "bettingOdds": -125.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Fitzpatrick, M v Harman, B)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "d9db91477378882e6a1264f94200a2a2",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Brian Harman",
                                    "name": "Brian Harman",
                                    "selectedText": "Brian Harman",
                                    "firstName": "Brian",
                                    "lastName": "Harman",
                                    "dob": "1987-01-19",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/27644.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "48a299e76dfe3dfe32c4e8203c25cf5c",
                                    "bettingOdds": 150.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }, {
                            "id": "b04bb9cb-ac9c-4e3e-a0c8-b8c3587a8010",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647798000000,
                            "title": "2 Ball",
                            "pointValue": 100,
                            "description": "2 Ball - 05:40 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Adam Hadwin",
                                    "name": "Adam Hadwin",
                                    "selectedText": "Adam Hadwin",
                                    "firstName": "Adam",
                                    "lastName": "Hadwin",
                                    "dob": "1987-11-02",
                                    "gender": "Male",
                                    "country": "CAN",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33399.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_CAN.png",
                                    "oddsId": "0da123d886c00a322bd8a035a6c16c35",
                                    "bettingOdds": -149.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Hadwin, A v Streb, R)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "130713286b2eebed1aacfef0ec626fce",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Robert Streb",
                                    "name": "Robert Streb",
                                    "selectedText": "Robert Streb",
                                    "firstName": "Robert",
                                    "lastName": "Streb",
                                    "dob": "1987-04-07",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/34431.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b0859c28d753af1a0e33a492d32c9e76",
                                    "bettingOdds": 180.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "89820ae6-2dc8-4fcb-8407-fee1866184d8",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647798600000,
                            "title": "2 Ball",
                            "pointValue": 100,
                            "description": "2 Ball - 05:50 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Justin Thomas",
                                    "name": "Justin Thomas",
                                    "selectedText": "Justin Thomas",
                                    "firstName": "Justin",
                                    "lastName": "Thomas",
                                    "dob": "1993-04-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/33448.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "58e84425446e77c8bd90cd0e46e5247d",
                                    "bettingOdds": -117.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Thomas, J v Burns, S)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "1744527f86c96daafe417bb98ff5a69a",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Sam Burns",
                                    "name": "Sam Burns",
                                    "selectedText": "Sam Burns",
                                    "firstName": "Sam",
                                    "lastName": "Burns",
                                    "dob": "1996-07-23",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47504.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "b222c3b3d7646b648fec0dfa8a9179bb",
                                    "bettingOdds": 140.0,
                                    "placedBet": false,
                                    "winner": true
                                }
                            ]
                        }, {
                            "id": "c3458fe0-6f2d-4966-a1f3-fe58920f0c85",
                            "prompt": "2 Ball",
                            "startTime": 1647216000000,
                            "endTime": 1647799200000,
                            "title": "2 Ball",
                            "pointValue": 100,
                            "description": "2 Ball - 06:00 Tee Time",
                            "showTeeTime": true,
                            "players": [{
                                    "id": "Matthew NeSmith",
                                    "name": "Matthew NeSmith",
                                    "selectedText": "Matthew NeSmith",
                                    "firstName": "Matthew",
                                    "lastName": "NeSmith",
                                    "dob": "1993-09-29",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/36871.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "dd6ef41f0dd425974556401fac63d1f2",
                                    "bettingOdds": -105.0,
                                    "placedBet": false,
                                    "winner": true
                                }, {
                                    "id": "Tie",
                                    "name": "Tie",
                                    "selectedText": "Tie (Riley, Davis v NeSmith, M)",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/tie.jpg",
                                    "flagUrl": "https://resources-us.yinzcam.com/pga/shared/pga_tour/images/no_flag.png",
                                    "oddsId": "771d455798f562b73b119a041e50f338",
                                    "bettingOdds": 775.0,
                                    "placedBet": false,
                                    "winner": false
                                }, {
                                    "id": "Davis Riley",
                                    "name": "Davis Riley",
                                    "selectedText": "Davis Riley",
                                    "firstName": "Davis",
                                    "lastName": "Riley",
                                    "dob": "1996-12-17",
                                    "gender": "Male",
                                    "country": "USA",
                                    "photoUrl": "https://resources-us.yinzcam.com/pga/shared/images/headshots/47995.png",
                                    "flagUrl": "https://resources-us.yinzcam.com/common/flags/ioc_USA.png",
                                    "oddsId": "7d77b3ad7bf1b766642127713b3ec8d6",
                                    "bettingOdds": 125.0,
                                    "placedBet": false,
                                    "winner": false
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}