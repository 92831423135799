import { Token } from "inversify-token";

export const YinzCamAPIConfigToken = new Token<YinzCamAPIConfig>(Symbol.for("YinzCamAPIConfig"));

export interface YinzCamAPIConfig {
  league: string;
  tricode: string;
  environment?: string;
  defaultCacheTimeSeconds: number;
  defaultRequestTimeoutSeconds: number;
  //stopPollingWhenWindowInvisible: boolean;
  sendCacheBusterParam: boolean;
  apiServiceOverrideEnabled: boolean;
  apiServiceOverrideUrls: { [key: string]: string };
}
