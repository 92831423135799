<script lang="ts">var _a;
import TextInput from "../TextInput/TextInput.svelte";
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { getContext } from 'svelte';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
$: key = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.key) || 'pin';
let pin;
const handleChange = (e) => {
    pin = e.target.value;
    console.log('pin: ', pin);
    tabContext.setParam(key, pin);
};
</script>
    
    <CardBase {element}>
        <div class="pin-input-field-wrapper">
            <TextInput placeholder="PIN" value={pin} onChange={handleChange} />
        </div>
    </CardBase>
    
    <style>.pin-input-field-wrapper {
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
}</style>
    