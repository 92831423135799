{#key previewEnabled}
<MenuItem iconF7={(previewEnabled)? "eye_slash" : "eye"} iconOnly {iconSize} on:click={handleClick} />
{/key}

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { MenuItem } from 'framework7-svelte';
import { CardsPageContext, CardsPageContextKey } from './context';
import { getContext } from 'svelte';
export let iconSize = 20;
const pageContext = getContext(CardsPageContextKey);
const previewStore = pageContext.editorModeManager.getPreviewStore();
$: previewEnabled = $previewStore;
function handleClick() {
    pageContext.editorModeManager.togglePreview();
}
</script>