import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { CardsDataSourceToken } from "../common/CardsDataSourceToken";
import { YinzCamAdsServerBannerAds } from "./YinzCamAdsServerBannerAds/YinzCamAdsServerBannerAds";

import { YinzCamAppServerAuxiliaryCompetitions } from "./YinzCamAppServerAuxiliaryCompetitions/YinzCamAppServerAuxiliaryCompetitions";
import { YinzCamAppServerAuxiliaryHistory } from "./YinzCamAppServerAuxiliaryHistory/YinzCamAppServerAuxiliaryHistory";
import { YinzCamAppServerAuxiliaryPlayers } from "./YinzCamAppServerAuxiliaryPlayers/YinzCamAppServerAuxiliaryPlayers";
import { YinzCamAppServerAuxiliarySchedule } from "./YinzCamAppServerAuxiliarySchedule/YinzCamAppServerAuxiliarySchedule";
import { YinzCamAppServerAuxiliaryStandings } from "./YinzCamAppServerAuxiliaryStandings/YinzCamAppServerAuxiliaryStandings";
import { YinzCamAppServerAuxiliaryTeam } from "./YinzCamAppServerAuxiliaryTeam/YinzCamAppServerAuxiliaryTeam";
import { YinzCamAppServerAuxiliaryTeams } from "./YinzCamAppServerAuxiliaryTeams/YinzCamAppServerAuxiliaryTeams";
import { YinzCamAppServerGameBox } from "./YinzCamAppServerGameBox/YinzCamAppServerGameBox";
import { YinzCamAppServerGameCommentary } from "./YinzCamAppServerGameCommentary/YinzCamAppServerGameCommentary";
import { YinzCamAppServerGameFormation } from "./YinzCamAppServerGameFormation/YinzCamAppServerGameFormation";
import { YinzCamAppServerGameList } from "./YinzCamAppServerGameList/YinzCamAppServerGameList";
import { YinzCamAppServerGameListNoFilter } from "./YinzCamAppServerGameList/YinzCamAppServerGameListNoFilter";
import { YinzCamAppServerGameListFilter } from "./YinzCamAppServerGameListFilter/YinzCamAppServerGameListFilter";
import { YinzCamAppServerGamePlays } from "./YinzCamAppServerGamePlays/YinzCamAppServerGamePlays";
import { YinzCamAppServerGameSchedule } from "./YinzCamAppServerGameSchedule/YinzCamAppServerGameSchedule";
import { YinzCamAppServerGameScores } from "./YinzCamAppServerGameScores/YinzCamAppServerGameScores";
import { YinzCamAppServerGameScoresRoundFilterOptions } from "./YinzCamAppServerGameScoresRoundFilterOptions/YinzCamAppServerGameScoresRoundFilterOptions";
import { YinzCamAppServerGameState } from "./YinzCamAppServerGameState/YinzCamAppServerGameState";
import { YinzCamAppServerHasData } from "./YinzCamAppServerHasData/YinzCamAppServerHasData";
import { YinzCamAppServerHeadingOptions } from "./YinzCamAppServerHeadingOptions/YinzCamAppServerHeadingOptions";
//YinzCamAppServerMediaGallery
import { YinzCamAppServerMedia } from "./YinzCamAppServerMedia/YinzCamAppServerMedia";
import { YinzCamAppServerMediaGallery } from "./YinzCamAppServerMediaGallery/YinzCamAppServerMediaGallery";
import { YinzCamAppServerMediaItem } from "./YinzCamAppServerMediaItem/YinzCamAppServerMediaItem";
import { YinzCamAppServerMediaNews } from "./YinzCamAppServerMediaNews/YinzCamAppServerMediaNews";
import { YinzCamAppServerStatsClubLeaders } from "./YinzCamAppServerStatsClubLeaders/YinzCamAppServerStatsClubLeaders";
import { YinzCamAppServerStatsPlayer } from "./YinzCamAppServerStatsPlayer/YinzCamAppServerStatsPlayer";
import { YinzCamAppServerStatsStandings } from "./YinzCamAppServerStatsStandings/YinzCamAppServerStatsStandings";
import { YinzCamAppServerStatsTeam } from "./YinzCamAppServerStatsTeam/YinzCamAppServerStatsTeam";
import { YinzCamAppServerTeamRoster } from "./YinzCamAppServerTeamRoster/YinzCamAppServerTeamRoster";
import { YinzCamAppServerTeamPositionFilter } from "./YinzCamAppServerTeamPositionFilter/YinzCamAppServerTeamPositionFilter";
import { YinzCamAppServerTeamTeamsList } from "./YinzCamAppServerTeamTeamsList/YinzCamAppServerTeamTeamsList";
import { YinzCamAppServerTeamTeamsListFilterOptions } from "./YinzCamAppServerTeamTeamsListFilterOptions/YinzCamAppServerTeamTeamsListFilterOptions";
import { YinzCamAppServerStatsStandingsFilter } from "./YinzCamAppServerStatsStandingsFilter/YinzCamAppServerStatsStandingsFilter";
import { YinzCamAppServerCompetitionsFilter } from "./YinzCamAppServerCompetitionsFilter/YinzCamAppServerCompetitionsFilter";
import { YinzCamAppServerTeamStandingsFilter } from "./YinzCamAppServerTeamStandingsFilter/YinzCamAppServerTeamStandingsFilter";
import { YinzCamAppServerPhaseListFilter } from "./YinzCamAppServerPhaseListFilter/YinzCamAppServerPhaseListFilter";
import { YinzCamAppServerGamePhaseList } from "./YinzCamAppServerGamePhaseList/YinzCamAppServerGamePhaseList";
import { YinzCamAppServerStandingsTypeFilter } from "./YinzCamAppServerStandingsTypeFilter/YinzCamAppServerStandingsTypeFilter";
import { YinzCamAppServerSearchResultsFilter } from "./YinzCamAppServerSearchResultsFilter/YinzCamAppServerSearchResultsFilter";
import { YinzCamAppServerSearchResultsSummary } from "./YinzCamAppServerSearchResultsSummary/YinzCamAppServerSearchResultsSummary";
import { YinzCamAppServerSearchResultsItems } from "./YinzCamAppServerSearchResultsItems/YinzCamAppServerSearchResultsItems";
import { YinzCamSignonServerMember } from "./YinzCamSignonServerMember/YinzCamSignonServerMember";
import { YinzCamSignonServerMemberCards } from "./YinzCamSignonServerMemberCards/YinzCamSignonServerMemberCards";
import { YinzCamAppServerMatchList } from './YinzCamAppServerMatchList/YinzCamAppServerMatchList';
import { YinzCamAppServerMediaGalleryArray } from './YinzCamAppServerMediaGalleryArray/YinzCamAppServerMediaGalleryArray';
import { YinzCamAppServerMediaGalleryArrayLength } from './YinzCamAppServerMediaGalleryArrayLength/YinzCamAppServerMediaGalleryArrayLength';
import { YinzCamAppServerMediaPagination } from './YinzCamAppServerMediaPagination/YinzCamAppServerMediaPagination';
import { YinzCamAppServerMediaFilter } from './YinzCamAppServerMediaFilter/YinzCamAppServerMediaFilter';
import { YinzCamAppServerAgendaEvent } from './YinzCamAppServerAgendaEvent/YinzCamAppServerAgendaEvent';
import { YinzCamAppServerPagesResources } from './YinzCamAppServerPagesResources/YinzCamAppServerPagesResources';
import { YinzCamAppServerSocialAggregator } from "./YinzCamAppServerSocialAggregator/YinzCamAppServerSocialAggregator";
import { YinzCamDropsServerCurrentDrop } from "./YinzCamDropsServerCurrentDrop/YinzCamDropsServerCurrentDrop";
import { YinzCamChatMedia } from "./YinzCamChatMedia/YinzCamChatMedia";
import { YinzCamSignonProfileSegment } from "./YinzCamSignonProfileSegment/YinzCamSignonProfileSegment";
//PGA added by serhii 2021-11-05
import { PGAPickList } from "./PGAPickList/PGAPickList";
import { PGAUserData } from "./PGAUserData/PGAUserData";
import { PGALeaderboardData } from './PGALeaderboardData/PGALeaderboardData';
import { PGABetResult } from "./PGABetResult/PGABetResult";

//SSO22
import { YinzCamSignonServerProfile } from "./YinzCamSignonServerProfile/YinzCamSignonServerProfile";
import { YinzCamSignonServerLinks } from "./YinzCamSignonServerLinks/YinzCamSignonServerLinks";

const SOURCE_LIBRARY = {
  YinzCamAdsServerBannerAds,
  YinzCamAppServerAuxiliaryCompetitions,
  YinzCamAppServerAuxiliaryHistory,
  YinzCamAppServerAuxiliaryPlayers,
  YinzCamAppServerAuxiliarySchedule,
  YinzCamAppServerAuxiliaryStandings,
  YinzCamAppServerAuxiliaryTeam,
  YinzCamAppServerAuxiliaryTeams,
  YinzCamAppServerGameBox,
  YinzCamAppServerGameCommentary,
  YinzCamAppServerGameFormation,
  YinzCamAppServerGameList,
  YinzCamAppServerGameListNoFilter,
  YinzCamAppServerGameListFilter,
  YinzCamAppServerGamePlays,
  YinzCamAppServerGameSchedule,
  YinzCamAppServerGameScores,
  YinzCamAppServerGameScoresRoundFilterOptions,
  YinzCamAppServerGameState,
  YinzCamAppServerHasData,
  YinzCamAppServerHeadingOptions,
  YinzCamAppServerMedia,
  YinzCamAppServerMediaGallery,
  YinzCamAppServerMediaItem,
  YinzCamAppServerMediaNews,
  YinzCamAppServerStatsClubLeaders,
  YinzCamAppServerStatsPlayer,
  YinzCamAppServerStatsStandings,
  YinzCamAppServerStatsTeam,
  YinzCamAppServerTeamRoster,
  YinzCamAppServerTeamPositionFilter,
  YinzCamAppServerTeamTeamsList,
  YinzCamAppServerTeamTeamsListFilterOptions,
  YinzCamAppServerStatsStandingsFilter,
  YinzCamAppServerCompetitionsFilter,
  YinzCamAppServerTeamStandingsFilter,
  YinzCamAppServerPhaseListFilter,
  YinzCamAppServerGamePhaseList,
  YinzCamAppServerStandingsTypeFilter,
  YinzCamAppServerSearchResultsFilter,
  YinzCamAppServerSearchResultsSummary,
  YinzCamAppServerSearchResultsItems,
  YinzCamSignonServerMember,
  YinzCamSignonServerMemberCards,
  YinzCamAppServerMatchList,
  YinzCamAppServerMediaGalleryArray,
  YinzCamAppServerMediaPagination,
  YinzCamAppServerMediaFilter,
  YinzCamAppServerAgendaEvent,
  YinzCamAppServerPagesResources,
  YinzCamAppServerSocialAggregator,
  YinzCamAppServerMediaGalleryArrayLength,
  YinzCamDropsServerCurrentDrop,
  YinzCamChatMedia,
  YinzCamSignonProfileSegment,
  PGAPickList,
  PGAUserData,
  PGALeaderboardData,
  PGABetResult,
  YinzCamSignonServerProfile,
  YinzCamSignonServerLinks
}

export const CardsDataSourcesModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    for (let k in SOURCE_LIBRARY) {
      const clazz = k.replace(/__/g, '.');
      const rec = SOURCE_LIBRARY[k];
      // this monkey patches each data source with its class name as listed in SOURCE_LIBRARY
      rec.prototype.clazz = clazz;
      bindToken(CardsDataSourceToken).to(rec).whenTargetNamed(clazz);
    }
  }));
});
