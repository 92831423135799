import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import { JSONSchema4 } from "json-schema";
import { t, tdate, ttime } from '../../../../js/i18n';
import { get } from "svelte/store";

@injectable()
export class YinzCamAppServerGameBox extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) private readonly server) {
    super({ server })
  }

  public getDisplayName(): string {
    return 'YinzCam Sports Data - Game Scores and Matchup';
  }

  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    const rsp = await this.server.singleRequest({ path: "/Game/List" });
    const dateFmt = get(tdate);
    return [].concat((<any>rsp?.data)?.GameList?.GameSection?.Game).filter(item => item?._attributes?.Id).map((game) => ({
      name: 'Match: ' + dateFmt(new Date(game.Date?._attributes.Timestamp), { month: 'short', day: 'numeric' }) + ' ' + ((game._attributes?.Home === "true")? 'Home' : 'Away') + ' vs.' + game.Opponent?._attributes.Name,
      path: `/Game/Box/${game._attributes?.Id}`
    }));
  }

  public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
    return null;
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;
    return data?.Boxscore;
  }
}
