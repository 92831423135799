<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Cropper from "svelte-easy-crop";
import getCroppedImg from "./canvasUtils";
import { t } from '../../../../../js/i18n';
import ProfilePhotoSaveButton from "../ProfilePhotoSaveButton/ProfilePhotoSaveButton.svelte";
import CancelButton from "../CancelButton/CancelButton.svelte";
export let onPhotoEditing = (fileName, dataUrl, file) => { };
export let popupControl;
let crop = { x: 0, y: 0 };
let zoom = 1;
let image, imageFile, fileinput, pixelCrop, croppedImage;
let filename = "";
const defaultSrc = "https://cdn1-www.dogtime.com/assets/uploads/2011/03/puppy-development.jpg";
function onFileSelected(e) {
    console.log('fileSelect: ', e);
    let imageFile = e.target.files[0];
    let reader = new FileReader();
    reader.onload = e => {
        image = e.target.result;
        filename = imageFile.name;
    };
    reader.readAsDataURL(imageFile);
}
function previewCrop(e) {
    pixelCrop = e.detail.pixels;
}
function cropImage() {
    return __awaiter(this, void 0, void 0, function* () {
        croppedImage = yield getCroppedImg(image, pixelCrop);
        onPhotoEditing(filename, croppedImage, imageFile);
        popupControl.closePopup();
    });
}
function reset() {
    croppedImage = null;
    image = null;
    popupControl.closePopup();
}
const rotate = () => {
};
</script>

<div class="photo-upload-wrapper">
  <h3 class="dialog-title">Add a Profile Image</h3>
  {#if !image}
    <div class="file-selector-layout">
      <input type="file" id="image-selector" class="image-selector" accept=".jpg, .jpeg, .png" on:change={(e)=>onFileSelected(e)} bind:this={fileinput} />
      <label for="">No Image</label>
    </div>
  {:else}
    <div class="image-area image-show-area">
      <Cropper
        {image}
        bind:crop 
        bind:zoom
        aspect={1}
        on:cropcomplete={previewCrop}
      />
    </div>
    <img src="/static/icons/icon-rotate.png" alt="rotate" class="icon-rotate" on:click={(e) => rotate()} />
    <ProfilePhotoSaveButton handleClick={(e) => cropImage()} />
    <CancelButton handleClick={(e) => reset()}/>
  {/if}
</div>

<style>.image-area {
  position: relative;
  width: 100%;
  height: 300px;
}

.photo-upload-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding: 22px 24px;
  background: white;
  align-items: center;
}

.dialog-title {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #202020;
  margin: 0;
}

.photo-upload-wrapper :global(.cropperArea) {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  border: 20px solid rgba(30,30,30,0.8);
  color: rgba(0, 0, 0, 0.6) !important;
}

.file-selector-layout {
  width: 100%;
  height: 300px;
  position: relative;
}

.image-selector + label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #202020;
  margin: 0;
  border: 1px dashed #202020;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.image-selector {
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.icon-rotate {
  width: 32px;
  height: 32px;
}</style>
