import { CardsDataSourceBase } from '../../common/CardsDataSourceBase'
import { injectable } from 'inversify'
import { injectToken } from 'inversify-token'
import { YinzCamAPIResponse } from 'yinzcam-api'
import { YinzCamAppServer, YinzCamAppServerToken } from 'yinzcam-appserver'
import { JSONSchema4 } from 'json-schema'

@injectable()
export class YinzCamAppServerAuxiliaryPlayers extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) private readonly server: YinzCamAppServer) {
    super({ server })
  }

  public getDisplayName(): string {
    return 'YinzCam Sports Data - Secondary Team Players';
  }

  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    const compId = specData?.compId;
    if (!compId) {
      return null;
    }
    const rsp = await this.server.singleRequest({ path: "/Auxiliary/Teams", params: { compId } });
    return [].concat((<any>rsp?.data)?.ArrayOfAuxTeamsXml?.AuxTeamsXml)?.filter(o => o).map((o) => ({
      name: 'Second Team: ' + (o.TeamName?._text || o.Id?._text || ''),
      path: `/Auxiliary/Players?teamId=${o.Id._text}`
    }));
  }

  public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
    const rsp = await this.server.singleRequest({ path: "/Auxiliary/Competitions" });
    const comps = [].concat((<any>rsp?.data)?.ArrayOfAuxCompetitionXml?.AuxCompetitionXml)?.filter(o => o?.Id?._text).map((o) => ({
      id: o.Id?._text,
      name: 'Second Team Competition: ' + (o.CompetitionName?._text || o.Id?._text || '')
    }));
    return {
      type: "object",
      properties: {
        compId: {
          type: "string",
          title: "Competition",
          description: "The competition to that the team participates in.",
          enum: comps.map(c => c.id),
          options: {
            enum_titles: comps.map(c => c.name)
          }
        }
      },
      required: [
        "compId"
      ]
    };
  }

  protected processResponse(
    response: YinzCamAPIResponse,
    specData?: { [key: string]: any },
  ): object | any[] {
    const data = response?.data as any

    if (specData?.filter) {
      let options = this.getFilter(data)
      return { options }
    } else if (specData?.groupId) {
      const urlParams = new URLSearchParams(response.url)
      const position = urlParams.get('positionId')
      let players =
        specData?.groupId !== position && position !== 'Todas'
          ? []
          : this.getPlayers(data, specData?.groupId)
      if (players.length > 0) {
        players.unshift({ heading: specData?.groupId })
      }
      return players
    }
    return []
      .concat(data?.ArrayOfAuxPlayersXml?.AuxPlayersXml)
      .filter((obj) => obj?.Id)
  }

  getFilter(data: any): any[] {
    const positions: string[] = []
      .concat(data?.ArrayOfAuxPlayersXml?.AuxPlayersXml)
      .filter((e: any) => e?.Id)
      .map((e: any) => e?.PlayerPosition?._text)
    let distinct = positions.filter((x, i, a) => a.indexOf(x) == i)
    return [
      {
        title: 'Todas',
        value: 'Todas',
      },
    ].concat(
      distinct.map((e) => ({
        title: e,
        value: e,
      })),
    )
  }

  getPlayers(data: any, groupId: string): any[] {
    return []
      .concat(data?.ArrayOfAuxPlayersXml?.AuxPlayersXml)
      .filter(
        (e: any) =>
          e?.Id &&
          (groupId === 'Todas' || groupId === e?.PlayerPosition?._text),
      )
      .map((e: any) => ({
        Id: e?.Id?._text,
        AppId: e?.AppId?._text,
        TeamId: e?.TeamId?._text,
        PlayerName: e?.PlayerName?._text,
        PlayerNumber: e?.PlayerNumber?._text,
        PlayerPosition: e?.PlayerPosition?._text,
        PlayerImageUrl: e?.PlayerImageUrl?._text,
        isActive: e?.isActive?._text,
      }))
  }
}
