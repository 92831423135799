import { CardsDataSourceBase } from '../../common/CardsDataSourceBase'
import { injectable } from 'inversify'
import { injectToken } from 'inversify-token'
import { YinzCamAPIResponse } from 'yinzcam-api'
import { YinzCamAppServerToken } from 'yinzcam-appserver'
import _ from 'lodash'

@injectable()
export class YinzCamAppServerAgendaEvent extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any } ): object | any[] {
    const data = response?.data as any
    return data?.CalendarEvents
  }
}
