<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import ActionButton from "../ActionButton/ActionButton.svelte";
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { CardsPageContext, CardsPageContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { resolveUrl } from '../../../../../js/url';
import { JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import { ycSSOSetState } from '../../../../../js/ycapp';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const pageContext = getContext(CardsPageContextKey);
const signonManager = getToken(ROOT, JanusSignonManagerToken);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: passwordKey = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.passwordKey;
$: emailKey = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.emailKey;
$: rememberMeKey = (_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.rememberMeKey;
$: resultKey = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.resultKey;
$: useDjangoLogin = ((_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.useDjangoLogin) || false;
$: dismissAfterLogin = ((_g = element === null || element === void 0 ? void 0 : element.data) === null || _g === void 0 ? void 0 : _g.dismissAfterLogin) || false;
$: isEmailValid = (_h = element === null || element === void 0 ? void 0 : element.data) === null || _h === void 0 ? void 0 : _h.isEmailValid;
$: isPasswordValid = (_j = element === null || element === void 0 ? void 0 : element.data) === null || _j === void 0 ? void 0 : _j.isPasswordValid;
$: email = emailKey ? ($mergedParams || {})[emailKey] : '';
$: password = passwordKey ? ($mergedParams || {})[passwordKey] : '';
$: isRememberMe = rememberMeKey ? ($mergedParams || {})[rememberMeKey] : '';
$: isValidEmail = isEmailValid ? ($mergedParams || {})[isEmailValid] : '';
$: isValidPassword = isPasswordValid ? ($mergedParams || {})[isPasswordValid] : '';
$: console.log('account_login: ', email, password, isValidEmail, isValidPassword, isRememberMe);
$: isValid = !!(email && password && isValidEmail === "true" && isValidPassword === "true");
const handleClick = (e) => __awaiter(void 0, void 0, void 0, function* () {
    var _k, _l;
    console.log('AccountLogInButton: ', email, password, isRememberMe);
    tabContext.setParam(resultKey, "false");
    let result = (useDjangoLogin) ?
        yield signonManager.loginWithDjangoEmailPassword(email, password) :
        yield signonManager.loginWithYinzCamUserPassword(email, password);
    console.log('SSO: login result', result);
    if (result.success) {
        ycSSOSetState(result.data.AccountCreated === 'true', result.data.YinzId, parseInt(result.data.ValidSpan), result.data.Ticket);
        // TODO: Determine if user needs to complete profile?
        // resolveUrl(`CompleteProfile?firstName=${firstName}&lastName=${lastName}&email=${email}`).func();
        // resolveUrl(`ConnectAccounts`).func();
        if (dismissAfterLogin) {
            signonManager.fireWorkflowEndEvent();
        }
        else if (signonManager.skipCompleteProfilePage) {
            resolveUrl(`ConnectAccounts`).func();
        }
        else {
            resolveUrl(`CompleteProfile`).func();
        }
        //signonManager.fireWorkflowEndEvent();
    }
    else {
        alert(((_l = (_k = result.data) === null || _k === void 0 ? void 0 : _k.UserMessage) === null || _l === void 0 ? void 0 : _l.Content) || 'An error occurred.');
    }
});
</script>
<CardBase {element}>
  <ActionButton onClick={handleClick} disabled={!isValid} title="Log in"/>
</CardBase>
