<Boundary>
  <!--
    align-items: stretch; /* ensures content fills the entire grid area. A bug noted in desktop news items. Original value of "center" */
    transform: translateZ(0); /* this fixes a bug in Safari rendering where it does not correctly apply overflow: hidden when using a border radius */
    /* see https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari */
  -->
  <CardsComponentWrapper style="
    display: flex;
    justify-content: center;
    align-items: stretch;
    transform: translateZ(0);
    overflow: hidden;
    height: {height};
    {cssDisplay}
    {(display)? `display: ${display} !important;` : ''}
    {cssVisibility}
    {buildCssBorder(element.border)}
    {buildCssPadding(element.padding)}
    {buildCssMargin(element.margin)}
    {buildCssBackground(element.background)}
  " {parentAttributes} {editorOptions}>
  {#if showPlaceholder}
    <PlaceholderCard
      element={expandedElement}
      {sources}
      width="100%"
      height="100px"
      title={$pbt(cardComponentName)}
    />
  {:else}
    <svelte:component
      this={cardComponent}
      element={expandedElement}
      {sources}
      {height}
      bind:contentAvailable={childContentAvailable}
      bind:sourceStore={childSourceStore}
      bind:contentActive={childContentActive}
      bind:parentAttributes={parentAttributes}
      bind:defaultSourceClasses={childDefaultSourceClasses}
    />
  {/if}
  </CardsComponentWrapper>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">var _a, _b, _c;
import { getContext, onMount, SvelteComponent } from "svelte";
import { get, Readable, writable } from "svelte/store";
import { YinzCamCardsServiceElement, YinzCamCardsServiceSource } from "yinzcam-cards";
import PlaceholderCard from "../library/PlaceholderCard/PlaceholderCard.svelte";
import { CardsDataSourceRegistration } from "./CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey } from "./context";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { buildCssBorder, buildCssBackground, buildCssPadding, expandTemplateParams, buildCssMargin, getConditionsCheckStore } from "../utilities/index";
import _ from "lodash";
import { CardsPageContext, CardsPageContextKey } from './context';
import { CardsEditorComponentOptions } from './CardsEditorInterfaces';
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import { CARDS_ELEMENT_SCHEMA, generateElementDataSourcesSchema } from "./CardsEditorComponentSchemata";
import { getNamed } from 'inversify-token';
import ROOT from '../../../inversify.config';
import { CardsLibraryToken } from "./CardsLibraryToken";
import { pbt } from '../../../js/i18n';
export let parentSequenceId = null;
export let sequenceId = null;
export let element;
export let height = 'initial';
export let contentAvailable = true; // exposed upstream for binding
export let contentActive = false; // exposed upstream for binding
export let updater;
const pageContext = getContext(CardsPageContextKey);
const tabContext = getContext(CardsTabContextKey);
const emmEnabled = pageContext.editorModeManager.getEnabledStore();
const emmPreview = pageContext.editorModeManager.getPreviewStore();
let expandedElement = element;
let mounted = false;
let childContentAvailable; // bound to card component
let childSourceStore; // bound to card component
let cssDisplay = ''; // display CSS on element (default to no change)
let cssVisibility = 'visibility: hidden;'; // visibility CSS on element (default to invisible until content available)
let childContentActive;
let parentAttributes;
let childDefaultSourceClasses;
let sources = writable([]);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
const elementConditionStore = writable(element === null || element === void 0 ? void 0 : element.conditions);
$: elementConditionStore.set(element === null || element === void 0 ? void 0 : element.conditions);
const conditionsCheckStore = getConditionsCheckStore(elementConditionStore);
$: if ((element === null || element === void 0 ? void 0 : element.data) && mergedParams && $mergedParams) {
    let expandedData = {};
    for (const [key, value] of Object.entries(element.data)) {
        if (typeof value === 'string') {
            try {
                expandedData[key] = expandTemplateParams(value, $mergedParams);
            }
            catch (err) {
                expandedData[key] = value;
            }
        }
        else {
            expandedData[key] = value;
        }
    }
    expandedElement = _.clone(element);
    expandedElement.data = expandedData;
}
$: if (sequenceId) {
    // register and unregister the card dynamically from data sources depending on whether conditions are met
    if ($conditionsCheckStore) {
        sources = tabContext.dataSourceManager.register(element.id, sequenceId, element.sourceIds);
    }
    else {
        tabContext.dataSourceManager.unregister(element.id);
        sources = writable([]);
    }
    element = element;
}
$: cardLibraryRecord = getNamed(ROOT, CardsLibraryToken, element.class) || getNamed(ROOT, CardsLibraryToken, "UnknownCard");
$: cardComponent = cardLibraryRecord["default"];
$: cardComponentName = _.isFunction(cardLibraryRecord.getDisplayName) ? cardLibraryRecord.getDisplayName() : element.class;
$: showPlaceholder = $emmEnabled && !$emmPreview;
$: if ($conditionsCheckStore) {
    if (showPlaceholder) {
        // If we're showing the placeholder card, content is always available
        contentAvailable = true;
    }
    else if (!_.isNil(childContentAvailable)) {
        // If contentAvailable is defined by the card component, that means it wants to explicitly control whether it has content available.
        contentAvailable = childContentAvailable;
    }
    else if (!_.isUndefined(childSourceStore)) {
        // If card provides access to its sourceStore, use the value of the store to determine whether it has content available.
        // Note that null here means that the sourceStore is defined by the card, it's just not available yet.
        contentAvailable = (!!$childSourceStore || ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.forceShow)) || false;
    }
    else {
        // Otherwise, assume the card doesn't load dynamic content and just display as soon as we're mounted.
        contentAvailable = (mounted || ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.forceShow)) || false;
    }
}
else {
    contentAvailable = false;
}
let updateDisplay = _.debounce((ca) => {
    cssDisplay = (ca) ? '' : 'display: none;';
}, 100);
$: updateDisplay(contentAvailable);
$: cssVisibility = (contentAvailable) ? '' : 'visibility: hidden;';
$: contentActive = childContentActive;
function generateSchema(component) {
    const schema = _.cloneDeep(CARDS_ELEMENT_SCHEMA);
    const rec = getNamed(ROOT, CardsLibraryToken, component.class);
    if (rec) {
        if (_.isFunction(rec.getDisplayName)) {
            schema.title = rec.getDisplayName();
        }
        else {
            schema.title = rec.clazz;
        }
        if (_.isFunction(rec.getElementConfigSpec)) {
            schema.properties.data = rec.getElementConfigSpec();
            schema.properties.data.title = schema.title;
        }
        else {
            delete schema.properties.data;
        }
        if (_.isFunction(rec.getElementDataSourceSpec)) {
            const dsSpec = rec.getElementDataSourceSpec();
            const curSources = tabContext.dataSourceManager.getDataSources() || [];
            schema.properties['sourceIds'] = generateElementDataSourcesSchema(dsSpec, curSources);
        }
    }
    return schema;
}
let editorOptions;
$: editorOptions = {
    pageContext,
    parentSequenceId,
    sequenceId,
    componentId: element.id,
    component: element,
    componentTypeName: 'Element',
    primaryColor: '#0DDE4F',
    reverseTextColor: 'black',
    childrenKey: null,
    configSpecGenerator: generateSchema,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    allowRepeats: true,
    updater,
    allowPreviewHoverEdit: true
};
// TODO: Fold this mechanism into the cssDisplay variable
let display = null;
$: visibleKey = element.visibleKey;
$: visible = (visibleKey) ? ($mergedParams || {})[visibleKey] : null;
$: if (visibleKey) {
    display = (visible === 'true') ? 'block' : 'none';
}
else {
    display = null;
}
onMount(() => {
    mounted = true;
    return () => {
        tabContext.dataSourceManager.unregister(element.id);
    };
});
</script>

