<script>
  import { onMount } from "svelte";
  export let coords = [0, 0];
  export let onSelect = (value) => {};
  let container;
  let zoom = 10;
  let map;
  let marker;
  let center = {lat: 0, lng: 0};
  console.log("coords: ", coords);

  const image = {
    url: "/static/icons/icon-marker.png",
    // This marker is 20 pixels wide by 32 pixels high.
    size: new google.maps.Size(24, 24),
    // The origin for this image is (0, 0).
    origin: new google.maps.Point(0, 0),
  };

  onMount(async () => {
    center = {
      lng: coords && coords.length > 0 ? coords[0] : 0,
      lat: coords && coords.length > 0 ? coords[1] : 0,
    };
    map = new google.maps.Map(container, {
      zoom,
      center,
    });
    marker = new google.maps.Marker({
      position: center,
      map,
      icon: image,
    });
    const geocoder = new google.maps.Geocoder();
    let infoWindow = new google.maps.InfoWindow();
    // Configure the click listener.
    map.addListener("click", (mapsMouseEvent) => {
      // Close the current InfoWindow.
      infoWindow.close();
      marker.setMap(null);

      // Create a new InfoWindow.
      // infoWindow = new google.maps.InfoWindow({
      //   position: mapsMouseEvent.latLng,
      // });

      center = mapsMouseEvent.latLng;
      console.log('center: ', center)
      geocodeLatLng(geocoder, map, infoWindow, center);

    });

    geocodeLatLng(geocoder, map, infoWindow, center);
  });

  function geocodeLatLng(geocoder, map, infowindow, latlng) {
    geocoder
      .geocode({ location: latlng })
      .then((response) => {
        console.log('GEOCODER RESPONSE', response);
        if (response.results[0]) {
          map.setZoom(11);
          if (marker) {
            marker.setMap(null);
          }
          marker = new google.maps.Marker({
            position: latlng,
            map,
            icon: image,
          });

          console.log("geoCoder: res: ", response.results);
          onSelect(response.results[0]);
          // infowindow.setContent(response.results[0].formatted_address);
          // infowindow.open(map, marker);
        } else {
          window.alert("No results found");
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  }

</script>

<div class="map-view" bind:this={container} />

<style>.map-view {
  width: 100%;
  height: 100%;
}</style>
