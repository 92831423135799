<Boundary>
  <div class="cards-page-top-flex">
    {#if $editorModeEnabled}
      <div class="cards-page-control-bar">
        <CardsEditorControlBar />
      </div>
    {/if}
    <div class="cards-page-left-flex">
      {#if $editorModeEnabled}
        <div class="cards-page-edit-bar">
          <CardsEditorEditBar />
        </div>
      {/if}
      {#if currentLayout}
        <div class="cards-page-content-area" use:applyStyles={'Default'}>
          <CardsComponentWrapper {editorOptions}>
            <CardsRegion sequenceId="ROOT" region={currentLayout.region} />
          </CardsComponentWrapper>  
        </div>
      {/if}
    </div>
  </div>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.cards-page-top-flex {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cards-page-edit-bar {
  flex: 0 1;
}

.cards-page-left-flex {
  flex: 1 1 1px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.cards-page-edit-bar {
  flex: 0 0 400px;
  height: 100%;
  width: 400px;
}

.cards-page-content-area {
  /*flex: 0 0 420px; /* TODO: MAKE THIS CONFIGURABLE WIDTH */
  flex: 1 1 0px;
  /*background-color: black;*/ /* TODO: MAKE THIS CONFIGURABLE */
  height: 100%;
  margin: auto;
  overflow: auto;
}</style>

<script lang='ts'>import ROOT from '../../../inversify.config';
import { getToken } from 'inversify-token';
import { YinzCamCardsServiceElement, YinzCamCardsServicePage, YinzCamCardsServicePageLayout, YinzCamCardsServiceToken } from 'yinzcam-cards';
import { Readable, Writable, writable } from 'svelte/store';
import { windowMetrics as wm } from '../../../js/stores';
import CardsRegion from './CardsRegion.svelte';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { applyStyles, createAnalyticsPageContext } from '../utilities';
import { setContext, onMount } from 'svelte';
import { CardsPageContext, CardsPageContextKey } from './context';
import { yinzcam } from '../../../js/yinzcam';
import CardsEditorControlBar from './CardsEditorControlBar.svelte';
import CardsEditorEditBar from './CardsEditorEditBar.svelte';
import { CardsEditorModeManager } from './CardsEditorModeManager';
import { ManualPassthrough } from 'yinzcam-rma';
import { YinzCamAPIRequestParameters } from 'yinzcam-api';
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import { CardsEditorComponentOptions } from "./CardsEditorInterfaces";
import { CARDS_PAGE_SCHEMA } from "./CardsEditorComponentSchemata";
import { CardsEditorFileUploadCallbacks } from './CardsEditorComponentInterfaces';
export let listName;
export let pathParams = [];
export let pageQuery = {};
export let pageData = getToken(ROOT, YinzCamCardsServiceToken).getCardsPage(listName).store;
// TODO: Move these callbacks up one level into PageBuilder...
export let enableEditorModeOnMount = false;
export let onPageDataUpdate = undefined;
export let onFileUpload = undefined;
export let onDisableSave = undefined;
export let onEnableSave = undefined;
export let onDisableFullscreen = undefined;
export let onEnableFullscreen = undefined;
const pathParamsMap = [listName, ...pathParams].reduce((acc, cur, idx) => { acc[`path${idx}`] = cur; return acc; }, {});
const combinedParamsMap = Object.assign(Object.assign({}, pageQuery), pathParamsMap);
// TODO: Move this and onPageDataUpdate up one level into PageBuilder...
const editorModeManager = new CardsEditorModeManager(ROOT, onPageDataUpdate, onFileUpload, onDisableSave, onEnableSave, onDisableFullscreen, onEnableFullscreen);
const defaultParamsComp = new ManualPassthrough({ name: `CardsPage_${listName}_defaultParamsComp`, saveToLocalStorage: false }, {});
const pathParamsMappingComp = new ManualPassthrough({ name: `CardsPage_${listName}_pathParamsMappingComp`, saveToLocalStorage: false }, []);
let editorModeEnabled = editorModeManager.getEnabledStore();
let analytics = createAnalyticsPageContext();
let sentPageView = false;
setContext(CardsPageContextKey, {
    name: listName,
    query: combinedParamsMap,
    discoModeClass: (CONFIG.discoMode) ? 'disco-mode-enabled' : 'disco-mode-disabled',
    editorModeManager,
    defaultParamsComp,
    pathParamsMappingComp,
    getAnalytics: () => analytics,
});
// Analytics page view logic:
// - only send a page view once per load, even if the analytics info changes
// - this is to avoid inconsistencies in reported events
// - don't send the page view until the page data has loaded
$: if ($pageData && !sentPageView) {
    analytics = createAnalyticsPageContext($pageData.analytics, combinedParamsMap);
    analytics.sendPageView();
    sentPageView = true;
}
$: defaultParamsComp.setValue({ params: $pageData === null || $pageData === void 0 ? void 0 : $pageData.defaultParameters } || {});
$: pathParamsMappingComp.setValue(($pageData === null || $pageData === void 0 ? void 0 : $pageData.pathParameterMapping) || []);
let layouts = [];
$: if ($pageData && ( /*!$editorModeEnabled ||*/!layouts || layouts.length === 0)) {
    try {
        const pageDataLayouts = $pageData.layouts;
        layouts = ((pageDataLayouts === null || pageDataLayouts === void 0 ? void 0 : pageDataLayouts.length) > 0) ? pageDataLayouts.sort((a, b) => a.breakpoint - b.breakpoint) : [];
    }
    finally { }
}
let currentLayout = null;
$: if (layouts && $wm) {
    try {
        let newLayout = layouts[0];
        for (let layout of layouts) {
            if ($wm.width >= layout.breakpoint) {
                newLayout = layout;
            }
            else {
                break;
            }
        }
        if ((newLayout === null || newLayout === void 0 ? void 0 : newLayout.id) !== (currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.id)) {
            currentLayout = newLayout;
        }
    }
    finally { }
}
let editorOptions;
$: console.log('onPageDataUpdate', onPageDataUpdate);
$: editorOptions = {
    parentSequenceId: "__PAGE_PARENT__",
    sequenceId: "__PAGE__",
    componentId: $pageData === null || $pageData === void 0 ? void 0 : $pageData.id,
    component: $pageData,
    componentTypeName: 'Page',
    primaryColor: '#202020',
    reverseTextColor: 'white',
    childrenKey: 'layouts',
    forceWidth: '350px',
    configSpec: CARDS_PAGE_SCHEMA,
    allowChildren: false,
    allowDeletion: false,
    allowDuplication: false,
    allowMovement: false,
    updater: editorModeManager.notifyPageDataUpdate
};
/*
function enableEditorMode() {
  console.log(`${listName}: enable editor mode`);
  editorModeManager.setEnabled(true);
}

function disableEditorMode() {
  console.log(`${listName}: disable editor mode`);
  editorModeManager.setEnabled(false);
}
*/
onMount(() => {
    if (enableEditorModeOnMount) {
        editorModeManager.setEnabled(true);
    }
    /*
    yinzcam.getCards().registerPage(listName, { enableEditorMode, disableEditorMode });
    return () => {
      yinzcam.getCards().unregisterPage(listName);
    }
    */
});
</script>
