<Boundary>
  <CardsContainerWrapper {editorOptions} style="
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    width: 100%;">
    {#each columns as column, i (column.id)}
      <CardsColumn {parentSequenceId} sequenceId={generateSequenceId('COL', parentSequenceId, i)} {column} updater={(column) => columns[i] = column} />
    {/each}
  </CardsContainerWrapper>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceColumn } from "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsColumn from "./CardsColumn.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { CardsEditorContainerOptions } from "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
export let parentSequenceId = "";
export let columns;
export let updater;
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: columns,
    updater
};
</script>
