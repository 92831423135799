<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { onMount } from 'svelte';
import { JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import { ycSSOThirdPartyAuth } from '../../../../../js/ycapp';
import { resolveUrl } from '../../../../../js/url';
import { ycSSOSetState } from '../../../../../js/ycapp';
export let element;
export let sources;
export let sourceStore;
const signonManager = getToken(ROOT, JanusSignonManagerToken);
$: clientConfig = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.clientConfig;
$: linkStore = findSourceStore($sources, 'YinzCamSignonServerLinks');
$: links = linkStore && ($linkStore === null || $linkStore === void 0 ? void 0 : $linkStore.Link);
$: connected = (((links === null || links === void 0 ? void 0 : links.length) > 0) ? links.find(l => (l === null || l === void 0 ? void 0 : l.Type) === 'NFLID') : null) || false;
onMount(() => {
    function afterConnect() {
        // TODO: redirect to complete profile and link other accounts
        if (signonManager.skipCompleteProfilePage) {
            resolveUrl(`ConnectAccounts`).func();
        }
        else {
            resolveUrl(`CompleteProfile`).func();
        }
    }
    if (connected) {
        afterConnect();
    }
    // The app launches Gigya, but we will register it as a NFLID account type.
    ycSSOThirdPartyAuth("GIGYA", clientConfig, (method, clientResult) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        console.log("ycSSOThirdPartyAuth callback inside NFLID", method, clientResult);
        //alert("Gigya Auth for NFLID Complete\n" + JSON.stringify(clientResult));
        if (!(clientResult === null || clientResult === void 0 ? void 0 : clientResult.uid) || !(clientResult === null || clientResult === void 0 ? void 0 : clientResult.uidSignature) || !(clientResult === null || clientResult === void 0 ? void 0 : clientResult.signatureTimestamp)) {
            console.warn(`ycSSOThirdPartyAuth callback for Gigya: missing uid, uidSignature, or signatureTimestamp`);
            return;
        }
        let result = yield signonManager.loginWithNFLID(clientResult.uid, clientResult.uidSignature, clientResult.signatureTimestamp);
        if (result.success) {
            ycSSOSetState(result.data.AccountCreated === 'true', result.data.YinzId, parseInt(result.data.ValidSpan), result.data.Ticket);
            afterConnect();
        }
        else {
            alert(((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.UserMessage) === null || _b === void 0 ? void 0 : _b.Content) || 'An error occurred.');
            signonManager.fireWorkflowEndEvent();
        }
    }));
});
</script>

<CardBase {element}>
</CardBase>
