<script lang="ts">var _a, _b, _c, _d;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { t } from '../../../../../js/i18n';
import { getFirstSourceStore } from '../../../utilities';
import { Readable } from 'svelte/store';
import { getContext, onMount } from 'svelte';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import YCLink from '../../../utilities/YCLink.svelte';
import { ycDismissWebView } from '../../../../../js/ycapp';
export let element;
export let sources;
const tabContext = getContext(CardsTabContextKey);
$: source = getFirstSourceStore($sources);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: text = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.text;
$: caption = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.caption;
$: allowCancel = ((_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.allowCancel) || false;
const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
        return 'Android';
    }
    else if (/iPad|iPhone|iPod/.test(ua) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return 'iOS';
    }
    return 'Other';
};
function onCancel() {
    ycDismissWebView();
}
</script>

<CardBase {element}>
  <div class="forced-update-page">
    <div class="forced-overlay" />
    <div class="forced-dialog">
      <div class="text-header">{caption}</div>
      <div class="content">
        <div class="text">{@html text}
        </div>
        <div class="button-box">
          {#if allowCancel}
          <button type="button" class="button" on:click={onCancel}>Cancel</button>            
          {/if}
          <a class="button external" target="_blank" href={
						getMobileOS() === "iOS" ? "https://geo.itunes.apple.com/us/app/seattle-seahawks/id558103335?mt=8" : "https://play.google.com/store/apps/details?id=com.yinzcam.nfl.seahawks"
					}>Update</a>
        </div>
      </div>
    </div>
  </div>
</CardBase>

<style type="text/scss">*,
*:before,
*:after {
  box-sizing: border-box;
}

.forced-update-page {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forced-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.26667);
}

.forced-dialog {
  position: absolute;
  padding: 0 40px 0 30px;
}

.forced-dialog .text-header {
  width: 262px;
  height: 40px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #69be29;
  font-family: 'Upperhand';
  color: #042342;
  font-size: 24px;
  position: absolute;
  top: -44px;
}

.forced-dialog img {
  width: 262px;
}

.forced-dialog .content {
  margin: -20px 0px 0 48px;
  padding: 30px 20px 15px 20px;
  background-color: white;
}

.forced-dialog .content .text {
  text-align: center;
  font-family: SFUIDisplay;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #696868;
}

.forced-dialog .content .button-box {
  margin: 15px 0 0 0;
  display: flex;
  gap: 14px;
  justify-content: center;
}

.forced-dialog .content .button-box .button {
  background-color: #001b36;
  width: 76px;
  height: 29px;
  border-radius: 8px;
  font-family: SFUIDisplay;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}</style>
