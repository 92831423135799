import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";
import { JSONSchema4 } from "json-schema";

@injectable()
export class YinzCamAppServerMedia extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  public getDisplayName(): string {
    return 'YinzCam Media Content - Media List';
  }

  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    return [
      {
        name: 'Combined Media List (all types)',
        path: '/Media/LongList'
      },
      {
        name: 'Type Media List - News',
        path: '/Media/TaggedList/media-type/N'
      },
      {
        name: 'Type Media List - Gallery',
        path: '/Media/TaggedList/media-type/G'
      },
      {
        name: 'Type Media List - Video',
        path: '/Media/TaggedList/media-type/V'
      }
    ];
  }

  public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
    return null;
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;
    
    let page, length;
    try {
      if (response?.url) {
        console.log("response url: ", response?.url);
        let url = new URL(response.url);
        page = parseInt(url.searchParams?.get("page1"));
        length = parseInt(url.searchParams?.get("length1"));
      }
    } catch (e) {
      // couldn't get page and legnth, ignore
    }
    
    let list = [].concat(data?.Media?.MediaSection).reduce((acc, cur) => acc.concat([].concat(cur?.Item)), []);
    if (!_.isNil(page) && !_.isNaN(page) && !_.isNil(length) && !_.isNaN(length)) {
      list = list.slice(length*page, Math.min(list.length, length*(page + 1)));
    }
    return list;
  }
}