import {
  YinzCamCardsServiceBorder,
  YinzCamCardsServiceBackground,
  YinzCamCardsServicePadding,
  YinzCamCardsServiceMargin,
  YinzCamCardsServiceElement,
  YinzCamCardsServiceConditions,
  YinzCamCardsServiceToken,
} from 'yinzcam-cards'
import _ from 'lodash'
import { CardsDataSourceRegistration } from '../common/CardsDataSourceRegistration'
import { Readable, writable, derived, Unsubscriber } from 'svelte/store'
import ROOT from '../../../inversify.config';
import { getToken } from 'inversify-token';
import { JanusAnalyticsManagerToken } from '../../../js/analytics';
import { v4 as uuid4 } from "uuid";
import { Device } from "framework7";
import { windowMetrics } from "../../../js/stores.js";
import { JanusModeContextManagerToken } from "../../../js/mode";
import { JanusSignonManagerToken } from '../../../js/sso';

const signonManager = getToken(ROOT, JanusSignonManagerToken)
const contextManager = getToken(ROOT, JanusModeContextManagerToken);
const cardsService = getToken(ROOT, YinzCamCardsServiceToken);
const langStore = contextManager.getLanguageComponent().store;

export function generateSequenceId(prefix: string, parentSequenceId: string, childSequenceNumber: number) {
  return parentSequenceId + '-' + prefix + _.padStart(childSequenceNumber.toString(), CONFIG.cardsSequenceNumberDigits, '0');
}

export function buildCssPadding(
  padding: YinzCamCardsServicePadding,
  defaultPadding?: YinzCamCardsServicePadding
) {
  let css: string = '';
  for (const dir of [ 'top', 'right', 'bottom', 'left' ]) {
    const val = padding?.[dir] || defaultPadding?.[dir];
    if (val) {
      css += `\npadding-${dir}: ${val};`;
    }
  }
  return css;
}

export function buildCssMargin(
  margin: YinzCamCardsServiceMargin,
  defaultMargin?: YinzCamCardsServiceMargin
) {
  let css: string = '';
  for (const dir of [ 'top', 'right', 'bottom', 'left' ]) {
    const val = margin?.[dir] || defaultMargin?.[dir];
    if (val) {
      css += `\nmargin-${dir}: ${val};`;
    }
  }
  return css;
}

export function buildCssBorder(
  border: YinzCamCardsServiceBorder,
  defaultBorder?: YinzCamCardsServiceBorder
) {
  let css: string = '';
  for (const dir of [ 'top', 'right', 'bottom', 'left', 'radius' ]) {
    const val = border?.[dir] || defaultBorder?.[dir];
    if (val) {
      css += `\nborder-${dir}: ${val};`;
    }
  }
  return css;
}

export function buildCssBackground(background: YinzCamCardsServiceBackground, defaultBackground?: YinzCamCardsServiceBackground) {
  let css: string = '';
  // backgrounds are strange because the different properties interact with each other
  // we use the background or the default background wholesale in this case
  if (!background) {
    background = defaultBackground;
    if (!background) {
      return css;
    } 
  }
  if (background.url) {
    css += `background-image: url('${background.url}');`;
  }
  for (const key of [ 'color', 'size', 'attachment', 'position', 'repeat' ]) {
    const val = background[key];
    if (val) {
      css += `\nbackground-${key}: ${val.toLowerCase()};`;
    }
  }
  return css;
}

export function buildCssScrollContainer(height?: string) {
  if (!height) {
    return "";
  }
  return `
    height: ${height};
    overflow-y: auto;
    position: relative;`;
}

export interface ExpandRepeatsInfo {
  cloned: boolean
  sourceId?: string
  originalId?: string
  order?: number
}

export interface RepeatingObject {
  id: string
  repeat?: number
  __expandRepeats?: ExpandRepeatsInfo
}

export function expandTemplateParams(
  template?: string,
  params?: { [key: string]: string }
) {
  try {
    if (
      !template ||
      typeof template !== 'string' ||
      !params ||
      !template.includes('{{')
    ) {
      return template
    }
    return _.template(template, { interpolate: /{{([\s\S]+?)}}/g })(params)  
  } catch (e) {
    return template;
  }
}

export function expandTemplateParamsRecursive(obj?: any, params?: { [key: string]: string }) {
  try {
    if (_.isString(obj)) {
      return expandTemplateParams(obj, params);
    } else if (_.isObjectLike(obj)) {
      let expandedObject = _.clone(obj);
      for (const [key, value] of Object.entries(obj)) {
        expandedObject[key] = expandTemplateParamsRecursive(value, params);
      }
      return expandedObject;
    } else {
      return obj;
    }
  } catch (e) {
    return obj;
  }
}

export function expandRepeats<T extends RepeatingObject>(objects?: T[], prefix: string = "", repeatAdd: any = {}): T[] {
  let expanded: T[] = [];
  if (!objects) {
    return expanded;
  }
  for (let object of objects) {
    if (object.__expandRepeats?.cloned) {
      continue;
    }
    let repeat = object.repeat || 1;
    if (object.id === repeatAdd.id && repeatAdd.addCount && Number.isInteger(Number(repeatAdd.addCount))) {
      repeat += Number(repeatAdd.addCount);
    }
    for (let i = 0; i < repeat; i++) {
      //let clone = _.clone(object)
      const tag = `-${prefix}${i}`;
      //const er: ExpandRepeatsInfo = clone.__expandRepeats = { cloned: false, originalId: clone.id };
      let clone;
      if (i == 0) {
        clone = _.clone(object);
        clone.__expandRepeats = { cloned: false, originalId: clone.id };
      } else {
        clone = _.cloneDeepWith(object, (v, k, o, s) => {
          //console.log("CLONEDEEP STACK", o, s);
          if (k === 'id' && _.isString(v)) {
            const layerTag = (s)? `-L${s.size}` : '';
            return `${v}_C${layerTag}${tag}`;
          }
          if (k === '__expandRepeats') {
            return null;
          }
        });
        clone.__expandRepeats = { 
          cloned: true,
          originalId: clone.id,
          sourceId: object.id,
          order: i - 1
        };
        delete clone.repeat;
      }
      /*
      for (let key in clone) {
        // TODO: Probably a way to make this type safe.
        let val: any = clone[key];
        if (Array.isArray(val) && val.length > 0 && typeof val[0] === 'object' && val[0] !== null) {
          clone[key] = expandRepeats(val, `${tag}-`, repeatAdd) as any;
        }
      }
      */
      expanded.push(clone)
    }
  }
  return expanded
}

export function findSourceStore(
  sources: CardsDataSourceRegistration[],
  clazz: string,
  tags?: string[]
): Readable<any> {
  if (!sources) {
    return null
  }
  let reg = sources.find((source) => source?.spec?.class === clazz)
  return reg ? reg.store : null
}

export function getFirstSourceStore(
  sources: CardsDataSourceRegistration[],
  tags?: string[]
): Readable<any> {
  if (!sources) {
    return null
  }
  let reg = sources.find((source) => !!source.spec)
  return reg ? reg.store : null
}

export function getHrefForMediaItem(
  type?: string,
  id?: string,
  slug?: string
): string {
  if (!type || (!id && !slug)) {
    return '#'
  }
  let suffix = ''
  if (slug) {
    suffix = `/${encodeURIComponent(slug)}`
  } else {
    suffix = `?mediaId=${encodeURIComponent(id)}`
  }
  let ret = '#';
  switch (type.toUpperCase()) {
    case 'N':
    case 'B':
      ret = `NewsReader${suffix}`
      break;
    case 'V':
    case 'Y':
      ret = `VideoPlayer${suffix}`
      break;
    case 'G':
      ret = `PhotoViewer${suffix}`
      break;
    case 'A':
      ret = `AudioPlayer${suffix}`
      break;
    default:
      ret = '#'
      break;
  }
  return ret;
}

export function getHrefForMatchCenter(gameId: string, slug?: string): string {
  if (!gameId && !slug) {
    return '#'
  }
  let suffix = ''
  if (slug) {
    suffix = `/${encodeURIComponent(slug)}`
  } else {
    suffix = `?gameId=${encodeURIComponent(gameId)}`
  }
  return `MatchCenter${suffix}`
}

export function getTeamLogoFallback(league?: string) {
  league = league || CONFIG.league
  return `https://resources-us.yinzcam.com/${league.toLowerCase()}/shared/logos/${league.toUpperCase()}_placeholder.png`
}

export function getTeamLogoFromLeagueAndTricode(
  league: string,
  tricode: string
) {
  // TODO: make this work for other leagues
  // https://resources-us.yinzcam.com/csf/shared/logos/CSF_ADC.png
  if (!league || !tricode) {
    return getTeamLogoFallback(league)
  }

  if (league.toLowerCase() === 'esp') {
    league = 'lfp'
  }

  return `https://resourceslfp.azureedge.net/${league.toLowerCase()}/shared/logos/esp_${tricode.toLowerCase()}_dark.png`
}

export function getTeamLogoFromTricode(tricode: string) {
  return getTeamLogoFromLeagueAndTricode(CONFIG.league, tricode)
}

export function getTeamLogoFromLogoId(logoId: string) {
  let leagueTricode = (logoId || '').split('_')
  if (!Array.isArray(leagueTricode) || leagueTricode.length < 2) {
    return getTeamLogoFallback()
  }
  return getTeamLogoFromLeagueAndTricode(leagueTricode[0], leagueTricode[1])
}

export function getTeamLogoFromTeamObject(obj: any) {
  if (!obj || !obj._attributes) {
    return getTeamLogoFallback()
  }
  if (obj._attributes.LogoId) {
    return getTeamLogoFromLogoId(obj._attributes.LogoId)
  }
  if (obj._attributes.TriCode) {
    return getTeamLogoFromTricode(obj._attributes.TriCode)
  }
  return getTeamLogoFallback()
}

export function createAnalyticsPageContext(analyticsData?: { path?: string, name?: string, content?: string }, params?: { [key: string]: string } ) {
  const data = expandTemplateParamsRecursive(analyticsData, params);
  const args = [data?.path, data?.name, data?.content];
  return getToken(ROOT, JanusAnalyticsManagerToken).createPageContext(...args);
}

export function getTemplatedElementDataFromSource(element: YinzCamCardsServiceElement, dataFieldName: string, source: any) {
  try {
    const dataFieldValue = element?.data?.[dataFieldName];
    
    if (_.isNumber(dataFieldValue) || _.isArray(dataFieldValue) || _.isObject(dataFieldValue)) {
      return dataFieldValue;
    }

    // If we can't find any string value for the field, return null
    if (!_.isString(dataFieldValue)) {
      return null;
    }
    // If the value doesn't include a template parameter, assume it's meant as a static value and just return that
    if (!dataFieldValue.includes('<%')) {
      return dataFieldValue;
    }
    // If we don't have a source object, we have no hope of resolving the template, so return null
    if (!_.isObjectLike(source)) {
      return null;
    }
    const ret = _.template(dataFieldValue, { 'variable': 'source' })(source);
    console.log('getTemplatedElementDataFromSource', dataFieldName, dataFieldValue, source, ret);
    return ret;
  } catch (e) {
    console.error("getTemplatedElementDataFromSource: failed to expand templated value", e);
    return null;
  }
}

// https://stackoverflow.com/a/67551175
export function blobToDataUri(blob: Blob): Promise<string> {
  console.log('BLOB TO DATA URI', blob);
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = _e => resolve(reader.result as string);
    reader.onerror = _e => reject(reader.error);
    reader.onabort = _e => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });

}

export function getConditionsCheckStore(conditionsStore: Readable<YinzCamCardsServiceConditions> | Readable<{ conditions: YinzCamCardsServiceConditions }>): Readable<boolean> {
  if (!conditionsStore || !windowMetrics) {
    return writable(false);
  }
  
  let signOnStore = signonManager.getStatusComponent().store;
  return derived([conditionsStore, windowMetrics as Readable<any>, langStore, signOnStore], ([$conditionsOrContainer, $wm, $lang, $signOnStore]) => {
    let conditionsMet: boolean = true;
    if (!$conditionsOrContainer) {
      return conditionsMet;
    }
    let $conditions: YinzCamCardsServiceConditions;
    if ("conditions" in $conditionsOrContainer) {
      $conditions = $conditionsOrContainer.conditions;
    } else {
      $conditions = $conditionsOrContainer;
    }
    if (!$conditions) {
      return conditionsMet;
    }
    if (!_.isNil($conditions.isNative)) {
      conditionsMet &&= $conditions.isNative === Device.cordova;
    }
    if (!_.isNil($conditions.isDesktop)) {
      conditionsMet &&= $wm && $conditions.isDesktop === $wm.isDesktop;
    }
    if (!_.isNil($conditions.language)) {
      conditionsMet &&= $conditions.language === $lang;
    }
    if ($conditions.not) {
      conditionsMet = !conditionsMet;
    }
    if ($conditions.loggedIn) {
      conditionsMet &&= $conditions.loggedIn === $signOnStore.loggedIn; 
    }
    console.log ('conditionMet: ', conditionsMet);
    return conditionsMet;
  });
}

export function getOmittedTitle(title: any, length: number = 57) {
  if (!title) {
    return title;
  }

  const str = String(title);
  return str.length > length ? str.substring(0, length) + " ..." : (str || '');
}

export function applyStyles(node: HTMLElement, origObj: (object| string)[] | object | string) {
  let unsubscribers: Unsubscriber[] = [];
  let styles: object[] = [];

  const setStyleAttribute = _.debounce(() => {
    let mergedStyle = _.merge.apply(_, [{}, ...styles]);
    let attrVal = '';
    _.forOwn(mergedStyle, (val, key) => {
      attrVal += `${key}: ${val};`;
    });
    node.setAttribute('style', attrVal);
  }, 100);

  function clearStyleAttribute() {
    node.removeAttribute('style');
  }

  function handleStyleObject(obj: (object| string)[] | object | string) {
    if (unsubscribers) {
      for (const el of unsubscribers) {
        if (el) {
          el();
        }
      }
    }

    if (_.isNil(obj)) {
      clearStyleAttribute();
      return;
    }

    let arr: (object | string)[];
    if (!_.isArray(obj)) {
      arr = [ obj ];
    } else {
      arr = obj;
    }

    if (arr.length === 0) {
      clearStyleAttribute();
      return;
    }

    unsubscribers = new Array(arr.length);
    styles = new Array(arr.length);
    let applyStylesNow = false;
    for (const [i, el] of arr.entries()) {
      if (_.isString(obj)) {
        unsubscribers[i] = cardsService.getStyle(obj).store.subscribe((val) => {
          styles[i] = val;
          setStyleAttribute();
        });
      } else if (_.isPlainObject(obj)) {
        styles[i] = obj;
        applyStylesNow = true;
      } else {
        console.error("handleStyleObject: unrecognized style object", obj)
      }
    }
    if (applyStylesNow) {
      setStyleAttribute();
    }
  }

  const funcs = {
    update: (newObj: (object| string)[] | object | string) => {
      handleStyleObject(newObj);
    },
    destroy: () => {
      handleStyleObject(null);
    }
  };

  funcs.update(origObj);

  return funcs;
}
