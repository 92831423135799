import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import { JSONSchema4 } from "json-schema";

@injectable()
export class YinzCamAppServerAuxiliaryCompetitions extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  public getDisplayName(): string {
    return 'YinzCam Sports Data - Second Team Competitions';
  }

  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    return [
      {
        name: 'Second Team Competitions',
        path: '/Auxiliary/Competitions'
      }
    ];
  }

  public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
    return null;
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;
    return {
      competitions: [].concat(data?.ArrayOfAuxCompetitionXml?.AuxCompetitionXml).filter(obj => obj.Id)
    };
  }
}
