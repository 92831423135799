import { CardsDataSourceBase } from '../../common/CardsDataSourceBase'
import { injectable } from 'inversify'
import { injectToken } from 'inversify-token'
import { YinzCamAPIResponse } from 'yinzcam-api'
import { YinzCamAppServerToken } from 'yinzcam-appserver'
import _ from 'lodash'

@injectable()
export class YinzCamAppServerSearchResultsFilter extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(
    response: YinzCamAPIResponse,
    specData?: { [key: string]: any },
  ): object | any[] {
    const data = response?.data as any

    let options = this.getSearchFilters(data)
    return { options }
  }

  getSearchFilters(data: any): any {
    const filter = []
      .concat(data?.SearchResults?.Filters?.Filter)
      .find((e) => e?._attributes?.QueryParameter === 'type')
    let filterArr = []
      .concat(filter?.FilterItems?.Items)
      .map((item) => {
        let attrs = item?._attributes
        return {
          name: attrs?.Name,
          id: attrs?.Id === "" ? "ALL" : attrs?.Id,
        }
      })

    const summary = data?.SearchResults?.ResultSummaries || []
    const summaryArr = []
      .concat(summary?.ResultSummary)
      .filter((item) => item?._attributes?.Type)
      .map((item) => {
        let attrs = item?._attributes
        return {
          id: attrs?.Type,
          count: attrs?.Count,
        }
      })

    summaryArr.forEach((summary) => {
      let k = filterArr.findIndex((filter) => filter.id == summary.id)
      if (k !== -1) {
        filterArr[k]['count'] = summary.count
      }
    })

    return filterArr
  }
}
