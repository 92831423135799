<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { Popup, Link } from 'framework7-svelte';
import PhotoUploadDialog from './PhotoUploadDialog.svelte';
import { onMount } from 'svelte';
import { JanusSignonManager, JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { getContext } from 'svelte';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const signonManager = getToken(ROOT, JanusSignonManagerToken);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
let profileStore;
$: profileStore = findSourceStore($sources, 'YinzCamSignonServerProfile');
$: profileSource = $sources.find((s) => { var _a; return ((_a = s.spec) === null || _a === void 0 ? void 0 : _a.class) === 'YinzCamSignonServerProfile'; });
$: linkStore = findSourceStore($sources, 'YinzCamSignonServerLinks');
$: entries = profileStore ? $profileStore === null || $profileStore === void 0 ? void 0 : $profileStore.Entry : [];
let profileRefreshed = false;
$: if (profileSource && !profileRefreshed) {
    setTimeout(() => {
        profileSource.refresh();
    }, 0);
    profileRefreshed = true;
}
let data = {};
$: {
    entries === null || entries === void 0 ? void 0 : entries.map(item => {
        data[item.Key] = item.Value;
    });
}
$: console.log('userprofileheader: ', data);
$: firstName = (data === null || data === void 0 ? void 0 : data.first_name) || ($mergedParams || {})['firstName'] || "";
$: lastName = (data === null || data === void 0 ? void 0 : data.last_name) || ($mergedParams || {})['lastName'] || "";
$: email = (data === null || data === void 0 ? void 0 : data.email) || ($mergedParams || {})['email'] || "";
$: image = (data === null || data === void 0 ? void 0 : data.image_url) || (data === null || data === void 0 ? void 0 : data.image_url_custom) || '/static/thumbs/default-avatar.png';
$: linkEnable = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.linkEnable) || false;
$: editable = ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.editable) || false;
let photoPopup, photoPopupOpened;
let photoDialogWrapper, photoDialogHeight;
const popupControl = {
    closePopup: function () {
        photoPopup.close();
    },
};
const photoEdit = (filename, dataUrl, file) => {
    image = dataUrl;
    console.log('croppedFile: ', filename, dataUrl, file);
    onPhotoEditing(filename, dataUrl, file);
    menuOpened = false;
};
// https://stackoverflow.com/a/43449212
function dataURItoBlob(dataURI) {
    var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    //array.length = binary.length;
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
}
const onPhotoEditing = (photoFileName, dataUrl, photoFile) => __awaiter(void 0, void 0, void 0, function* () {
    console.log('photo edit', photoFileName, dataUrl, photoFile);
    image = dataUrl;
    let rsp = yield signonManager.uploadUserProfileImage(dataURItoBlob(dataUrl), photoFileName);
    let photoUrl = rsp.data;
    let profileData = { image_url: photoUrl, image_url_custom: photoUrl };
    let res = yield signonManager.updateUserProfileSegment(profileData);
});
let menuOpened = false;
// test
// $: linked_socials = [
//   {
//     name: 'Ticketmaster',
//     thumb: '/static/icons/icon-ticketmaster.png',
//     background: '#256DD7'
//   },
//   {
//     name: 'Apple',
//     thumb: '/static/icons/icon-apple.png',
//     background: '#000'
//   },
// ];
$: linked_socials = [];
let wrapper;
const getLinkedTexts = (linked_socials) => {
    if (linked_socials.length === 0) {
        return 'Link Account';
    }
    else if (linked_socials.length === 1) {
        return 'Linked Account';
    }
    else {
        return 'Linked Accounts';
    }
};
const openMenu = () => {
    menuOpened = true;
    const parent = wrapper.closest('.wrapper').parentElement;
    parent.style.zIndex = "3";
};
onMount(() => {
    const resizeObserver = new ResizeObserver(() => photoDialogHeight = photoDialogWrapper.clientHeight);
    resizeObserver.observe(photoDialogWrapper);
    profileRefreshed = false;
});
const onCloseMenu = () => {
    menuOpened = false;
    const parent = wrapper.closest('.wrapper').parentElement;
    parent.style.zIndex = "0";
};
const removePhoto = () => __awaiter(void 0, void 0, void 0, function* () {
    image = '/static/thumbs/default-avatar.png';
    let profileData = { image_url: '', image_url_custom: '' };
    let res = yield signonManager.updateUserProfileSegment(profileData);
    onCloseMenu();
});
</script>

<CardBase element={element}>
  <div class="user-profile-header-wrapper" bind:this={wrapper}>
    <div class="main-info">
      <div class="avatar-layout">
        <img src={image} alt="avatar" class="avatar" />
        {#if editable}
          <img src="/static/icons/icon-camera-black.png" 
            alt="edit_photo" 
            class="edit-photo-trigger" 
            on:click={openMenu}
          />
          {#if menuOpened}
            <div class="action-menu">
              <ul>
                <li>
                  <Link  
                    popupOpen=".sso22-photo-edit-popup"
                    class="label primary">
                    Choose From Library
                  </Link>
                </li>
                <li>
                  <Link  
                    popupOpen=".sso22-photo-edit-popup"
                    class="label primary">
                    Take Photo
                  </Link>
                </li>
                <li on:click={removePhoto}>Remove Photo</li>
                <li on:click={onCloseMenu}>Cancel</li>
              </ul>
            </div>
          {/if}
        {/if}
      </div>
      <div class="text-info">
        <span class="name">{`${firstName} ${lastName}`}</span>
        <span class="email">{email}</span>
      </div>
    </div>
    {#if linkEnable}
    <div class="link-info" 
      style="
        margin-left: {linked_socials.length > 0 ? '-30px' : '0px'}; 
      ">
      <div class="linked-socials">
        {#each linked_socials as social}
          <img src={social.thumb} alt="thumb" class="social-icon" style="background: {social.background}" /> 
        {/each}
        {#if linked_socials.length > 0}
        <img src="/static/icons/icon-attach.png" alt="attach" class="attach" />
        {/if}
      </div>
      <span class="link-text" style="color: {linked_socials.length > 0 ? 'rgba(140, 149, 158, 0.5)' : 'var(--alav-s-primary)'}">{getLinkedTexts(linked_socials)}</span>
      <img src="/static/icons/icon-camera.png" alt="camera" class="icon icon-camera" />
      <img src="/static/icons/icon-edit.png" alt="edit" class="icon icon-edit" />
    </div>
    {/if}
  </div>
</CardBase>

<Popup
  bind:this={photoPopup}
  class={'sso22-popup sso22-photo-edit-popup'}
  backdropEl={'#child-popup-backdrop'}
  opened={photoPopupOpened}
  onPopupClosed={() => (photoPopupOpened = false)}
  style="--f7-popup-tablet-height: {photoDialogHeight}px;"
  closeByBackdropClick={true}
  closeOnEscape={true}
>
  <div class="popup-content-wrapper" bind:this={photoDialogWrapper}>
    <i class="f7-icons" on:click={() => popupControl.closePopup()}>xmark</i>
    <PhotoUploadDialog {popupControl} onPhotoEditing={photoEdit} />
  </div>
</Popup>

<style>.user-profile-header-wrapper {
  width: 100%;
  height: 67px;
  background: white;
}

.main-info {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.main-info .avatar {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: block;
}

.avatar-layout {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.edit-photo-trigger {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  bottom: -5px;
  cursor: pointer;
}

.action-menu {
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  width: 254px;
  -webkit-filter: drop-shadow(0px 8px 64px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px 8px 64px rgba(0, 0, 0, 0.1));
  -webkit-backdrop-filter: blur(80px);
          backdrop-filter: blur(80px);
  border-radius: 12px;
  background: rgba(37, 37, 37, 0.5);
  overflow: hidden;
  z-index: 10;
}

.action-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.action-menu ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #FFFFFF;
  padding: 11px 16px;
  border-color: #636363;
  border-style: solid;
  border-left-width: 0;
  border-bottom-width: 0;
  border-right-width: 0;
  cursor: pointer;
}

.action-menu :global(ul li a) {
  padding: 0;
  color: white;
}

.action-menu ul li:not(:first-child):not(:last-child) {
  border-top-width: 1px;
}

.action-menu ul li:last-child {
  border-top-width: 8px;
}

.action-menu ul li:first-child {
  border-top-width: 0;
}

.text-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
}

.text-info .name {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  color: #202020;
}

.text-info .email {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */
  color: #8C959E;
}

.link-info {
  display: flex;
  align-items: center;
  padding: 0px 22px 0 67px;
  margin-top: -20px;
}

.linked-socials {
  display: flex;
  align-items: center;
}

.linked-socials .social-icon {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.linked-socials .attach {
  width: 16px;
  height: 16px;
  margin: 0 2px;
}

.link-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.link-info .icon {
  width: 18px;
  height: 18px;
}

.icon.icon-camera {
  margin-left: auto;
}

.icon.icon-edit {
  margin-left: 10px;
}

:global(.sso22-popup) {
  --f7-popup-tablet-width: 354px;
  width: var(--f7-popup-tablet-width) !important;
  height: auto;
  min-height: var(--f7-popup-tablet-height) !important;
  left: calc(50% - var(--f7-popup-tablet-width)/2);
  top: calc(50% - var(--f7-popup-tablet-height)/2);
  box-shadow: 0px 0px 30px 0px;
  overflow: hidden;
  border-radius: 8px;
}

.popup-content-wrapper {
  width: 100%;
  position: relative;
}

.popup-content-wrapper i {
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 24px;
}

:global(.non-fullscreen-popup-backdrop) {
  width: 150vw;
  height: 150vh;
  margin-left: -50vw;
  margin-top: -50vh;
}

.photo-popup-backdrop {
  background: rgba(0,0,0,0.66667);
}</style>