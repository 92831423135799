import { injectable, Container, optional } from "inversify";
import { injectToken, Token, TokenContainerModule, multiInjectToken } from "inversify-token";
import { YinzCamInjectModule } from 'yinzcam-inject';
import { JanusModeContextManager, JanusModeContextManagerToken } from "./mode";
import { BufferManager, ManualPassthrough, ReactiveMicrocomponent, SimpleSink } from 'yinzcam-rma';
import { BufferedPassthrough } from "yinzcam-rma";
import loader from '@beyonk/async-script-loader';
import _ from "lodash";
import { JanusSignonManager, JanusSignonManagerToken, SignonStatus } from "./sso";
import { lightFormat } from "date-fns";

const JanusAnalyticsStateManagerToken = new Token<JanusAnalyticsStateManager>(Symbol.for("JanusAnalyticsStateManager"));
const JanusAnalyticsServiceProviderToken = new Token<JanusAnalyticsServiceProvider>(Symbol.for("JanusAnalyticsServiceProvider"));

export const JanusAnalyticsManagerToken = new Token<JanusAnalyticsManager>(Symbol.for("JanusAnalyticsManager"));

export const JanusAnalyticsManagerModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    for (const key in CONFIG.analyticsProviderClassNames) {
      const name = CONFIG.analyticsProviderClassNames[key];
      const clazz = (<any>ServiceProviders)[name];
      bindToken(JanusAnalyticsServiceProviderToken).to(clazz);
    }
    bindToken(JanusAnalyticsStateManagerToken).to(JanusAnalyticsStateManager).inSingletonScope();
    bindToken(JanusAnalyticsManagerToken).to(JanusAnalyticsManager).inSingletonScope();
  }));
});

interface JanusAnalyticsEvent {
  meta: {
    page: {
      path: string,
      name: string,
      content?: string
    },
    timestamp: number
  },
  category: string,
  type: string,
  detail?: string
}

export interface JanusAnalyticsPageContext {
  sendPageView();
  sendInteractionEvent(type: string, detail?: string): void;
  validate(path?: string, name?: string, content?: string): boolean;
}

interface JanusAnalyticsServiceProvider {
  connectToManager(eventSource: ReactiveMicrocomponent<JanusAnalyticsEvent>);
}

// this is stored outside of the manager class to avoid circular dependencies
@injectable()
class JanusAnalyticsStateManager {
  public readonly appId: string;
  public readonly eventSource: ManualPassthrough<JanusAnalyticsEvent>;

  public constructor(
    @injectToken(JanusModeContextManagerToken) private readonly modeManager: JanusModeContextManager) {
    this.appId = `${CONFIG.league}_${CONFIG.tricode}`.toUpperCase();
    this.eventSource = new ManualPassthrough({
      name: 'JanusAnalyticsManager:eventSource',
      saveToLocalStorage: false,
      bufferInputs: true
    });
  }
}

@injectable()
export class JanusAnalyticsManager {
  private readonly dummySp: ServiceProviders.DummyJanusAnalyticsServiceProvider;

  public constructor (
    @injectToken(JanusAnalyticsStateManagerToken) private readonly stateManager: JanusAnalyticsStateManager,
    @multiInjectToken(JanusAnalyticsServiceProviderToken) @optional() private readonly serviceProviders: JanusAnalyticsServiceProvider[]) {
    // If service providers were specified connect them to the source; otherwise, connect a dummy provider to drain the source.
    if (!serviceProviders?.length) {
      console.warn("JanusAnalyticsManager: no service providers configured, all analytics events will be discarded");
      this.serviceProviders = [ new ServiceProviders.DummyJanusAnalyticsServiceProvider() ];
    }
    for (const sp of this.serviceProviders) {
      sp.connectToManager(this.stateManager.eventSource);
    }
  }

  public createPageContext(path?: string, name?: string, content?: string): JanusAnalyticsPageContext {
    return new InternalJanusAnalyticsPageContext(this.stateManager, path, name, content);
  }
}

class InternalJanusAnalyticsPageContext implements JanusAnalyticsPageContext {
  public constructor(
    private readonly stateManager: JanusAnalyticsStateManager,
    private readonly path: string,
    private readonly name: string,
    private readonly content?: string) {
      this.path = this.path || location.pathname;
      this.name = this.name || document.title;  
  }

  sendPageView(): void {
    this.postEvent("navigation", "page_view");
  }

  sendInteractionEvent(type: string, detail?: string): void {
    this.postEvent("interaction", type, detail);
  }

  validate(path?: string, name?: string, content?: string): boolean {
    return path === this.path && name === this.name && content === this.content;
  }

  private postEvent(category: string, type: string, detail?: string): void {
    // This gets immediately consumed by downstream buffers.
    let fullEvent: JanusAnalyticsEvent = {
      meta: {
        page: {
          path: this.path,
          name: this.name,
          content: this.content
        },
        timestamp: Date.now()
      },
      category,
      type,
      detail
    };
    this.stateManager.eventSource.setValue(fullEvent);
  }
}

namespace ServiceProviders {
  export class DummyJanusAnalyticsServiceProvider implements JanusAnalyticsServiceProvider {
    private eventSink: SimpleSink<[JanusAnalyticsEvent]> | null;

    connectToManager(eventSource: ReactiveMicrocomponent<JanusAnalyticsEvent>) {
      this.eventSink = new SimpleSink({
        name: `DummyJanusAnalyticsServiceProvider_eventSink`,
        saveToLocalStorage: false
      }, async (e: JanusAnalyticsEvent) => {
        // discard
      }, eventSource);
    }    
  }

  abstract class AbstractBufferingJanusAnalyticsServiceProvider implements JanusAnalyticsServiceProvider {
    private eventBuffer: BufferedPassthrough<JanusAnalyticsEvent> | null;
    private eventSink: SimpleSink<[BufferManager<JanusAnalyticsEvent>]> | null;
    private initialized: boolean;
    private failed: boolean;
    private bufferManager: BufferManager<JanusAnalyticsEvent> | null;
  
    protected constructor(protected readonly name: string) {
      this.eventBuffer = null;
      this.eventSink = null;
      this.initialized = false;
      this.failed = false;
      this.bufferManager = null;
    }
  
    public connectToManager(eventSource: ReactiveMicrocomponent<JanusAnalyticsEvent>) {
      // Connect pipeline
      this.eventBuffer = new BufferedPassthrough({
        name: `AbstractJanusAnalyticsServiceProvider:${this.name}_eventBuffer`,
        bufferMaxLength: 100
      }, eventSource);
      this.eventSink = new SimpleSink({
        name: `AbstractJanusAnalyticsServiceProvider:${this.name}_eventSink`,
        saveToLocalStorage: false
      }, async (bm: BufferManager<JanusAnalyticsEvent>) => {
        this.bufferManager = bm;
        if (this.initialized) {
          await this.eventsAvailable(this.bufferManager.getLength());
        }
      }, this.eventBuffer);
  
      // Initialize pipeline
      this.initialize()
      .then(() => {
        console.info(`AbstractJanusAnalyticsServiceProvider:${this.name}: initialized successfully`);
        this.initialized = true;
      })
      .catch((reason) => {
        console.error(`AbstractJanusAnalyticsServiceProvider:${this.name}: initialization failed - events will not be processed by this service provider`, reason);
        this.failed = true;
      })
      .finally(() => {
        if (this.bufferManager && !this.failed) {
          this.eventsAvailable(this.bufferManager.getLength());
        }
      });
    }
  
    protected consumeAllEvents(): JanusAnalyticsEvent[] {
      return (this.bufferManager)? this.bufferManager.consume() : [];
    }
  
    protected abstract initialize(): Promise<void>;
  
    protected abstract eventsAvailable(count: number): Promise<void>;
  }
  
  @injectable()
  export class GoogleAnalyticsServiceProvider extends AbstractBufferingJanusAnalyticsServiceProvider {
    private static readonly GA_LOCAL_STORAGE_KEY = 'ga:clientId';
  
    private readonly scriptScheme: string;
    private readonly properties: string[];
    private readonly configurations: any;
  
    public constructor() {
      super('GoogleAnalytics');
      this.scriptScheme = 'https://';
      this.properties = []; //['UA-188593099-1'];
      this.configurations = {};
    }
  
    protected initialize(): Promise<void> {
      function test() {
        //console.log(`GoogleAnalyticsServiceProvider.process: test`);
        const ga = window['ga'];
        return Array.isArray(ga?.q);
      }
    
      function callback(resolve, reject) {
        try {
          this.properties.forEach(p => {
            let config = this.configurations[p] || {};
            config['storage'] = 'none';
            config['clientId'] = localStorage.getItem(GoogleAnalyticsServiceProvider.GA_LOCAL_STORAGE_KEY);
            const ga = window['ga'];
            ga('create', p, config);
            ga(function (tracker) {
              localStorage.setItem(GoogleAnalyticsServiceProvider.GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
            });
            ga('set', 'checkProtocolTask', function () { /* nothing */ });
            resolve();
            /*
            let page = path;
            if (params) {
              page = `${path}?${new URLSearchParams(params).toString()}`;
            }
            ga('set', 'page', page);
            ga('send', 'pageview');
            */
          });
        } catch (e) {
          reject(e);
        }
      }
  
      window['ga'] = window['ga'] || function() {
        (window['ga'].q = window['ga'].q || []).push(arguments);
      };
      window['ga'].l = window['ga'].l || Date.now()
      return new Promise<void>((resolve, reject) => {
        loader([{
          type: 'script',
          url: `${this.scriptScheme}www.google-analytics.com/analytics.js`
        }], test, callback.bind(this, resolve, reject));
      });
    }
  
    protected async eventsAvailable(count: number): Promise<void> {
      const events = this.consumeAllEvents();
      // NOT IMPLEMENTED!    
    }
  }

  interface GoogleTagManagerDataObject {
    [key: string]: string | GoogleTagManagerDataObject;
  }
  
  @injectable()
  export class GoogleTagManagerServiceProvider extends AbstractBufferingJanusAnalyticsServiceProvider {
    private readonly scriptScheme: string;
    protected readonly containerId: string;
    protected readonly dataLayer: any[];
    protected readonly dataObject: GoogleTagManagerDataObject;
  
    public constructor(private readonly pushFullDataObject = false, private readonly expandDotNotationKeys = false) {
      super('GoogleTagManager');
      this.scriptScheme = '//'; // could override to https:// for use in Cordova, but better to use Cordova plugin instead?
      this.containerId = CONFIG.gtmContainerId; //'GTM-TLJ6SCM';
      this.dataLayer = [];
      this.dataObject = {};
    }

    protected updateDataLayer(vars: any) {
      const updateObj = (this.pushFullDataObject)? this.dataObject : {};
      for (let key in vars) {
        if (this.expandDotNotationKeys && key.includes('.')) {
          const parts = key.split('.');
          const obj = parts.slice(0, -1).reduce((o, i) => o[i] = o[i] || {}, updateObj);
          obj[parts[parts.length - 1]] = vars[key];
        } else {
          updateObj[key] = vars[key];
        }
      }
      //console.log('UPDATE DATA LAYER', JSON.stringify(updateObj, null, 2));
      this.dataLayer.push(updateObj);
    }
  
    protected async initialize(): Promise<void> {
      const test = () => {
        return _.isObjectLike((<any>window).google_tag_manager);
      }
    
      const callback = (resolve, reject) => {
        try {
          this.initializeGoogleTagManager().then(resolve).catch(reject);
        } catch (e) {
          console.error(`GoogleTagManagerServiceProvider.process: failed to initialize`, e);
          reject(e);
        }
      }
  
      const l = 'dataLayer';
      const w = window;
      w[l] = this.dataLayer;
      this.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
      await this.initializeDataLayer();
      return new Promise<void>((resolve, reject) => {
        loader([{
          type: 'script',
          url: `${this.scriptScheme}www.googletagmanager.com/gtm.js?id=${this.containerId}`
        }], test, callback.bind(this, resolve, reject));
      });
    }
  
    // for subclasses to override with their own data layer code
    protected async initializeDataLayer(): Promise<void> {
      // Do nothing
    }

    protected async initializeGoogleTagManager(): Promise<void> {
      // Do nothing
    }
  
    protected async eventsAvailable(count: number): Promise<void> {
      const events = this.consumeAllEvents();
      for (const event of events) {
        try {
          await this.processEvent(event);
        } catch (e) {
          console.error('GoogleTagManagerServiceProvider.eventsAvailable: exception in processEvent', e);
        }
      }
    }

    protected async processEvent(event: JanusAnalyticsEvent): Promise<void> {
      switch (event.category) {
        case "navigation":
          switch (event.type) {
            case "page_view":
              this.updateDataLayer({
                "event": "pageviewCustomEvent",
                "pagePath": event.meta.page.path,
                "pageTitle": event.meta.page.name,
                "pageContent": event.meta.page.content
              });
              break;
          }
      }
    }
  }
  
  @injectable()
  export class LaLigaGoogleTagManagerServiceProvider extends GoogleTagManagerServiceProvider {
    private static readonly MILLISECONDS_PER_SECOND = BigInt(1000);
    private static readonly TICKS_PER_MILLISECOND = BigInt(10000);
    private static readonly TICKS_0AD_TO_1970AD = BigInt(62135769600)
      * LaLigaGoogleTagManagerServiceProvider.MILLISECONDS_PER_SECOND
      * LaLigaGoogleTagManagerServiceProvider.TICKS_PER_MILLISECOND;

    private readonly sinks: SimpleSink[];
    private sequenceNumber: number;

    public constructor(
      @injectToken(JanusModeContextManagerToken) private readonly contextManager: JanusModeContextManager,
      @injectToken(JanusSignonManagerToken) private readonly signonManager: JanusSignonManager) {
      super(true, true);
      this.sinks = [];
    }

    protected async initializeDataLayer(): Promise<void> {
      await super.initializeDataLayer();

      this.updateDataLayer({'technical.clientId': CONFIG.dspClientId});

      //this.updateDataLayer({'appVersion': CONFIG.version});
      this.updateDataLayer({'technical.appVersion': this.containerId});

      this.updateDataLayer({'technical.hostName': location.hostname});

      const dspGuestId = await this.signonManager.getDSPGuestId();
      this.updateDataLayer({'user.guestId': dspGuestId });

      const nowLocal = new Date();
      const nowUtc = new Date(nowLocal.getTime() + nowLocal.getTimezoneOffset()*60000);
      const dateFormatted = lightFormat(nowUtc, 'yyyyMMddHHmmss');
      const sessionId = `${dspGuestId}_${dateFormatted}`;
      this.updateDataLayer({'user.sessionId': sessionId });
      this.sequenceNumber = 0;

      this.sinks.push(new SimpleSink('LaLigaGoogleTagManagerServiceProvider_signonStatusSink', async (ss: SignonStatus) => {
        this.updateDataLayer({'user.userType': (ss?.loggedIn)? 'Registered' : 'Guest'});
      }, this.signonManager.getStatusComponent()));

      this.sinks.push(new SimpleSink('LaLigaGoogleTagManagerServiceProvider_userIdSink', async (ss: SignonStatus, prof: { [key: string]: string }) => {
        const hasIdGlobal = ss?.loggedIn && prof?.['id_global'];
        this.updateDataLayer({'user.userId': (hasIdGlobal)? prof?.['id_global'] : dspGuestId});
        this.updateDataLayer({'user.registeredUserId': (hasIdGlobal)? prof?.['id_global'] : null});
      }, this.signonManager.getStatusComponent(), this.signonManager.getDSPProfileSegmentComponent()));

      this.sinks.push(new SimpleSink('LaLigaGoogleTagManagerServiceProvider_languageSink', async (lang: string) => {
        this.updateDataLayer({'content.language': lang});
      }, this.contextManager.getLanguageComponent()));

      // I guess these need to be blank for now
      this.updateDataLayer({
        "video.videoId": "",
        "video.videoName": "",
        "video.videoPlayer": "",
        "video.videoCurrentTime": "",
        "video.videoDuration": "",
        "video.videoCastOrMirrorScreen": "",
        "video.videoIsLive": "",
        "video.videoQuality": "",
        "video.videoAction": ""
      });

      // TODO: wire this up to game box
      this.updateDataLayer({
        "custom.matchStatus": ""
      });
    }

    protected async processEvent(event: JanusAnalyticsEvent): Promise<void> {
      const self = LaLigaGoogleTagManagerServiceProvider;
      let update: any = {
        "content.eventTime": new Date(event.meta.timestamp).toISOString(),
        "content.eventSequence": ++this.sequenceNumber,
        "technical.ticks": ((BigInt(event.meta.timestamp)*self.TICKS_PER_MILLISECOND) + self.TICKS_0AD_TO_1970AD).toString(10),
        "content.pageName": event.meta.page.name
      };
      if (_.isString(event.meta.page.path)) {
        const parts = event.meta.page.path.split("/");
        let i = 1;
        for (const part of parts) {
          if (i > 9) break;
          if (!part) continue;
          update[`content.hierarchy${i++}`] = part;
        }  
      }
      switch (event.category) {
        case "navigation":
          switch (event.type) {
            case "page_view":
              update["content.VirtualPage"] = event.meta.page.path;
              break;
            default:
              return super.processEvent(event);
          }
          break;
        case "interaction":
          update["content.eventCategory"] = "Interaccion";
          update["content.eventAction"] = event.type;
          if (event.detail) update["content.eventLabel"] = event.detail;
          break;
        default:
          return super.processEvent(event);
      }
      this.updateDataLayer(update);
    }
  }
}