import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";
import { JSONSchema4 } from "json-schema";

@injectable()
export class YinzCamAppServerGameListNoFilter extends CardsDataSourceBase {
  private chosenLastPlayedMatch: boolean = false;
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  public getDisplayName(): string {
    return 'YinzCam Sports Data - Game List';
  }

  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    return [
      {
        name: 'Game List',
        path: '/Game/List'
      }
    ];
  }

  public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
    return null;
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
   
    const team = {
      FullName: "Deportivo Alavés",
      Id: "11",
      LogoId: "ESP_ALA",
      Name: "Alavés",
      TeamPageAvailable: "false",
      TriCode: "ALA"
    };

    let games = [].concat(data?.GameList?.GameSection?.Game).filter(item => item?._attributes?.Id);

    games = games.map((game) => {
      game['Team']={"_attributes": team};
      return game;
    });

    games =  games.reverse();
    let playedGames = games.filter(item => item._attributes.Type === 'F');
    let LGames = games.filter(item => item._attributes.Type === 'C');
    let SGames = games.filter(item => item._attributes.Type === 'S');
    let lastPlayedGame = playedGames.shift();
    let resultGames = [].concat(lastPlayedGame ? [lastPlayedGame] : []).concat(LGames ? LGames : []).concat(SGames ? SGames : []).concat(playedGames ? playedGames : []);

    return resultGames;
  }
}
