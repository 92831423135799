<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { JanusSignonManager, JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import { onMount } from 'svelte/internal';
const signonManager = getToken(ROOT, JanusSignonManagerToken);
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
$: key = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.key;
$: text = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.text;
let checked = false;
const onClick = (e) => {
    console.log('value: ', checked, checked + "");
    tabContext.setParam(key, checked + "");
};
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    const profileData = yield signonManager.getUserProfileSegment();
    const data = (profileData === null || profileData === void 0 ? void 0 : profileData.data) || {};
    let emailOptin = data.email_optin;
    let smsOptin = data.sms_optin;
    checked = data[key] === "true";
    console.log('optin: ', data, data[key]);
    tabContext.setParam(key, checked + "");
}));
</script>
  <CardBase {element}>
    <div class="sms-optin-wrapper">
      <div class="round">
        <input type="checkbox" id={key} bind:checked on:change={onClick} />
        <label for={key} />
      </div>
      <label for={key} class="text">
          {@html text}
      </label>
    </div>
  </CardBase>
  <style>.sms-optin-wrapper {
  display: flex;
  align-items: flex-start;
}

.sms-optin-wrapper :global(.link) {
  text-decoration: none;
  color: var(--alav-s-primary);
}

.text {
  text-align: left;
  margin-left: 11px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #898989;
}

.round {
  position: relative;
  height: 18px;
  width: 18px;
}

.round label {
  background-color: transparent;
  border: 1px solid #D8D8D8;
  border-radius: 6px;
  cursor: pointer;
  position: absolute;
  top: 0; left: 0;
  height: 18px;
  width: 18px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
  height: 5px;
  width: 9px;
  top: 3px;
  left: 3px;
}

.round input[type="checkbox"] {
  visibility: hidden;
  margin: 0;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--alav-s-primary);
  border-color: var(--alav-s-primary);
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}</style>
  