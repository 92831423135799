<F7App params={ f7params } >
  <!-- Tailwind import for styling -->
  <!--<Tailwind/>-->

  <!-- NOTE: YCTopBar is added within YCPage -->

  <!-- Right panel -->
  <!--<YCRightPanel/>-->
  <LaLigaPanel/>

  <!-- App views, note that static bottom bar has to be below the main view. -->
  <Views>
    <!-- For Framework7 v6 -->
    <!-- <View main class="safe-areas" url="/" browserHistory={!Device.cordova} browserHistoryInitialMatch={!Device.cordova} /> -->
    <View main class="safe-areas" url="/" pushState={!Device.cordova} pushStateSeparator="" pushStateRoot={new URL(window.location).origin}
      animate={enablePageTransitions} preloadPreviousPage={enablePageTransitions} iosSwipeBack={enablePageTransitions} mdSwipeBack={enablePageTransitions} auroraSwipeBack={enablePageTransitions} />
    <!-- <YCBottomBar/> -->
  </Views>

  <div class="popup-backdrop cards-editor-popup-backdrop" />
</F7App>

<script>
  import { onMount } from 'svelte';
  import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
  import { f7, f7ready, App as F7App, Views, View, Popup, Page, Navbar, NavRight, Link, Block } from 'framework7-svelte';
  import cordovaApp from '../js/cordova-app';
  import routes from '../js/routes';
  //import Tailwind from './Tailwind.svelte';
  import YCRightPanel from './YCRightPanel.svelte';
  import YCBottomBar from './YCBottomBar.svelte';
  import { getToken } from 'inversify-token';
  import ROOT from '../inversify.config';
  import { JanusPushManagerToken } from '../js/push';
  import { JanusInternationalizationManagerToken } from '../js/i18n';
  import { resolveUrl } from '../js/url';
  import LaLigaPanel from './LaLigaPanel.svelte';
  import { currentRoute } from '../js/routes';

  const pushManager = getToken(ROOT, JanusPushManagerToken);
  const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);

  const enablePageTransitions = !CONFIG.disablePageTransitions;

  // init internationalization early
  i18nManager.init();

  // Framework7 Parameters
  let f7params = {
    id: 'com.copa.libertadores.livescores', // App bundle ID
    name: 'CONMEBOL Libertadores', // App name
    theme: 'auto', // Automatic theme detection

    // App routes
    routes: routes,

    // Register service worker
    serviceWorker: Device.cordova ? {} : {
      path: '/service-worker.js',
    },
    // Input settings
    input: {
      scrollIntoViewOnFocus: Device.cordova && !Device.electron,
      scrollIntoViewCentered: Device.cordova && !Device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
      iosOverlaysWebView: true,
      androidOverlaysWebView: false,
    },
    panels3d: {
      enabled: true,
    },
    panel: {
        swipe: 'right',
    },
    navbar: {
      iosCenterTitle: true,
      mdCenterTitle: true,
      auroraCenterTitle: true,
    },
    stackPages: true
  };
  // Login screen demo data
  let username = '';
  let password = '';

  function alertLoginData() {
    f7.dialog.alert('Username: ' + username + '<br>Password: ' + password, () => {
      f7.loginScreen.close();
    });
  }

  onMount(() => {
    f7ready(async () => {

      // Init cordova APIs (see cordova-app.js)      
      if (Device.cordova) {
        cordovaApp.init(f7);
      }

      pushManager.handleAppLaunch();

      f7.view.main.router.on('routeChange', (newRoute) => {
        //console.log('ROUTE CHANGE', newRoute);
        currentRoute.set(newRoute);
      });
    });
  })
</script>