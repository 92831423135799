<script lang="ts">import { YinzCamCardsServiceArrayLayout, YinzCamCardsServiceElement } from 'yinzcam-cards';
import { generateSequenceId } from '../utilities';
import CardsElement from './CardsElement.svelte';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { Swiper } from 'swiper/svelte';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/effect-cube/effect-cube.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import 'swiper/components/effect-flip/effect-flip.scss';
import _ from 'lodash';
import { afterUpdate } from 'svelte';
import { afterUpdateDebounce } from "../../../js/utilities";
import { getContext } from "svelte";
import { CardsPageContext, CardsPageContextKey } from './context';
import { CardsEditorContainerOptions } from "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
export let parentSequenceId = '';
export let layout;
export let contentAvailable = true;
export let updater;
// TODO: This is kind of crappy to have in all of these components
// just because it needs to change its layout in outline mode. Is there a better way?
const pageContext = getContext(CardsPageContextKey);
const emmEnabled = pageContext.editorModeManager.getEnabledStore();
const emmPreview = pageContext.editorModeManager.getPreviewStore();
let swiperComponent;
let childContentAvailable = {};
// NOTE: If in the future we have trouble with contentActive during element insertion/removal, it may be due to
// childContentActive being an array. During insertions, the existing array position (at insertion) is reused,
// and the element at the end of the list will get pushed an "undefined" contentActive value through its
// contentAvailable binding since it's beyond the end of the list. Svelte won't push an update to that
// value from the child to parent because nothing has changed inside that element (it just got moved).
// This was fixed for childContentAvailable by using a map and the element's ID, but for contentActive
// that would need a rewrite of logic since contentActive relies on the sequence of elements.
let childContentActive = [];
let hasScrolledToActive = false;
let whenFirstContentAvailable;
let backgroundFilter;
let outlineMode;
$: outlineMode = $emmEnabled && !$emmPreview;
$: {
    contentAvailable = Object.values(childContentAvailable).some((item) => item);
}
$: allowScrollToActive = !_.isNil(layout.scrollToActive)
    ? layout.scrollToActive
    : true;
$: maxElements = (layout === null || layout === void 0 ? void 0 : layout.maxElements) || Number.MAX_SAFE_INTEGER;
afterUpdate(afterUpdateDebounce(() => {
    let swiperInstance = swiperComponent === null || swiperComponent === void 0 ? void 0 : swiperComponent.swiper();
    if (!whenFirstContentAvailable && contentAvailable) {
        whenFirstContentAvailable = Date.now();
    }
    if (swiperInstance && swiperInstance.$el) {
        swiperInstance.slideTo(0);
        if (allowScrollToActive &&
            !hasScrolledToActive &&
            contentAvailable &&
            Date.now() - whenFirstContentAvailable < 1000) {
            let firstContentActiveIndex = childContentActive.indexOf(true);
            if (firstContentActiveIndex >= 0) {
                swiperInstance.slideTo(firstContentActiveIndex, 0);
                hasScrolledToActive = true;
            }
        }
        if (layout.swiperNavigation) {
            swiperInstance.on('slideChange', () => {
                controlNavigationButton();
            });
        }
        swiperInstance.update();
    }
}));
$: onPrev = () => {
    let swiperInstance = swiperComponent === null || swiperComponent === void 0 ? void 0 : swiperComponent.swiper();
    swiperInstance.slidePrev(layout.scrollSpeed || 400);
};
$: onNext = () => {
    let swiperInstance = swiperComponent === null || swiperComponent === void 0 ? void 0 : swiperComponent.swiper();
    swiperInstance.slideNext(layout.scrollSpeed || 400);
};
let btnPrev, btnNext;
$: controlNavigationButton = () => {
    if (layout.swiperNavigation) {
        let swiperInstance = swiperComponent === null || swiperComponent === void 0 ? void 0 : swiperComponent.swiper();
        let perGroup = layout.maxColumns > 1 ? Math.ceil(layout.elements.length / layout.maxColumns) : layout.elements.length;
        if (perGroup === layout.elements.length) {
            btnPrev.classList.remove('hidden');
            btnNext.classList.remove('hidden');
        }
        if (swiperInstance.progress === 0) {
            btnPrev.classList.add('hidden');
            btnNext.classList.remove('hidden');
        }
        else if (swiperInstance.progress === 1) {
            btnNext.classList.add('hidden');
            btnPrev.classList.remove('hidden');
        }
        else {
            btnPrev.classList.remove('hidden');
            btnNext.classList.remove('hidden');
        }
    }
};
$: backgroundFilter = layout.backgroundFilter;
let paginationOffset = layout.paginationOffset || '0px';
let pagination_background_color = layout.paginationBackgroundColor || 'var(--yc-theme-color-background)';
let pagination_active_color = layout.paginationActiveColor || 'var(--yc-theme-color-active)';
$: padding = (layout === null || layout === void 0 ? void 0 : layout.swiperPadding) || { top: '0px', right: '0px', bottom: '0px', left: '0px' };
function elementUpdater(i, element) {
    layout.elements[i] = element;
    updater(layout.elements);
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    forceHorizontalScrollbar: true,
    updater
};
$: console.log('swiper layoutelement: ', layout === null || layout === void 0 ? void 0 : layout.elements);
$: console.log('swiper maxElement: ', layout === null || layout === void 0 ? void 0 : layout.maxElements);
$: console.log('swiper contentAvailable: ', childContentAvailable);
</script>

<Boundary>
  {#if outlineMode}
    <CardsContainerWrapper style="width: 100%; overflow-x: scroll; display: grid; grid-auto-flow: column; grid-auto-columns: 200px; grid-column-gap: 10px; justify-content: safe center;" {editorOptions}>
      {#each layout.elements as element, i (element.id)}
        <CardsElement
          {parentSequenceId}
          sequenceId={generateSequenceId('ELT', parentSequenceId, i)}
          {element}
          height="100%"
          bind:contentAvailable={childContentAvailable[element.id]}
          bind:contentActive={childContentActive[i]}
          updater={elementUpdater.bind(null, i)}
        />
      {/each}
    </CardsContainerWrapper>
  {:else}
  <div
    class="swiper-layout"
    class:pagination={!!layout.swiperPagination}
    style="--yinzcam-cards-array-swiper-layout-slide-width:{(layout.maxColumns)? 'auto' : (layout.slideWidth || '40%')};
      --pagination-active-color:{pagination_active_color};
      --pagination-background-color: {pagination_background_color};
      --pagination-offset: {paginationOffset};
      mask-image: {backgroundFilter};
      -webkit-mask-image: {backgroundFilter};
      --padding-top: {typeof padding.top === 'string' ? padding.top : (padding.top + 'px')};
      --padding-right: {typeof padding.right === 'string' ? padding.right : (padding.right + 'px')};
      --padding-bottom: {typeof padding.bottom === 'string' ? padding.bottom : (padding.bottom + 'px')};
      --padding-left: {typeof padding.left === 'string' ? padding.left : (padding.left + 'px')};
      min-height: {layout.minHeight};
      max-height: {layout.maxHeight};
      "
  >
    <Swiper
      bind:this={swiperComponent}
      navigation={false}
      pagination={!!layout.swiperPagination}
      speed={layout.scrollSpeed || 400}
      slidesPerView={layout.maxColumns || 'auto'}
      spaceBetween={layout.gapPixels || 10}
      effect={layout.swipeEffect || 'slide'}
      freeMode={!layout.slideSnap}
      observer={true}
      observeSlideChildren={false}
      centerInsufficientSlides={false}
      slidesPerGroup={layout.maxColumns || 1}
    >
      {#each layout.elements as element, i (element.id)}
        <div
          class="swiper-slide"
          class:display-none={i >= maxElements || childContentAvailable[element.id] === false}
        >
          <CardsElement
            {parentSequenceId}
            sequenceId={generateSequenceId('ELT', parentSequenceId, i)}
            {element}
            height="100%"
            bind:contentAvailable={childContentAvailable[element.id]}
            bind:contentActive={childContentActive[i]}
            updater={elementUpdater.bind(null, i)}
          />
        </div>
      {/each}
    </Swiper>
    {#if !!layout.swiperNavigation}
      <div class="btn-navigation" class:show-only-hover={layout.swiperNavigationOnHover}>
        <div class="btn btn-prev hidden" on:click={onPrev} bind:this={btnPrev} style="margin-left: {layout.swiperNavigationOffset || "-80px"}">
          <i class="f7-icons">chevron_left</i>
        </div>
        <div class="btn btn-next" on:click={onNext} bind:this={btnNext} style="margin-right: {layout.swiperNavigationOffset || "-80px"}">
          <i class="f7-icons">chevron_right</i>
        </div>
      </div>
    {/if}
  </div>
  {/if}
</Boundary>

<style>/*
  *, *::before, *::after {
    box-sizing: border-box;
  }
  */

.swiper-layout {
  position: relative;
}

.btn-navigation {
  position: absolute;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top:  calc((50% - 28px - var(--padding-bottom)/2 - var(--padding-top)/2 ));
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.btn-navigation .btn {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  border: none;
  background: #b0b8bf;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-navigation .btn:hover {
  background: #818b94;
}

/* .btn-navigation .btn-prev {
    margin-left: -80px;
  }
  .btn-navigation .btn-next {
    margin-right: -80px;
  } */

.btn-navigation .btn i {
  font-size: 28px;
}

:global(.hidden) {
  visibility: hidden !important;
}

:global(.swiper-container) {
  padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
}

.swiper-layout :global(.swiper-slide) {
  width: var(--yinzcam-cards-array-swiper-layout-slide-width);
  /* this is important so that each slide fills the parent div's vertical space */
  height: initial !important;
}

@media (max-width: 768px) {
  .swiper-layout :global(.swiper-slide) {
    width: var(--yinzcam-cards-array-swiper-layout-slide-width) !important ;
    max-width: 100vw;
    /* this is important so that each slide fills the parent div's vertical space */
    height: initial !important;
  }
}

:global(.swiper-pagination.swiper-pagination-bullets) {
  bottom: var(--pagination-offset) !important;
}

:global(.swiper-pagination .swiper-pagination-bullet) {
  background: var(--pagination-background-color);
}

:global(.swiper-pagination .swiper-pagination-bullet-active) {
  background: var(--pagination-active-color);
}

.show-only-hover.btn-navigation {
  visibility: hidden;
}

.swiper-layout:hover .show-only-hover.btn-navigation {
  visibility: visible;
}</style>
