import { YinzCamSoccerAppServer } from './YinzCamSoccerAppServer';
import { YinzCamAPI, YinzCamAPIRequest } from 'yinzcam-api';
import { register } from 'yinzcam-registry';
import { APP_SERVER_REGISTRY } from './constants';
import { YinzCamAppServer } from './YinzCamAppServer';

@register(APP_SERVER_REGISTRY, 'yc')
export class YinzCamSSOServer extends YinzCamAppServer {
  
  public constructor(api: YinzCamAPI) {
    console.log ('yinzcam yc server: ', api)
    super(api, '');
  }

}
