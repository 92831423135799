<Boundary>
  <CardsComponentWrapper style="
    {buildCssPadding(section.padding)}
    {buildCssMargin(section.margin)}
    {buildCssBackground(section.background)}
    {buildCssScrollContainer(section.height)}
  " {editorOptions}>
    <CardsColumns parentSequenceId={sequenceId} columns={section.columns} updater={(columns) => section.columns = columns} />
  </CardsComponentWrapper>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { YinzCamCardsServiceSection } from "yinzcam-cards";
import { buildCssBackground, buildCssMargin, buildCssPadding, buildCssScrollContainer } from "../utilities/index";
import CardsColumns from "./CardsColumns.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import { CardsEditorComponentOptions } from "./CardsEditorInterfaces";
import { CARDS_SECTION_SCHEMA } from "./CardsEditorComponentSchemata";
export let parentSequenceId = null;
export let sequenceId = null;
export let section;
export let updater;
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: section.id,
    component: section,
    componentTypeName: 'Section',
    primaryColor: '#19DEC6',
    reverseTextColor: 'black',
    childrenKey: 'columns',
    configSpec: CARDS_SECTION_SCHEMA,
    allowChildren: true,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    updater
};
/*
function buildColumnsFromContent(sectionId: string, content: YinzCamCardsServiceArray[]): YinzCamCardsServiceColumn[] {
  if (!sectionId || !_.isArray(content)) {
    return [];
  }
  return [{
    id: `${sectionId}_AUTO_COLUMN`,
    width: 100,
    content
  }];
}

$: columns = (_.isArray(section?.columns))? section.columns : buildColumnsFromContent(section?.id, section?.content);
*/
</script>
