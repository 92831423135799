import { YinzCamAPI, YinzCamAPIResponse } from 'yinzcam-api';
import { AbstractReactiveMicrocomponent, ReactiveMicrocomponent } from 'yinzcam-rma';
import { CardsDataSourceOutput } from './CardsDataSourceOutput';

export abstract class CardsDataSourceComponent extends AbstractReactiveMicrocomponent<CardsDataSourceOutput, [YinzCamAPIResponse]> {
  public constructor(name: string, input: ReactiveMicrocomponent<YinzCamAPIResponse>) {
    super({ name, saveToLocalStorage: false }, input);
  }

  public abstract refresh(): void;
}
