import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamSignonServerToken } from "yinzcam-signon";

@injectable()
export class YinzCamSignonServerMemberCards extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamSignonServerToken) server) {
    super({ server, defaultPath: '/member' });
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;    
    return [].concat(data?.Member?.Cards).filter(card => card.Id);
  }
}
