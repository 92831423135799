import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";

@injectable()
export class YinzCamAppServerPhaseListFilter extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    let options = this.getPhases(data);
    let secondaryOptions = this.getCompetitionOptions(data);
    let currentPhase = this.getCurrentPhase(data);
    return { options, secondaryOptions, currentPhase };
  }
  
  getPhases(data: any): any {
    let filter = [].concat(data?.PhaseList?.Filters?.Filter).find(e => e?._attributes?.QueryParameter === 'phase');
    let phases = [].concat(filter?.FilterItems?.Items).filter(item => item?._attributes?.Id).map(item => {
        let attrs = item?._attributes;
        return {
          title: attrs?.Name,
          value: attrs?.Id
        };
      });
    return phases;
  }

  getCompetitionOptions(data: any): any {
    let filter = [].concat(data?.PhaseList?.Filters?.Filter).find(e => e?._attributes?.QueryParameter === 'compId');
    return [].concat(filter?.FilterItems?.Items).filter(item => item?._attributes?.Id).map(item => {
      let attrs = item?._attributes;
      return {
        title: attrs?.Name,
        value: attrs?.Id
      };
    });
  }

  getCurrentPhase(data: any): any {
    const currentPhase = data?.PhaseList?.CurrentPhase?._attributes
    return {
      title: currentPhase?.Name,
      value: currentPhase?.Id
    }
  }
 }