<script lang="ts">var _a, _b, _c;
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import ActionButton from "../ActionButton/ActionButton.svelte";
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { CardsPageContext, CardsPageContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { resolveUrl } from '../../../../../js/url';
import { JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const pageContext = getContext(CardsPageContextKey);
const signonManager = getToken(ROOT, JanusSignonManagerToken);
$: link = (_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.link;
$: title = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.title;
$: endWorkflow = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.endWorkflow;
const handleClick = (e) => {
    if (endWorkflow) {
        signonManager.fireWorkflowEndEvent();
    }
    else if (link) {
        resolveUrl(link).func();
    }
};
</script>
<CardBase {element}>
    <ActionButton onClick={handleClick} {title} disabled={false}/>
</CardBase>