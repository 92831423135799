<script context="module" lang="ts">let globalPopupControl = null;
// TODO: gotta have a better way of tracking query string state across modals
let retryOnStateChange = false;
export function closeCurrentModal() {
    if (!globalPopupControl) {
        return;
    }
    globalPopupControl.closePopup(true);
}
</script>

<script lang="ts">import YCPage from "../components/YCPage.svelte";
import { GoogleAnalyticsUA } from "svelte-google-analytics/src";
import CardsPage from "../components/cards/common/CardsPage.svelte";
import { Popup, Link } from "framework7-svelte";
import { onMount } from "svelte";
import { ycSSOEndDialog } from "../js/ycapp";
import { JanusSignonManagerToken } from "../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../inversify.config';
import { Device } from 'framework7';
export let f7route;
const listName = f7route.params.listName;
const pathParams = f7route.params.path1
    ? [f7route.params.path1]
    : undefined;
const pageQuery = f7route.query;
const signonManager = getToken(ROOT, JanusSignonManagerToken);
let popup;
let popupOpened = false;
let modalHeight;
let overflow;
let contentWrapper;
let closedByNavigation = false;
let shiftPx = 0;
function focusListener() {
    var _a;
    const ae = document.activeElement;
    const popupEl = (_a = popup === null || popup === void 0 ? void 0 : popup.instance()) === null || _a === void 0 ? void 0 : _a.el;
    if (!ae || !popupEl || !(Device.android || Device.androidChrome)) {
        return;
    }
    //console.log('focused: ', ae.tagName);
    if (ae.tagName === 'INPUT' || ae.tagName === 'TEXTAREA') {
        const aeRect = ae.getBoundingClientRect();
        const vh = window.innerHeight;
        // add previous shiftPx because the popup may already be shifted
        // which will throw off the normal y position of the popup
        shiftPx = Math.max(aeRect.y + shiftPx - vh / 3, 0);
        popupEl.style.transform = `translate(0px, ${-shiftPx}px)`;
    }
    else {
        shiftPx = 0;
        popupEl.style.removeProperty('transform');
    }
}
const popupControl = {
    openPopup: function () {
        popup.open(true);
        popupOpened = true;
        document.addEventListener('focus', focusListener, true);
    },
    closePopup: function (onNavigation) {
        document.removeEventListener('focus', focusListener);
        closedByNavigation = onNavigation;
        popupOpened = false;
        popup.close(false);
        if (!closedByNavigation) {
            const retry = signonManager.isUserStateChanged() && retryOnStateChange;
            const dataChanged = signonManager.isUserStateChanged();
            ycSSOEndDialog(retry, dataChanged);
        }
    },
};
onMount(() => {
    if (pageQuery === null || pageQuery === void 0 ? void 0 : pageQuery.retryOnStateChange) {
        retryOnStateChange = (pageQuery === null || pageQuery === void 0 ? void 0 : pageQuery.retryOnStateChange) === 'true';
    }
    if (pageQuery === null || pageQuery === void 0 ? void 0 : pageQuery.skipCompleteProfilePage) {
        signonManager.skipCompleteProfilePage = (pageQuery === null || pageQuery === void 0 ? void 0 : pageQuery.skipCompleteProfilePage) === 'true';
    }
    const resizeObserver = new ResizeObserver(() => {
        overflow = contentWrapper.clientHeight > window.innerHeight * 95 / 100 ? 'auto' : 'hidden';
        modalHeight = Math.min(contentWrapper.clientHeight, window.innerHeight * 95 / 100);
        console.log('scroll: ', contentWrapper.scrollTop, contentWrapper.parentElement.scrollTop);
        if (overflow === 'hidden') {
            contentWrapper.scrollTop = 0;
            contentWrapper.parentElement.scrollTop = 0;
        }
    });
    resizeObserver.observe(contentWrapper);
    signonManager.addWorkflowEndListener(() => {
        popupControl.closePopup(false);
    });
    globalPopupControl = popupControl;
    popupControl.openPopup();
});
const onCloseButtonTapped = () => {
    popupControl.closePopup(false);
};
const onPopupOpen = () => {
    console.log("MODAL: onPopupOpen");
    // handle the keyboarrd overlay
    /*
    const activeElement: Element = document.activeElement;
    if (activeElement.tagName === 'input' || activeElement.tagName === 'textarea') {
        console.log('input_focused: ', activeElement)
        const modal: HTMLElement = document.querySelector('.cards-popup');
        let view = modal.previousElementSibling;

        while(view) {
            if (view.className == 'views') {
                view.style.position = 'absolute';
            }
            view = view.previousElementSibling;
        }
    }
    */
    const parent = document.querySelector('#framework7-root');
    parent.style.display = 'flex';
    parent.style.justifyContent = 'center';
    parent.style.alignItems = 'center';
};
const onPopupClose = () => {
    console.log("MODAL: onPopupClose");
    // below logic handles backdrop clicks
    if (popupOpened) {
        popupControl.closePopup(false);
    }
    /*
    // handle the keyboarrd overlay
    const modal: HTMLElement = document.querySelector('.cards-popup');
    let view = modal.previousElementSibling;
    while(view) {
        if (view.className == 'views') {
            view.style.position = 'relative';
        }
        view = view.previousElementSibling;
    }
    */
};
</script>

<YCPage query={pageQuery}>
    <Popup
        bind:this={popup}
        class="cards-popup"
        style="--f7-popup-tablet-height: {modalHeight}px; --overflow: {overflow}"
        backdropEl={".cards-popup-backdrop"}
        onPopupOpen={onPopupOpen}
        onPopupClose={onPopupClose}
        closeByBackdropClick={true}
        closeOnEscape={false}
        animate={true}
        tabletFullscreen={true}
    >
        <div class="popup-content-wrapper" bind:this={contentWrapper}>
            <i class="f7-icons" on:click={onCloseButtonTapped}>xmark</i>
            <CardsPage {listName} {pathParams} {pageQuery} />
            <div class="popup-backdrop" id="child-popup-backdrop"></div>
        </div>
    </Popup>
    <div class="popup-backdrop cards-popup-backdrop" on:click={onPopupClose} class:display-none={!popupOpened} />
</YCPage>

<style>.popup-content-wrapper {
  width: 100%;
  position: relative;
}

.popup-content-wrapper i {
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 24px;
  z-index: 999;
  /* fixes for Safari z-index bugs */
  transform: translate3d(0, 0, 0);
}

:global(.cards-popup) {
  --f7-popup-tablet-width: 354px;
  width: var(--f7-popup-tablet-width) !important;
  height: var(--f7-popup-tablet-height) !important;
  left: calc(50% - var(--f7-popup-tablet-width) / 2);
  top: calc(50% - var(--f7-popup-tablet-height) / 2);
  box-shadow: 0px 0px 30px 0px;
  overflow-y: var(--overflow);
  overflow-x: hidden;
  border-radius: 8px;
  position: static !important;
}

:global(.popup-content-wrapper .cards-page-left-flex) {
  overflow: visible !important;
}

.display-none {
  display: none;
}</style>
