<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import ActionButton from "../ActionButton/ActionButton.svelte";
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { CardsPageContext, CardsPageContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { resolveUrl } from '../../../../../js/url';
import { JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const pageContext = getContext(CardsPageContextKey);
const signonManager = getToken(ROOT, JanusSignonManagerToken);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: firstNameKey = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.firstNameKey;
$: lastNameKey = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.lastNameKey;
$: emailKey = (_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.emailKey;
$: birthdayKey = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.birthdayKey;
$: addressKey = (_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.addressKey;
$: smsOptinKey = (_g = element === null || element === void 0 ? void 0 : element.data) === null || _g === void 0 ? void 0 : _g.smsOptinKey;
$: emailOptinKey = (_h = element === null || element === void 0 ? void 0 : element.data) === null || _h === void 0 ? void 0 : _h.emailOptinKey;
$: privacyOptinKey = (_j = element === null || element === void 0 ? void 0 : element.data) === null || _j === void 0 ? void 0 : _j.privacyOptinKey;
$: newsletterOptinKey = (_k = element === null || element === void 0 ? void 0 : element.data) === null || _k === void 0 ? void 0 : _k.newsletterOptinKey;
$: endWorkflow = (_l = element === null || element === void 0 ? void 0 : element.data) === null || _l === void 0 ? void 0 : _l.endWorkflow;
$: firstName = firstNameKey ? ($mergedParams || {})[firstNameKey] : '';
$: lastName = lastNameKey ? ($mergedParams || {})[lastNameKey] : '';
$: email = emailKey ? ($mergedParams || {})[emailKey] : '';
$: address = addressKey ? ($mergedParams || {})[addressKey] : '';
$: birthdate = birthdayKey ? ($mergedParams || {})[birthdayKey] : '';
$: smsOptin = smsOptinKey ? ($mergedParams || {})[smsOptinKey] : '';
$: emailOptin = emailOptinKey ? ($mergedParams || {})[emailOptinKey] : '';
$: privacyOptin = privacyOptinKey ? ($mergedParams || {})[privacyOptinKey] : '';
$: newsletterOptin = newsletterOptinKey ? ($mergedParams || {})[newsletterOptinKey] : '';
$: _address = address ? JSON.parse(address) : {
    address_country: '',
    address_street: '',
    address_city: '',
    address_division_1: '',
    address_postal: '',
    phone: ''
};
const handleClick = (e) => __awaiter(void 0, void 0, void 0, function* () {
    var _m, _o;
    console.log('ProfileSaveButton: ', firstName, lastName, email, _address.address_country, _address.address_street, _address.address_city, _address.address_division_1, _address.address_postal, _address.phone, birthdate, smsOptin);
    if (addressKey && !_address.address_country) {
        alert('Country is required.');
        return;
    }
    if (addressKey && !(_address.address_country || _address.address_street || _address.address_city || _address.address_postal || _address.address_state || _address.phone || birthdate)) {
        resolveUrl(`ConnectAccounts`).func();
        return;
    }
    const profileData = {
        first_name: firstName,
        last_name: lastName,
        name: (firstName || lastName) ? (firstName || '') + ' ' + (lastName || '') : null,
        address_country: _address.address_country,
        address_street: _address.address_street,
        address_street_1: _address.address_street,
        address_city: _address.address_city,
        address_division_1: _address.address_division_1,
        address_postal: _address.address_postal,
        phone: _address.phone,
        birth_date: birthdate,
        sms_optin: (smsOptinKey) ? (smsOptin === "true" ? 'true' : 'false') : null,
        email_optin: (emailOptinKey) ? (emailOptin === "true" ? 'true' : 'false') : null,
        privacy_optin: (privacyOptinKey) ? (privacyOptin === "true" ? 'true' : 'false') : null,
        newsletter_optin: (newsletterOptinKey) ? (newsletterOptin === "true" ? 'true' : 'false') : null
    };
    // Remove empty fields
    // Object.keys(profileData).map(key => {
    //     if (!profileData[key]) {
    //         delete profileData[key];
    //     }
    // });
    if (smsOptinKey)
        profileData.sms_optin = smsOptin === "true" ? 'true' : 'false';
    if (emailOptinKey)
        profileData.email_optin = emailOptin === "true" ? 'true' : 'false';
    if (privacyOptinKey)
        profileData.privacy_optin = privacyOptin === "true" ? 'true' : 'false';
    if (newsletterOptinKey)
        profileData.newsletter_optin = newsletterOptin === "true" ? 'true' : 'false';
    let result = yield signonManager.updateUserProfileSegment(profileData);
    if (result.success) {
        if (endWorkflow) {
            signonManager.fireWorkflowEndEvent();
        }
        else {
            resolveUrl(`ConnectAccounts`).func();
        }
    }
    else {
        alert(((_o = (_m = result.data) === null || _m === void 0 ? void 0 : _m.UserMessage) === null || _o === void 0 ? void 0 : _o.Content) || 'An error occurred.');
    }
});
</script>

<CardBase {element}>
  <ActionButton onClick={handleClick} disabled={(addressKey && !_address.address_country) || (smsOptin === 'true' && !_address.phone) || (emailOptinKey && emailOptin !== 'true') || (privacyOptinKey && privacyOptin !== 'true')} title="Save"/>
</CardBase>
