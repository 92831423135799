import { CardsDataSourceBase } from '../../common/CardsDataSourceBase'
import { injectable } from 'inversify'
import { injectToken } from 'inversify-token'
import { YinzCamAPIResponse } from 'yinzcam-api'
import { YinzCamAppServerToken } from 'yinzcam-appserver'
import _ from 'lodash'

@injectable()
export class YinzCamAppServerPagesResources extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({
      server,
      defaultPath: '/Pages/Resources',
      defaultParams: { type: 'pages' },
    })
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any
    let pageResources = [].concat(data?.ArrayOfResourcesXml?.ResourcesXml).filter(e => e).map(e => ({
      AppId: e?.AppId?._text,
      CreateTime: e?.CreateTime?._text,
      Environment: e?.Environment?._text,
      Id: e?.Id?._text,
      Name: e?.Name?._text,
      Slug: e?.Slug?._text,
      Type: e?.Type?._text,
      UpdateTime: e?.UpdateTime?._text,
      Version: e?.Version?._text,
      Description: e?.Description?._text,
      ImageUrl: e?.ImageUrl?._text
    }))
    return { data: pageResources }
  }
}
