<div class="cards-editor-component-edit-popup" style="--bar-color: {barColor}; --text-color: {textColor};">
  <div class="cards-editor-component-edit-control-bar theme-dark">
    <Menu>
      <div style="font-size: {iconSize}px; padding-right: 5px;">{title}</div>
      <!--<MenuItem iconF7="xmark" iconOnly {iconSize} on:click={onClose} />-->
      <div style="flex-grow: 1"></div>
      <MenuItem iconF7="xmark" iconOnly {iconSize} on:click={onClose} />
    </Menu>
  </div>
  <div bind:this={jsonEditorDiv} class="cards-editor-component-edit-json-editor">
  </div>
</div>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.cards-editor-component-edit-popup {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.cards-editor-component-edit-control-bar {
  width: 100%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: var(--bar-color);
  color: var(--text-color);
  padding: 5px 0px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 999;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  --f7-menu-bg-color: black;
  --f7-menu-text-color: white;
  --f7-menu-font-size: 12px;
  --f7-menu-item-height: 32px;
}

.cards-editor-component-edit-control-bar :global(.menu) {
  width: 100%;
}

.cards-editor-component-edit-json-editor {
  width: 100%;
  padding: 10px;
  flex-grow: 1;
  overflow: scroll;
  color: black;
}

.cards-editor-component-edit-json-editor :global(.form-control) {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.cards-editor-component-edit-json-editor :global(.form-control) > :global(.je-form-input-label) {
  order: 0;
}

.cards-editor-component-edit-json-editor :global(.form-control) > :global(:not(.je-form-input-label)) {
  order: 1;
}

.cards-editor-component-edit-json-editor :global(.form-control) > :global(p.je-form-input-label),
  .cards-editor-component-edit-json-editor :global(.je-object__container) > :global(p) {
  margin: 0;
  padding-bottom: 5px;
  font-weight: normal;
  font-style: italic;
}

.cards-editor-component-edit-json-editor :global(.form-control) > :global(select),
  .cards-editor-component-edit-json-editor :global(.form-control) > :global(input) {
  border: 1px solid #6f7e95;
  padding: 5px 0px 3px 3px;
  /*width: 400px;*/ /* useful when in a wider display format */
}

.cards-editor-component-edit-json-editor :global(.form-control) > :global(.je-checkbox) {
  padding-left: 5px;
}

.cards-editor-component-edit-json-editor :global(.je-object__container) > :global(.je-indented-panel) {
  margin: 0;
  border: 0;
}

.cards-editor-component-edit-json-editor :global(.je-object__title) {
  margin: 0;
}</style>

<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Menu, MenuItem } from 'framework7-svelte';
import { onDestroy, onMount } from 'svelte';
import { JSONEditor } from '@json-editor/json-editor/dist/jsoneditor.js';
import _ from 'lodash';
import { CardsEditorFileUploadCallbacks, CardsEditorComponentEditorOptions } from './CardsEditorComponentInterfaces';
import { Jodit } from 'jodit';
import 'jodit/build/jodit.min.css';
import { pbt } from '../../../js/i18n';
export let options;
$: closePopup = (options === null || options === void 0 ? void 0 : options.closePopup) || (() => { });
$: title = (options === null || options === void 0 ? void 0 : options.title) || '';
$: barColor = (options === null || options === void 0 ? void 0 : options.barColor) || '#aaaaaa';
$: textColor = (options === null || options === void 0 ? void 0 : options.textColor) || 'white';
$: schema = (options === null || options === void 0 ? void 0 : options.schema) || {};
$: data = (options === null || options === void 0 ? void 0 : options.data) || {};
$: onUpdate = (options === null || options === void 0 ? void 0 : options.onUpdate) || (() => { });
$: onFileUpload = (options === null || options === void 0 ? void 0 : options.onFileUpload) || (() => { });
const iconSize = 20;
let jsonEditorDiv;
let jsonEditor;
let lastSchema = {};
if (!window.Jodit) {
    window.Jodit = Jodit;
}
function onClose() {
    closePopup();
}
function onDataChanged() {
    console.log('ON DATA CHANGED', jsonEditor.getValue());
    onUpdate(jsonEditor.getValue());
}
function createJsonEditor() {
    lastSchema = schema;
    // I don't like doing this, but json-editor won't pass options to Jodit
    window.Jodit.defaultOptions.controls.image = {
        //name: "Upload Image",
        tooltip: $pbt("Upload image to website."),
        //iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
        exec: ((editor) => __awaiter(this, void 0, void 0, function* () {
            const fileUpload = (file) => {
                return new Promise((resolve, reject) => {
                    onFileUpload(file, {
                        onFileUploadStarted(id) {
                            console.log('FILE UPLOAD STARTED!', id);
                        },
                        onFileUploadProgress(id, percent) {
                            console.log('FILE UPLOAD PROGRESS!', id, percent);
                        },
                        onFileUploadComplete(id, url) {
                            console.log('FILE UPLOAD COMPLETE!', id, url);
                            resolve(url);
                        },
                        onFileUploadError(id, reason) {
                            console.log('FILE UPLOAD ERROR :(', id, reason);
                            reject(reason);
                        }
                    });
                });
            };
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();
            input.onchange = function () {
                return __awaiter(this, void 0, void 0, function* () {
                    const imageFile = input.files[0];
                    if (!imageFile) {
                        return;
                    }
                    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
                        return;
                    }
                    const url = yield fileUpload(imageFile);
                    const image = editor.selection.j.createInside.element('img');
                    image.setAttribute('src', url);
                    image.setAttribute('style', 'max-width: 100%');
                    editor.selection.insertNode(image);
                });
            };
        }))
    };
    //(<any>window).Jodit.defaultOptions.buttonsXS[1] = 'uploadImage';
    JSONEditor.defaults.language = 'es';
    JSONEditor.defaults.languages.es = {
        button_add_row_title: "Agregar {{0}}",
        button_collapse: "Colapsar",
        button_copy_row_title_short: "Copiar",
        button_delete_all: "Todos",
        button_delete_all_title: "Borrar Todos",
        button_delete_last: "Ultimo {{0}}",
        button_delete_last_title: "Borrar Ultimo {{0}}",
        button_delete_node_warning: "Seguro que quieres borrarlo?",
        button_delete_row_title: "Borrar {{0}}",
        button_delete_row_title_short: "Borrar",
        button_expand: "Expandir",
        button_move_down_title: "Mover abajo",
        button_move_up_title: "Mover arriba",
        button_object_properties: "Propiedades del Objeto",
        choices_placeholder_text: "Empeza a escribir para agregar un valor",
        default_array_item_title: "item",
        error_additionalItems: "No se admiten mas items en esta lista",
        error_additional_properties: "No se admiten mas propiedades, pero la propiedad {{0}} esta seteada",
        error_anyOf: "El valor debe corresponder con uno de los schemas provistos",
        error_date: "La fecha debe estar en formato {{0}}",
        error_datetime_local: "La fecha y hora deben estar en formato {{0}}",
        error_dependency: "Tiene que tener la propiedad {{0}}",
        error_disallow: "El valor no puede ser del tipo {{0}}",
        error_disallow_union: "El valor no puede ser uno de los tipos no admitidos",
        error_enum: "El valor debe ser una de las opciones posible",
        error_hostname: "El hostname esta en el formato incorrecto",
        error_invalid_epoch: "La fecha debe ser posterior al 1 enero de 1970",
        error_ipv4: "El valor debe ser una IPv4 valida con el formato de 4 numeros entre 0 y 255 separados por puntos",
        error_ipv6: "El valor debe ser una IPv6 valida",
        error_maxItems: "El valor debe tener como maximo {{0}} items",
        error_maxLength: "El valor debe tener como maximo {{0}} caracteres",
        error_maxProperties: "El valor debe tener como maximo {{0}} propiedades",
        error_maximum_excl: "El valor debe tener menos de {{0}}",
        error_maximum_incl: "El valor debe tener como maximo {{0}}",
        error_minItems: "El valor debe ser al menos {{0}} items",
        error_minLength: "El valor debe ser al menos {{0}} caracteres",
        error_minProperties: "El objeto debe tener al menos {{0}} propiedades",
        error_minimum_excl: "El valor debe ser mas que {{0}}",
        error_minimum_incl: "El valor debe ser al menos {{0}}",
        error_multipleOf: "El valor debe ser un multiplo de {{0}}",
        error_not: "El valor no debe ser validado contra el schema provisto",
        error_notempty: "Valor requerido",
        error_notset: "La propiedad debe estar seteada",
        error_oneOf: "El valor debe ser validado exactamente contra todos los schemas provisto. Ahora solo valida contra {{0}} de los mismos.",
        error_pattern: "El valor debe ser compatible con el patron {{0}}",
        error_required: "Al objeto le falta la propiedad '{{0}}'",
        error_time: "El valor debe estar en el formato {{0}}",
        error_type: "El valor debe ser del tipo {{0}}",
        error_type_union: "El valor debe ser de uno de los tipos provistos",
        error_uniqueItems: "La lista debe tener items unicos",
        flatpickr_clear_button: "Vaciar",
        flatpickr_toggle_button: "Expandir/Colapsar"
    };
    jsonEditor = new JSONEditor(jsonEditorDiv, {
        schema,
        startval: data,
        disable_collapse: true,
        disable_edit_json: true,
        disable_properties: true,
        remove_empty_properties: true,
        no_additional_properties: true,
        use_default_values: false,
        show_opt_in: true,
        display_required_only: false,
        object_layout: 'categories',
        theme: 'html',
        // NOTE: This doesn't work, but it should.
        /*
        jodit: {
          disabled: false,
          controls: {
            uploadImage: ...
          }
        },
        */
        upload: {
            auto_upload: true,
            enable_drag_drop: true,
            upload_handler: (type, file, cbs) => {
                if (type === 'root.upload_fail') {
                    cbs.failure($pbt('Upload failed.'));
                    return;
                }
                onFileUpload(file, {
                    onFileUploadStarted(id) {
                        console.log('FILE UPLOAD STARTED!', id);
                    },
                    onFileUploadProgress(id, percent) {
                        console.log('FILE UPLOAD PROGRESS!', id, percent);
                        cbs.updateProgress(percent);
                    },
                    onFileUploadComplete(id, url) {
                        console.log('FILE UPLOAD COMPLETE!', id, url);
                        cbs.updateProgress();
                        cbs.success(url);
                    },
                    onFileUploadError(id, reason) {
                        console.log('FILE UPLOAD ERROR :(', id, reason);
                        cbs.failure(reason);
                    }
                });
            }
        }
    });
    jsonEditor.on('change', onDataChanged);
    console.log('JSONEDITOR', jsonEditor);
}
function destroyJsonEditor() {
    jsonEditor.off('change', onDataChanged);
    jsonEditor.destroy();
}
let rootActiveTabId = null;
function saveJsonEditorState() {
    var _a, _b, _c;
    rootActiveTabId = (_c = (_b = (_a = jsonEditor === null || jsonEditor === void 0 ? void 0 : jsonEditor.editors) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b.active_tab) === null || _c === void 0 ? void 0 : _c.id;
}
function findSiblingWithId(el, id) {
    var _a;
    const siblings = el.parentNode.children;
    let sibling = null;
    for (let i = 0; i < siblings.length; i++) {
        if (((_a = siblings[i]) === null || _a === void 0 ? void 0 : _a.id) === id) {
            sibling = siblings[i];
            break;
        }
    }
    return sibling;
}
function restoreJsonEditorState() {
    var _a, _b;
    if (rootActiveTabId) {
        let rootActiveTab = (_b = (_a = jsonEditor === null || jsonEditor === void 0 ? void 0 : jsonEditor.editors) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b.active_tab;
        if (rootActiveTab) {
            const tabHandle = findSiblingWithId(rootActiveTab, rootActiveTabId);
            if (tabHandle) {
                tabHandle.click();
            }
        }
    }
}
function updateSchema(newSchema) {
    //console.log('updateSchema', newSchema);
    if (!jsonEditor || _.isEqual(lastSchema, newSchema)) {
        return;
    }
    //console.log('updateSchema RECREATING JSON EDITOR', newSchema);
    saveJsonEditorState();
    destroyJsonEditor();
    createJsonEditor();
    restoreJsonEditorState();
}
$: updateSchema(schema);
onMount(() => {
    createJsonEditor();
});
onDestroy(() => {
    if (jsonEditor) {
        destroyJsonEditor();
    }
});
</script>