import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";

@injectable()
export class YinzCamAppServerMediaFilter extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    // const list = [].concat(data?.Media?.MediaSection).reduce((acc, cur) => acc.concat([].concat(cur?.Item)), [])
    // const categories = list.map(e => ({
    //   value: "category/" + e?.PrimaryCategoryTag?._text,
    //   title: e?.PrimaryCategoryName?._text,
    // })).filter(e => e.value && e.title !== "--")
    // const options = [{
    //   title: "Todo",
    //   value: "media-type/G"
    // }].concat(categories.filter((x, i, a) => a.findIndex(e => e.value === x.value) == i))

    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }

    const filter = []
      .concat(data?.Media?.Filters?.Filter)
      .find((e) => e?._attributes?.QueryParameter === 'primaryCategory')
    const options = [].concat(filter?.FilterItems?.Items).filter(e => e?._attributes).map(e => ({
      title: e?._attributes?.DisplayName || capitalize(e?._attributes?.Name),
      value: e?._attributes?.Name === "ALL" ? specData?.type : ("category/" + e?._attributes?.Name)
    }))
    return { options };
  }
}