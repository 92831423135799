<script lang="ts">import { onMount } from "svelte";
import Geolocation from "svelte-geolocation";
import Map from './Map.svelte';
export let popupControl;
export let handleSelect = (value) => { };
let selectedValue;
const onMapSelect = (value) => {
    selectedValue = value;
};
const onMenuClick = (menuType) => {
    handleSelect(selectedValue);
    popupControl.closePopup();
};
let getPosition = true;
let ready = false;
window['initMap'] = function () {
    ready = true;
    console.log('ready: ', ready);
};
</script>

<svelte:head>
	<script defer async
	src="https://maps.googleapis.com/maps/api/js?key=AIzaSyASpNiJOYEATPTBwCLKrmUKbhA61TUeWW8&callback=initMap">
	</script>
</svelte:head>

<div class="location-wrapper">
  <div class="text-layout">
    <h3 class="title">Allow this app to use your location?</h3>
    <span class="description">
      Some features in this app are only available in certain geographic locations.
    </span>
  </div>
  <Geolocation
    getPosition="{getPosition}"
    watch={true}
    let:coords
    let:loading
    let:success
    let:error
    let:notSupported
  >
    {#if notSupported}
    <span class="description">Location services are not supported on this device. Please enter your location manually.</span>
    <ul>
      <li on:click={() => popupControl.closePopup()}>Close</li>
    </ul>
    {:else}
      {#if loading}
        Loading...
        <ul>
          <li on:click={() => popupControl.closePopup()}>Close</li>
        </ul>
      {/if}
      {#if success}
        {#if ready}
        <div class="map-wrapper">
          <Map onSelect={onMapSelect} {coords}/>
        </div>
        {/if}
        <ul>
          <li on:click={() => onMenuClick(0)}>Action Once</li>
          <li on:click={() => onMenuClick(1)}>Allow While Using App</li>
          <li on:click={() => popupControl.closePopup()}>Don't Allow</li>
        </ul>
      {/if}
      {#if error}
      <span class="description">We're unable to obtain your location. Please go to your device Settings and enable Location Permissions for this app, and then try again.</span>
      <ul>
        <li on:click={() => popupControl.closePopup()}>Close</li>
      </ul>
      {/if}
    {/if}
  </Geolocation>
</div>
<style>.location-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #F2F2F2;
}

.text-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 16px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #000000;
  margin: 0;
}

.description {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.078px;
  color: #000000;
}

.map-wrapper {
  width: 100%;
  height: 183px;
}

ul {
  padding: 0;
  margin: 0;
  width: 100%;
}

ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.408px;
  color: var(--alav-s-secondary);
  padding: 11px 0;
  border-top: 1px solid #999899;
}</style>