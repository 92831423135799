import Home from '../pages/Home.svelte';
import Cards from '../pages/Cards.svelte';
import Modal from '../pages/Modal.svelte';
import * as ModalModule from '../pages/Modal.svelte';
import WebView from '../pages/WebView.svelte';
import NotFoundPage from '../pages/NotFoundPage.svelte';
import PageBuilder from '../pages/PageBuilder.svelte';
import { JanusInternationalizationManagerToken, JanusInternationalizationManager } from './i18n';
import { getToken } from 'inversify-token';
import ROOT from '../inversify.config';
import { Unsubscriber, writable, Writable } from 'svelte/store';
import { f7, f7ready } from 'framework7-svelte';
import { JanusSignonManagerToken } from './sso';

const signonManager = getToken(ROOT, JanusSignonManagerToken)
const signonStatus = signonManager.getStatusComponent().store

const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);
const currentLanguage = i18nManager.getCurrentLanguage();
const homeRedirect = `/pages/${currentLanguage}/Home`;
let languageUnsubscribe: Unsubscriber = null;
const enableLanguageUrlSwitch = (to, from, resolve, reject) => {
  const initialLang = to.params.lang;
  console.log('SET CURRENT LANGUAGE', initialLang);
  i18nManager.setCurrentLanguage(initialLang);
  // Listen for changes in language and change route as needed
  // we need to subscribe on the next tick because the setCurrentLanguage value may not have passed through to the store yet
  setTimeout(() => languageUnsubscribe = i18nManager.getCurrentLanguageStore().subscribe((lang) => {
    console.log('LANGUAGE CHANGE', lang);
    if (lang !== initialLang) {
      f7.view.main.router.navigate({
        name: to.name,
        params: { ...to.params, lang },
        query: (Object.keys(to.query).length)? to.query : undefined
      }, {
        //clearPreviousHistory: true
      });
    }
  }), 0);
  resolve();
};
const disableLanguageUrlSwitch = (to, from, resolve, reject) => {
  if (languageUnsubscribe) {
    languageUnsubscribe();
    languageUnsubscribe = null;
  }
  resolve();
};

const closeCurrentModal = (to, from, resolve, reject) => {
  
  // For Framework 7 calendar routing
  const {path: toPath} = to.route;
  const {listName: fromPath} = from.params;

  if (fromPath === 'CompleteProfile' && toPath === 'date/') {
    resolve();
    return;
  }

  (<any>ModalModule).closeCurrentModal();
  resolve();
};

export interface Route {
  query: { [key: string]: string };
  params: { [key: string]: string };
  url: string;
  path: string;
  // this is the route's source configuration (from the routes array below)
  route: {
    name: string;
    path: string;
  }
  name: string;
}

// This is set by YCApp.svelte
export const currentRoute: Writable<Route> = writable(null);

const authGuard = (to, from, resolve, reject) => {
  const toListName = to.params.listName;
  const initialLang = to.params.lang;
  if( toListName === 'Profile') {
    return signonStatus.subscribe(signOn => {
      if (!signOn.loggedIn) {
        reject();
        f7.popup.open('.login-popup');
      } else {
        resolve()
      }
    })
  } else {
    resolve();
  }
}

// WARNING: Redirects break prerendering for the website. Don't use redirects here unless absolutely necessary.
const routes = [
  {
    path: '/',
    //component: Home
    redirect: homeRedirect
  },
  /* ???: For some reason the alias feature was not working, so defined these as separate paths. */
  {
    path: '/home',
    //component: Home,
    redirect: homeRedirect
  },
  {
    path: '/index.html',
    //component: Home,
    redirect: homeRedirect
  },
  {
    name: 'page-with-slug',
    path: '/pages/:lang/:listName/:path1',
    component: Cards,
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch
  },
  {
    name: 'page',
    path: '/pages/:lang/:listName',
    component: Cards,
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch
  },
  {
    name: 'modal',
    path: '/modals/:lang/:listName',
    component: Modal,
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: [closeCurrentModal, disableLanguageUrlSwitch]
  },
  {
    path: '/webview',
    component: WebView,
  },
  {
    path: '/builder',
    component: PageBuilder,
    beforeLeave: function ({resolve, reject}) {
      // "Relax," said the night man...
      if (typeof reject === 'function') {
        reject();
      }
    }
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
