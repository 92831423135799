import { YinzCamAPIRequestComponent, YinzCamAPIResponse } from 'yinzcam-api';
import { AbstractReactiveMicrocomponent } from 'yinzcam-rma';
import { YinzCamCardsServiceElement, YinzCamCardsServicePage, YinzCamCardsServicePageLayout, YinzCamCardsServiceTab } from './YinzCamCardsServiceTypes';

export class YinzCamCardsServicePageComponent extends AbstractReactiveMicrocomponent<YinzCamCardsServicePage, [/*YinzCamAPIResponse, */YinzCamAPIResponse]> {
  public constructor(private readonly name: string,/* srv1Input: YinzCamAPIRequestComponent,*/ srv2Input: YinzCamAPIRequestComponent) {
    super({ name },/* srv1Input,*/ srv2Input);
  }

  protected async update($control: unknown,/* $srv1rsp: YinzCamAPIResponse,*/ $srv2rsp: YinzCamAPIResponse): Promise<YinzCamCardsServicePage> {
    let page: YinzCamCardsServicePage = { id: this.name, layouts: [] };
    if ($srv2rsp && !$srv2rsp.isStatusNot2xx && $srv2rsp.data) {
      page = $srv2rsp.data as YinzCamCardsServicePage;
      page.layouts = page.layouts || [];
    }/*
    if ($srv1rsp && !$srv1rsp.isStatusNot2xx && $srv1rsp.data) {
      // create a layout at 768px wide unless there is already one less than that
      if (!page.layouts.find(layout => layout.breakpoint < 768)) {
        page.layouts = [
          this.buildLayoutFromV1Response($srv1rsp.data),
          ...page.layouts
        ]
      }
    }
    */
    //console.log(page);
    return page;
  }

  /*
  private buildLayoutFromV1Response(data: any): YinzCamCardsServicePageLayout {
    //console.log(data);

    const layoutId = `${this.name}_CARDSV1`;
    const ret: YinzCamCardsServicePageLayout = {
      id: layoutId,
      breakpoint: 0,
      region: {
        id: `${layoutId}_ROOT`,
        direction: 'VERTICAL',
        scrollable: false,
        size: 100,
        regions: []
      }
    };
    const regions = ret.region.regions;

    if (data.HeaderList) {
      let fragmentId = this.extractFragmentIdFromYCURL(data.HeaderList);
      if (fragmentId) {
        regions.push({
          id: `${layoutId}_HEADER`,
          direction: 'VERTICAL',
          scrollable: false,
          size: 0,
          tabs: [
            {
              id: `${layoutId}_HEADER_TAB`,
              fragmentId
            }
          ]
        });  
      }
    }

    if (data.Tab) {
      const tabs = [].concat(data.Tab);
      const waistbandTabs: YinzCamCardsServiceElement[] = tabs.map(tab => {
        return {
          id: `${layoutId}_WAISTBAND_TAB_ELEMENT_${tab.Title}`,
          class: 'PlaceholderCard',
          data: {
            title: tab.Title
          }
        }
      });
      regions.push({
        id: `${layoutId}_WAISTBAND`,
        direction: 'VERTICAL',
        scrollable: false,
        size: 0,
        tabs: [
          {
            id: `${layoutId}_WAISTBAND_TAB`,
            sections: [
              {
                id: `${layoutId}_WAISTBAND_SECTION`,
                content: [
                  {
                    id: `${layoutId}_WAISTBAND_ARRAY`,
                    layouts: [
                      {
                        id: `${layoutId}_WAISTBAND_ARRAY_LAYOUT`,
                        breakpoint: 0,
                        type: 'GRID',
                        splitEqually: true,
                        elements: waistbandTabs
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      });

      const contentTabs: YinzCamCardsServiceTab[] = tabs.map(tab => {
        return {
          id: `${layoutId}_CONTENT_TAB_${tab.Title}`,
          default: (tab?.Default)? tab.Default.toLowerCase() === 'true' : false,
          fragmentId: this.extractFragmentIdFromYCURL(tab?.Action)
        }
      });
      regions.push({
        id: `${layoutId}_CONTENT`,
        direction: 'VERTICAL',
        scrollable: true,
        size: 0,
        tabs: contentTabs
      });
    }

    return ret;
  }
  */

  private extractFragmentIdFromYCURL(ycurl: string): string {
    try {
      if (!ycurl) {
        return null;
      }
      const url = new URL(ycurl);
      let path = url.searchParams.get('path');
      if (!path) {
        path = url.searchParams.get('Path');
      }
      if (!path) {
        return null;
      }
      let fragmentId = path.substring(path.lastIndexOf('/') + 1);
      return fragmentId;
    } catch (err) {
      return null;
    }
  }
}
