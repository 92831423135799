
export  async function getCountries(filterText, isOrder = false) {
  let url = "https://api.countrystatecity.in/v1/countries"
  let countries  = await getResults(url, filterText);
  if (isOrder) {
    const indexUS = countries.findIndex(country => country.iso2 === 'US');
    const indexCA = countries.findIndex(country => country.iso2 === 'CA');
    let orderedCountry = [].concat(countries[indexUS]).concat(countries[indexCA]).concat([].concat(countries.slice(0, indexCA)).concat(countries.slice(indexCA + 1, indexUS)).concat(countries.slice(indexUS + 1)))
    return orderedCountry;
  }
  return countries;
}

export  function getStates(filterText = '', country) {
  let url = country ? `https://api.countrystatecity.in/v1/countries/${country}/states` : `https://api.countrystatecity.in/v1/states`;
  return  getResults(url, filterText);
}

export  function getCities(filterText = '', country = 'US', state = null) {
  console.log("getCities: ", state, country)
  // if (!country) {
  //   return ['please select country'];
  // }
  let url = state ? `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities` : `https://api.countrystatecity.in/v1/countries/${country}/cities`;
  return  getResults(url, filterText);
}

function getResults(url, filterText) {
  var headers = new Headers();
  headers.append("X-CSCAPI-KEY", "QmhnbE9NR3Z1TGJZdzVEVVNUaGtSOTRzV0NKWXQ0UHpiMTQwVzdoTA==");
  var requestOptions = {
    method: 'GET',
    headers: headers,
    // redirect: 'follow'
  };

  return fetch(url, requestOptions)
    .then(response => {
      let result = response.json();
      return result;
    }).then(result => {
      console.log('filterText', filterText)
      if (filterText) {
        if (Array.isArray(filterText)) {
          result = result.filter(item => {
            if (!item.name) return false;
      	  return !filterText.includes(item.name);
          })
        } else {
              result = result.filter(item => {
                if (!item.name) return false;
                return !filterText.toLowerCase().includes(item.name.toLowerCase());
              })
        }
      }
      return result;
    })
}


export default {
  getCountries,
  getCities,
  getStates
};
