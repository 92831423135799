function emailValidator() {
    return function email(value) {
        return (value && !!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || 'Correo electrónico inválido'
    }
}

function requiredValidator() {
    return function required(value) {
        return (value !== undefined && value !== null && value !== '') || 'This field is required'
    }
}

function confirmEmailValidator() {
    return function email(value) {
        const {email} = value;
        const {confirm_email} = value;
        return ((confirm_email === email) && !!confirm_email && !!confirm_email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || 'Correo electrónico inválido'
    }
}

function passwordValidator() {
    return function password(value) {
        let password = value.password;
        let username = value.username;
        let validationData = {
            len: {
                value: true,
                msg: '8 to 15 characters'
            },
            special: {
                value: true,
                msg: '1 Special Symbol ($&%!@?)'
            },
            complex: {
                value: true,
                msg: 'Password must include numbers, uppercase, and lowercase letters'
            },
            username: {
                value: true,
                msg: "Can't contain user's name"
            }
        };

        console.log("passwordValid: ", password, username)

        if (!password) return 'This field is required';
        validationData['len'].value = password.length >= 8 && password.length <= 15;
        validationData['special'].value = isSpecialCharacter(password);
        validationData['complex'].value = isComplex(password);
        validationData['username'].value = containUsername(password, username);
        return validationData;
    }

    function isComplex(value) {
        return /[0-9]/g.test(value) && /[a-z]/g.test(value) && /[A-Z]/g.test(value);
    }
    
    function isSpecialCharacter(value) {
        return /[~`!@#$%\^&*+=\-()?\[\]\\';,/{}|\\":<>\?]/g.test(value);
    }

    function containUsername(password, username) {
        console.log('validate_username', username)
        if (!password || !username) return true;
        return (password.indexOf(username) == -1);
    }
}

function confirmPasswordValidator() {
    return function password(value) {
        const {password} = value;
        const {confirm_password} = value;
        return ((confirm_password === password) && !!confirm_password );
    }
}

export {
    emailValidator,
    passwordValidator,
    requiredValidator,
    confirmEmailValidator,
    confirmPasswordValidator
}