<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { f7, f7ready } from "framework7-svelte";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { CardsTabContext, CardsTabContextKey, } from "../../../common/context";
import { getContext, onMount } from "svelte";
import moment from 'moment';
import { JanusSignonManager, JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import TextInput from "../TextInput/TextInput.svelte";
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
$: key = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.key) || "birthdate";
// $: mask = element?.data?.mask as string || "00/00/0000";
// $: maskString = element?.data?.maskString as string || "MM/DD/YYYY";
const signonManager = getToken(ROOT, JanusSignonManagerToken);
let birthdate;
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    const profileData = yield signonManager.getUserProfileSegment();
    const data = (profileData === null || profileData === void 0 ? void 0 : profileData.data) || {};
    console.log('profileData: ', data);
    birthdate = data.birth_date ? new Date(data.birth_date) : null;
    if (birthdate) {
        birthdate = moment(birthdate, format);
        birthdate = birthdate.format(format);
    }
}));
let calendar;
let format = 'MM/DD/YYYY';
const placeholder = format;
onMount(() => {
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    calendar = f7.calendar.create({
        inputEl: "#sso22-demo-calendar-events",
        closeOnSelect: true,
        events: [
            //- more events this day
            {
                date: today,
                color: "#ff0000",
            },
            {
                date: today,
                color: "#00ff00",
            },
        ],
    });
    calendar.on("dayClick", (calendar, dayEl, year, month, day) => {
        let date = moment(new Date(year, month, day));
        console.log('dayClick: ', key, date === null || date === void 0 ? void 0 : date.toISOString());
        tabContext.setParam(key, date === null || date === void 0 ? void 0 : date.toISOString());
        birthdate = date.format(format);
    });
});
const minDate = new Date("1900-01-01");
const maxDate = new Date("2023-01-01");
$: if (calendar && birthdate) {
    let date = moment(birthdate, format);
    calendar.setValue([date]);
}
</script>

<CardBase {element}>
<div class="wrapper">
  <!-- <input
    type="text"
    placeholder={placeholder}
    readonly={true}
    id="sso22-demo-calendar-events"
    class="input"
    value={birthdate || ''}
  /> -->
  <TextInput id="sso22-demo-calendar-events" className="input" readonly={true} placeholder="Date of Birth" value={birthdate || ''} onChange={() => {}} />
</div>
</CardBase>

<style>.wrapper {
  display: block;
  text-align: left;
  width: 100%;
}

.input {
  width: 100% !important;
  height: 40px !important;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
  padding-left: 16px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: block;
  position: relative;
}

:global(::-webkit-input-placeholder) { /* Chrome/Opera/Safari */
  color: black;
}

:global(:-moz-placeholder) { /* Firefox 19+ */
  color: black;
}

:global(:-ms-input-placeholder) { /* IE 10+ */
  color: black;
}

:global(:-moz-placeholder) { /* Firefox 18- */
  color: black;
}

:global(.calendar-months) {
  background: white;
}

:global(.calendar-day.calendar-day-selected .calendar-day-number) {
  color: var(--f7-calendar-selected-text-color);
  background-color: var(--alav-s-secondary);
}

:global(.calendar .toolbar a) {
  color: var(--alav-s-secondary);
}</style>
