<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import LinkAccountCard from "../LinkAccountCard/LinkAccountCard.svelte";
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { Popup, Link } from 'framework7-svelte';
import { onMount } from 'svelte';
import { JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { resolveUrl } from '../../../../../js/url';
import { ycSSOThirdPartyAuth } from '../../../../../js/ycapp';
export let element;
export let sources;
export let sourceStore;
const signonManager = getToken(ROOT, JanusSignonManagerToken);
$: clientConfig = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.clientConfig) || {};
$: linkStore = findSourceStore($sources, 'YinzCamSignonServerLinks');
$: links = linkStore && ($linkStore === null || $linkStore === void 0 ? void 0 : $linkStore.Link);
$: connected = (((links === null || links === void 0 ? void 0 : links.length) > 0) ? links.find(l => (l === null || l === void 0 ? void 0 : l.Type) === 'FACEBOOK') : null) || false;
const onConnect = () => {
    if (!connected) {
        ycSSOThirdPartyAuth("FACEBOOK", clientConfig, (method, clientResult) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            console.log("ycSSOThirdPartyAuth callback inside FB", method, clientResult);
            if (!(clientResult === null || clientResult === void 0 ? void 0 : clientResult.accessToken)) {
                console.warn(`ycSSOThirdPartyAuth callback for ${method}: no access token in client result`);
                return;
            }
            let result = yield signonManager.linkWithFacebookAccount(clientResult.accessToken, true);
            if (result.success) {
                resolveUrl(`Connected?linkedImage=${encodeURIComponent('/static/icons/icon-facebook.png')}`).func();
            }
            else {
                alert(((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.UserMessage) === null || _b === void 0 ? void 0 : _b.Content) || 'An error occurred.');
            }
        }));
    }
    else {
        // TODO: Unlink
        const url = `DisconnectAccounts?accountType=${"facebook"}`;
        const resolved = resolveUrl(url);
        resolved.func();
    }
};
</script>

<CardBase {element}>
    <LinkAccountCard title='Facebook' image={'/static/icons/icon-facebook.png'} onConnect={onConnect} {connected} {sources} />
</CardBase>