import { CardsDataSourceBase } from '../../common/CardsDataSourceBase'
import { injectable } from 'inversify'
import { injectToken } from 'inversify-token'
import { YinzCamAPIResponse } from 'yinzcam-api'
import { YinzCamAppServerToken } from 'yinzcam-appserver'
import _ from 'lodash'

@injectable()
export class YinzCamAppServerSearchResultsSummary extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(
    response: YinzCamAPIResponse,
    specData?: { [key: string]: any },
  ): object | any[] {
    const data = response?.data as any
    const summaries: number | undefined = this.getSummaries(data, specData?.section || "ALL")
    return { summaries }
  }

  getSummaries(data: any, section: string): number | undefined {
    let sum: number = 0
    let fetched = false
    const sectionLowercase = section.toLocaleLowerCase()
    const summary = data?.SearchResults?.ResultSummaries || []
    summary?.ResultSummary?.filter(
      item => sectionLowercase === "all" || item?._attributes?.Type?.toLowerCase() === sectionLowercase
    ).forEach((item) => {
      fetched = true
      const itemType = item?._attributes?.Type?.toLowerCase()
      const count = Number(item?._attributes?.Count);
      if (itemType && itemType !== "all"
        && (sectionLowercase === "all" || itemType === sectionLowercase)
        && !Number.isNaN(count)
      ) {
        sum += count
      }
    })

    return fetched ? sum : undefined
  }
}
