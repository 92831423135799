import { YinzCamAPIRequestComponent, YinzCamAPIResponse, YinzCamServer } from "yinzcam-api";
import { ControlBase } from "yinzcam-rma";
import { CardsDataSourceOutput } from "./CardsDataSourceOutput";
import { CardsDataSource } from "./CardsDataSource";
import { CardsDataSourceComponent } from "./CardsDataSourceComponent";
import { CardsDataSourceConfiguration } from "./CardsDataSourceConfiguration";
import { injectable } from "inversify";
import { expandTemplateParamsRecursive } from "../utilities";
import { JSONSchema4 } from "json-schema";

@injectable()
export abstract class CardsDataSourceBase implements CardsDataSource {

  protected constructor(protected readonly config: CardsDataSourceConfiguration) {
  }

  public getDisplayName(): string {
    // the clazz variable is injected by module.ts
    return (<any>this).clazz;
  }

  // undefined = "No config data is defined, so allow client to enter free form data"
  // null = "No config data is needed, so don't allow client to enter anything"
  public getDataSourceConfigSpec(): Promise<JSONSchema4> {
    return Promise.resolve(undefined);
  }

  // undefined = "No paths are defined, so allow client to enter free form data"
  // null or empty array = "No paths are allowed"
  public getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    return Promise.resolve(undefined);
  }

  public getDataSourceComponent(path?: string, specData?: { [key: string]: any }, contextParams?: { [key: string]: string }): CardsDataSourceComponent {
    path = path || this.config.defaultPath;
    if (!path) {
      throw new Error('no path provided for data source and default path not defined');
    }
    let url = new URL(path, 'http://www.example.org/'); // we just need any url to resolve against since we only care about path and params
    let params = { ...(this.config.defaultParams || {}) };
    url.searchParams.forEach((val, key) => params[key] = val);
    // TODO: is this necessary?
    params = expandTemplateParamsRecursive(params, contextParams);
    let self = this;
    let cardComponentClass = class extends CardsDataSourceComponent {
      public constructor(name: string, private readonly reqComp: YinzCamAPIRequestComponent) {
        super(name, reqComp);
      }
      public refresh(): void {
        this.reqComp.refresh();
      }
      protected async update($control: ControlBase, $response: YinzCamAPIResponse): Promise<CardsDataSourceOutput> {
        return { data: self.processResponse($response, specData) };
      }
    };
    let apiComponent = this.config.server.getRequest({ path: url.pathname, params });
    return new cardComponentClass(url.toString(), apiComponent);
  }

  protected abstract processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[];

}