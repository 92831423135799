<Boundary>
  <div class="yinzcam-card-base" style="--yinzcam-card-base-width: {width}; --yinzcam-card-base-height: {height};">
    <slot></slot>
  </div>
</Boundary>

<style>.yinzcam-card-base {
  width: var(--yinzcam-card-base-width);
  height: var(--yinzcam-card-base-height);
}

/* Svelte components can only have one root element, so this always styles the root element of the child component. */

/*.yinzcam-card-base > :global(:first-child) {
    height: var(--yinzcam-card-base-height);
  }*/</style>

<script lang="ts">import { YinzCamCardsServiceElement } from "yinzcam-cards";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
export let element;
export let width = (element === null || element === void 0 ? void 0 : element.width) || '100%';
export let height = (element === null || element === void 0 ? void 0 : element.height) || 'initial';
//$: width = (!element || !element.width)? '100%': element.width;
</script>
