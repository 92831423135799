import { YinzCamAPI } from 'yinzcam-api';
import { register } from 'yinzcam-registry';
import { APP_SERVER_REGISTRY } from './constants';
import { YinzCamAppServer } from './YinzCamAppServer';

@register(APP_SERVER_REGISTRY, 'f1')
export class YinzCamF1AppServer extends YinzCamAppServer {
  public constructor(api: YinzCamAPI) {
    super(api, 'V1/');
  }
}
