{#if _resolved && !_resolved.error}
  <Link href={_resolved.href} external={_resolved.external} target={(_resolved.external)?'_blank':'_self'} on:click={handleClick} {...$$restProps}>
    <slot></slot>
  </Link>
{:else if href}
  <Link {href} {external} on:click={handleClick} {...$$restProps}>
    <slot></slot>
  </Link>
{:else}
  <slot></slot>
{/if}

<style>*, *:before, *:after {
  box-sizing:border-box;
}</style>

<script lang="ts">import { createEventDispatcher } from 'svelte';
import { ResolvedUrl, resolveUrl } from '../../../js/url';
import { currentRoute } from '../../../js/routes';
import { Link } from 'framework7-svelte';
import _ from 'lodash';
export let resolved = undefined;
export let href = false;
export let external = false;
export let autoResolve = true;
const dispatch = createEventDispatcher();
// Introduce internal resolved variable for auto-resolve function.
// Can't overwrite resolved because we will lose reactive updates.
let _resolved = resolved;
function isResolvedUrl(obj) {
    return !_.isNil(obj === null || obj === void 0 ? void 0 : obj.href);
}
$: if (_.isUndefined(resolved)) {
    if (isResolvedUrl(href)) {
        _resolved = href;
    }
    else if (_.isString(href) && autoResolve) {
        _resolved = resolveUrl(href, $currentRoute);
    }
    else {
        _resolved = undefined;
    }
}
else {
    _resolved = resolved;
}
const handleClick = (e) => {
    dispatch('click', e);
};
</script>
