<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../../common/CardBase.svelte";
import { Readable } from "svelte/store";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { onMount } from "svelte";
import { JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from "inversify-token";
import ROOT from "../../../../../inversify.config";
import { f7 } from "framework7-svelte";
export let element;
export let sources;
export let sourceStore;
const signonManager = getToken(ROOT, JanusSignonManagerToken);
$: accountType = "";
$: console.log('account_type_url: ', accountType);
const returnToPrev = () => {
    f7.view.main.router.back();
};
const disconnectTicketmaster = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    let result = yield signonManager.unlinkWithTicketmasterAccount();
    if (result.success) {
        returnToPrev();
    }
    else {
        alert(((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.UserMessage) === null || _b === void 0 ? void 0 : _b.Content) || 'An error occurred.');
    }
});
const disconnectFacebook = () => __awaiter(void 0, void 0, void 0, function* () {
    var _c, _d;
    let result = yield signonManager.unlinkWithFacebookAccount();
    if (result.success) {
        returnToPrev();
    }
    else {
        alert(((_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.UserMessage) === null || _d === void 0 ? void 0 : _d.Content) || 'An error occurred.');
    }
});
const disconnectApple = () => __awaiter(void 0, void 0, void 0, function* () {
    var _e, _f;
    let result = yield signonManager.unlinkWithAppleAccount();
    if (result.success) {
        returnToPrev();
    }
    else {
        alert(((_f = (_e = result.data) === null || _e === void 0 ? void 0 : _e.UserMessage) === null || _f === void 0 ? void 0 : _f.Content) || 'An error occurred.');
    }
});
const disconnectSeatGeek = () => __awaiter(void 0, void 0, void 0, function* () {
    var _g, _h;
    let result = yield signonManager.unlinkWithSeatGeekAccount();
    if (result.success) {
        returnToPrev();
    }
    else {
        alert(((_h = (_g = result.data) === null || _g === void 0 ? void 0 : _g.UserMessage) === null || _h === void 0 ? void 0 : _h.Content) || 'An error occurred.');
    }
});
const disconnectClear = () => __awaiter(void 0, void 0, void 0, function* () {
    var _j, _k;
    let result = yield signonManager.unlinkWithClearAccount();
    if (result.success) {
        returnToPrev();
    }
    else {
        alert(((_k = (_j = result.data) === null || _j === void 0 ? void 0 : _j.UserMessage) === null || _k === void 0 ? void 0 : _k.Content) || 'An error occurred.');
    }
});
const handleDisconnect = () => {
    switch (accountType) {
        case "ticketmaster":
            disconnectTicketmaster();
            return;
        case "facebook":
            disconnectFacebook();
            return;
        case "apple":
            disconnectApple();
            return;
        case "seatgeek":
            disconnectSeatGeek();
            return;
        case "clear":
            disconnectClear();
            return;
    }
};
onMount(() => {
    setTimeout(() => {
        const path = f7.views.main.router;
        const { query } = path.currentRoute;
        accountType = query.accountType;
    }, 500);
});
</script>

<CardBase {element}>
    <div class="wrapper">
        <button class="btn btn-cancel" on:click={returnToPrev}>CANCEL</button>
        <button class="btn btn-dsiconnect" on:click={handleDisconnect}>DISCONNECT</button>
    </div>
</CardBase>
<style>.wrapper {
  display: flex;
  gap: 12px;
}

.btn {
  width: 149px;
  height: 38px;
  border-radius: 8px;
  border: 1px solid var(--alav-s-primary);
  color: var(--alav-s-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 10px;
  margin-left: auto;
}</style>