<script lang="ts">var _a, _b, _c, _d, _e;
import TextInput from "../TextInput/TextInput.svelte";
import { createFieldValidator } from "../Validator/validation";
import { emailValidator, requiredValidator, confirmEmailValidator } from "../Validator/validators";
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { CardsPageContext, CardsPageContextKey } from '../../../common/context';
import { getContext } from 'svelte';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const pageContext = getContext(CardsPageContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: key = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.key) || 'email';
$: validKey = ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.validKey) || 'isValidEmail';
$: valid = validKey ? ($mergedParams || {})[validKey] : 'true';
$: enableConfirm = (_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.enableConfirm;
$: enableValidate = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.enableValidate;
let email = '', confirm_email = '';
const handleEmailChange = (e) => {
    email = e.target.value;
    console.log('email: ', email, $email_validity.valid);
    if (enableConfirm) {
        handleChange(email, $email_validity.valid && $confirm_email_validity.valid);
    }
    else {
        handleChange(email, $email_validity.valid);
    }
};
const handleConfirmEmailChange = (e) => {
    confirm_email = e.target.value;
    console.log('confirm_email: ', confirm_email);
    handleChange(email, $email_validity.valid && $confirm_email_validity.valid);
};
const handleChange = (emailString, valid) => {
    tabContext.setParam(key, emailString);
    tabContext.setParam(validKey, valid + "");
};
let email_validity;
let email_validate;
let confirm_email_validity;
let confirm_email_validate;
$: {
    if (!enableConfirm) {
        [email_validity, email_validate] = createFieldValidator(requiredValidator(), emailValidator());
    }
    else {
        [email_validity, email_validate] = createFieldValidator(requiredValidator(), emailValidator());
        [confirm_email_validity, confirm_email_validate] = createFieldValidator(requiredValidator(), confirmEmailValidator());
    }
}
const getEmailErrorMessage = (validity) => {
    // Force check validity due to default lazy validation
    if (enableConfirm) {
        handleChange(email, validity.valid && $confirm_email_validity.valid);
    }
    else {
        handleChange(email, validity.valid);
    }
    return (enableValidate && validity.dirty && !validity.valid) ? 'Please enter a valid email address.' : '';
};
const getConfirmEmailErrorMessage = (validity) => {
    // Force check validity due to default lazy validation
    if (enableConfirm) {
        handleChange(email, validity.valid && $email_validity.valid);
    }
    else {
        handleChange(email, validity.valid);
    }
    return (enableValidate && validity.dirty && !validity.valid) ? 'Email addresses should match.' : '';
};
</script>
<CardBase {element}>
<div class="email-input-field-wrapper">
    <TextInput 
        placeholder="Email Address" 
        value={email} 
        type="email"
        onChange={handleEmailChange} 
        validate={email_validate}
        validateValue={email} 
        error={getEmailErrorMessage($email_validity)}    
    />
    {#if enableConfirm}
    <TextInput 
        placeholder="Confirm Email Address" 
        type="email"
        value={confirm_email} 
        error={getConfirmEmailErrorMessage($confirm_email_validity)}    
        onChange={handleConfirmEmailChange}
        validateValue={{email, confirm_email}} 
        validate={confirm_email_validate}
    />
    {/if}
</div>
</CardBase>

<style>.email-input-field-wrapper {
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
}</style>