<script lang="ts">import TextInput from "../TextInput/TextInput.svelte";
let username;
const handleChange = (e) => {
    username = e.target.value;
    console.log('username: ', username);
};
</script>
    
    <div class="username-input-field-wrapper">
        <TextInput placeholder="Game Username" value={username} onChange={handleChange} />
    </div>
    
    <style>.username-input-field-wrapper {
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
}</style>