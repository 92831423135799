<script lang="ts">var _a, _b;
import ActionButton from "../ActionButton/ActionButton.svelte";
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { CardsPageContext, CardsPageContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { resolveUrl } from '../../../../../js/url';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const pageContext = getContext(CardsPageContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: emailKey = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.emailKey;
$: email = emailKey ? ($mergedParams || {})[emailKey] : '';
$: isValid = !!email;
const handleClick = (e) => {
    console.log('ResetPasswordStart: ', email);
    // resolveUrl(`CheckEmail?email=${email}`).func();
    window.open("mailto:" + email);
};
</script>
    <CardBase {element}>
    <ActionButton onClick={handleClick} disabled={!isValid} title="Confirm Pin"/>
    </CardBase>  