<script lang="ts">var _a, _b, _c, _d, _e, _f;
import TextInput from "../TextInput/TextInput.svelte";
import { createFieldValidator } from "../Validator/validation";
import { confirmPasswordValidator, passwordValidator, requiredValidator, } from "../Validator/validators";
import { CardsDataSourceRegistration } from "../../../common/CardsDataSourceRegistration";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import { Readable } from "svelte/store";
import { CardsTabContext, CardsTabContextKey } from "../../../common/context";
import { getContext } from "svelte";
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const handleChange = (password, valid) => {
    tabContext.setParam(key, password);
    tabContext.setParam(validKey, valid + "");
};
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: enableConfirm = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.enableConfirm) || false;
$: enableValidate = ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.enableValidate) || false;
$: placeholder = (_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.placeholder;
$: key = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.key;
$: validKey = (_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.validKey;
let password_validity, password_validate;
let confirm_password_validity, confirm_password_validate;
$: {
    if (enableValidate) {
        [password_validity, password_validate] = createFieldValidator(requiredValidator(), passwordValidator());
        [confirm_password_validity, confirm_password_validate] = createFieldValidator(requiredValidator(), confirmPasswordValidator());
    }
    else {
        [password_validity, password_validate] = createFieldValidator(requiredValidator());
    }
}
let password = "", confirm_password = '';
const handlePasswordChange = (e) => {
    password = e.target.value;
    if (enableConfirm) {
        handleChange(password, passwordValid($password_validity.message) && $confirm_password_validity.valid);
    }
    else {
        handleChange(password, passwordValid($password_validity.message));
    }
};
const handleConfirmpasswordChange = (e) => {
    confirm_password = e.target.value;
    handleChange(password, passwordValid($password_validity.message) && $confirm_password_validity.valid);
};
const getPasswordErrorMessage = (validity) => {
    // Force check password_validity due to default lazy validation
    handleChange(password, passwordValid(validity.message));
    if (!validity.dirty) {
        return "";
    }
    if (enableValidate) {
        return passwordValid(validity.message) ? "" : "error";
    }
    else {
        return password ? "" : "Password is required";
    }
};
const getConfirmPasswordErrorMessage = (validity) => {
    // Force check validity due to default lazy validation
    handleChange(password, validity.valid && passwordValid($password_validity.message));
    return (enableValidate && validity.dirty && !validity.valid) ? 'Passwords should match.' : '';
};
let showPassword, showConfirmPassword;
let focus = false;
const toggleShowPassword = () => {
    showPassword = !showPassword;
};
const toggleShowConfirmPassword = () => {
    showConfirmPassword = !showConfirmPassword;
};
const passwordValid = (data) => {
    if (!enableValidate) {
        return password ? true : false;
    }
    if (!data)
        return false;
    for (let key in data) {
        if (!data[key].value)
            return false;
    }
    return true;
};
const onBlur = (e) => {
    focus = false;
    if (document.getElementById("tooltip-container"))
        document.getElementById("tooltip-container").classList.add("none");
};
const onFocus = (e) => {
    focus = true;
    if (document.getElementById("tooltip-container") &&
        !passwordValid($password_validity.message))
        document.getElementById("tooltip-container").classList.remove("none");
};
const getConfirmText = (validity) => {
    if (validity.valid) {
        return "Confirm New Password";
    }
    else {
        return "Passwords should match.";
    }
};
</script>

<div class="password-input-field-wrapper">
  <TextInput
    {placeholder}
    type={showPassword ? "text" : "password"}
    value={password}
    onChange={handlePasswordChange}
    validate={password_validate}
    validateValue={{ password, username: "username" }}
    error={getPasswordErrorMessage($password_validity)}
    icon={showPassword
      ? "/static/icons/icon-see.png"
      : "/static/icons/icon-hide.png"}
    onIconClick={toggleShowPassword}
    handleBlur={onBlur}
    handleFocus={onFocus}
  />
  {#if enableConfirm}
    <TextInput
      type={showConfirmPassword ? 'text' : 'password'} 
      placeholder={getConfirmText($confirm_password_validity)}
      value={confirm_password} 
      error={getConfirmPasswordErrorMessage($confirm_password_validity)}    
      onChange={handleConfirmpasswordChange}
      validateValue={{password, confirm_password}} 
      validate={confirm_password_validate}
      onIconClick={toggleShowConfirmPassword}
      icon={showConfirmPassword ? "/static/icons/icon-see.png" : "/static/icons/icon-hide.png"}    
  />
  {/if}

  {#if $password_validity.message && $password_validity.dirty && password.length > 0 && enableValidate}
    <div
      class="tooltip-container {passwordValid($password_validity.message) ? 'none' : ''}"
      id="tooltip-container"
    >
      <div class="custom-tooltip">
        <div class="tooltip-arrow" />
        <span class="tooltip-title"> Your password should contain:</span>
        <ul class="list-unstyled">
          {#each Object.values($password_validity.message) as message}
            {#if message && message.msg}
              <li class="text">
                <i class="f7-icons {message.value ? 'checked' : 'un-checked'}"
                  >{message.value ? "checkmark_alt" : "xmark"}</i
                >
                {message.msg}
              </li>
            {/if}
          {/each}
        </ul>
      </div>
    </div>
  {/if}
</div>

<style>.password-input-field-wrapper {
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.tooltip-title {
  flex-grow: 0;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #282c30;
}

.tooltip-arrow {
  width: 15px;
  height: 15px;
  bottom: -8px;
  background: white;
  position: absolute;
  transform: rotate(45deg);
}

.tooltip-container {
  width: 281px;
  padding-top: 10px;
  transition: all 0.3s;
  position: absolute;
  z-index: 1;
  left: calc(50% - 140.5px);
  box-shadow:  0 0 12px 7px #e1dede;
  bottom: calc(100% + 20px);
  background: white;
  border-radius: 8px;
}

.none {
  display: none;
}

ul.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0 !important;
}

.custom-tooltip {
  max-width: 768px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  background-color: white;
  position: relative;
}

li.text {
  flex-grow: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #282c30;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

li.text i {
  margin-right: 3px !important;
  font-size: 16px !important;
}

.checked {
  color: #0DDE4F;
}

.un-checked {
  color: red;
}

@media (max-width: 991px) {
  .tooltip-container {
    max-width: 254px;
    left: calc(50% - 127px);
  }

  li.text {
    margin-bottom: 10px;
  }

  .tooltip-title,
    li.text,
    li.text i {
    font-size: 12px;
  }
}</style>
