import { YinzCamSoccerAppServer } from './YinzCamSoccerAppServer';
import { YinzCamAPI, YinzCamAPIRequest } from 'yinzcam-api';
import { register } from 'yinzcam-registry';
import { APP_SERVER_REGISTRY } from './constants';
import { YinzCamAppServer } from './YinzCamAppServer';

@register(APP_SERVER_REGISTRY, 'nba')
export class YinzCamYCAppServer extends YinzCamAppServer {
  
  public constructor(api: YinzCamAPI) {
    console.log ('yinzcam yc server: ', api)
    super(api, '');
  }

  public test(): string {
    return 'This is test string';
  }

  public saveNewGroup(icon: string|number, name: string, participants: string[]) {
    return this.saveNewGroupRequest({ImageUrl: icon, Name: name, participants});
  }

  public saveNewChat(data: any) {
    return this.saveNewChatRequest(data);
  }

}
