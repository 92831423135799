/* eslint-disable */
import { CardsDataSourceBase } from '../../common/CardsDataSourceBase'
import { injectable } from 'inversify'
import { injectToken } from 'inversify-token'
import { YinzCamAPIResponse } from 'yinzcam-api'
import _ from 'lodash'
import { YinzCamAppServerToken } from 'yinzcam-appserver'
import { JSONSchema4 } from "json-schema";

@injectable()
export class YinzCamAppServerSocialAggregator extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  public getDisplayName(): string {
    return 'YinzCam Social Aggregator';
  }

  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    return [
      {
        name: 'Social Media Post List',
        path: '/Social/Aggregator'
      }
    ];
  }

  public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
    // TODO: Get list of configured social sources from backend
    return {
      type: "object",
      properties: {
        type: {
          type: "string",
          title: "Type",
          description: "The type of social media account. Choose 'Controlled by Filter Bar' if the card should respond to changes in filter bar on the same page.",
          enum: [ '{{filterPath}}', 'all', 'twitter', 'instagram', 'facebook' ],
          options: {
            enum_titles: [ 'Controlled by Filter Bar', 'All', 'Twitter', 'Instagram', 'Facebook' ]
          }
        }
      },
      required: [
        "type"
      ]
    };
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    const type: string = specData.type;
    const result = (!type || type === 'all') ? data?.posts : data?.posts?.filter(record => record?.source?.toLowerCase() === type.toLowerCase() );
    return result;
  }
}
