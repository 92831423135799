<script lang="ts">import LinkAccountCard from "../LinkAccountCard/LinkAccountCard.svelte";
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { Popup, Link } from 'framework7-svelte';
import { onMount } from 'svelte';
import { JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { getContext } from 'svelte';
export let element;
export let sources;
export let sourceStore;
const onConnect = () => {
};
</script>

<CardBase {element}>
    <LinkAccountCard title='Google' image={'/static/icons/icon-google.png'} onConnect={onConnect} {sources} />
</CardBase>