import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { YinzCamCardsServerV1, YinzCamCardsServerV1Token } from './YinzCamCardsServerV1';
import { YinzCamCardsServerV2, YinzCamCardsServerV2Token } from './YinzCamCardsServerV2';
import { YinzCamCardsServiceFragmentComponent } from './YinzCamCardsServiceFragmentComponent';
import { YinzCamCardsServiceMenuComponent } from './YinzCamCardsServiceMenuComponent';
import { YinzCamCardsServiceMessagesComponent } from './YinzCamCardsServiceMessagesComponent';
import { YinzCamCardsServicePageComponent } from './YinzCamCardsServicePageComponent';
import { YinzCamCardsServiceStyleComponent } from './YinzCamCardsServiceStyleComponent';

export const YinzCamCardsServiceToken = new Token<YinzCamCardsService>(Symbol.for("YinzCamCardsService"));

@injectable()
export class YinzCamCardsService {
  public constructor(
    @injectToken(YinzCamCardsServerV1Token) private readonly srv1: YinzCamCardsServerV1,
    @injectToken(YinzCamCardsServerV2Token) private readonly srv2: YinzCamCardsServerV2) {
  }

  public getCardsPage(id: string): YinzCamCardsServicePageComponent {
    //let srv1Input = this.srv1.getWaistband(id);
    let srv2Input = this.srv2.getPage(id);
    return new YinzCamCardsServicePageComponent(id, /*srv1Input,*/ srv2Input);
  }

  public getCardsFragment(id: string): YinzCamCardsServiceFragmentComponent {
    return new YinzCamCardsServiceFragmentComponent(id, this.srv2.getFragment(id));
  }

  public getMessages(lang: string): YinzCamCardsServiceMessagesComponent {
    return new YinzCamCardsServiceMessagesComponent(lang, this.srv2.getMessages(lang));
  }

  public getMenu(id: string): YinzCamCardsServiceMenuComponent {
    return new YinzCamCardsServiceMenuComponent(id, this.srv2.getMenu(id));
  }

  public getStyle(id: string): YinzCamCardsServiceStyleComponent {
    return new YinzCamCardsServiceStyleComponent(id, this.srv2.getStyle(id));
  }
}
