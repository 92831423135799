<!-- svelte-ignore missing-declaration -->
<svelte:head>
  <title>{CONFIG.defaultPageTitle}</title>
  <meta name="description" content={CONFIG.defaultPageDescription} />
  <meta name="keywords" content={CONFIG.defaultPageKeywords} />
</svelte:head>
<F7Page name={titleText || ''} noToolbar={_noBottomNav}>
  <!--<YCTopBar {titleText} {titleImageUrl} navBar={_navBar} backButton={_backButton} />-->
  <!-- Uses slots to let pages insert whatever content they want. See https://svelte.dev/tutorial/slots -->
  <slot></slot>
</F7Page>

<style></style>

<script lang="ts">import { Page as F7Page } from 'framework7-svelte';
import YCTopBar from './YCTopBar.svelte';
import { windowMetrics as wm } from '../js/stores';
import _ from 'lodash';
import { onMount } from 'svelte';
import { ycPreloadComplete } from '../js/ycapp';
// Declaring props: https://svelte.dev/tutorial/declaring-props
export let titleText;
export let titleImageUrl;
export let navBar;
export let backButton;
export let noBottomNav;
export let query;
let _navBar;
let _noBottomNav;
let _backButton;
$: {
    _navBar = true;
    if (!_.isNil(CONFIG.defaultNavBarVisible)) {
        _navBar = CONFIG.defaultNavBarVisible;
    }
    if (!_.isNil(navBar)) {
        _navBar = navBar;
    }
    else if (!_.isNil(query.navBar)) {
        _navBar = query.navBar === 'true';
    }
    else if ($wm.width >= 768) {
        _navBar = false;
    }
}
$: {
    _noBottomNav = false;
    if (!_.isNil(CONFIG.defaultBottomNavHidden)) {
        _noBottomNav = CONFIG.defaultBottomNavHidden;
    }
    if (!_.isNil(noBottomNav)) {
        _noBottomNav = noBottomNav;
    }
    else if (!_.isNil(query.noBottomNav)) {
        _noBottomNav = query.noBottomNav === 'true';
    }
    else if ($wm.width >= 768) {
        _noBottomNav = true;
    }
}
$: {
    _backButton = true;
    if (!_.isNil(backButton)) {
        _backButton = backButton;
    }
    else if (!_.isNil(query.backButton)) {
        _backButton = query.backButton === 'true';
    }
}
onMount(() => {
    setTimeout(() => {
        window.prerenderReady = true;
        ycPreloadComplete();
    }, 5000);
});
</script>
