import { Container } from "inversify";
import { TokenContainerModule } from "inversify-token";
import { YinzCamInjectModule } from "yinzcam-inject";
import { CardsLibraryRecord, CardsLibraryToken } from "../common/CardsLibraryToken";

import * as UnknownCard from "./UnknownCard/UnknownCard.svelte";
import * as PlaceholderCard from "./PlaceholderCard/PlaceholderCard.svelte";
import * as AdSlot from "./AdSlot/AdSlot.svelte";
import * as HTML from "./HTML/HTML.svelte";
import * as SectionHeading from "./SectionHeading/SectionHeading.svelte";
import * as SimpleCard from "./SimpleCard/SimpleCard.svelte";

// SSO22
import * as SSO22__AccountRegisterButton from './SSO22/AccountRegisterButton/AccountRegisterButton.svelte';
import * as SSO22__ProfileSaveButton from './SSO22/ProfileSaveButton/ProfileSaveButton.svelte';
import * as SSO22__ResetPasswordStartButton from './SSO22/ResetPasswordStartButton/ResetPasswordStartButton.svelte';
import * as SSO22__OpenEmailAppButton from './SSO22/OpenEmailAppButton/OpenEmailAppButton.svelte';
import * as SSO22__ProfilePhotoSaveButton from './SSO22/ProfilePhotoSaveButton/ProfilePhotoSaveButton.svelte';
import * as SSO22__AccountLogInButton from './SSO22/AccountLogInButton/AccountLogInButton.svelte';
import * as SSO22__ResetPasswordEndButton from './SSO22/ResetPasswordEndButton/ResetPasswordEndButton.svelte';
import * as SSO22__NameInputField from './SSO22/NameInputField/NameInputField.svelte';
import * as SSO22__EmailInputField from './SSO22/EmailInputField/EmailInputField.svelte';
import * as SSO22__PhoneNumberInputField from './SSO22/PhoneNumberInputField/PhoneNumberInputField.svelte';
import * as SSO22__BirthDateInputField from './SSO22/BirthDateInputField/BirthDateInputField.svelte';
import * as SSO22__UsernameInputField from './SSO22/UsernameInputField/UsernameInputField.svelte';
import * as SSO22__AddressInputField from './SSO22/AddressInputField/AddressInputField.svelte';
import * as SSO22__OptInCheckbox from './SSO22/OptInCheckbox/OptInCheckbox.svelte';
import * as SSO22__RememberMeCheckbox from './SSO22/RememberMeCheckbox/RememberMeCheckbox.svelte';
import * as SSO22__UserProfileHeader from './SSO22/UserProfileHeader/UserProfileHeader.svelte';
import * as SSO22__LinkCompleteHeader from './SSO22/LinkCompleteHeader/LinkCompleteHeader.svelte';
import * as SSO22__LinkTicketmasterAccount from './SSO22/LinkTicketmasterAccount/LinkTicketmasterAccount.svelte';
import * as SSO22__LinkGoogleAccount from './SSO22/LinkGoogleAccount/LinkGoogleAccount.svelte';
import * as SSO22__LinkAppleAccount from './SSO22/LinkAppleAccount/LinkAppleAccount.svelte';
import * as SSO22__LinkSeatGeekAccount from './SSO22/LinkSeatGeekAccount/LinkSeatGeekAccount.svelte';
import * as SSO22__LinkFacebookAccount from './SSO22/LinkFacebookAccount/LinkFacebookAccount.svelte';
import * as SSO22__LinkClearAccount from './SSO22/LinkClearAccount/LinkClearAccount.svelte';
import * as SSO22__LearnMore from './SSO22/LearnMore/LearnMore.svelte';
import * as SSO22__PasswordInputField from './SSO22/PasswordInputField/PasswordInputField.svelte';
import * as SSO22__NavigationButton from './SSO22/NavigationButton/NavigationButton.svelte';
import * as SSO22__ResetPasswordValidateButton from './SSO22/ResetPasswordValidateButton/ResetPasswordValidateButton.svelte';
import * as SSO22__PinInputField from './SSO22/PinInputField/PinInputField.svelte';
import * as SSO22__DisconnectAccount from "./SSO22/DisconnectAccount/DisconnectAccount.svelte";
import * as SSO22__LoginWithNFLIDAccountAuto from "./SSO22/LoginWithNFLIDAccountAuto/LoginWithNFLIDAccountAuto.svelte";
import * as Seahawks__ForcedUpdate from './Seahawks/ForcedUpdate/ForcedUpdate.svelte';
import _ from "lodash";

const CARD_LIBRARY: { [ key: string ]: CardsLibraryRecord } = {
  UnknownCard,
  PlaceholderCard,
  AdSlot,
  HTML,
  SectionHeading,
  SimpleCard,
  SSO22__AccountRegisterButton,
  SSO22__ProfileSaveButton,
  SSO22__ResetPasswordStartButton,
  SSO22__OpenEmailAppButton,
  SSO22__ProfilePhotoSaveButton,
  SSO22__AccountLogInButton,
  SSO22__ResetPasswordEndButton,
  SSO22__NameInputField,
  SSO22__EmailInputField,
  SSO22__PhoneNumberInputField,
  SSO22__BirthDateInputField,
  SSO22__UsernameInputField,
  SSO22__AddressInputField,
  SSO22__OptInCheckbox,
  SSO22__RememberMeCheckbox,
  SSO22__UserProfileHeader,
  SSO22__LinkCompleteHeader,
  SSO22__LinkTicketmasterAccount,
  SSO22__LinkAppleAccount,
  SSO22__LinkFacebookAccount,
  SSO22__LinkGoogleAccount,
  SSO22__LinkSeatGeekAccount,
  SSO22__LinkClearAccount,
  SSO22__LearnMore,
  SSO22__PasswordInputField,
  SSO22__NavigationButton,
  SSO22__ResetPasswordValidateButton,
  SSO22__PinInputField,
  SSO22__DisconnectAccount,
  SSO22__LoginWithNFLIDAccountAuto,
  Seahawks__ForcedUpdate,
};

export const CardsLibraryModule: YinzCamInjectModule = new YinzCamInjectModule((container: Container): void => {
  container.load(new TokenContainerModule((bindToken) => {
    for (let k in CARD_LIBRARY) {
      const clazz = k.replace(/__/g, '.');
      const rec = CARD_LIBRARY[k];
      rec.clazz = clazz;
      let filtered = false;
      if (!_.isNil(CONFIG.cardClassFilters) && clazz !== 'PlaceholderCard' && clazz !== 'UnknownCard') {
        filtered = true;
        for (let filterKey in CONFIG.cardClassFilters) {
          const filter = CONFIG.cardClassFilters[filterKey];
          if ((filter.slice(-1) === '*' && clazz.startsWith(filter.slice(0, -2))) || clazz === filter) {
            filtered = false;
            break;
          }
        }
      }
      if (!filtered) {
        bindToken(CardsLibraryToken).toConstantValue(rec).whenTargetNamed(clazz);
      }
    }
  }));
});
