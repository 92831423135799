import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";

@injectable()
export class YinzCamAppServerGameListFilter extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    let options;
    if ("month" === specData.grouping) {
      options = this.getMonthGrouping(data);
    }
    let secondaryOptions = this.getCompetitionOptions(data);
    return { options, secondaryOptions };
  }
  
  getMonthGrouping(data: any): any {
    let games = [].concat(data?.GameList?.GameSection?.Game).filter(item => item?._attributes?.Id);
    let months = _.uniq(games.map(game => new Date(game?.Date?._attributes?.Timestamp).getMonth()));
    return this._getMonthArray(months);
  }

  getCompetitionOptions(data: any): any {
    let filter = [].concat(data?.GameList?.Filters?.Filter).find(e => e?._attributes?.QueryParameter === 'compId');
    return [].concat(filter?.FilterItems?.Items).filter(item => item?._attributes?.Id).map(item => {
      let attrs = item?._attributes;
      return {
        title: attrs?.Name,
        value: attrs?.Id
      };
    });
  }

  _getMonthArray(gameMonths: any[]): any {
    const monthNames = [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic' ];
    const monthNamesMobile = [ 'Enero', 'Febrero', 'Marcha', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];
    // let firstMonth = gameMonths && gameMonths[0] || 8; // if there is no data, we always start september. 
    let firstMonth = 8; // we start always september
    let months = [];
    for (let i = 0; i <= 11; i += 1) {
      let currentMonth = (firstMonth + i) % 12; 
      let item = {
        title: monthNames[currentMonth],
        value: currentMonth.toString(),
        titleMobile: monthNamesMobile[currentMonth],
        isEmpty: gameMonths.indexOf(currentMonth) === -1
      }
      months.push(item);
    }

    return months;
  }
}
