<script lang="ts">import MaskInput from "svelte-input-mask";
export let onChange = (e) => { };
export let placeholder = "Placeholder";
export let label = "";
export let value = "";
export let error = "";
export let validate = "";
export let validateValue = "";
export let icon = "";
export let mask = "";
export let maskChar = "";
export let maskString = "";
export let type = "text";
export let pattern = null;
export let onIconClick = () => { };
export let readonly = false;
export let id = "";
export let className = "";
export let handleBlur = (e) => { };
export let handleFocus = (e) => { };
let visibleMask = true;
const toggleMask = (e) => {
    console.log('focus: ', e);
    visibleMask = e;
    // console.log('value: ', value)
};
const onBlur = (e) => {
    if (handleBlur) {
        handleBlur(e);
    }
};
const onFocus = (e) => {
    if (handleFocus) {
        handleFocus(e);
    }
};
</script>

<label for="" class="styled-input-box input" class:error={error}>
    {#if mask}
        <MaskInput 
            {type}
            {pattern}
            class="input__field input-outline {className}" 
            placeholder=" "
            showMask={visibleMask} 
            on:input={onChange} 
            on:change={onChange}
            on:focus={() => toggleMask(true)}
            on:blur={() => toggleMask(false)}
            autocapitalize={(type === 'email' || type === 'password') ? 'off' : ''}
            mask={mask} 
            maskChar={maskChar}
            {maskString}
            {readonly}
            {id}
        />
    {:else}
    {#if validate}
        <input 
            {type}
            {pattern}
            class="input__field input-outline {className}" 
            value={value} 
            autocapitalize={(type === 'email' || type === 'password') ? 'off' : ''}
            placeholder={" "} 
            on:input={onChange}  
            on:change={onChange}
            on:blur={onBlur}
            on:focus={onFocus}
            use:validate={validateValue}
            {readonly}
            {id}
        />
    {:else}
        <input
            {type}
            {pattern}
            class="input__field input-outline {className}" 
            value={value}
            autocapitalize={(type === 'email' || type === 'password') ? 'off' : ''}
            placeholder={" "} 
            on:input={onChange} 
            on:change={onChange}
            {readonly}
            {id}
            on:blur={onBlur}
            on:focus={onFocus}
        />
        {/if}
    {/if}
    {#if type ==="password" || (placeholder && !error)}
    <span class="label input__label">{placeholder}</span>
    {/if}
    {#if type!="password" && error}
    <span class="label input__label error">{error}</span>
    {/if}
    {#if icon}
    <img src={icon} alt="icon" class="icon" on:click={onIconClick} />
    {/if}
</label>

<style type="text/scss">@import url("https://rsms.me/inter/inter.css");

.styled-input-box {
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: block;
  position: relative;
}

:global(.styled-input-box input) {
  width: 100%;
  height: var(--yc-sso-text-input-height, 100%);
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  outline: none;
  font-size: 12px;
  z-index: 1;
  background: transparent;
}

.styled-input-box .label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  position: absolute;
  left: 16px;
  top: 14px;
}

.styled-input-box.error {
  border-color: #DE322F !important;
}

.styled-input-box .label.error {
  color: #DE322F !important;
}

.styled-input-box .icon {
  height: 24px;
  position: absolute;
  right: 16px;
  top: 8px;
  z-index: 2;
  cursor: pointer;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: black;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: black;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: black;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: black;
}

:root {
  --size-bezel: .5rem;
  --size-radius: 4px;
}

.input {
  position: relative;
  display: flex;
  align-items: center;
}

.input__label {
  background: pink;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: var(--color-background);
  transition: transform 120ms ease-in;
}

[dir="ltr"] .input__field{
  text-align: left;
}

[dir="rtl"] .input__field{
  text-align: right;
}

.input__field {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  transition: all .3s ease;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
}

.input__field:focus::-webkit-input-placeholder {
  color: var(--primary-light-color);
}

.input__field:focus::-moz-placeholder {
  color: var(--primary-light-color);
}

.input__field:focus::placeholder {
  color: var(--primary-light-color);
}

.input__field::-webkit-input-placeholder {
  color: transparent;
}

.input__field::-moz-placeholder {
  color: transparent;
}

.input__field::placeholder {
  color: transparent;
}

.input__field.input-outline {
  background-color: transparent;
  color: var(--primary-color);
}

.input__field:not(:-moz-placeholder-shown) + .input__label {
  position: absolute;
  transform: translate(0, -65%) scale(0.67);
  color: var(--primary-color);
  background: white;
  width: -moz-fit-content;
  width: fit-content;
}

.input__field:focus + .input__label, .input__field:not(:placeholder-shown) + .input__label {
  position: absolute;
  transform: translate(0, -65%) scale(0.67);
  color: var(--primary-color);
  background: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 540px) {
  :global(.input__field):not(:-moz-placeholder-shown) {
    margin-top: 10px;
  }
  :global(.input__field):focus, :global(.input__field):not(:placeholder-shown) {
    margin-top: 10px;
  }

  :global(.input__field):not(:-moz-placeholder-shown) + .input__label {
    position: absolute;
    transform: translate(0, -65%) scale(0.67);
    color: var(--primary-color);
    background: white;
    width: -moz-fit-content;
    width: fit-content;
  }

  :global(.input__field):focus + .input__label, :global(.input__field):not(:placeholder-shown) + .input__label {
    position: absolute;
    transform: translate(0, -65%) scale(0.67);
    color: var(--primary-color);
    background: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .input__label {
    font-size: 12px;
    top: -3px;
  }
}</style>