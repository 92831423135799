<script lang="ts">var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { resolveUrl } from '../../../../../js/url';
export let element;
export let sources;
export let sourceStore;
$: title = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.title) || "";
$: linkText = ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.linkText) || "Learn More";
$: linkHref = ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.linkHref) || "";
$: subTitle = ((_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.subTitle) || "";
$: description = ((_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.description) || "";
$: expandable = (_f = element === null || element === void 0 ? void 0 : element.data) === null || _f === void 0 ? void 0 : _f.expandable;
$: titleColor = ((_g = element === null || element === void 0 ? void 0 : element.data) === null || _g === void 0 ? void 0 : _g.titleColor) || "var(--sso22-learnmore-title-color, #000000)";
$: linkColor = ((_h = element === null || element === void 0 ? void 0 : element.data) === null || _h === void 0 ? void 0 : _h.linkColor) || "var(--sso22-learnmore-link-color, var(--alav-s-primary, #000000))";
$: subtitleColor = ((_j = element === null || element === void 0 ? void 0 : element.data) === null || _j === void 0 ? void 0 : _j.subtitleColor) || "var(--sso22-learnmore-subtitle-color, #000000)";
$: descriptionColor = ((_k = element === null || element === void 0 ? void 0 : element.data) === null || _k === void 0 ? void 0 : _k.descriptionColor) || "var(--sso22-learnmore-description-color, #8C959E)";
let expand = false;
const toggle = () => {
    if (!linkHref) {
        expand = !expand;
    }
};
const navigate = () => {
    if (linkHref) {
        resolveUrl(linkHref).func();
    }
    else {
        expand = !expand;
    }
};
</script>

<div class="learnmore-wrapper" class:expand={expand}>
    <div class="main">
        <span class="title" style="color: {titleColor};">{title}</span>
        {#if expandable !== false}
        <span class="collapse-info" style="color: {linkColor};" on:click={() => navigate()}>{expand ? 'Close' : linkText}</span>
        {/if}
    </div>
    {#if expandable !== false}
    <div class="more" class:visible={expand}>
      {#if subTitle}
      <span class="sub-title" style="color: {subtitleColor};">{subTitle}</span>
      {/if}
      {#if description}
      <span class="sub-description" style="color: {descriptionColor};">{description}</span>
      {/if}
    </div>
    {/if}
</div>

<style>.learnmore-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 52px;
  transition: all 0.3s;
}

:global(.learnmore-wrapper.expand) {
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}

.main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  flex-direction: column;
  gap: 5px;
}

.more {
  width: 100%;
  padding: 18px 22px;
  display: none;
  border-top: 1px solid #D8D8D8;
}

.more span {
  display: block;
}

:global(.more.visible) {
  display: block !important;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  /* identical to box height, or 83% */
  text-align: center;
}

.collapse-info {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  /* identical to box height, or 83% */
  text-align: center;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 2em;
  margin: -2em;
}

.sub-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 18px;
}

.sub-description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
}</style>
