<script lang="ts">var _a, _b;
import CardBase from '../../../common/CardBase.svelte';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import { Readable } from 'svelte/store';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import ROOT from '../../../../../inversify.config';
import TextInput from "../TextInput/TextInput.svelte";
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: isRequiredKey = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.isRequiredKey;
$: isRequired = isRequiredKey ? ($mergedParams || {})[isRequiredKey] : '';
let mask = "000-0000-0000";
let phoneNumber = '';
const handleChange = ({ detail }) => {
    phoneNumber = detail.inputState.maskedValue;
    console.log('phoneNumber: ', phoneNumber);
};
const placeholder = isRequired === 'true' ? "Phone Number*" : "Phone Number";
</script>
    <CardBase {element}>
        <div class="phonenumber-input-field-wrapper">
            <TextInput type="number" placeholder={placeholder} value={phoneNumber} mask={mask} maskChar={'X'} onChange={handleChange} pattern={'\\d*'} />
        </div>
    </CardBase>
    <style>.phonenumber-input-field-wrapper {
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
}</style>