<script lang="ts">import { Readable } from 'svelte/store';
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
export let image = '/static/icons/icon-ticketmaster.png';
export let title = "Ticketmaster";
export let connected = false;
export let onConnect = () => { };
export let sources;
$: linkSource = $sources.find((s) => { var _a; return ((_a = s.spec) === null || _a === void 0 ? void 0 : _a.class) === 'YinzCamSignonServerLinks'; });
let linksRefreshed = false;
$: if (linkSource && !linksRefreshed) {
    setTimeout(() => {
        linkSource.refresh();
    }, 0);
    linksRefreshed = true;
}
</script>

<div class="link-account-card-wrapper">
    <img src={image} alt="avatar" class="account-logo" />
    <span class="title">{title}</span>
    <button class="btn-connect" class:btn-connect-connected={connected}
        class:btn-connect-disconnected={!connected} on:click={onConnect}>Connect{#if connected}ed{/if}</button>
</div>

<style>.link-account-card-wrapper {
  height: 62px;
  width: 100%;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #D8D8D8;
  padding: 12px 16px;
}

.account-logo {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  border: 1px solid #D8D8D8;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  margin-left: 12px;
}

.btn-connect {
  width: 112px;
  height: 38px;
  border-radius: 8px;
  border: 1px solid var(--alav-s-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 10px;
  margin-left: auto;
}

.btn-connect-connected {
  color: white;
  background: var(--alav-s-primary);
}

.btn-connect-disconnected {
  color: var(--alav-s-primary);
  background: white;
}</style>