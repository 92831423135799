import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAdsServerToken } from "yinzcam-ads";

// NOTE: This does not work, at least not the defaultParams part. It needs debugged
// in DataSourceManager in order to be used.

// NOTE: This is NOT USED for implementing YC ads, yet.
@injectable()
export class YinzCamAdsServerBannerAds extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAdsServerToken) server) {
    super({ server, defaultPath: '/', defaultParams: { major: "{{path0}}"} });
  }

  public getDisplayName(): string {
    return "YinzCam Ads - Banner Ads";
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any;
    return data?.ads || {};
  }
}
