import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamDropsServer, YinzCamDropsServerToken, YinzCamDropsServiceDrop } from "yinzcam-drops";

@injectable()
export class YinzCamDropsServerCurrentDrop extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamDropsServerToken) server: YinzCamDropsServer) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    return response?.data as YinzCamDropsServiceDrop;
  }
}
