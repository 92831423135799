<div class="cc-wrapper">
  {#each buttons as button (button.id)}
  <div class="cc-button" class:cc-active={!button.showOnlyWhenActive || active} class:clickable={!!button.onClick} on:click={button.onClick || (() => {})}>
    {#if button.icon}
      <Icon size="16px" tooltip={(!button.showOnlyWhenActive || active)? button.tooltip : undefined} f7={button.icon} />
    {/if}
    {#if button.label}
      <span>{button.label}</span>
    {/if}
  </div>    
  {/each}
</div>

<style>*, *::before, *::after {
  box-sizing: border-box;
}

.cc-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 100%;
  padding-left: 3px;
  width: 100%;
  flex-wrap: wrap;
}

.cc-button {
  margin-right: 5px;
  transition: 0.3s;
  opacity: 1;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.cc-button.clickable {
  cursor: pointer;
}

.cc-button:not(.clickable) {
  cursor: not-allowed;
}

.cc-button:not(.cc-active) {
  margin-right: 0px;
  width: 0px;
  opacity: 0;
}</style>

<script lang="ts">import { Icon } from 'framework7-svelte';
export let active;
export let buttons = [];
</script>
