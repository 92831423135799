<CardBase {element} {sources}>
  <div style="
    width: {width || 'auto'};
    height: {height || 'auto'};
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    text-align: center;
  ">
    {title || `Select this element and select an element type on the left to customize.`}
  </div>
</CardBase>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">import { Readable } from "svelte/store";
import { YinzCamCardsServiceElement } from "yinzcam-cards";
import CardBase from "../../common/CardBase.svelte";
import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
export let element;
export let sources;
export let width = null;
export let height = null;
export let title = null;
</script>
