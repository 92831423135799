<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e;
import ActionButton from "../ActionButton/ActionButton.svelte";
import { CardsDataSourceRegistration } from '../../../common/CardsDataSourceRegistration';
import { YinzCamCardsServiceElement } from 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { findSourceStore, getHrefForMediaItem } from '../../../utilities';
import { Readable } from 'svelte/store';
import { CardsTabContext, CardsTabContextKey } from '../../../common/context';
import { CardsPageContext, CardsPageContextKey } from '../../../common/context';
import { getContext } from 'svelte';
import { resolveUrl } from '../../../../../js/url';
import { JanusSignonManagerToken } from "../../../../../js/sso";
import { getToken } from 'inversify-token';
import ROOT from '../../../../../inversify.config';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
const pageContext = getContext(CardsPageContextKey);
const signonManager = getToken(ROOT, JanusSignonManagerToken);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
$: emailKey = (_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.emailKey;
$: email = emailKey ? ($mergedParams || {})[emailKey] : '';
$: pinKey = (_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.pinKey;
$: pin = pinKey ? ($mergedParams || {})[pinKey] : '';
$: passwordKey = (_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.passwordKey;
$: password = passwordKey ? ($mergedParams || {})[passwordKey] : '';
$: validKey = (_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.validKey;
$: isValid = validKey ? ($mergedParams || {})[validKey] : 'false';
$: disabled = !(isValid === "true" && password);
const handleClick = (e) => __awaiter(void 0, void 0, void 0, function* () {
    var _f, _g;
    let result = yield signonManager.passwordResetEnd(email, pin, password);
    if (result.success) {
        resolveUrl(`Login`).func();
    }
    else {
        alert(((_g = (_f = result.data) === null || _f === void 0 ? void 0 : _f.UserMessage) === null || _g === void 0 ? void 0 : _g.Content) || 'An error occurred.');
    }
});
</script>

<CardBase {element}>
    <ActionButton onClick={handleClick} {disabled} title="Reset password"/>
</CardBase>
