<script lang="ts">export let onClick = (e) => {
    console.log('onCLick;');
};
export let title = 'Button';
export let disabled = false;
export let outline = false;
</script>

<button class="btn sso22-action-button" class:outline={outline} on:click={onClick} disabled={disabled}> {title} </button>

<style>.btn {
  width: 100%;
  height: 38px !important;
  border-radius: 8px;
  background: var(--alav-s-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: white;
  text-transform: uppercase;
  border-color: transparent;
  cursor: pointer;
  font-family: inherit;
}

.btn:disabled {
  background: #ccd0d1 !important;
}

:global(.sso22-action-button.outline) {
  color: var(--alav-s-primary) !important;
  background: white !important;
  border: 1px solid #F50D0D !important;
}</style>
