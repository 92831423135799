import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import { tdate } from "../../../../js/i18n"
import _ from "lodash";
import { JSONSchema4 } from "json-schema";

@injectable()
export class YinzCamAppServerGameList extends CardsDataSourceBase {

  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    let gameData;
    if (specData?.grouping == "month") {
      gameData = this.getGamesByMonth(data);
    } else {
      gameData = this.getGamesByIndividual(data);
    }
    return gameData;
  }

  public getDisplayName(): string {
    return 'YinzCam Sports Data - Game List with Filter';
  }

  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    return [
      {
        name: 'Game List',
        path: '/Game/List'
      }
    ];
  }

  public async getDataSourceConfigSpec(): Promise<JSONSchema4> {
    return null;
  }
  
  getGamesByMonth(data: any): any {
    const monthNames = [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic' ];
    const fullMonthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const team = {
      FullName: "Deportivo Alavés",
      Id: "11",
      LogoId: "ESP_ALA",
      Name: "Alavés",
      TeamPageAvailable: "false",
      TriCode: "ALA"
    };
    let games = [].concat(data?.GameList?.GameSection?.Game).filter(item => item?._attributes?.Id);
    games = games.map((game) => {
      game['Team']={"_attributes": team};
      return game;
    });
    let gameMonths = _.uniq(games.map(game => new Date(game?.Date?._attributes?.Timestamp).getMonth()));
    let months = this._getMonthArray(gameMonths);
    let gamesByMonths = _.groupBy(games, game => new Date(game?.Date?._attributes?.Timestamp).getMonth());
    return months.map(month => {
      return {
        title: monthNames[month],
        mobileTitle: fullMonthNames[month],
        data: gamesByMonths[month],
        year: this._getYear(games ? games[0]: '', month),
      };
    });
  }

  getGamesByIndividual(data: any): any {
    const Team: any = {
      FullName: data?.GameList?.Team?._attributes?.FullName,
      Id: data?.GameList?.Team?._attributes?.Id,
      Name: data?.GameList?.Team?._attributes?.Name,
      TriCode: data?.GameList?.Team?._attributes?.TriCode,
    }
    let games = [].concat(data?.GameList?.GameSection?.Game).filter(item => item?._attributes?.Id);
    let simpleGameData = games.map((game: any) => {
      const IsHome = game?._attributes?.Home ? JSON.parse(game?._attributes?.Home) : false
      const Opponent: any = {
        FullName: game?.Opponent?._attributes?.FullName,
        Id: game?.Opponent?._attributes?.Id,
        Name: game?.Opponent?._attributes?.Name,
        TriCode: game?.Opponent?._attributes?.TriCode,
      }

      let GameState = game?._attributes?.GameState
      let Result = game?._attributes?.Result
      let HomeScore: number = 0
      let AwayScore: number = 0
      if (Result != "") {
        let ScoringPlays = [].concat(game?.ScoringPlays?.ScoringPlay).filter(ScoringPlay => ScoringPlay?.Description);
        ScoringPlays.forEach(
          ScoringPlay => {
            let HomeScore = ScoringPlay?._attributes?.IsHome ? JSON.parse(ScoringPlay?._attributes?.IsHome) : false
            if (HomeScore) {
              HomeScore += 1
            } else {
              AwayScore += 1
            }
          }
        )
      } else {
        HomeScore = -1
        AwayScore = -1
      }

      return {
        Round: game?._attributes?.Round,
        Timestamp: game?.Date?._attributes?.Timestamp,
        HomeTeam: IsHome ? Team : Opponent,
        AwayTeam: IsHome ? Opponent : Team,
        HomeScore,
        AwayScore,
        GameState,
        Result,
        IsHome,
        Competition: {
          LogoUrl: game?.Competition?.LogoUrl?._text,
          Name: game?.Competition?._attributes?.Name,
        }
      }
    })

    return {
      SecondTeamSchedule: simpleGameData
    }
  }

  _getMonthArray(gameMonths: any[]): any {
    // let firstMonth = gameMonths && gameMonths[0] || 8; // if there is no game data, we always start from september
    let firstMonth = 8 // we always start from september
    let months = [];
    for (let i = 0; i <= 11; i += 1) {
      let currentMonth = (firstMonth + i) % 12; 
      months.push(currentMonth);
    }
    return months;
  }

  _getYear(firstGame, currentMonth) {
    if (!firstGame) return new Date().getFullYear();
    let firstMonth = new Date(firstGame?.Date?._attributes?.Timestamp).getMonth();
    let firstYear = new Date(firstGame?.Date?._attributes?.Timestamp).getFullYear();
    return currentMonth >= firstMonth ? firstYear : firstYear + 1;
  }
}
