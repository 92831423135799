import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";
import _ from "lodash";


@injectable()
export class YinzCamAppServerMatchList extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }
  protected processResponse(response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    let gameData = this.getPartidosList(data);

    return gameData;
  }
  getPartidosList(data: any): any {
    const team = {
      FullName: "Deportivo Alavés",
      Id: "11",
      LogoId: "ESP_ALA",
      Name: "Alavés",
      TeamPageAvailable: "false",
      TriCode: "ALA"
    };
    let games = [].concat(data?.GameList?.GameSection?.Game).filter(item => item?._attributes?.Id);
    games = games.map((game) => {
      game['Team']={"_attributes": team};
      return game;
    });
 
    return {data: games};
  }
}
