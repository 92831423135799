<Boundary>
  <CardsContainerWrapper {editorOptions}>
    {#each expandedArrays as array, i (array.id)}
      <div class:display-none={!(_.isUndefined(childContentAvailable[i]) || childContentAvailable[i])} style={array.style || ""}>
        <CardsArray {parentSequenceId} sequenceId={generateSequenceId('ARY', parentSequenceId, i)} {array} bind:contentAvailable={childContentAvailable[i]} updater={arrayUpdater.bind(null, i)}/>
      </div>
    {/each}
  </CardsContainerWrapper>
</Boundary>

<style>*, *::before, *::after {
  box-sizing: border-box;
}</style>

<script lang="ts">var _a;
import { YinzCamCardsServiceArray, YinzCamCardsServiceElement } from "yinzcam-cards";
import { expandRepeats, generateSequenceId, RepeatingObject } from "../utilities";
import CardsArray from "./CardsArray.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { CardsTabContext, CardsTabContextKey } from '../common/context';
import { getContext } from "svelte";
import _ from "lodash";
import { CardsEditorContainerOptions } from "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
export let parentSequenceId = "";
export let arrays;
export let contentAvailable = true;
export let updater;
const tabContext = getContext(CardsTabContextKey);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
let childContentAvailable = [];
$: {
    contentAvailable = childContentAvailable.some((item) => _.isUndefined(item) || item);
}
let expandedArrays = [];
let dndMode = false;
$: if (_.isArray(arrays) && !dndMode) {
    // TODO: may want to put this back in if need inline expansion of entire arrays
    /*
    let paramObj = {...$mergedParams}
    let actionObj = JSON.parse(paramObj['localEvent'] || "{}")
    let repeatAdd = {};
    if (actionObj.type === "INCREASE_REPEAT_COUNT") {
      repeatAdd = {
        'id': actionObj.id,
        'addCount': actionObj.value,
      }
    }
    */
    expandedArrays = expandRepeats(arrays /*, "", repeatAdd*/);
    //console.log("DND DONE EXPANDED ARRAYS", expandedArrays);
}
function arraysUpdater(newArrays) {
    // WARNING: This is very important. It is very easy to break drag and drop with this code.
    // The DND library will call this function when elements are moving. This means the array
    // will contain shadow (placeholder) elements. In this state, we can't push changes up to
    // the unexpanded array, because they will get expanded and mess things up. So we introduce
    // dndMode and set it here if any of the elements are shadow items, and clear it otherwise.
    // Even in DND mode we still need to update the expanded array so that the DOM stays in sync,
    // so we update currentLayout.elements directly in that case.
    dndMode = newArrays.some(arr => arr.isDndShadowItem);
    if (dndMode) {
        expandedArrays = newArrays;
    }
    else {
        arrays = newArrays.filter(arr => { var _a, _b; return !((_b = (_a = arr) === null || _a === void 0 ? void 0 : _a.__expandRepeats) === null || _b === void 0 ? void 0 : _b.cloned); });
        updater(arrays);
    }
}
function arrayUpdater(i, array) {
    var _a;
    if ((_a = array.__expandRepeats) === null || _a === void 0 ? void 0 : _a.cloned) {
        console.error("Attempt to update a cloned object!", array);
        return;
    }
    const idx = arrays.findIndex((a) => a.id === array.id);
    if (idx < 0) {
        console.error("Updated array not found in original arrays", array);
        return;
    }
    //console.log("updating original arrays at index", idx);
    arrays[idx] = array;
    updater(arrays);
    /*
    if ((<RepeatingObject>array).__expandRepeats?.cloned) {
      console.error("Attempt to update a cloned object!", array);
      return;
    }
    const idx = arrays.findIndex((a) =>  a.id === array.id);
    if (idx < 0) {
      console.error("Attempt to update a cloned object!", array);
      return;
    }
    arrays[idx] = array;
    */
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: expandedArrays,
    updater: arraysUpdater
};
</script>
